<p-card *ngIf="!EstCharge()">
	<div class="fluid formgrid grid">
		<div class="flex flex-column gap-2 col-12 text-center">
			<p-progressSpinner></p-progressSpinner>
		</div>
	</div>
</p-card>
<div class="card" *ngIf="EstCharge()">
<div class="fluid formgrid grid">
    <div class="flex flex-column gap-2 col-12">
        <label for="lastname">Boite d'outils</label>
                <p-dropdown [options]="boites" [(ngModel)]="selectionBoite" optionLabel="nom" [showClear]="true" appendTo="body"
                    filter="true" filterBy="nom"
                    placeholder="Sélectionner une boite d'outils...">
                    <ng-template pTemplate="selectedItem">
                        <div *ngIf="selectionBoite">
                            <div>{{selectionBoite.nom}}</div>
                        </div>
                    </ng-template>
                    <ng-template let-boite pTemplate="item">
                        <div>
                            <div>{{boite.nom}}</div>
                        </div>
                    </ng-template>
                </p-dropdown>
                <small class="p-error" *ngIf="submitted && !selectionBoite">La boite d'outils est obligatoire.</small>
    </div>
   
</div>
<div class="grid mt-2">
    <div class="col-12 text-right ">
        <button pButton pRipple label="Confirmer" icon="pi pi-save" class="p-button-primary"
        (click)="confirmer()"></button>
        <button pButton pRipple label="Annuler" icon="pi pi-ban" class="p-button-text" (click)="annuler()"></button>
    </div>
</div>
</div>
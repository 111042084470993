<p-card *ngIf="!EstCharge()">
	<div class="fluid formgrid grid">
		<div class="flex flex-column gap-2 col-12 text-center">
			<p-progressSpinner></p-progressSpinner>
		</div>
	</div>
</p-card>
<div class="card" *ngIf="EstCharge()">
<div class="fluid formgrid grid">
    <div class="flex flex-column gap-2 col-12">
        <label for="lastname">Emplacement</label>
                <p-dropdown [options]="emplacements" [(ngModel)]="selectionEmplacement" optionLabel="nom" [showClear]="true" appendTo="body"
                    filter="true" filterBy="nom"
                    placeholder="Sélectionner un emplacement...">
                    <ng-template pTemplate="selectedItem">
                        <div *ngIf="selectionEmplacement">
                            <div>{{selectionEmplacement.nom}} ({{selectionEmplacement.code}})</div>
                        </div>
                    </ng-template>
                    <ng-template let-emplacement pTemplate="item">
                        <div>
                            <div>{{emplacement.nom}} ({{emplacement.code}})</div>
                        </div>
                    </ng-template>
                </p-dropdown>
                <small class="p-error" *ngIf="submitted && !selectionEmplacement">Veuillez faire une sélection.</small>
    </div>
   
</div>
<div class="grid mt-2">
    <div class="col-12 text-right ">
        <button pButton pRipple label="Confirmer" icon="pi pi-save" class="p-button-primary"
        (click)="confirmer()"></button>
        <button pButton pRipple label="Annuler" icon="pi pi-ban" class="p-button-text" (click)="annuler()"></button>
    </div>
</div>
</div>
import { Component, ViewChild, ElementRef, ɵConsole } from "@angular/core"; import { Location } from "@angular/common";
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { BlockUIModule } from 'primeng/blockui';
import { MessageService } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { DocumentEntite } from 'src/app/entites/document';
import { ReparationEntite } from 'src/app/entites/reparation';
import { BaseComponent } from 'src/app/base/base-component';
import { Fournisseur } from 'src/app/entites/fournisseur';
import { ProfilService } from 'src/app/services/profil-service';
import { AppMainComponent } from 'src/app/app.main.component';
import { ItemService } from 'src/app/services/item-service';
import { AchatService } from 'src/app/services/achat-service';
import { ReparationService } from 'src/app/services/reparation-service';
import { FournisseurService } from 'src/app/services/fournisseur-service';
import { DonneesBaseService } from 'src/app/services/donnees-base-service';
import { EStatuts } from 'src/app/entites/enums/statuts';
import { TypeDocumentEntite } from 'src/app/entites/type-document';
import { DocumentService } from 'src/app/services/document-service';

export interface ParametresDetailDocument {
    document: DocumentEntite;
}

@Component({
    templateUrl: './detail-document.component.html'
})
export class DetailDocumentComponent extends BaseComponent {

    donnees: ParametresDetailDocument;
    
    document: DocumentEntite;

    typesDocument: TypeDocumentEntite[];
    submitted: boolean = false;

    constructor(
        profilService: ProfilService,
        route: ActivatedRoute, 
        routerService: Router,
        appMain: AppMainComponent, location: Location, 
        public itemService: ItemService,
        public achatService: AchatService,
        public reparationService: ReparationService,
        private documentService: DocumentService,
        public fournisseurService: FournisseurService,
        public messageService: MessageService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public sdb: DonneesBaseService) {
        super(sdb, profilService, route, routerService, appMain);
    }

    ngOnInit() {

        this.donnees = this.config.data;

        this.document = JSON.parse(JSON.stringify(this.donnees.document));
        this.document.date = new Date(this.document.date);
    
        this.typesDocument = this.sdb.TypesDocument();

        this.CompleterChargement();
    }

    annuler() {
        this.ref.close(null);
    }

    sauvegarder() {

        this.submitted = true;
        if (!this.valider()) {
            this.messageService.add({ key: 'detailDocument', severity: 'error', summary: 'Erreurs', detail: 'Veuillez corriger les erreurs de saisie.' });
            return;
        }

        this.Bloquer();

        this.documentService.ModifierDocument(this.document).then(x => {

            this.Debloquer();

            this.ref.close(this.document);
        }).catch(() => {
            this.ref.close(null);
        });
    }

    valider() {
        return this.document.date && 
        this.document.nom;
    }
}
<div class="fluid formgrid grid">
    <div class="flex flex-column gap-2 col-12">
        <label for="date">Plage de date pour le calcul des coûts</label>
        <div class="flex gap-2">
            <div class="flex flex-column gap-1">
                <p-calendar class="max-w-full" [(ngModel)]="dateDebut" [showWeek]="true" [showIcon]="true"
                    appendTo="body" />
                <small class="p-error" *ngIf="rechercheSubmitted && !dateDebut">La date de début est
                    obligatoire.</small>
            </div>
            <div class="flex flex-column gap-1">
                <p-calendar class="max-w-full" [(ngModel)]="dateFin" [showWeek]="true" [showIcon]="true"
                    appendTo="body" />
                <small class="p-error" *ngIf="rechercheSubmitted && !dateFin">La date de fin est obligatoire.</small>
            </div>
            <p-button label="Rechercher" icon="pi pi-search" (click)="rechercher()"></p-button>
        </div>
        <small>Cliquez pour rechercher tout les items de bon de commande dans la plage de date.</small>
    </div>
    <div class="col-12 mt-2">
        <p-table [value]="items" [loading]="loading" [showLoader]="false" [scrollable]="true" scrollHeight="500px"
            dataKey="itemBonCommandeId" rowGroupMode="subheader" groupRowsBy="item.numero">
            <ng-template pTemplate="caption">
                <h3 class="text-center m-0">Bons de commandes</h3>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th>Numéro Bon Commande</th>
                    <th>Date</th>
                    <th>Quantité</th>
                    <th>Prix</th>
                    <th>Total</th>
                    <th>Quantité sélectionnée</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item let-index="rowIndex">
                <tr>
                    <td>{{item.itemBonCommande?.bonCommande?.numero}} ({{item.item.description}})</td>
                    <td>{{item.date | date:'dd/MM/yyyy'}}</td>
                    <td>{{item.quantite}}</td>
                    <td>
                        <div *ngIf="item.itemBonCommande == null">
                            <p-inputNumber [(ngModel)]="item.prix" [showButtons]="false" [step]="0.01" [min]="0"
                                mode="currency" currency="CAD" locale="en-CA" />
                        </div>
                        <div *ngIf="item.itemBonCommande != null">
                            {{item.prix | currency:'CAD':'symbol-narrow':'1.2-2'}}
                        </div>
                    </td>
                    <td>{{item.quantite * item.prix | currency:'CAD':'symbol-narrow':'1.2-2'}}</td>
                    <td><p-inputNumber [(ngModel)]="item.quantiteRequise" [showButtons]="true" [step]="1" [min]="0"/></td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="5">Aucun item trouvé</td>
                </tr>
            </ng-template>
            <ng-template pTemplate="loadingbody">
                <tr>
                    <td colspan="5" class="text-center">
                        <p-progressSpinner></p-progressSpinner>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>

<div class="text-right mt-3">
    <button pButton pRipple label="Confirmer" icon="pi pi-save" class="p-button-primary" (click)="confirmer()"></button>
    <button pButton pRipple label="Annuler" icon="pi pi-ban" class="p-button-text" (click)="annuler()"></button>
</div>
<p-toast></p-toast>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<p-overlayPanel #panelNavigationDirecte>
	<ng-template pTemplate>
		<input #champNavigationDirecte id="numero" type="text" pInputText [(ngModel)]="numeroNavigationDirecte"
			(keyup.enter)="confirmerNavigationDirecte()">
	</ng-template>
</p-overlayPanel>

<p-card *ngIf="!EstCharge()">
	<div class="fluid formgrid grid">
		<div class="flex flex-column gap-2 my-2 col-12 text-center">
			<p-progressSpinner></p-progressSpinner>
		</div>
	</div>
</p-card>

<div *ngIf="EstCharge()" class="grid">

	<div class="col-12">
		<div class="card">
			<h5>{{item.description}} ({{item.numero}})
				<span [class]="'statut ' + item.statut.nom.toLowerCase()">
					{{item.statut.nom}}
				</span>
				<app-bouton-favori [titrePage]="titrePage"></app-bouton-favori>
				<span *ngIf="modeApercu">
					<i class="pi pi-id-card ml-4"></i>
					<a class="lienFicheComplete" (click)="ouvrirFicheComplete()">Aller à la fiche complète</a>
				</span>
			</h5>

			<h6 *ngIf="item.estLie" style="margin-top: .5rem;">
				<i class="pi pi-link ml-3" style="font-size: 1rem"></i>
				{{item.parent}}
				<i class="pi pi-eye ml-3" style="font-size: 1rem; color: #689F38; cursor: pointer;"
					(click)="ouvrirItemLie($event)"></i>
			</h6>



			<p-menubar autoDisplay="false" [model]="menu" *ngIf="!modeApercu">
				<p-button *ngIf="estAutorise('item_modifier') && modeConsultation()" (click)="btnModifier_click()"
					icon="pi pi-pencil" styleClass="p-button-primary"></p-button>
				<p-button *ngIf="modeAjoutModification()" (onClick)="enregistrerModifications()" label
					icon="pi pi-check" styleClass="p-button-success mr-2">
				</p-button>
				<p-button *ngIf="modeAjoutModification()" (onClick)="annulerModification($event)" icon="pi pi-times"
					styleClass="p-button-secondary"></p-button>
			</p-menubar>
		</div>
	</div>


</div>


<div *ngIf="EstCharge()" class="grid">
	<div class="col-12">
		<div class="card">



			<p-tabView [scrollable]="true" #tabView [(activeIndex)]="tabIndex" orientation="left" (onChange)="surChangementOnglet($event)">


				<!-- #region Infos générales  -->
				<p-tabPanel header="Informations générales" leftIcon="pi pi-info-circle">
					<!-- <div class="col-12 text-right">
						<p-button label="Modifier" icon="pi pi-pencil" iconPos="left"></p-button>
					</div> -->
					<div class="fluid formgrid grid">

						<div class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-3">
							<label for="firstname">Numéro<span class="requis">*</span><app-navigation-directe type="1" *ngIf="!modeApercu"></app-navigation-directe></label>
							<input id="firstname" type="text" pInputText [readonly]="true" [(ngModel)]="item.numero">
						</div>
						<div class="flex flex-column gap-2 my-2 col-12 md:col-12 lg:col-6">
							<label for="lastname">Description<span class="requis">*</span></label>
							<input id="lastname" type="text" pInputText [readonly]="modeConsultation()"
								[(ngModel)]="item.description">
							<small class="p-error" *ngIf="submitted && !item.description">La description
								est
								obligatoire.</small>
						</div>
						<div *ngIf="item.categorie.estUnique" class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-3">
							<label for="compagnie">Compagnie<span class="requis">*</span></label>
							<p-dropdown [options]="listeCompagnies" [(ngModel)]="item.compagnie" optionLabel="nom"
								[readonly]="modeConsultation()" appendTo="body" filter="true" filterBy="nom"
								placeholder="Sélectionnez une compagnie...">
								<ng-template pTemplate="selectedItem">
									<div *ngIf="item.compagnie">
										<div>{{item.compagnie.nom}}</div>
									</div>
								</ng-template>
								<ng-template let-compagnie pTemplate="item">
									<div>
										<div>{{compagnie.nom}}</div>
									</div>
								</ng-template>
							</p-dropdown>
							<small class="p-error" *ngIf="submitted && !item.compagnie">La compagnie
								est
								obligatoire.</small>
						</div>
						<div class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-3">
							<label for="lastname">Catégorie<span class="requis">*</span></label>

							<p-dropdown [(ngModel)]="item.categorie" inputId="categorie" [options]="listeCategories"
								(onChange)="surSelectionCategorie()" [readonly]="modeConsultation()" optionLabel="nom"
								placeholder="Sélectionnez..." appendTo="body">
								<!-- 	<ng-template let-option pTemplate="item">
									<span>{{option.nom}}</span>
								</ng-template> -->
								<ng-template pTemplate="selectedItem">
									<div *ngIf="item.categorie">
										<div>{{item.categorie.nom}}</div>
									</div>
								</ng-template>
								<ng-template let-option pTemplate="item">
									<div>
										<div>{{option.nom}}</div>
									</div>
								</ng-template>
							</p-dropdown>
							<small class="p-error" *ngIf="submitted && !item.categorie">La catégorie est
								obligatoire.</small>
						</div>
						<div class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-3">
							<label for="lastname">Sous-catégorie</label>
							<p-dropdown [options]="listeSousCategories" [(ngModel)]="item.sousCategorie"
								optionLabel="nom" appendTo="body" filter="true" filterBy="nom"
								[readonly]="modeConsultation()" placeholder="Sélectionnez une sous-catégorie...">
								<ng-template pTemplate="selectedItem">
									<div *ngIf="item.sousCategorie">
										<div>{{item.sousCategorie.nom}}</div>
									</div>
								</ng-template>
								<ng-template let-sousCategorie pTemplate="item">
									<div>
										<div>{{sousCategorie.nom}}</div>
									</div>
								</ng-template>
							</p-dropdown>
						</div>
						<div class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-3">
							<label for="marque">Marque</label>
							<input id="marque" type="text" pInputText [readonly]="modeConsultation()"
								[(ngModel)]="item.marque">
						</div>
						<div class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-3">
							<label for="modele">Modèle</label>
							<input id="modele" type="text" pInputText [readonly]="modeConsultation()"
								[(ngModel)]="item.modele">
						</div>
						<div *ngIf="item.categorie.estUnique" class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-3">
							<label for="emplacement">Emplacement courant</label>
							<input id="emplacement" type="text" pInputText readonly="true"
								value="{{item.emplacement?.nom}}">
						</div>
						<div class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-3">
							<label for="lastname">Localisation entrepot</label>
							<p-dropdown [(ngModel)]="item.localisation" inputId="localisation"
								[options]="listeLocalisations" [readonly]="modeConsultation()" optionLabel="nom"
								placeholder="Sélectionnez..." appendTo="body">
								<ng-template pTemplate="selectedItem">
									<div *ngIf="item.localisation">
										<div>{{item.localisation.nom}}</div>
									</div>
								</ng-template>
								<ng-template let-option pTemplate="item">
									<div>
										<div>{{option.nom}}</div>
									</div>
								</ng-template>
							</p-dropdown>
						</div>
						<div *ngIf="item.indSuiviMecanique" class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-3">
							<label for="compagnie">Département</label>
							<p-dropdown [options]="listeDepartementsMecanique" [(ngModel)]="item.departementMecanique" optionLabel="nom"
								[readonly]="modeConsultation()" appendTo="body" filter="true" filterBy="nom"
								placeholder="Sélectionnez un département...">
								<ng-template pTemplate="selectedItem">
									<div *ngIf="item.departementMecanique">
										<div>{{item.departementMecanique.nom}}</div>
									</div>
								</ng-template>
								<ng-template let-departement pTemplate="item">
									<div>
										<div>{{departement.nom}}</div>
									</div>
								</ng-template>
							</p-dropdown>
						</div>
						<div *ngIf="item.categorie.estFacturable" class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-3">
							<label for="lastname">Type de facturation</label>
							<p-dropdown [(ngModel)]="item.typeFacturation" inputId="typeFacturation"
								[options]="listeTypesFacturation" [readonly]="modeConsultation()" optionLabel="nom"
								placeholder="Sélectionnez..." appendTo="body">
								<ng-template pTemplate="selectedItem">
									<div *ngIf="item.typeFacturation">
										<div>{{item.typeFacturation.nom}}</div>
									</div>
								</ng-template>
								<ng-template let-option pTemplate="item">
									<div>
										<div>{{option.nom}}</div>
									</div>
								</ng-template>
							</p-dropdown>
						</div>
						<div *ngIf="item.categorie.estFacturable" class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-3">
							<label for="noCtrl">Numéro CTRL</label>
							<input id="noCtrl" type="text" pInputText [readonly]="modeConsultation()"
								[(ngModel)]="item.noCtrl">
						</div>
					</div>

					<div *ngIf="item.indSuiviMecanique && !modeConsultation()" class="fluid formgrid grid">

						<div class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-3">
							<label for="raison">Suivi des heures</label>
							<p-checkbox [binary]="true" [(ngModel)]="item.indSuiviHeures" style="display: block;"></p-checkbox>
						</div>
						
						<div class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-3">
							<label for="raison">Suivi des KM</label>
							<p-checkbox [binary]="true" [(ngModel)]="item.indSuiviKms" style="display: block;"></p-checkbox>
						</div>
					</div>

					<div class="fluid formgrid grid" *ngIf="item.statut.statutId == 2">

						<div class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-3">
							<label for="raison">Raison d'archivage</label>
							<input id="raison" type="text" pInputText readonly="true"
								value="{{item.raisonArchive?.nom}}">
						</div>
						<div class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-9">
							<label for="commentaire">Commentaire d'archivage</label>
							<input id="commentaire" type="text" pInputText readonly="true"
								[(ngModel)]="item.commentaireArchive">
						</div>
					</div>

					<div [class.modificationAttributs]="attributsEnModification">

						<h5>Attributs supplémentaires <span
								style="font-size: 1rem;">({{nbAttributsRenseignes}}/{{nbAttributsTotal}})</span>
							<p-button
								*ngIf="estAutorise('item_modifier') && chargementCompleteAttributs && !attributsEnModification && !modeApercu"
								(click)="modifierAttributs()" icon="pi pi-pencil"
								styleClass="p-button-primary p-button-outlined p-button-sm ml-3"></p-button>
							<p-button *ngIf="chargementCompleteAttributs && attributsEnModification"
								(onClick)="enregistrerAttributs()" label icon="pi pi-check"
								styleClass="p-button-success ml-3 mr-2">
							</p-button>
							<p-button *ngIf="chargementCompleteAttributs && attributsEnModification"
								(onClick)="annulerAttributs()" icon="pi pi-times" styleClass="p-button-secondary">
							</p-button>
						</h5>

						<p-card *ngIf="!chargementCompleteAttributs">
							<div class="fluid formgrid grid">
								<div class="flex flex-column gap-2 my-2 col-12 text-center">
									<p-progressSpinner></p-progressSpinner>
								</div>
							</div>
						</p-card>

						<p-table *ngIf="chargementCompleteAttributs" [value]="attributs" dataKey="valeurAttributId"
							tableStyleClass="tableMoyen">
							<ng-template pTemplate="header">
								<tr>
									<th>Attribut</th>
									<th>Valeur</th>
								</tr>
							</ng-template>
							<ng-template pTemplate="body" let-valeurattr>
								<tr *ngIf="attributsEnModification || valeurattr.valeur">
									<td>{{valeurattr.attribut.nom}}<span
											*ngIf="attributsEnModification && valeurattr.attribut.indObligatoire"
											class="requis">*</span></td>
									<td *ngIf="!attributsEnModification && valeurattr.attribut.typeChamp == 1">
										{{valeurattr.valeur}}</td>
									<td *ngIf="!attributsEnModification && valeurattr.attribut.typeChamp == 2">
										{{valeurattr.valeur}}</td>
									<td *ngIf="!attributsEnModification && valeurattr.attribut.typeChamp == 3">
										{{valeurattr.valeur}}</td>
									<td *ngIf="!attributsEnModification && valeurattr.attribut.typeChamp == 4">
										{{valeurattr.valeur | date:'yyyy-MM-dd'}}</td>
									<td *ngIf="!attributsEnModification && valeurattr.attribut.typeChamp == 5">
										{{valeurattr.valeur}}</td>
									<td pEditableColumn *ngIf="attributsEnModification">
										<p-cellEditor>
											<ng-template pTemplate="input">
												<input *ngIf="valeurattr.attribut.typeChamp == 1" pInputText type="text"
													[(ngModel)]="valeurattr.valeur" style="width: 100%;">
												<p-inputNumber *ngIf="valeurattr.attribut.typeChamp == 2"
													[(ngModel)]="valeurattr.valeur" inputStyle="width: 100%;">
												</p-inputNumber>
												<p-inputNumber *ngIf="valeurattr.attribut.typeChamp == 3"
													[(ngModel)]="valeurattr.valeur" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="5"
													inputStyle="width: 100%;"></p-inputNumber>
												<p-calendar *ngIf="valeurattr.attribut.typeChamp == 4" appendTo="body"
													showIcon=true [(ngModel)]="valeurattr.valeur"
													dateFormat="yyyy-MM-dd"></p-calendar>
												<p-checkbox *ngIf="valeurattr.attribut.typeChamp == 5" [binary]="true"
													[(ngModel)]="valeurattr.valeur"></p-checkbox>
											</ng-template>
											<ng-template pTemplate="output">
												{{valeurattr.valeur}}
											</ng-template>
										</p-cellEditor>
									</td>
								</tr>
							</ng-template>
							<ng-template pTemplate="emptymessage">
								<tr>
									<td colspan="2">Aucun attribut supplémentaire renseigné.</td>
								</tr>
							</ng-template>
						</p-table>

					</div>

				</p-tabPanel>
				<!-- #endregion -->
				<!-- #region Inventaire -->
				<p-tabPanel *ngIf="estAutorise('item_inventaire') && !item.categorie.estUnique" header="Inventaire"
					leftIcon="pi pi-list">
					<p-menu #menuContextuelInventaire popup="popup" [model]="contenuMenuContextuelInventaire"></p-menu>

					<p-card *ngIf="!chargementCompleteInventaire">
						<div class="fluid formgrid grid">
							<div class="flex flex-column gap-2 my-2 col-12 text-center">
								<p-progressSpinner></p-progressSpinner>
							</div>
						</div>
					</p-card>

					<p-table *ngIf="chargementCompleteInventaire" [value]="item.inventaire" autoLayout="true"
						tableStyleClass="tableNormale" dataKey="inventaireId">
						<ng-template pTemplate="header">
							<tr>
								<th>Code</th>
								<th>Nom</th>
								<th>Quantité</th>
								<th style="min-width: 5rem; width: 5rem;"></th>
							</tr>
						</ng-template>
						<ng-template pTemplate="body" let-inventaire>
							<tr [pContextMenuRow]="inventaire">
								<td>{{inventaire.emplacement.code}}</td>
								<td>{{inventaire.emplacement.nom}}</td>
								<td>{{inventaire.quantite}}</td>
								<td *ngIf="!modeApercu">
									<button
										*ngIf="this.estAutorise('emplacement_detail') || this.estAutorise('item_ajuster_inventaire')"
										pButton pRipple icon="pi pi-bars" class="p-button-secondary mr-2 boutonGrille"
										(click)="surMenuContextuelInventaire($event, inventaire)"></button>
								</td>
							</tr>
						</ng-template>
						<ng-template pTemplate="emptymessage">
							<tr>
								<td colspan="3">Aucun inventaire.</td>
							</tr>
						</ng-template>
					</p-table>
				</p-tabPanel>
				<!-- #endregion -->
				<!-- #region Historique -->
				<p-tabPanel *ngIf="estAutorise('item_historique')"
					[header]="'Historique (' + item.nbEntreesHistorique + ')'" leftIcon="pi pi-calendar">
					<p-menu #menuContextuelHistorique popup="popup" [model]="contenuMenuContextuelHistorique"></p-menu>

					<p-card *ngIf="!chargementCompleteHistorique">
						<div class="fluid formgrid grid">
							<div class="flex flex-column gap-2 my-2 col-12 text-center">
								<p-progressSpinner></p-progressSpinner>
							</div>
						</div>
					</p-card>

					<p-table *ngIf="chargementCompleteHistorique" #dtHistorique tableStyleClass="tableNormale"
						[value]="item.historique" autoLayout="true" dataKey="id"
						[globalFilterFields]="['requisition.numero','requisition.de.code','requisition.de.nom','requisition.a.code', 'requisition.a.nom', 'requisition.demandeur']">
						<ng-template pTemplate="caption">
							<div class="flex p-flex-column p-flex-md-row p-jc-md-between table-header">
								<span class="p-input-icon-left">
									<i class="pi pi-search"></i>
									<input pInputText type="text"
										(input)="dtHistorique.filterGlobal($event.target.value, 'contains')"
										placeholder="Recherche..." />
								</span>
							</div>
						</ng-template>
						<ng-template pTemplate="header">
							<tr>
								<th pSortableColumn="requisition.numero">
									<div class="flex justify-content-between align-items-center">
										Numéro
										<p-sortIcon field="requisition.numero"></p-sortIcon>
										<p-columnFilter type="text" field="requisition.numero" display="menu"
											class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="requisition.date">
									<div class="flex justify-content-between align-items-center">
										Date
										<p-sortIcon field="requisition.date"></p-sortIcon>
										<p-columnFilter type="requisition.date" field="date" display="menu"
											class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="requisition.type">
									<div class="flex justify-content-between align-items-center">
										Type
										<p-sortIcon field="requisition.type"></p-sortIcon>
										<p-columnFilter type="text" field="requisition.type" display="menu"
											class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="requisition.de.nom">
									<div class="flex justify-content-between align-items-center">
										De
										<p-sortIcon field="requisition.de.nom"></p-sortIcon>
										<p-columnFilter type="text" field="requisition.de.nom" display="menu"
											class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="requisition.a.nom">
									<div class="flex justify-content-between align-items-center">
										A
										<p-sortIcon field="requisition.a.nom"></p-sortIcon>
										<p-columnFilter type="text" field="requisition.a.nom" display="menu"
											class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="requisition.quantite">
									<div class="flex justify-content-between align-items-center">
										Quantité
										<p-sortIcon field="requisition.quantite"></p-sortIcon>
										<p-columnFilter type="text" field="requisition.quantite" display="menu"
											class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th *ngIf="!modeApercu" style="min-width: 5rem; width: 5rem;"></th>
							</tr>
						</ng-template>
						<ng-template pTemplate="body" let-historique>
							<tr [pContextMenuRow]="historique">
								<td>
									{{historique.requisition.numero}}
								</td>
								<td>
									{{historique.requisition.date | date:'yyyy-MM-dd'}}
								</td>
								<td>
									{{historique.requisition.type.nom}}
								</td>
								<td>
									{{historique.requisition.de.nom}} ({{historique.requisition.de.code}})
								</td>
								<td>
									{{historique.requisition.a.nom}} ({{historique.requisition.a.code}})
								</td>
								<td>
									{{historique.quantite}}
								</td>
								<td *ngIf="!modeApercu">
									<button *ngIf="estAutorise('requisition_detail')" pButton pRipple icon="pi pi-bars"
										class="p-button-secondary mr-2 boutonGrille"
										(click)="surMenuContextuelHistorique($event, historique)"></button>
								</td>
							</tr>
						</ng-template>
						<ng-template pTemplate="emptymessage">
							<tr>
								<td colspan="6">Aucun historique.</td>
							</tr>
						</ng-template>
					</p-table>
				</p-tabPanel>
				<!-- #endregion -->
				<!-- #region Heures -->
				<p-tabPanel *ngIf="estAutorise('item_heures') && item.categorie.estUnique" header="Heures/KM"
					leftIcon="pi pi-clock">
					<p-menu #menuContextuelReleveHeure popup="popup" [model]="contenuMenuContextuelReleveHeure">
					</p-menu>
					<p-menu #menuContextuelReleveKm popup="popup" [model]="contenuMenuContextuelReleveKm">
					</p-menu>

					<p-card *ngIf="!chargementCompleteRelevesHeure">
						<div class="fluid formgrid grid">
							<div class="flex flex-column gap-2 my-2 col-12 text-center">
								<p-progressSpinner></p-progressSpinner>
							</div>
						</div>
					</p-card>

					<div *ngIf="chargementCompleteRelevesHeure">
						<p-table #dtHeures [value]="item.relevesHeure" dataKey="releveHeureId"
							tableStyleClass="tableNormale" autoLayout="true" styleClass="p-datatable-striped"
							[rowHover]="true" [filterDelay]="0" [globalFilterFields]="['date','nbHeure']">
							<ng-template pTemplate="caption">
								<div class="flex align-content-center justify-content-between">
									<div>
										<span class="p-input-icon-left">
											<i class="pi pi-search"></i>
											<input pInputText type="text"
												(input)="dtHeures.filterGlobal($event.target.value, 'contains')"
												placeholder="Recherche..." />
										</span>
										<!-- <button *ngIf="estAutorise('item_heure_creer') && !modeApercu" pButton pRipple
											icon="pi pi-plus"
											class="p-button-rounded p-button-success p-button-sm mb-2 ml-4"
											(click)="ajouterReleveHeure()"></button> -->

									</div>
									<h5 class="titreTableau m-0">Heures</h5>
									<div>
										<p-multiSelect disabled="true" [style]="{minWidth: '200px'}"
											placeholder="Colonnes..."></p-multiSelect>
									</div>
								</div>
							</ng-template>
							<ng-template pTemplate="header">
								<tr>
									<th pSortableColumn="date">
										<div class="flex justify-content-between align-items-center">
											Date
											<p-sortIcon field="date"></p-sortIcon>
											<p-columnFilter type="date" field="date" display="menu" class="ml-auto">
											</p-columnFilter>
										</div>
									</th>
									<th pSortableColumn="nbHeure">
										<div class="flex justify-content-between align-items-center">
											Nb Heures
											<p-sortIcon field="nbHeure"></p-sortIcon>
											<p-columnFilter type="numeric" field="nbHeure" display="menu"
												class="ml-auto">
											</p-columnFilter>
										</div>
									</th>
									<th pSortableColumn="employe.nom">
										<div class="flex justify-content-between align-items-center">
											Saisi par
											<p-sortIcon field="employe.nom"></p-sortIcon>
											<p-columnFilter type="text" field="employe.nom" display="menu"
												class="ml-auto">
											</p-columnFilter>
										</div>
									</th>
									<th *ngIf="!modeApercu" style="min-width: 5rem; width: 5rem;"></th>
								</tr>
							</ng-template>
							<ng-template pTemplate="body" let-heure>

								<tr>
									<td>{{heure.date | date:'yyyy-MM-dd'}}</td>
									<td>{{heure.nbHeure}}</td>
									<td>{{heure.employe | nomEmploye}}</td>

									<td *ngIf="!modeApercu">
										<button
											*ngIf="estAutorise('item_heure_modifier') || estAutorise('item_heure_supprimer')"
											pButton pRipple icon="pi pi-bars"
											class="p-button-secondary mr-2 boutonGrille"
											(click)="surMenuContextuelReleveHeure($event, heure)"></button>
									</td>
								</tr>
							</ng-template>
							<ng-template pTemplate="emptymessage">
								<tr>
									<td colspan="4">Aucun relevé d'heures.</td>
								</tr>
							</ng-template>
						</p-table>
						<br>
						<p-table #dtKms [value]="item.relevesKm" dataKey="releveKmId" tableStyleClass="tableNormale"
							autoLayout="true" styleClass="p-datatable-striped" [rowHover]="true" [filterDelay]="0"
							[globalFilterFields]="['date','nbKm']">
							<ng-template pTemplate="caption">
								<div class="flex align-content-center justify-content-between">
									<div>
										<span class="p-input-icon-left">
											<i class="pi pi-search"></i>
											<input pInputText type="text"
												(input)="dtKms.filterGlobal($event.target.value, 'contains')"
												placeholder="Recherche..." />
										</span>
										<!-- <button *ngIf="estAutorise('item_heure_creer') && !modeApercu" pButton pRipple
											icon="pi pi-plus"
											class="p-button-rounded p-button-success p-button-sm mb-2 ml-4"
											(click)="ajouterReleveKm()"></button> -->

									</div>
									<h5 class="titreTableau m-0">Kms</h5>
									<div>
										<p-multiSelect disabled="true" [style]="{minWidth: '200px'}"
											placeholder="Colonnes..."></p-multiSelect>
									</div>
								</div>
							</ng-template>
							<ng-template pTemplate="header">
								<tr>
									<th pSortableColumn="date">
										<div class="flex justify-content-between align-items-center">
											Date
											<p-sortIcon field="date"></p-sortIcon>
											<p-columnFilter type="date" field="date" display="menu" class="ml-auto">
											</p-columnFilter>
										</div>
									</th>
									<th pSortableColumn="nbKm">
										<div class="flex justify-content-between align-items-center">
											Nb Km
											<p-sortIcon field="nbKm"></p-sortIcon>
											<p-columnFilter type="numeric" field="nbKm" display="menu"
												class="ml-auto">
											</p-columnFilter>
										</div>
									</th>
									<th pSortableColumn="employe.nom">
										<div class="flex justify-content-between align-items-center">
											Saisi par
											<p-sortIcon field="employe.nom"></p-sortIcon>
											<p-columnFilter type="text" field="employe.nom" display="menu"
												class="ml-auto">
											</p-columnFilter>
										</div>
									</th>
									<th *ngIf="!modeApercu" style="min-width: 5rem; width: 5rem;"></th>
								</tr>
							</ng-template>
							<ng-template pTemplate="body" let-km>

								<tr>
									<td>{{km.date | date:'yyyy-MM-dd'}}</td>
									<td>{{km.nbKm}}</td>
									<td>{{km.employe | nomEmploye}}</td>

									<td *ngIf="!modeApercu">
										<button
											*ngIf="estAutorise('item_heure_modifier') || estAutorise('item_heure_supprimer')"
											pButton pRipple icon="pi pi-bars"
											class="p-button-secondary mr-2 boutonGrille"
											(click)="surMenuContextuelReleveKm($event, km)"></button>
									</td>
								</tr>
							</ng-template>
							<ng-template pTemplate="emptymessage">
								<tr>
									<td colspan="4">Aucun relevé de KM.</td>
								</tr>
							</ng-template>
						</p-table>
					</div>
				</p-tabPanel>
				<!-- #endregion -->
				<!-- #region Fournisseurs -->
				<p-tabPanel *ngIf="estAutorise('item_fournisseurs') && !item.categorie.estUnique"
					[header]="'Fournisseurs (' + item.nbEntreesFournisseurs + ')'" leftIcon="pi pi-user">
					<p-menu #menuContextuelFournisseur popup="popup" [model]="contenuMenuContextuelFournisseur">
					</p-menu>

					<p-card *ngIf="!chargementCompletePrixFournisseurs">
						<div class="fluid formgrid grid">
							<div class="flex flex-column gap-2 my-2 col-12 text-center">
								<p-progressSpinner></p-progressSpinner>
							</div>
						</div>
					</p-card>

					<p-table *ngIf="chargementCompletePrixFournisseurs" #dtFournisseurs [value]="item.fournisseurs"
						tableStyleClass="tableNormale" autoLayout="true" dataKey="id"
						[globalFilterFields]="['fournisseur.nom','prix','fournisseur.numero','numeroFournisseur']">
						<ng-template pTemplate="caption">
							<div class="flex p-flex-column p-flex-md-row p-jc-md-between table-header">
								<div>
									<span class="p-input-icon-left">
										<i class="pi pi-search"></i>
										<input pInputText type="text"
											(input)="dtFournisseurs.filterGlobal($event.target.value, 'contains')"
											placeholder="Recherche..." />

									</span>
									<button *ngIf="estAutorise('temp_mec') || estAutorise('bdc_creer')" pButton pRipple
										icon="pi pi-plus" class="p-button-success mr-2 ml-4 mb-2 boutonGrille"
										(click)="AjouterFournisseur()"></button>
								</div>
								<div></div>
								<div></div>

							</div>
						</ng-template>
						<ng-template pTemplate="header">
							<tr>
								<th pSortableColumn="numero">
									<div class="flex justify-content-between align-items-center">
										Numéro
										<p-sortIcon field="numero"></p-sortIcon>
										<p-columnFilter type="text" field="numero" display="menu" class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="Nom">
									<div class="flex justify-content-between align-items-center">
										Nom
										<p-sortIcon field="Nom"></p-sortIcon>
										<p-columnFilter type="text" field="Nom" display="menu" class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th>
									<div class="flex justify-content-between align-items-center">
										Statut
									</div>
								</th>
								<th pSortableColumn="numeroFournisseur">
									<div class="flex justify-content-between align-items-center">
										No pièce fournisseur
										<p-sortIcon field="numeroFournisseur"></p-sortIcon>
										<p-columnFilter type="text" field="numeroFournisseur" display="menu"
											class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="prix">
									<div class="flex justify-content-between align-items-center">
										Prix
										<p-sortIcon field="prix"></p-sortIcon>
										<p-columnFilter type="text" field="prix" display="menu" class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="dateDebut">
									<div class="flex justify-content-between align-items-center">
										Date de début
										<p-sortIcon field="dateDebut"></p-sortIcon>
										<p-columnFilter type="dateDebut" field="date" display="menu" class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="dateFin">
									<div class="flex justify-content-between align-items-center">
										Date de fin
										<p-sortIcon field="dateFin"></p-sortIcon>
										<p-columnFilter type="dateFin" field="date" display="menu" class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="fournisseur.contactPrincipal.prenom">
									<div class="flex justify-content-between align-items-center">
										Contact principal
										<p-sortIcon field="fournisseur.contactPrincipal.prenom"></p-sortIcon>
										<p-columnFilter type="text" field="fournisseur.contactPrincipal.prenom"
											display="menu" class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="fournisseur.contactPrincipal.courriel">
									<div class="flex justify-content-between align-items-center">
										Courriel
										<p-sortIcon field="fournisseur.contactPrincipal.courriel"></p-sortIcon>
										<p-columnFilter type="text" field="fournisseur.contactPrincipal.courriel"
											display="menu" class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="fournisseur.contactPrincipal.telephone">
									<div class="flex justify-content-between align-items-center">
										Téléphone
										<p-sortIcon field="fournisseur.contactPrincipal.telephone"></p-sortIcon>
										<p-columnFilter type="text" field="fournisseur.contactPrincipal.telephone"
											display="menu" class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="fournisseur.contactPrincipal.cellulaire">
									<div class="flex justify-content-between align-items-center">
										Cellulaire
										<p-sortIcon field="fournisseur.contactPrincipal.cellulaire"></p-sortIcon>
										<p-columnFilter type="text" field="fournisseur.contactPrincipal.cellulaire"
											display="menu" class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th *ngIf="!modeApercu" style="min-width: 5rem;"></th>
							</tr>
						</ng-template>
						<ng-template pTemplate="body" let-prixFournisseur>
							<tr [pContextMenuRow]="prixFournisseur">
								<td>
									{{prixFournisseur.fournisseur.numero}}
								</td>
								<td>
									{{prixFournisseur.fournisseur.nom}}
								</td>
								<td>
									<span *ngIf="!prixFournisseur.dateFin || prixFournisseur.dateFin > dateJour"
										class="statut actif">
										Actif
									</span>
									<span
										*ngIf="prixFournisseur.dateDebut > dateJour || (prixFournisseur.dateFin && prixFournisseur.dateFin < dateJour)"
										class="statut inactif">
										Inactif
									</span>
								</td>
								<td>
									{{prixFournisseur.numeroFournisseur}}
								</td>
								<td>
									{{prixFournisseur.prix | currency}}
								</td>
								<td>
									{{prixFournisseur.dateDebut | date:'yyyy-MM-dd'}}
								</td>
								<td>
									{{prixFournisseur.dateFin | date:'yyyy-MM-dd'}}
								</td>
								<td>{{prixFournisseur.fournisseur.contactPrincipal?.prenom}}
									{{prixFournisseur.fournisseur.contactPrincipal?.nom}}</td>
								<td>{{prixFournisseur.fournisseur.contactPrincipal?.courriel}}</td>
								<td>{{prixFournisseur.fournisseur.contactPrincipal?.telephone}}</td>
								<td>{{prixFournisseur.fournisseur.contactPrincipal?.cellulaire}}</td>
								<td *ngIf="!modeApercu">
									<button *ngIf="estAutorise('fournisseur_detail') || estAutorise('bdc_creer')"
										pButton pRipple icon="pi pi-bars" class="p-button-secondary mr-2 boutonGrille"
										(click)="surMenuContextuelFournisseur($event, prixFournisseur)"></button>
								</td>
							</tr>
						</ng-template>
						<ng-template pTemplate="emptymessage">
							<tr>
								<td colspan="11">Aucun fournisseur.</td>
							</tr>
						</ng-template>
					</p-table>
				</p-tabPanel>
				<!-- #endregion -->
				<!-- #region Achats -->
				<p-tabPanel *ngIf="estAutorise('item_achats') && !item.categorie.estUnique"
					[header]="'Achats (' + item.nbEntreesAchats + ')'" leftIcon="pi pi-shopping-cart">

					<p-menu #menuContextuelAchat popup="popup" [model]="contenuMenuContextuelAchat"></p-menu>

					<p-card *ngIf="!chargementCompleteAchats">
						<div class="fluid formgrid grid">
							<div class="flex flex-column gap-2 my-2 col-12 text-center">
								<p-progressSpinner></p-progressSpinner>
							</div>
						</div>
					</p-card>

					<p-table *ngIf="chargementCompleteAchats" #dtAchats [value]="item.achats" autoLayout="true"
						tableStyleClass="tableNormale" dataKey="itemBonCommandeId"
						[globalFilterFields]="['noFournisseur','prix','bonCommande.numero','bonCommande.dateAchat','bonCommande.fournisseur.numero','bonCommande.fournisseur.nom']">
						<ng-template pTemplate="caption">
							<div class="flex p-flex-column p-flex-md-row p-jc-md-between table-header">
								<span class="p-input-icon-left">
									<i class="pi pi-search"></i>
									<input pInputText type="text"
										(input)="dtAchats.filterGlobal($event.target.value, 'contains')"
										placeholder="Recherche..." />
									<button pButton pRipple label="Analyser les achats" icon="pi pi-chart-line" class="p-button-text" (click)="analyserItem()"></button>
								</span>
							</div>
						</ng-template>
						<ng-template pTemplate="header">
							<tr>
								<th pSortableColumn="bonCommande.numero">
									<div class="flex justify-content-between align-items-center">
										No BDC
										<p-sortIcon field="bonCommande.numero"></p-sortIcon>
										<p-columnFilter type="text" field="bonCommande.numero" display="menu" class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="bonCommande.dateAchat">
									<div class="flex justify-content-between align-items-center">
										Date
										<p-sortIcon field="bonCommande.dateAchat"></p-sortIcon>
										<p-columnFilter type="date" field="bonCommande.dateAchat" display="menu" class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th>
									<div class="flex justify-content-between align-items-center">
										Statut
									</div>
								</th>

								<!-- <th pSortableColumn="numeroFournisseur">
									<div class="flex justify-content-between align-items-center">
										No pièce fournisseur
										<p-sortIcon field="numeroFournisseur"></p-sortIcon>
										<p-columnFilter type="text" field="numeroFournisseur" display="menu"
											class="ml-auto">
										</p-columnFilter>
									</div>
								</th> -->
								<th pSortableColumn="bonCommande.fournisseur.nom">
									<div class="flex justify-content-between align-items-center">
										Fournisseur
										<p-sortIcon field="bonCommande.fournisseur.nom"></p-sortIcon>
										<p-columnFilter type="text" field="bonCommande.fournisseur.nom" display="menu"
											class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="quantite">
									<div class="flex justify-content-between align-items-center">
										Quantité
										<p-sortIcon field="quantite"></p-sortIcon>
										<p-columnFilter type="number" field="quantite" display="menu" class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="prix">
									<div class="flex justify-content-between align-items-center">
										Prix
										<p-sortIcon field="prix"></p-sortIcon>
										<p-columnFilter type="text" field="prix" display="menu" class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th *ngIf="!modeApercu" style="min-width: 5rem;"></th>
							</tr>
						</ng-template>
						<ng-template pTemplate="body" let-achat>
							<tr [pContextMenuRow]="achat">
								<td>
									{{achat.bonCommande.numero}}
								</td>
								<td>
									{{achat.bonCommande.dateAchat | date:'yyyy-MM-dd'}}
								</td>
								<td>
									<span
										[class]="'statut ' + achat.bonCommande.statut.nom.toLowerCase().replace(' ','')">
										{{achat.bonCommande.statut.nom}}
									</span>
								</td>
								<!-- <td>
									{{achat.bonCommande.fournisseur.numero}}
								</td> -->
								<td>
									{{achat.bonCommande.fournisseur.nom}} ({{achat.bonCommande.fournisseur.numero}})
								</td>
								<td>
									{{achat.quantite}}
								</td>
								<td>
									{{achat.prix | currency}}
								</td>
								<td *ngIf="!modeApercu">
									<button
										*ngIf="estAutorise('bdc_detail') || estAutorise('fournisseur_detail') || estAutorise('bdc_creer')"
										pButton pRipple icon="pi pi-bars" class="p-button-secondary mr-2 boutonGrille"
										(click)="surMenuContextuelAchat($event, achat)"></button>
									<button *ngIf="estAutorise('bdc_detail')" pButton pRipple icon="pi pi-external-link"
									        class="p-button-secondary mr-2 boutonGrille"
									        (click)="OuvrirDetailBdcNouvelOnglet(achat.bonCommandeId)"></button>
								</td>
							</tr>
						</ng-template>
						<ng-template pTemplate="emptymessage">
							<tr>
								<td colspan="7">Aucun achat.</td>
							</tr>
						</ng-template>
					</p-table>
				</p-tabPanel>
				<!-- #endregion -->
				<!-- #region Soumissions -->
				<p-tabPanel *ngIf="estAutorise('item_soumissions') && !item.categorie.estUnique"
					[header]="'Soumissions (' + item.nbEntreesSoumissions + ')'" leftIcon="pi pi-dollar">
					<p-menu #menuContextuelSoumissions popup="popup" [model]="contenuMenuContextuelSoumissions">
					</p-menu>

					<p-card *ngIf="!chargementCompleteSoumissions">
						<div class="fluid formgrid grid">
							<div class="flex flex-column gap-2 my-2 col-12 text-center">
								<p-progressSpinner></p-progressSpinner>
							</div>
						</div>
					</p-card>

					<p-table *ngIf="chargementCompleteSoumissions" #dtSoumissions [value]="item.soumissions"
						[columns]="selectedColumns" dataKey="soumissionId" autoLayout="true"
						tableStyleClass="tableNormale" 
						[(contextMenuSelection)]="selectionSoumission" [rowHover]="true" [rows]="25" [paginator]="true"
						[filterDelay]="0"
						[globalFilterFields]="['numero','date','fournisseur.nom','fournisseur.numero']">
						<ng-template pTemplate="caption">
							<div class="flex align-content-center justify-content-between">
								<div>
									<span class="p-input-icon-left">
										<i class="pi pi-search"></i>
										<input pInputText type="text"
											(input)="dtSoumissions.filterGlobal($event.target.value, 'contains')"
											placeholder="Recherche..." />
									</span>
									<button *ngIf="!modeApercu" pButton pRipple icon="pi pi-plus"
										class="p-button-rounded p-button-success p-button-sm mb-2 ml-4"
										(click)="ajouterSoumission()"></button>
								</div>
								<h5 class="titreTableau m-0">Soumissions</h5>
								<div>
									<p-multiSelect [options]="cols" [(ngModel)]="selectedColumns" optionLabel="header"
										selectedItemsLabel="{0} colonnes sélectionnés" [style]="{minWidth: '200px'}"
										placeholder="Colonnes..."></p-multiSelect>
								</div>
							</div>
						</ng-template>

						<ng-template pTemplate="header" let-columns>
							<tr>
								<th pSortableColumn="fournisseur.nom">
									<div class="flex justify-content-between align-items-center">
										Fournisseur
										<p-sortIcon field="fournisseur.nom"></p-sortIcon>
										<p-columnFilter type="text" field="fournisseur.nom" display="menu"
											class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="statut.nom">
									<div class="flex justify-content-between align-items-center">
										Statut
										<p-sortIcon field="statut.nom"></p-sortIcon>
										<p-columnFilter type="text" field="statut.nom" display="menu" class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="dateDebut">
									<div class="flex justify-content-between align-items-center">
										Date de début
										<p-sortIcon field="dateDebut"></p-sortIcon>
										<p-columnFilter type="text" field="dateDebut" display="menu" class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="dateFin">
									<div class="flex justify-content-between align-items-center">
										Date de fin
										<p-sortIcon field="dateFin"></p-sortIcon>
										<p-columnFilter type="date" field="dateFin" display="menu" class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="dateDebutSaisie">
									<div class="flex justify-content-between align-items-center">
										Date début saisie
										<p-sortIcon field="dateDebutSaisie"></p-sortIcon>
										<p-columnFilter type="date" field="dateDebutSaisie" display="menu"
											class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="dateFinSaisie">
									<div class="flex justify-content-between align-items-center">
										Date Fin Saisie
										<p-sortIcon field="dateFinSaisie"></p-sortIcon>
										<p-columnFilter type="date" field="dateFinSaisie" display="menu"
											class="ml-auto">
										</p-columnFilter>
									</div>
								</th>

								<th *ngIf="!modeApercu" style="min-width: 7rem; width: 7rem;"></th>
							</tr>
						</ng-template>
						<ng-template pTemplate="body" let-soumission let-columns="columns">
							<tr>

								<td>{{soumission.fournisseur.nom}}</td>
								<td><span [class]="'statut ' + soumission.statut.nom.toLowerCase().replace(' ', '')">
										{{soumission.statut.nom}}
									</span></td>
								<td>{{soumission.dateDebut | date:'yyyy-MM-dd'}}</td>
								<td>{{soumission.dateFin | date:'yyyy-MM-dd'}}</td>
								<td>{{soumission.dateDebutSaisie | date:'yyyy-MM-dd'}}</td>
								<td>{{soumission.dateFinSaisie | date:'yyyy-MM-dd'}}</td>

								<td *ngIf="!modeApercu" class="celluleTableau flex">
									<button
										*ngIf="estAutorise('soumission_detail') || estAutorise('fournisseur_detail')"
										pButton pRipple icon="pi pi-bars" class="p-button-secondary mr-2 boutonGrille"
										(click)="surMenuContextuelSoumission($event, soumission)"></button>
								</td>
							</tr>
						</ng-template>
						<ng-template pTemplate="emptymessage">
							<tr>
								<td colspan="7">Aucune soumission.</td>
							</tr>
						</ng-template>
					</p-table>

				</p-tabPanel>
				<!-- #endregion -->
				<!-- #region Associations -->
				<p-tabPanel
					*ngIf="(estAutorise('temp_mec') || estAutorise('item_detail')) && item.categorie.categorieId == 6"
					[header]="'Associations (' + item.nbEntreesAssociations + ')'" leftIcon="pi pi-user">
					<p-menu #menuContextuelAssociation popup="popup" [model]="contenuMenuContextuelAssociation">
					</p-menu>

					<p-card *ngIf="!chargementCompleteAssociations">
						<div class="fluid formgrid grid">
							<div class="flex flex-column gap-2 my-2 col-12 text-center">
								<p-progressSpinner></p-progressSpinner>
							</div>
						</div>
					</p-card>

					<p-table *ngIf="chargementCompleteAssociations" #dtAssoc [value]="item.associations"
						[columns]="selectedColumns" dataKey="pieceUniteId" autoLayout="true"
						tableStyleClass="tableNormale"  [rowHover]="true"
						[filterDelay]="0"
						[globalFilterFields]="['piece.numero','piece.description','piece.modele','piece.marque','categorieEntretien.code','categorieEntretien.description']">
						<ng-template pTemplate="caption">
							<div class="flex align-content-center justify-content-between">
								<div>
									<span class="p-input-icon-left">
										<i class="pi pi-search"></i>
										<input #rechercheItem pInputText type="text"
											(input)="dtAssoc.filterGlobal($event.target.value, 'contains')"
											placeholder="Recherche..." />
									</span>
									<button *ngIf="estAutorise('piece_associer')" pButton pRipple icon="pi pi-plus"
										class="p-button-success mr-2 ml-4 mb-2 boutonGrille"
										(click)="ajouterAssociation()"></button>
								</div>
								<div>
									<h5 class="titreTableau m-0">Associations</h5>
								</div>

								<div>
									<p-multiSelect disabled="true" [style]="{minWidth: '200px'}"
										placeholder="Colonnes..."></p-multiSelect>
								</div>
							</div>
						</ng-template>
						<ng-template pTemplate="header" let-columns>
							<tr>
								<th pSortableColumn="categorieEntretien.code">
									<div class="flex justify-content-between align-items-center">
										Catégorie
										<p-sortIcon field="categorieEntretien.code"></p-sortIcon>
										<p-columnFilter type="text" field="categorieEntretien.code" display="menu"
											class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="unite.numero">
									<div class="flex justify-content-between align-items-center">
										Numéro
										<p-sortIcon field="unite.numero"></p-sortIcon>
										<p-columnFilter type="text" field="unite.numero" display="menu"
											class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="unite.description">
									<div class="flex justify-content-between align-items-center">
										Description
										<p-sortIcon field="unite.description"></p-sortIcon>
										<p-columnFilter type="text" field="unite.description" display="menu"
											class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="unite.marque">
									<div class="flex justify-content-between align-items-center">
										Marque
										<p-sortIcon field="unite.marque"></p-sortIcon>
										<p-columnFilter type="text" field="unite.marque" display="menu"
											class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="unite.modele">
									<div class="flex justify-content-between align-items-center">
										Modèle
										<p-sortIcon field="unite.modele"></p-sortIcon>
										<p-columnFilter type="text" field="unite.modele" display="menu"
											class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="note">
									<div class="flex justify-content-between align-items-center">
										Note
										<p-sortIcon field="note"></p-sortIcon>
										<p-columnFilter type="text" field="note" display="menu"
											class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th style="min-width: 7rem; width: 7rem;"></th>

							</tr>


						</ng-template>
						<ng-template pTemplate="body" let-piece let-columns="columns">
							<tr>
								<td>{{piece.categorieEntretien?.code}} - {{piece.categorieEntretien?.description}}</td>
								<td>{{piece.unite.numero}}</td>
								<td>{{piece.unite.description}}</td>
								<td>{{piece.unite.marque}}</td>
								<td>{{piece.unite.modele}}</td>
								<td>{{piece.note}}</td>
								<td class="celluleTableau">
									<div class="flex">
										<button pButton pRipple icon="pi pi-bars"
											class="p-button-secondary mr-2 boutonGrille"
											(click)="surMenuContextuelAssociation($event, piece)"></button>
									</div>
								</td>

							</tr>
						</ng-template>
						<ng-template pTemplate="emptymessage">
							<tr>
								<td colspan="8">Aucune association.</td>
							</tr>
						</ng-template>
					</p-table>

				</p-tabPanel>
				<!-- #endregion -->
				<!-- #region Documents -->
				<p-tabPanel *ngIf="estAutorise('item_documents')" header="Documents ({{chargementCompleteDocuments ? documentsComplet.length : '...'}}) " leftIcon="pi pi-file-pdf">
					<ng-template pTemplate="content">
						<app-document [typeLienDocument]="ETypeLienDocument.Item" [idLien]="itemId"
                        [numero]="item.numero"></app-document>
					</ng-template>
				</p-tabPanel>
				<!-- #endregion -->
				<!-- #region Documents liés -->
				<p-tabPanel *ngIf="estAutorise('item_documents_lies') && item.categorie.estUnique"
					header="Documents liés" leftIcon="pi pi-file-pdf">
					<p-menu #menuContextuelDocumentLie popup="popup" [model]="contenuMenuContextuelDocumentLie">
					</p-menu>

					<p-card *ngIf="!chargementCompleteDocumentsLies">
						<div class="fluid formgrid grid">
							<div class="flex flex-column gap-2 my-2 col-12 text-center">
								<p-progressSpinner></p-progressSpinner>
							</div>
						</div>
					</p-card>

					<div *ngIf="chargementCompleteDocumentsLies">
						<p-table #dtDocumentsLies [value]="item.documentslies" dataKey="documentLieId"
							tableStyleClass="tableNormale" autoLayout="true" styleClass="p-datatable-striped"
							[rowHover]="true" [filterDelay]="0" [globalFilterFields]="['type.nom','date','nom']">
							<ng-template pTemplate="caption">
								<div class="flex align-content-center justify-content-between">
									<div>
										<span class="p-input-icon-left">
											<i class="pi pi-search"></i>
											<input pInputText type="text"
												(input)="dtDocumentsLies.filterGlobal($event.target.value, 'contains')"
												placeholder="Recherche..." />
										</span>

									</div>
									<h5 class="titreTableau m-0">Documents liés</h5>
									<div>
										<p-multiSelect disabled="true" [style]="{minWidth: '200px'}"
											placeholder="Colonnes..."></p-multiSelect>
									</div>
								</div>
							</ng-template>
							<ng-template pTemplate="header">
								<tr>
									<th pSortableColumn="typeDocumentLie.nom">
										<div class="flex justify-content-between align-items-center">
											Type
											<p-sortIcon field="typeDocumentLie.nom"></p-sortIcon>
											<p-columnFilter type="text" field="typeDocumentLie.nom" display="menu"
												class="ml-auto">
											</p-columnFilter>
										</div>
									</th>
									<th pSortableColumn="date">
										<div class="flex justify-content-between align-items-center">
											Date
											<p-sortIcon field="date"></p-sortIcon>
											<p-columnFilter type="date" field="date" display="menu" class="ml-auto">
											</p-columnFilter>
										</div>
									</th>
									<th pSortableColumn="numero">
										<div class="flex justify-content-between align-items-center">
											Numéro
											<p-sortIcon field="numero"></p-sortIcon>
											<p-columnFilter type="text" field="numero" display="menu" class="ml-auto">
											</p-columnFilter>
										</div>
									</th>
									<th pSortableColumn="nom">
										<div class="flex justify-content-between align-items-center">
											Nom
											<p-sortIcon field="nom"></p-sortIcon>
											<p-columnFilter type="text" field="nom" display="menu" class="ml-auto">
											</p-columnFilter>
										</div>
									</th>
									<th *ngIf="!modeApercu" style="min-width: 5rem"></th>
								</tr>
							</ng-template>
							<ng-template pTemplate="body" let-doc>

								<tr>
									<td>{{doc.typeDocumentLie.nom}}</td>
									<td>{{doc.date | date:'yyyy-MM-dd'}}</td>
									<td>{{doc.numero}}</td>
									<td>{{doc.nom}}</td>
									<td *ngIf="!modeApercu">
										<button
											*ngIf="estAutorise('item_document_lie_modifier') || estAutorise('item_document_lie_supprimer')"
											pButton pRipple icon="pi pi-bars"
											class="p-button-secondary mr-2 boutonGrille"
											(click)="surMenuContextuelDocumentLie($event, garantie)"></button>
									</td>
								</tr>
							</ng-template>
							<ng-template pTemplate="emptymessage">
								<tr>
									<td colspan="4">Aucun document lié.</td>
								</tr>
							</ng-template>
						</p-table>
					</div>
				</p-tabPanel>
				<!-- #endregion -->
				<!-- #region Liaisons -->
				<p-tabPanel *ngIf="estAutorise('item_liaisons') && item.categorie.estUnique"
					[header]="'Liaisons (' + item.nbEntreesLiaisons + ')'" leftIcon="pi pi-sitemap">
					<p-menu #menuContextuelLiaison popup="popup" [model]="contenuMenuContextuelLiaison"></p-menu>

					<p-card *ngIf="!chargementCompleteLiaisons">
						<div class="fluid formgrid grid">
							<div class="flex flex-column gap-2 my-2 col-12 text-center">
								<p-progressSpinner></p-progressSpinner>
							</div>
						</div>
					</p-card>

					<div *ngIf="chargementCompleteLiaisons">

						<p-table #dtLiaisons [value]="item.liaisons" [(contextMenuSelection)]="selectionLiaison"
							tableStyleClass="tableNormale" [contextMenu]="cmLiaison" dataKey="itemId">
							<ng-template pTemplate="caption">
								<div class="flex align-content-center justify-content-between">
									<div>
										<span class="p-input-icon-left">
											<i class="pi pi-search"></i>
											<input pInputText type="text"
												(input)="dtLiaisons.filterGlobal($event.target.value, 'contains')"
												placeholder="Recherche..." />
										</span>
										<button *ngIf="estAutorise('item_liaison_creer') && !modeApercu" pButton pRipple
											icon="pi pi-plus"
											class="p-button-rounded p-button-success p-button-sm mb-2 ml-4"
											(click)="ajouterLiaison()"></button>

									</div>
									<h5 class="titreTableau m-0">Liaisons</h5>
									<div>
										<p-multiSelect disabled="true" [style]="{minWidth: '200px'}"
											placeholder="Colonnes..."></p-multiSelect>
									</div>
								</div>
							</ng-template>
							<ng-template pTemplate="header">
								<tr>
									<th>Numéro</th>
									<th>Description</th>
									<th *ngIf="!modeApercu" style="min-width: 5rem; width: 5rem;"></th>
								</tr>
							</ng-template>
							<ng-template pTemplate="body" let-item>
								<tr [pContextMenuRow]="item">
									<td>{{item.numero}}</td>
									<td>{{item.description}}</td>
									<td *ngIf="!modeApercu">
										<button
											*ngIf="estAutorise('item_detail') || estAutorise('item_liaison_supprimer')"
											pButton pRipple icon="pi pi-bars"
											class="p-button-secondary mr-2 boutonGrille"
											(click)="surMenuContextuelLiaison($event, item)"></button>
									</td>
								</tr>
							</ng-template>
							<ng-template pTemplate="emptymessage">
								<tr>
									<td colspan="2">Aucune liaison.</td>
								</tr>
							</ng-template>
						</p-table>
					</div>
				</p-tabPanel>
				<!-- #endregion -->
				<!-- #region Garanties -->
				<p-tabPanel *ngIf="estAutorise('item_garanties') && item.categorie.estUnique"
					[header]="'Garanties (' + item.nbEntreesGaranties + ')'" leftIcon="pi pi-shield">
					<p-menu #menuContextuelGarantie popup="popup" [model]="contenuMenuContextuelGarantie"></p-menu>

					<p-card *ngIf="!chargementCompleteGaranties">
						<div class="fluid formgrid grid">
							<div class="flex flex-column gap-2 my-2 col-12 text-center">
								<p-progressSpinner></p-progressSpinner>
							</div>
						</div>
					</p-card>

					<div *ngIf="chargementCompleteGaranties">
						<p-table #dtGaranties [value]="item.garanties" dataKey="garantieId"
							tableStyleClass="tableNormale" autoLayout="true" styleClass="p-datatable-striped"
							[rowHover]="true" [filterDelay]="0"
							[globalFilterFields]="['type.nom','dateDebut','dateFin','description']">
							<ng-template pTemplate="caption">
								<div class="flex align-content-center justify-content-between">
									<div>
										<span class="p-input-icon-left">
											<i class="pi pi-search"></i>
											<input pInputText type="text"
												(input)="dtGaranties.filterGlobal($event.target.value, 'contains')"
												placeholder="Recherche..." />
										</span>
										<button *ngIf="estAutorise('item_garantie_creer') && !modeApercu" pButton
											pRipple icon="pi pi-plus"
											class="p-button-rounded p-button-success p-button-sm mb-2 ml-4"
											(click)="ajouterGarantie()"></button>

									</div>
									<h5 class="titreTableau m-0">Garanties</h5>
									<div>
										<p-multiSelect disabled="true" [style]="{minWidth: '200px'}"
											placeholder="Colonnes..."></p-multiSelect>
									</div>
								</div>
							</ng-template>
							<ng-template pTemplate="header">
								<tr>
									<th pSortableColumn="type.nom">
										<div class="flex justify-content-between align-items-center">
											Type
											<p-sortIcon field="type.nom"></p-sortIcon>
											<p-columnFilter type="text" field="type.nom" display="menu"
												class="ml-auto">
											</p-columnFilter>
										</div>
									</th>

									<th pSortableColumn="statut.nom">
										<div class="flex justify-content-between align-items-center">
											Statut
											<p-sortIcon field="statut.nom"></p-sortIcon>
											<p-columnFilter field="statut" matchMode="in" display="menu"
												[showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
												<ng-template pTemplate="filter" let-value let-filter="filterCallback">
													<p-multiSelect [ngModel]="value" [options]="statuts"
														placeholder="Any" (onChange)="filter($event.value)"
														optionLabel="name">
														<ng-template let-option pTemplate="item">
															<div class="multiselect-representative-option">

																<span class="ml-1">{{option.nom}}</span>
															</div>
														</ng-template>
													</p-multiSelect>
												</ng-template>
											</p-columnFilter>
										</div>
									</th>
									<th pSortableColumn="dateDebut">
										<div class="flex justify-content-between align-items-center">
											Date de début
											<p-sortIcon field="dateDebut"></p-sortIcon>
											<p-columnFilter type="date" field="dateDebut" display="menu"
												class="ml-auto">
											</p-columnFilter>
										</div>
									</th>
									<th pSortableColumn="dateFin">
										<div class="flex justify-content-between align-items-center">
											Date de fin
											<p-sortIcon field="dateFin"></p-sortIcon>
											<p-columnFilter type="date" field="dateFin" display="menu"
												class="ml-auto">
											</p-columnFilter>
										</div>
									</th>
									<th pSortableColumn="description">
										<div class="flex justify-content-between align-items-center">
											Description
											<p-sortIcon field="description"></p-sortIcon>
											<p-columnFilter type="text" field="description" display="menu"
												class="ml-auto">
											</p-columnFilter>
										</div>
									</th>
									<th *ngIf="!modeApercu" style="min-width: 5rem"></th>
								</tr>
							</ng-template>
							<ng-template pTemplate="body" let-garantie>

								<tr>
									<td>{{garantie.type.nom}}</td>
									<td><span [class]="'statut ' + garantie.statut.nom.replace(' ','').toLowerCase()">
											{{garantie.statut.nom}}
										</span>
									</td>

									<td>{{garantie.dateDebut | date:'yyyy-MM-dd'}}</td>
									<td>{{garantie.dateFin | date:'yyyy-MM-dd'}}</td>
									<td>{{garantie.description}}</td>

									<td *ngIf="!modeApercu">
										<button
											*ngIf="estAutorise('item_garantie_modifier') || estAutorise('item_garantie_supprimer')"
											pButton pRipple icon="pi pi-bars"
											class="p-button-secondary mr-2 boutonGrille"
											(click)="surMenuContextuelGarantie($event, garantie)"></button>
									</td>
								</tr>
							</ng-template>
							<ng-template pTemplate="emptymessage">
								<tr>
									<td colspan="6">Aucune garantie.</td>
								</tr>
							</ng-template>
						</p-table>
					</div>
				</p-tabPanel>
				<!-- #endregion -->

				<!-- TODO: Vérifier l'IndSuiviMecanique -->

				<!-- #region Mécanique -->
				<p-tabPanel
					*ngIf="(estAutorise('temp_mec') || estAutorise('unite_entretien')) && item.categorie.estUnique"
					header="Entretien" leftIcon="pi pi-briefcase">

					<p-card *ngIf="!chargementCompleteEntretiens">
						<div class="fluid formgrid grid">
							<div class="flex flex-column gap-2 my-2 col-12 text-center">
								<p-progressSpinner></p-progressSpinner>
							</div>
						</div>
					</p-card>

					<div *ngIf="chargementCompleteEntretiens">
						<div class="fluid formgrid grid">
							<div class="flex flex-column gap-2 my-2 col-12 md:col-2">
								<label for="heuresItem">Heures au compteur</label>
								<span class="champConsultation">{{item.heuresCompteur}} 
									<i class="pi pi-pencil ml-3" style="cursor: pointer;" (click)="ouvrirAjustements()"></i>
									<i class="pi pi-plus ml-3" style="cursor: pointer;" (click)="ajouterHeuresApprouvee()"></i>
								</span>
							</div>
							<div class="flex flex-column gap-2 my-2 col-12 md:col-2">
								<label for="heuresItem">Heures réelles</label>
								<span class="champConsultation">{{item.heuresReelles}}</span>
							</div>
							<div class="flex flex-column gap-2 my-2 col-12 md:col-2">
								<label for="heuresItem">KM au compteur</label>
								<span class="champConsultation">{{item.kmCompteur}} 
									<i class="pi pi-pencil ml-3" style="cursor: pointer;" (click)="ouvrirAjustementsKm()"></i>
									<i class="pi pi-plus ml-3" style="cursor: pointer;" (click)="ajouterKmsApprouvee()"></i>
								</span>
							</div>
							<div class="flex flex-column gap-2 my-2 col-12 md:col-2">
								<label for="heuresItem">KM réels</label>
								<span class="champConsultation">{{item.kmReel}}</span>
							</div>
							<div class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-3">
								<label for="emplacement">Emplacement courant</label>
								<span class="champConsultation">{{item.emplacement?.nom | valeurVide}}</span>
							</div>
						</div>

						<div class="fluid formgrid grid">
							<div class="flex flex-column gap-2 my-2 col-12">
								<label for="emplacement">Note mécanique</label>
								<span *ngIf="modeConsultation()" class="champConsultation" style="white-space: pre-line; font-size: 1.5rem; color: red;">{{item.noteMecanique | valeurVide}}</span>
								<textarea [rows]="5" pInputTextarea *ngIf="!modeConsultation()" [(ngModel)]="item.noteMecanique" ></textarea>
							</div>
						</div>


						<p-tabView [scrollable]="true" #tabViewEntretiens orientation="left" [(activeIndex)]="tabIndexEntretiens"
							(onChange)="surChangementOngletEntretiens($event)">


							

							<!-- #region Entretiens préventifs -->
							<p-tabPanel *ngIf="estAutorise('unite_preventifs') || estAutorise('temp_mec')"
								header="Préventifs" leftIcon="pi pi-shield">


								<!------------ PRÉVENTIFS ------------>

								<p-card *ngIf="!chargementCompleteEntretiensPreventifs">
									<div class="fluid formgrid grid">
										<div class="flex flex-column gap-2 my-2 col-12 text-center">
											<p-progressSpinner></p-progressSpinner>
										</div>
									</div>
								</p-card>


								<p-menu #menuContextuelEntretiensPreventifs popup="popup"
									[model]="contenuMenuContextuelEntretiensPreventifs"></p-menu>

								<div *ngIf="chargementCompleteEntretiensPreventifs" class="fluid formgrid grid">


									<div class="col-12 mb-3">
										<p-message *ngIf="calculEnCours" style="width: 100%;" severity="warn"
											text="Des calculs sont en cours pour certains des entretiens affichés. Les informations de ces entretiens ne sont donc pas disponibles pour le moment."
											styleClass="mr-2">

											<i class="pi pi-refresh ml-3" (click)="rechercherUnite()"></i>
										</p-message>
									</div>
								</div>


								<p-table *ngIf="chargementCompleteEntretiensPreventifs" #dt
									[value]="item.entretiensPreventifs" [columns]="selectedColumns"
									dataKey="entretienPreventifId" autoLayout="true" tableStyleClass="tableNormale"
									[(selection)]="selectionEntretiensPreventifs"
									(onRowSelect)="surChangementSelectionEntretiensPreventifs()"
									(onRowUnselect)="surChangementSelectionEntretiensPreventifs()"
									(onHeaderCheckboxToggle)="surChangementSelectionEntretiensPreventifs()"
									 [rowHover]="true" [filterDelay]="0"
									[globalFilterFields]="['description']">
									<ng-template pTemplate="caption">
										<div class="flex align-content-center justify-content-between">
											<div>
												<span class="p-input-icon-left">
													<i class="pi pi-search"></i>
													<input #rechercheItem pInputText type="text"
														(input)="dt.filterGlobal($event.target.value, 'contains')"
														(keyup.enter)="surEntreeRecherchePreventifs()"
														placeholder="Recherche..." />
													
												</span>
												<button *ngIf="estAutorise('temp_mec')" pButton pRipple
													icon="pi pi-plus"
													class="p-button-success mr-2 ml-4 mb-2 boutonGrille"
													(click)="AjouterEntretienPreventif()"></button>
												<button *ngIf="estAutorise('temp_mec')" pButton pRipple
													icon="pi pi-check-square"
													class="p-button-success mr-2 ml-4 mb-2 boutonGrille"
													[disabled]="!selectionEntretiensPreventifs || selectionEntretiensPreventifs.length == 0"
													(click)="assignerEntretiensPreventifs(selectionEntretiensPreventifs)"></button>
												<button *ngIf="estAutorise('temp_mec')" pButton pRipple
													icon="pi pi-bars"
													class="p-button-secondary mr-2 ml-4 mb-2 boutonGrille"
													(click)="surMenuContextuelEntretiensPreventifs($event)"></button>
											</div>
											<div>
												<h5 class="titreTableau m-0">Entretiens préventifs</h5>
											</div>

											<div>
												<p-multiSelect disabled="true" [style]="{minWidth: '200px'}"
													placeholder="Colonnes..."></p-multiSelect>
											</div>
										</div>
									</ng-template>
									<ng-template pTemplate="header" let-columns>
										<tr>
											<th rowspan="2" style="min-width: 3rem">
											
											</th>
											<th rowspan="2" pSortableColumn="description">
												<div class="flex justify-content-between align-items-center">
													Description
													<p-sortIcon field="description"></p-sortIcon>
													<p-columnFilter type="text" field="description" display="menu"
														class="ml-auto">
													</p-columnFilter>
												</div>
											</th>
											<th rowspan="2" pSortableColumn="etat">
												<div class="flex justify-content-between align-items-center">
													Statut
													<p-sortIcon field="etat"></p-sortIcon>
												</div>
											</th>
											<th *ngIf="afficherHeures" colspan="3">
												Heures
											</th>
											<th *ngIf="afficherKms" colspan="3">
												KMs
											</th>
											<th *ngIf="afficherMois" colspan="3">
												Mois
											</th>

											<th rowspan="2" pSortableColumn="bonTravail.numero">
												<div class="flex justify-content-between align-items-center">
													No bon de travail
													<p-sortIcon field="bonTravail.numero"></p-sortIcon>
												</div>
											</th>
											<th rowspan="2" style="min-width: 7rem; width: 7rem;"></th>
										</tr>

										<tr>
											<th *ngIf="afficherHeures" pSortableColumn="heureFait">
												<div class="flex justify-content-between align-items-center">
													Fait
													<p-sortIcon field="heuresFait"></p-sortIcon>
													<p-columnFilter type="numeric" field="heureFait" display="menu"
														class="ml-auto">
													</p-columnFilter>
												</div>
											</th>
											<th *ngIf="afficherHeures" pSortableColumn="heureDu">
												<div class="flex justify-content-between align-items-center">
													Dû
													<p-sortIcon field="heuresDu"></p-sortIcon>
													<p-columnFilter type="numeric" field="heureDu" display="menu"
														class="ml-auto">
													</p-columnFilter>
												</div>
											</th>
											<th *ngIf="afficherHeures" pSortableColumn="heureEcart">
												<div class="flex justify-content-between align-items-center">
													Écart
													<p-sortIcon field="heuresEcart"></p-sortIcon>
													<p-columnFilter type="numeric" field="heureEcart" display="menu"
														class="ml-auto">
													</p-columnFilter>
												</div>
											</th>
											<th *ngIf="afficherKms" pSortableColumn="kmFait">
												<div class="flex justify-content-between align-items-center">
													Fait
													<p-sortIcon field="heuresFait"></p-sortIcon>
													<p-columnFilter type="numeric" field="kmFait" display="menu"
														class="ml-auto">
													</p-columnFilter>
												</div>
											</th>
											<th *ngIf="afficherKms" pSortableColumn="heuresDu">
												<div class="flex justify-content-between align-items-center">
													Dû
													<p-sortIcon field="kmDu"></p-sortIcon>
													<p-columnFilter type="numeric" field="kmDu" display="menu"
														class="ml-auto">
													</p-columnFilter>
												</div>
											</th>
											<th *ngIf="afficherKms" pSortableColumn="kmEcart">
												<div class="flex justify-content-between align-items-center">
													Écart
													<p-sortIcon field="heuresEcart"></p-sortIcon>
													<p-columnFilter type="numeric" field="kmEcart" display="menu"
														class="ml-auto">
													</p-columnFilter>
												</div>
											</th>
											<th *ngIf="afficherMois" pSortableColumn="moisFait">
												<div class="flex justify-content-between align-items-center">
													Fait
													<p-sortIcon field="heuresFait"></p-sortIcon>
													<p-columnFilter type="numeric" field="moisFait" display="menu"
														class="ml-auto">
													</p-columnFilter>
												</div>
											</th>
											<th *ngIf="afficherMois" pSortableColumn="moisDu">
												<div class="flex justify-content-between align-items-center">
													Dû
													<p-sortIcon field="heuresDu"></p-sortIcon>
													<p-columnFilter type="numeric" field="moisDu" display="menu"
														class="ml-auto">
													</p-columnFilter>
												</div>
											</th>
											<th *ngIf="afficherMois" pSortableColumn="moisEcart">
												<div class="flex justify-content-between align-items-center">
													Écart
													<p-sortIcon field="heuresEcart"></p-sortIcon>
													<p-columnFilter type="numeric" field="moisEcart" display="menu"
														class="ml-auto">
													</p-columnFilter>
												</div>
											</th>
										</tr>
									</ng-template>
									<ng-template pTemplate="body" let-preventif let-columns="columns">
										<tr *ngIf="!preventif.indCalculEnCours" [pContextMenuRow]="preventif">
											<td>
												<p-tableCheckbox *ngIf="!preventif.bonTravail" [value]="preventif"></p-tableCheckbox>
											</td>
											<td>{{preventif.description}}</td>
											<td><span
													[class]="'statut ' + preventif.statut.nom.toLowerCase().replace(' ','')">
													{{preventif.statut.nom}}
												</span>
											</td>
											<td *ngIf="afficherHeures">{{preventif.heureFaitCompteur}}</td>
											<td *ngIf="afficherHeures">{{preventif.heureDuCompteur}}</td>
											<td *ngIf="afficherHeures">{{preventif.heureEcart}}</td>
											<td *ngIf="afficherKms">{{preventif.kmFaitCompteur}}</td>
											<td *ngIf="afficherKms">{{preventif.kmDuCompteur}}</td>
											<td *ngIf="afficherKms">{{preventif.kmEcart}}</td>
											<td *ngIf="afficherMois">{{preventif.moisFait | date:'yyyy-MM-dd' }}</td>
											<td *ngIf="afficherMois">{{preventif.moisDu | date:'yyyy-MM-dd'}}</td>
											<td *ngIf="afficherMois">{{preventif.moisEcart}}</td>
											<td>{{preventif.bonTravail?.numero}}</td>
											<td class="celluleTableau">
												<div class="flex">
													<button pButton pRipple icon="pi pi-bars"
														class="p-button-secondary mr-2 boutonGrille"
														(click)="surMenuContextuelEntretienPreventif($event, preventif)"></button>
												</div>
											</td>
										</tr>
										<tr *ngIf="preventif.indCalculEnCours" [pContextMenuRow]="preventif">
											<td>
											</td>
											<td>{{preventif.description}} (calcul en cours...)</td>
											<td>---</td>
											<td *ngIf="afficherHeures">---</td>
											<td *ngIf="afficherHeures">---</td>
											<td *ngIf="afficherHeures">---</td>
											<td *ngIf="afficherKms">---</td>
											<td *ngIf="afficherKms">---</td>
											<td *ngIf="afficherKms">---</td>
											<td *ngIf="afficherMois">---</td>
											<td *ngIf="afficherMois">---</td>
											<td *ngIf="afficherMois">---</td>
											<td>---</td>
											<td class="celluleTableau">
												<div class="flex">
													<button pButton pRipple icon="pi pi-bars"
														class="p-button-secondary mr-2 boutonGrille"
														(click)="surMenuContextuelEntretienPreventif($event, preventif)"></button>
												</div>
											</td>
										</tr>
									</ng-template>
									<ng-template pTemplate="emptymessage">
										<tr>
											<td colspan="8">Aucun item.</td>
										</tr>
									</ng-template>
								</p-table>

							</p-tabPanel>
							<!-- #endregion -->

							<!-- #region Entretiens correctifs -->
							<p-tabPanel *ngIf="estAutorise('unite_correctifs') || estAutorise('temp_mec')"
								header="Correctifs" leftIcon="pi pi-briefcase">


								<!------------ CORRECTIFS ------------>

								<p-card *ngIf="!chargementCompleteEntretiensCorrectifs">
									<div class="fluid formgrid grid">
										<div class="flex flex-column gap-2 my-2 col-12 text-center">
											<p-progressSpinner></p-progressSpinner>
										</div>
									</div>
								</p-card>


								<p-menu #menuContextuelEntretiensCorrectifs popup="popup"
									[model]="contenuMenuContextuelEntretiensCorrectifs"></p-menu>

									<div *ngIf="chargementCompleteEntretiensCorrectifs" class="fluid formgrid grid">


										<div class="flex flex-column gap-2 my-2 col-12 md:col-3">
											<label for="lastname">Code de catégorie</label>
											<p-dropdown [options]="listeCategoriesEntretien"
												[(ngModel)]="selectionCategorieEntretienCorrectifs" [showClear]="true"
												optionLabel="description" [showClear]="true" filter="true"
												filterBy="code,description" appendTo="body" (onChange)="filtrerEntretiensCorrectifs()"
												placeholder="Sélectionner une catégorie...">
												<ng-template pTemplate="selectedItem">
													<div *ngIf="selectionCategorieEntretienCorrectifs">
														<div>{{selectionCategorieEntretienCorrectifs.code}} -
															{{selectionCategorieEntretienCorrectifs.description}}
														</div>
													</div>
												</ng-template>
												<ng-template let-categorie pTemplate="item">
													<div>
														<div>{{categorie.code}} - {{categorie.description}}</div>
													</div>
												</ng-template>
											</p-dropdown>
	
	
										</div>
									</div>

								<p-table *ngIf="chargementCompleteEntretiensCorrectifs" #dt
									[value]="entretiensCorrectifsFiltrees" [columns]="selectedColumns"
									dataKey="entretienCorrectifId" autoLayout="true" tableStyleClass="tableNormale"
									[(selection)]="selectionEntretiensCorrectifs"
									(onRowSelect)="surChangementSelectionEntretiensCorrectifs()"
									(onRowUnselect)="surChangementSelectionEntretiensCorrectifs()"
									(onHeaderCheckboxToggle)="surChangementSelectionEntretiensCorrectifs()"
									 [rowHover]="true" [filterDelay]="0"
									[globalFilterFields]="['description', 'note','categorie.description','bonTravail.numero']">
									<ng-template pTemplate="caption">
										<div class="flex align-content-center justify-content-between">
											<div>
												<span class="p-input-icon-left">
													<i class="pi pi-search"></i>
													<input #rechercheItem pInputText type="text"
														(input)="dt.filterGlobal($event.target.value, 'contains')"
														(keyup.enter)="surEntreeRechercheCorrectifs()"
														placeholder="Recherche..." />
												</span>
												<button *ngIf="estAutorise('entretien_correctif_creer') || estAutorise('temp_mec')" pButton pRipple
													icon="pi pi-plus"
													class="p-button-success mr-2 ml-4 mb-2 boutonGrille"
													(click)="ajouterEntretienCorrectif()"></button>
												<button *ngIf="estAutorise('temp_mec')" pButton pRipple
													icon="pi pi-check-square"
													class="p-button-success mr-2 ml-4 mb-2 boutonGrille"
													[disabled]="!selectionEntretiensCorrectifs || selectionEntretiensCorrectifs.length == 0"
													(click)="assignerEntretiensCorrectifs(selectionEntretiensCorrectifs)"></button>
											</div>
											<div>
												<h5 class="titreTableau m-0">Entretiens correctifs</h5>
											</div>

											<div>
												<p-multiSelect disabled="true" [style]="{minWidth: '200px'}"
													placeholder="Colonnes..."></p-multiSelect>
											</div>
										</div>
									</ng-template>
									<ng-template pTemplate="header" let-columns>
										<tr>
											<th style="min-width: 3rem">
											
											</th>
											<th pSortableColumn="etat">
												<div class="flex justify-content-between align-items-center">
													Sévérité
													<p-sortIcon field="etat"></p-sortIcon>
												</div>
											</th>
											<th pSortableColumn="description">
												<div class="flex justify-content-between align-items-center">
													Description
													<p-sortIcon field="description"></p-sortIcon>
													<p-columnFilter type="text" field="description" display="menu"
														class="ml-auto">
													</p-columnFilter>
												</div>
											</th>
											<th>
												<div class="flex justify-content-between align-items-center">
													Statut
													<p-columnFilter field="statut.nom" matchMode="in" display="menu" [showMatchModes]="false"
														[showOperator]="false" [showAddButton]="false">
														<ng-template pTemplate="filter" let-value let-filter="filterCallback">
															<p-multiSelect [ngModel]="value" [options]="filtresStatut" placeholder="Any"
																(onChange)="filter($event.value)" optionLabel="nom">
																<ng-template let-option pTemplate="item">
																	<div class="multiselect-representative-option">
					
																		<span class="ml-1">{{option}}</span>
																	</div>
																</ng-template>
															</p-multiSelect>
														</ng-template>
													</p-columnFilter>
												</div>
											</th>
											<th>
												<div class="flex justify-content-between align-items-center">
													Catégorie
													<p-columnFilter field="categorie.description" matchMode="in" display="menu" [showMatchModes]="false"
														[showOperator]="false" [showAddButton]="false">
														<ng-template pTemplate="filter" let-value let-filter="filterCallback">
															<p-multiSelect [ngModel]="value" [options]="filtresCategorieEntretien" placeholder="Any"
																(onChange)="filter($event.value)" optionLabel="description">
																<ng-template let-option pTemplate="item">
																	<div class="multiselect-representative-option">
					
																		<span class="ml-1">{{option}}</span>
																	</div>
																</ng-template>
															</p-multiSelect>
														</ng-template>
													</p-columnFilter>
												</div>
											</th>
											<!-- <th pSortableColumn="etat">
												<div class="flex justify-content-between align-items-center">
													Statut
													<p-sortIcon field="etat"></p-sortIcon>
												</div>
											</th>
											<th pSortableColumn="categorie.description">
												<div class="flex justify-content-between align-items-center">
													Catégorie
													<p-sortIcon field="categorie.description"></p-sortIcon>
												</div>
											</th> -->
											<th pSortableColumn="saisiPar">
												<div class="flex justify-content-between align-items-center">
													Saisi par
													<p-sortIcon field="saisiPar"></p-sortIcon>
													<p-columnFilter type="text" field="saisiPar" display="menu"
														class="ml-auto">
													</p-columnFilter>
												</div>
											</th>
											<th pSortableColumn="date">
												<div class="flex justify-content-between align-items-center">
													Date création
													<p-sortIcon field="date"></p-sortIcon>
													<p-columnFilter type="date" field="date" display="menu"
														class="ml-auto">
													</p-columnFilter>
												</div>
											</th>
											<th pSortableColumn="etat">
												<div class="flex justify-content-between align-items-center">
													No bon de travail
													<p-sortIcon field="etat"></p-sortIcon>
												</div>
											</th>
											<th pSortableColumn="bonTravail.dateTravail">
												<div class="flex justify-content-between align-items-center">
													Date BT
													<p-sortIcon field="bonTravail.dateTravail"></p-sortIcon>
													<p-columnFilter type="bonTravail.dateTravail" field="date" display="menu"
														class="ml-auto">
													</p-columnFilter>
												</div>
											</th>
											<th pSortableColumn="indPieceEnCommande">
												<div class="flex justify-content-between align-items-center">
													Pièce en commande
													<p-sortIcon field="indPieceEnCommande"></p-sortIcon>
												</div>
											</th>
											<th pSortableColumn="note">
												<div class="flex justify-content-between align-items-center">
													Note
													<p-sortIcon field="note"></p-sortIcon>
												</div>
											</th>
											<th style="min-width: 7rem; width: 7rem;"></th>

										</tr>


									</ng-template>
									<ng-template pTemplate="body" let-correctif let-columns="columns">
										<tr [pContextMenuRow]="correctif">
											<td>
												<p-tableCheckbox *ngIf="!correctif.bonTravail" [value]="correctif"></p-tableCheckbox>
											</td>
											<td><span
													[class]="'severite ' + correctif.severite.nom.toLowerCase().replace(' ', '')">
													{{correctif.severite.nom}}
												</span></td>
											<td>{{correctif.description}}</td>
											<td><span
													[class]="'statut ' + correctif.statut.nom.toLowerCase().replace(' ','')">
													{{correctif.statut.nom}}
												</span>
											</td>
											<td>{{correctif.categorie?.description}}</td>
											<td>{{correctif.employe | nomEmploye}}</td>
											<td>{{correctif.dateCreation | date:'yyyy-MM-dd'}}</td>
											<td>{{correctif.bonTravail?.numero}}</td>
											<td>{{correctif.bonTravail?.dateTravail | date:'yyyy-MM-dd'}}</td>
											<td>
												<p-checkbox [disabled]="true" [binary]="true" [(ngModel)]="correctif.indPieceEnCommande" style="display: block;"></p-checkbox>
											</td>
											<td>{{correctif.note}}</td>
											<td class="celluleTableau">
												<div class="flex">
													<button pButton pRipple icon="pi pi-bars"
														class="p-button-secondary mr-2 boutonGrille"
														(click)="surMenuContextuelEntretienCorrectif($event, correctif)"></button>
												</div>
											</td>
 
										</tr>
									</ng-template>
									<ng-template pTemplate="emptymessage">
										<tr>
											<td colspan="12">Aucun entretien.</td>
										</tr>
									</ng-template>
								</p-table>
							</p-tabPanel>
							<!-- #endregion -->

							<!-- #region Bons de travail -->
							<p-tabPanel *ngIf="estAutorise('unite_bons_travail') || estAutorise('temp_mec')"
								header="Bons de travail" leftIcon="pi pi-check-square">

								<p-card *ngIf="!chargementCompleteBonsTravail">
									<div class="fluid formgrid grid">
										<div class="flex flex-column gap-2 my-2 col-12 text-center">
											<p-progressSpinner></p-progressSpinner>
										</div>
									</div>
								</p-card>

								<p-table *ngIf="chargementCompleteBonsTravail" #dt [value]="item.bonsTravail" [columns]="selectedColumns"
									dataKey="bonTravailId" autoLayout="true" tableStyleClass="tableNormale"
									 [rowHover]="true" [rows]="100"
									[paginator]="true" [rowsPerPageOptions]="[10,25,50,100]" [filterDelay]="0"
									[globalFilterFields]="['numero','date']">
									<ng-template pTemplate="caption">
										<div class="flex align-content-center justify-content-between">
											<div>
												<span class="p-input-icon-left">
													<i class="pi pi-search"></i>
													<input pInputText type="text"
														(input)="dt.filterGlobal($event.target.value, 'contains')"
														placeholder="Recherche..." />
												</span>
												<button *ngIf="estAutorise('bontravail_creer') || estAutorise('temp_mec')" pButton pRipple
													icon="pi pi-plus"
													class="p-button-success mr-2 ml-4 mb-2 boutonGrille"
													(click)="AjouterBdt()"></button>
											</div>
											<h5 class="titreTableau m-0">Bons de travail</h5>
											<div>
												<p-multiSelect [options]="colonnesDisponibles"
													[(ngModel)]="selectedColumns" optionLabel="entete"
													selectedItemsLabel="{0} colonnes sélectionnés"
													[style]="{minWidth: '200px'}" placeholder="Colonnes...">
												</p-multiSelect>
											</div>
										</div>
									</ng-template>

									<ng-template pTemplate="header" let-columns>
										<tr>
											<th pSortableColumn="numero">
												<div class="flex justify-content-between align-items-center">
													Numéro
													<p-sortIcon field="numero"></p-sortIcon>
													<p-columnFilter type="text" field="numero" display="menu"
														class="ml-auto">
													</p-columnFilter>
												</div>
											</th>
											<th pSortableColumn="dateDebut">
												<div class="flex justify-content-between align-items-center">
													Date
													<p-sortIcon field="dateDebut"></p-sortIcon>
													<p-columnFilter type="date" field="dateDebut" display="menu"
														class="ml-auto">
													</p-columnFilter>
												</div>
											</th>
											<th pSortableColumn="item.numero">
												<div class="flex justify-content-between align-items-center">
													Unité
													<p-sortIcon field="item.numero"></p-sortIcon>
													<p-columnFilter type="text" field="item.numero" display="menu"
														class="ml-auto">
													</p-columnFilter>
												</div>
											</th>
											<th pSortableColumn="item.description">
												<div class="flex justify-content-between align-items-center">
													Description
													<p-sortIcon field="item.description"></p-sortIcon>
													<p-columnFilter type="text" field="item.description" display="menu"
														class="ml-auto">
													</p-columnFilter>
												</div>
											</th>
											<th pSortableColumn="heuresCompteur">
												<div class="flex justify-content-between align-items-center">
													Heures
													<p-sortIcon field="heuresCompteur"></p-sortIcon>
													<p-columnFilter type="text" field="heuresCompteur" display="menu" class="ml-auto">
													</p-columnFilter>
												</div>
											</th>
											
											<th pSortableColumn="kmCompteur">
												<div class="flex justify-content-between align-items-center">
													KM
													<p-sortIcon field="kmCompteur"></p-sortIcon>
													<p-columnFilter type="text" field="kmCompteur" display="menu" class="ml-auto">
													</p-columnFilter>
												</div>
											</th>
											<th pSortableColumn="type">
												<div class="flex justify-content-between align-items-center">
													Statut
													<p-sortIcon field="type"></p-sortIcon>
													<p-columnFilter field="statut" matchMode="in" display="menu"
														[showMatchModes]="false" [showOperator]="false"
														[showAddButton]="false">
														<ng-template pTemplate="filter" let-value
															let-filter="filterCallback">
															<p-multiSelect [ngModel]="value" [options]="statuts"
																placeholder="Any" (onChange)="filter($event.value)"
																optionLabel="name">
																<ng-template let-option pTemplate="item">
																	<div class="multiselect-representative-option">

																		<span class="ml-1">{{option.nom}}</span>
																	</div>
																</ng-template>
															</p-multiSelect>
														</ng-template>
													</p-columnFilter>
												</div>
											</th>
											<th pSortableColumn="contreMaitre.prenom">
												<div class="flex justify-content-between align-items-center">
													Contre-Maître
													<p-sortIcon field="contreMaitre.prenom"></p-sortIcon>
													<p-columnFilter type="text" field="contreMaitre.prenom"
														display="menu" class="ml-auto">
													</p-columnFilter>
												</div>
											</th>
											<th pSortableColumn="mecanicien.prenom">
												<div class="flex justify-content-between align-items-center">
													Mécanicien
													<p-sortIcon field="mecanicien.prenom"></p-sortIcon>
													<p-columnFilter type="text" field="mecanicien.prenom" display="menu"
														class="ml-auto">
													</p-columnFilter>
												</div>
											</th>
											<th style="min-width: 10rem; width: 10rem;"></th>
										</tr>
									</ng-template>
									<ng-template pTemplate="body" let-bdt let-columns="columns">
										<tr>
											<td>{{bdt.numero}}</td>
											<td>{{bdt.dateTravail | date:'yyyy-MM-dd'}}</td>
											<td>{{bdt.item.numero}}</td>
											<td>{{bdt.item.description}}</td>
											<td>
												{{bdt.heuresCompteur | valeurVide}}
											</td>
											<td>
												{{bdt.kmCompteur | valeurVide}}
											</td>
											<td><span
													[class]="'statut ' + bdt.statut.nom.toLowerCase().replace(' ', '')">
													{{bdt.statut.nom}}
												</span></td>
											<td>{{bdt.contreMaitre | nomEmploye}}</td>
											<td>{{bdt.mecanicien | nomEmploye}}</td>
											<td class="celluleTableau">
												<div class="flex">
													<button *ngIf="estAutorise('temp_mec') || estAutorise('bonstravail')" pButton pRipple icon="pi pi-eye"
														class="p-button-success mr-2 boutonGrille"
														(click)="OuvrirDetailBdt(bdt.bonTravailId)"></button>
													<button *ngIf="estAutorise('temp_mec') || estAutorise('bonstravail')" pButton pRipple
														icon="pi pi-external-link"
														class="p-button-secondary mr-2 boutonGrille"
														(click)="OuvrirDetailBdtNouvelOnglet(bdt.bonTravailId)"></button>
													<button pButton pRipple icon="pi pi-bars"
														class="p-button-secondary mr-2 boutonGrille"
														(click)="surMenuContextuelItem($event, bdt)"></button>	
												</div>
											</td>
										</tr>
									</ng-template>
									<ng-template pTemplate="emptymessage">
										<tr>
											<td colspan="6">Aucun bon de travail.</td>
										</tr>
									</ng-template>
								</p-table>

							</p-tabPanel>
							<!-- #endregion -->
							
							<!-- #region VADs -->
							<p-tabPanel *ngIf="estAutorise('unite_vads') || estAutorise('temp_mec')"
								header="Vérifications avant départ" leftIcon="pi pi-briefcase">


								<p-card *ngIf="!chargementCompleteVad">
									<div class="fluid formgrid grid">
										<div class="flex flex-column gap-2 my-2 col-12 text-center">
											<p-progressSpinner></p-progressSpinner>
										</div>
									</div>
								</p-card>

								<!------------ VADs ------------>

								<h3 *ngIf="chargementCompleteVad">À venir</h3>

								<!-- <p-menu #menuContextuelVads popup="popup" [model]="contenuMenuContextuelVads"></p-menu>

								<p-table *ngIf="chargementCompleteVad" #dtVads [value]="item.vads" [columns]="selectedColumns" dataKey="vadId"
									autoLayout="true" tableStyleClass="tableNormale" [(selection)]="selectionVads"
									(onRowSelect)="surChangementSelectionVads()"
									(onRowUnselect)="surChangementSelectionVads()"
									(onHeaderCheckboxToggle)="surChangementSelectionVads()"
									 [rowHover]="true" [rows]="10"
									[paginator]="true" [filterDelay]="0" [globalFilterFields]="['description']">
									<ng-template pTemplate="caption">
										<div class="flex align-content-center justify-content-between">
											<div>
												<span class="p-input-icon-left">
													<i class="pi pi-search"></i>
													<input #rechercheVad pInputText type="text"
														(input)="dtVads.filterGlobal($event.target.value, 'contains')"
														(keyup.enter)="surEntreeRechercheCorrectifs()"
														placeholder="Recherche..." />
												</span>
											</div>
											<div>
												<h5 class="titreTableau m-0">Vérifications avant départ</h5>
											</div>

											<div>
												<p-multiSelect disabled="true" [style]="{minWidth: '200px'}"
													placeholder="Colonnes..."></p-multiSelect>
											</div>
										</div>
									</ng-template>
									<ng-template pTemplate="header" let-columns>
										<tr>
											<th pSortableColumn="date">
												<div class="flex justify-content-between align-items-center">
													Date
													<p-sortIcon field="date"></p-sortIcon>
													<p-columnFilter type="date" field="date" display="menu"
														class="ml-auto">
													</p-columnFilter>
												</div>
											</th>
											<th pSortableColumn="description">
												<div class="flex justify-content-between align-items-center">
													Description
													<p-sortIcon field="description"></p-sortIcon>
													<p-columnFilter type="text" field="description" display="menu"
														class="ml-auto">
													</p-columnFilter>
												</div>
											</th>
											<th pSortableColumn="saisiPar">
												<div class="flex justify-content-between align-items-center">
													Chauffeur
													<p-sortIcon field="saisiPar"></p-sortIcon>
													<p-columnFilter type="text" field="saisiPar" display="menu"
														class="ml-auto">
													</p-columnFilter>
												</div>
											</th>
											<th pSortableColumn="date">
												<div class="flex justify-content-between align-items-center">
													Statut
													<p-sortIcon field="date"></p-sortIcon>
													<p-columnFilter type="date" field="date" display="menu"
														class="ml-auto">
													</p-columnFilter>
												</div>
											</th>
											<th pSortableColumn="saisiPar">
												<div class="flex justify-content-between align-items-center">
													Nb total points
													<p-sortIcon field="saisiPar"></p-sortIcon>
													<p-columnFilter type="text" field="saisiPar" display="menu"
														class="ml-auto">
													</p-columnFilter>
												</div>
											</th>
											<th pSortableColumn="saisiPar">
												<div class="flex justify-content-between align-items-center">
													Nb points défecteux
													<p-sortIcon field="saisiPar"></p-sortIcon>
													<p-columnFilter type="text" field="saisiPar" display="menu"
														class="ml-auto">
													</p-columnFilter>
												</div>
											</th>
											<th pSortableColumn="saisiPar">
												<div class="flex justify-content-between align-items-center">
													Note
													<p-sortIcon field="saisiPar"></p-sortIcon>
													<p-columnFilter type="text" field="saisiPar" display="menu"
														class="ml-auto">
													</p-columnFilter>
												</div>
											</th>

											<th style="min-width: 7rem; width: 7rem;"></th>

										</tr>


									</ng-template>
									<ng-template pTemplate="body" let-vad let-columns="columns">
										<tr [pContextMenuRow]="correctif">
											<td>{{vad.date | date:'yyyy-MM-dd HH:mm:ss'}}</td>
											<td>{{vad.description}}</td>
											<td>{{vad.chauffeur}}</td>
											<td><span
													[class]="'statut ' + vad.statut.nom.toLowerCase().replace(' ','')">
													{{vad.statut.nom}}
												</span>
											</td>

											<td>{{vad.nbItems}}</td>
											<td>{{vad.nbDefauts}}</td>
											<td>{{vad.notes}}</td>

											<td class="celluleTableau flex">
												<button *ngIf="estAutorise('temp_mec')" pButton pRipple
													icon="pi pi-eye" class="p-button-success mr-2 boutonGrille"
													(click)="ouvrirDetailVad(vad)"></button>
												
												<button pButton pRipple icon="pi pi-bars"
													class="p-button-secondary mr-2 boutonGrille"
													(click)="surMenuContextuelVad($event, vad)"></button>
											</td>

										</tr>
									</ng-template>
									<ng-template pTemplate="emptymessage">
										<tr>
											<td colspan="8">Aucune vérification avant départ.</td>
										</tr>
									</ng-template>
								</p-table> -->



							</p-tabPanel>
							<!-- #endregion -->

							<!-- #region Pièces -->
							<p-tabPanel *ngIf="estAutorise('unite_pieces') || estAutorise('temp_mec')" header="Pièces"
								leftIcon="pi pi-briefcase">

								<p-card *ngIf="!chargementCompletePieces">
									<div class="fluid formgrid grid">
										<div class="flex flex-column gap-2 my-2 col-12 text-center">
											<p-progressSpinner></p-progressSpinner>
										</div>
									</div>
								</p-card>


								<p-menu #menuContextuelPieces popup="popup" [model]="contenuMenuContextuelPieces">
								</p-menu>

								<div *ngIf="chargementCompletePieces" class="fluid formgrid grid">


									<div class="flex flex-column gap-2 my-2 col-12 md:col-3">
										<label for="lastname">Code de catégorie</label>
										<p-dropdown [options]="listeCategoriesEntretien"
											[(ngModel)]="selectionCategorieEntretien" [showClear]="true"
											optionLabel="description" [showClear]="true" filter="true"
											filterBy="code,description" appendTo="body" (onChange)="filtrerPieces()"
											placeholder="Sélectionner une catégorie...">
											<ng-template pTemplate="selectedItem">
												<div *ngIf="selectionCategorieEntretien">
													<div>{{selectionCategorieEntretien.code}} -
														{{selectionCategorieEntretien.description}}
													</div>
												</div>
											</ng-template>
											<ng-template let-categorie pTemplate="item">
												<div>
													<div>{{categorie.code}} - {{categorie.description}}</div>
												</div>
											</ng-template>
										</p-dropdown>


									</div>
								</div>


								<p-table *ngIf="chargementCompletePieces" #dtPieces [value]="piecesFiltrees"
									[columns]="selectedColumns" dataKey="pieceUniteId" autoLayout="true"
									tableStyleClass="tableNormale" 
									[rowHover]="true" [filterDelay]="0"
									[globalFilterFields]="['piece.numero','piece.description','piece.modele','piece.marque','categorieEntretien.code','categorieEntretien.description']">
									<ng-template pTemplate="caption">
										<div class="flex align-content-center justify-content-between">
											<div>
												<span class="p-input-icon-left">
													<i class="pi pi-search"></i>
													<input #rechercheItem pInputText type="text"
														(input)="dtPieces.filterGlobal($event.target.value, 'contains')"
														placeholder="Recherche..." />
												</span>
												<button *ngIf="estAutorise('piece_associer') || estAutorise('temp_mec')"
													pButton pRipple icon="pi pi-plus"
													class="p-button-success mr-2 ml-4 mb-2 boutonGrille"
													(click)="ajouterPiece()"></button>
											</div>
											<div>
												<h5 class="titreTableau m-0">Pièces</h5>
											</div>

											<div>
												<p-multiSelect disabled="true" [style]="{minWidth: '200px'}"
													placeholder="Colonnes..."></p-multiSelect>
											</div>
										</div>
									</ng-template>
									<ng-template pTemplate="header" let-columns>
										<tr>
											<th pSortableColumn="categorieEntretien.code">
												<div class="flex justify-content-between align-items-center">
													Catégorie
													<p-sortIcon field="categorieEntretien.code"></p-sortIcon>
													<p-columnFilter type="text" field="categorieEntretien.code"
														display="menu" class="ml-auto">
													</p-columnFilter>
												</div>
											</th>
											<th pSortableColumn="piece.numero">
												<div class="flex justify-content-between align-items-center">
													Numéro
													<p-sortIcon field="piece.numero"></p-sortIcon>
													<p-columnFilter type="text" field="piece.numero" display="menu"
														class="ml-auto">
													</p-columnFilter>
												</div>
											</th>
											<th pSortableColumn="piece.description">
												<div class="flex justify-content-between align-items-center">
													Description
													<p-sortIcon field="piece.description"></p-sortIcon>
													<p-columnFilter type="text" field="piece.description" display="menu"
														class="ml-auto">
													</p-columnFilter>
												</div>
											</th>
											<th pSortableColumn="piece.marque">
												<div class="flex justify-content-between align-items-center">
													Marque
													<p-sortIcon field="piece.marque"></p-sortIcon>
													<p-columnFilter type="text" field="piece.marque" display="menu"
														class="ml-auto">
													</p-columnFilter>
												</div>
											</th>
											<th pSortableColumn="piece.modele">
												<div class="flex justify-content-between align-items-center">
													Modèle
													<p-sortIcon field="piece.modele"></p-sortIcon>
													<p-columnFilter type="text" field="piece.modele" display="menu"
														class="ml-auto">
													</p-columnFilter>
												</div>
											</th>
											<th pSortableColumn="piece.description">
												<div class="flex justify-content-between align-items-center">
													Fournisseur principal
													<p-sortIcon field="piece.description"></p-sortIcon>
													<p-columnFilter type="text" field="piece.description" display="menu"
														class="ml-auto">
													</p-columnFilter>
												</div>
											</th>
											<th pSortableColumn="note">
												<div class="flex justify-content-between align-items-center">
													Note
													<p-sortIcon field="note"></p-sortIcon>
													<p-columnFilter type="text" field="note" display="menu"
														class="ml-auto">
													</p-columnFilter>
												</div>
											</th>
											<th style="min-width: 7rem; width: 7rem;"></th>

										</tr>


									</ng-template>
									<ng-template pTemplate="body" let-piece let-columns="columns">
										<tr>



											<td>{{piece.categorieEntretien?.code}} -
												{{piece.categorieEntretien?.description}}</td>
											<td>{{piece.piece.numero}}</td>
											<td>{{piece.piece.description}}</td>
											<td>{{piece.piece.marque}}</td>
											<td>{{piece.piece.modele}}</td>
											<td *ngIf="piece.fournisseurPrincipal">
												{{piece.fournisseurPrincipal?.nom}}
												({{piece.fournisseurPrincipal?.code}})
											</td>
											<td *ngIf="!piece.fournisseurPrincipal"></td>
											<td>{{piece.note}}</td>

											<td class="celluleTableau">
												<div class="flex">
													<button pButton pRipple icon="pi pi-bars"
														class="p-button-secondary mr-2 boutonGrille"
														(click)="surMenuContextuelPiece($event, piece)"></button>
												</div>
											</td>

										</tr>
									</ng-template>
									<ng-template pTemplate="emptymessage">
										<tr>
											<td colspan="7">Aucune pièce.</td>
										</tr>
									</ng-template>
								</p-table>




							</p-tabPanel>
							<!-- #endregion -->

							<!-- #region Historique achats -->
							<p-tabPanel *ngIf="estAutorise('temp_mec') || estAutorise('unite_historique_achat')"
								header="Historique d'achat de pièces" leftIcon="pi pi-dollar">
								<p-menu #menuContextuelHistoriqueAchatPieces popup="popup"
									[model]="contenuMenuContextuelHistoriqueAchatPieces"></p-menu>

								<p-card *ngIf="!chargementCompleteHistoriqueAchatPieces">
									<div class="fluid formgrid grid">
										<div class="flex flex-column gap-2 my-2 col-12 text-center">
											<p-progressSpinner></p-progressSpinner>
										</div>
									</div>
								</p-card>
								<p-table *ngIf="chargementCompleteHistoriqueAchatPieces" #dtHistoriqueAchatPieces
									tableStyleClass="tableNormale" [value]="item.historiqueAchatPieces"
									autoLayout="true" dataKey="id" [globalFilterFields]="['bonCommande.numero']">
									<!-- <ng-template pTemplate="caption">
										<div class="flex p-flex-column p-flex-md-row p-jc-md-between table-header">
											<span class="p-input-icon-left">
												<i class="pi pi-search"></i>
												<input pInputText type="text"
													(input)="dtHistoriqueAchatPieces.filterGlobal($event.target.value, 'contains')"
													placeholder="Recherche..." />
											</span>
										</div>
									</ng-template> -->
									<ng-template pTemplate="header">
										<tr>
											<th pSortableColumn="item.numero">
												<div class="flex justify-content-between align-items-center">
													Numéro
													<p-sortIcon field="item.numero"></p-sortIcon>
													<p-columnFilter type="text" field="item.numero" display="menu"
														class="ml-auto">
													</p-columnFilter>
												</div>
											</th>
											<th pSortableColumn="item.description">
												<div class="flex justify-content-between align-items-center">
													Description
													<p-sortIcon field="item.description"></p-sortIcon>
													<p-columnFilter type="text" field="item.description" display="menu"
														class="ml-auto">
													</p-columnFilter>
												</div>
											</th>
											<th pSortableColumn="item.marque">
												<div class="flex justify-content-between align-items-center">
													Marque
													<p-sortIcon field="item.marque"></p-sortIcon>
													<p-columnFilter type="text" field="item.marque" display="menu"
														class="ml-auto">
													</p-columnFilter>
												</div>
											</th>
											<th pSortableColumn="item.modele">
												<div class="flex justify-content-between align-items-center">
													Modèle
													<p-sortIcon field="item.modele"></p-sortIcon>
													<p-columnFilter type="text" field="item.modele" display="menu"
														class="ml-auto">
													</p-columnFilter>
												</div>
											</th>
											<th pSortableColumn="bonCommande.numero">
												<div class="flex justify-content-between align-items-center">
													No BDC
													<p-sortIcon field="bonCommande.numero"></p-sortIcon>
													<p-columnFilter type="text" field="bonCommande.numero"
														display="menu" class="ml-auto">
													</p-columnFilter>
												</div>
											</th>
											<th pSortableColumn="bonCommande.dateAchat">
												<div class="flex justify-content-between align-items-center">
													Date
													<p-sortIcon field="bonCommande.dateAchat"></p-sortIcon>
													<p-columnFilter type="date" field="bonCommande.dateAchat"
														display="menu" class="ml-auto">
													</p-columnFilter>
												</div>
											</th>
											<th>
												<div class="flex justify-content-between align-items-center">
													Statut
												</div>
											</th>

											<th pSortableColumn="bonCommande.fournisseur.nom">
												<div class="flex justify-content-between align-items-center">
													Fournisseur
													<p-sortIcon field="bonCommande.fournisseur.nom"></p-sortIcon>
													<p-columnFilter type="text" field="bonCommande.fournisseur.nom"
														display="menu" class="ml-auto">
													</p-columnFilter>
												</div>
											</th>
											<th pSortableColumn="quantite">
												<div class="flex justify-content-between align-items-center">
													Quantité
													<p-sortIcon field="quantite"></p-sortIcon>
													<p-columnFilter type="number" field="quantite" display="menu"
														class="ml-auto">
													</p-columnFilter>
												</div>
											</th>
											<th pSortableColumn="prix">
												<div class="flex justify-content-between align-items-center">
													Prix
													<p-sortIcon field="prix"></p-sortIcon>
													<p-columnFilter type="text" field="prix" display="menu"
														class="ml-auto">
													</p-columnFilter>
												</div>
											</th>
											<th *ngIf="!modeApercu" style="min-width: 5rem;"></th>
										</tr>
									</ng-template>
									<ng-template pTemplate="body" let-achat>
										<tr [pContextMenuRow]="achat">
											<td>{{achat.item.numero}}
											</td>
											<td>{{achat.item.description}}</td>

											<td>
												{{achat.item.marque}}
											</td>
											<td>
												{{achat.item.modele}}
											</td>
											<td>
												{{achat.bonCommande.numero}}
											</td>
											<td>
												{{achat.bonCommande.dateAchat | date:'yyyy-MM-dd'}}
											</td>
											<td>
												<span
													[class]="'statut ' + achat.bonCommande.statut.nom.toLowerCase().replace(' ','')">
													{{achat.bonCommande.statut.nom}}
												</span>
											</td>
											<td>
												{{achat.bonCommande.fournisseur.nom}}
												({{achat.bonCommande.fournisseur.numero}})
											</td>
											<td>
												{{achat.quantite}}
											</td>
											<td>
												{{achat.prix | currency}}
											</td>
											<td *ngIf="!modeApercu">
												<button
													*ngIf="estAutorise('bdc_detail') || estAutorise('fournisseur_detail') || estAutorise('bdc_creer')"
													pButton pRipple icon="pi pi-bars"
													class="p-button-secondary mr-2 boutonGrille"
													(click)="surMenuContextuelHistoriqueAchatPieces($event, achat)"></button>
											</td>
										</tr>
									</ng-template>
									<ng-template pTemplate="emptymessage">
										<tr>
											<td colspan="6">Aucun historique.</td>
										</tr>
									</ng-template>
								</p-table>
							</p-tabPanel>
							<!-- #endregion -->

							<!-- #region Historique envoi -->
							<p-tabPanel *ngIf="estAutorise('temp_mec') || estAutorise('unite_historique_envoi')"
								header="Historique d'envoi de pièces" leftIcon="pi pi-calendar">
								<p-menu #menuContextuelHistoriquePieces popup="popup"
									[model]="contenuMenuContextuelHistoriquePieces"></p-menu>

								<p-card *ngIf="!chargementCompleteHistoriquePieces">
									<div class="fluid formgrid grid">
										<div class="flex flex-column gap-2 my-2 col-12 text-center">
											<p-progressSpinner></p-progressSpinner>
										</div>
									</div>
								</p-card>

								<p-table *ngIf="chargementCompleteHistoriquePieces" #dtHistoriquePieces
									tableStyleClass="tableNormale" [value]="item.historiquePieces" autoLayout="true"
									dataKey="id"
									[globalFilterFields]="['requisition.numero','requisition.de.code','requisition.de.nom','requisition.a.code', 'requisition.a.nom', 'requisition.demandeur']">
									<ng-template pTemplate="caption">
										<div class="flex p-flex-column p-flex-md-row p-jc-md-between table-header">
											<span class="p-input-icon-left">
												<i class="pi pi-search"></i>
												<input pInputText type="text"
													(input)="dtHistoriquePieces.filterGlobal($event.target.value, 'contains')"
													placeholder="Recherche..." />
											</span>
										</div>
									</ng-template>
									<ng-template pTemplate="header">
										<tr>
											<th pSortableColumn="itemRequisition.item.numero">
												<div class="flex justify-content-between align-items-center">
													No pièce
													<p-sortIcon field="itemRequisition.item.numero"></p-sortIcon>
													<p-columnFilter type="text" field="itemRequisition.item.numero"
														display="menu" class="ml-auto">
													</p-columnFilter>
												</div>
											</th>
											<th pSortableColumn="itemRequisition.item.description">
												<div class="flex justify-content-between align-items-center">
													Description
													<p-sortIcon field="itemRequisition.item.description"></p-sortIcon>
													<p-columnFilter type="text" field="itemRequisition.item.description"
														display="menu" class="ml-auto">
													</p-columnFilter>
												</div>
											</th>
											<th pSortableColumn="itemRequisition.item.marque">
												<div class="flex justify-content-between align-items-center">
													Marque
													<p-sortIcon field="itemRequisition.item.marque"></p-sortIcon>
													<p-columnFilter type="text" field="itemRequisition.item.marque"
														display="menu" class="ml-auto">
													</p-columnFilter>
												</div>
											</th>
											<th pSortableColumn="itemRequisition.item.modele">
												<div class="flex justify-content-between align-items-center">
													Modèle
													<p-sortIcon field="itemRequisition.item.modele"></p-sortIcon>
													<p-columnFilter type="text" field="itemRequisition.item.modele"
														display="menu" class="ml-auto">
													</p-columnFilter>
												</div>
											</th>
											<th pSortableColumn="requisition.numero">
												<div class="flex justify-content-between align-items-center">
													No réquisition
													<p-sortIcon field="requisition.numero"></p-sortIcon>
													<p-columnFilter type="text" field="requisition.numero"
														display="menu" class="ml-auto">
													</p-columnFilter>
												</div>
											</th>
											<th pSortableColumn="requisition.date">
												<div class="flex justify-content-between align-items-center">
													Date
													<p-sortIcon field="requisition.date"></p-sortIcon>
													<p-columnFilter type="requisition.date" field="date" display="menu"
														class="ml-auto">
													</p-columnFilter>
												</div>
											</th>

											<th pSortableColumn="requisition.a.nom">
												<div class="flex justify-content-between align-items-center">
													Endroit
													<p-sortIcon field="requisition.a.nom"></p-sortIcon>
													<p-columnFilter type="text" field="requisition.a.nom" display="menu"
														class="ml-auto">
													</p-columnFilter>
												</div>
											</th>
											<th pSortableColumn="requisition.quantite">
												<div class="flex justify-content-between align-items-center">
													Quantité
													<p-sortIcon field="requisition.quantite"></p-sortIcon>
													<p-columnFilter type="text" field="requisition.quantite"
														display="menu" class="ml-auto">
													</p-columnFilter>
												</div>
											</th>
											<th *ngIf="!modeApercu" style="min-width: 5rem; width: 5rem;"></th>
										</tr>
									</ng-template>
									<ng-template pTemplate="body" let-historique>
										<tr [pContextMenuRow]="historique">
											<td>
												{{historique.itemRequisition.item.numero}}
											</td>
											<td>
												{{historique.itemRequisition.item.description}}
											</td>
											<td>
												{{historique.itemRequisition.item.marque}}
											</td>
											<td>
												{{historique.itemRequisition.item.modele}}
											</td>
											<td>
												{{historique.requisition.numero}}
											</td>
											<td>
												{{historique.requisition.date | date:'yyyy-MM-dd'}}
											</td>
											<td>
												{{historique.requisition.a.nom}} ({{historique.requisition.a.code}})
											</td>
											<td>
												{{historique.quantite}}
											</td>
											<td *ngIf="!modeApercu">
												<button *ngIf="estAutorise('temp_mec')" pButton pRipple
													icon="pi pi-bars" class="p-button-secondary mr-2 boutonGrille"
													(click)="surMenuContextuelHistoriquePieces($event, historique)"></button>
											</td>
										</tr>
									</ng-template>
									<ng-template pTemplate="emptymessage">
										<tr>
											<td colspan="6">Aucun historique.</td>
										</tr>
									</ng-template>
								</p-table>
							</p-tabPanel>
							<!-- #endregion -->

						</p-tabView>

					</div>

				</p-tabPanel>
				<!-- #endregion -->
				<!-- #region Notes  -->
				<p-tabPanel *ngIf="estAutorise('item_notes')" [header]="'Notes (' + item.nbEntreesNotes + ')'"
					leftIcon="pi pi-pencil">
					<p-menu #menuContextuelNote popup="popup" [model]="contenuMenuContextuelNote"></p-menu>

					<p-card *ngIf="!chargementCompleteNotes">
						<div class="fluid formgrid grid">
							<div class="flex flex-column gap-2 my-2 col-12 text-center">
								<p-progressSpinner></p-progressSpinner>
							</div>
						</div>
					</p-card>

					<div *ngIf="chargementCompleteNotes">
						<p-table #dtNotes [value]="item.notes" dataKey="noteId" tableStyleClass="tableNormale"
							autoLayout="true"  [rowHover]="true"
							[filterDelay]="0" [globalFilterFields]="['description']">
							<ng-template pTemplate="caption">
								<div class="flex align-content-center justify-content-between">
									<div>
										<span class="p-input-icon-left">
											<i class="pi pi-search"></i>
											<input pInputText type="text"
												(input)="dtNotes.filterGlobal($event.target.value, 'contains')"
												placeholder="Recherche..." />
										</span>
										<button *ngIf="estAutorise('item_note_creer') && !modeApercu" pButton pRipple icon="pi pi-plus"
											class="p-button-success mr-2 ml-4 mb-2 boutonGrille"
											(click)="ajouterNote()"></button>

									</div>
									<h5 class="titreTableau m-0">Notes</h5>
									<div>
										<p-multiSelect disabled="true" [style]="{minWidth: '200px'}"
											placeholder="Colonnes..."></p-multiSelect>
									</div>
								</div>
							</ng-template>
							<ng-template pTemplate="header">
								<tr>
									<th pSortableColumn="date">
										<div class="flex justify-content-between align-items-center">
											Date
											<p-sortIcon field="date"></p-sortIcon>
											<p-columnFilter type="date" field="date" display="menu" class="ml-auto">
											</p-columnFilter>
										</div>
									</th>
									<th pSortableColumn="description">
										<div class="flex justify-content-between align-items-center">
											Note
											<p-sortIcon field="description"></p-sortIcon>
											<p-columnFilter type="text" field="description" display="menu"
												class="ml-auto">
											</p-columnFilter>
										</div>
									</th>
									<th pSortableColumn="demandeur.nom">
										<div class="flex justify-content-between align-items-center">
											Auteur
											<p-sortIcon field="demandeur"></p-sortIcon>
											<p-columnFilter type="text" field="demandeur" display="menu"
												class="ml-auto">
											</p-columnFilter>
										</div>
									</th>
									<th *ngIf="!modeApercu" style="min-width: 5rem"></th>
								</tr>
							</ng-template>
							<ng-template pTemplate="body" let-note>

								<tr>
									<td>{{note.date | date:'yyyy-MM-dd'}}</td>
									<td>{{note.description}}</td>
									<td>{{note.employe | nomEmploye}}</td>
									<td *ngIf="!modeApercu">
										<button
											*ngIf="estAutorise('item_note_modifier') || estAutorise('item_note_supprimer')"
											pButton pRipple icon="pi pi-bars"
											class="p-button-secondary mr-2 boutonGrille"
											(click)="surMenuContextuelNote($event, note)"></button>
									</td>
								</tr>
							</ng-template>
							<ng-template pTemplate="emptymessage">
								<tr>
									<td colspan="4">Aucune note.</td>
								</tr>
							</ng-template>
						</p-table>
					</div>
				</p-tabPanel>
				<!-- #endregion -->
			</p-tabView>
		</div>
	</div>


</div>

<!-- #region Dialogues -->

<!-- ##########   DIALOGUE IMPRESSION   ########## -->

<p-dialog [(visible)]="dialogueImpression" [style]="{width: '20%'}" header="Options d'impression" [modal]="true"
	[contentStyle]="{'overflow': 'visible'}" styleClass="fluid formgrid grid">
	<ng-template pTemplate="content">

		<div class="formgrid grid">
			<div class="flex flex-column gap-2 my-2 col-6" style="vertical-align: top;">
				<button pButton pRipple label="Sélectionner tout" class="p-button-text"
					(click)="selectionnerTout()"></button>
			</div>
			<div class="flex flex-column gap-2 my-2 col-6" style="vertical-align: top;">
				<button pButton pRipple label="Désélectionner tout" class="p-button-text"
					(click)="deselectionnerTout()"></button>
			</div>
			<div class="flex flex-column gap-2 my-2 col-12" style="vertical-align: top;">
				<p-checkbox label="Identification" [binary]="true" [(ngModel)]="optionsImpression.identification"
					disabled="true"></p-checkbox>
			</div>
			<div class="flex flex-column gap-2 my-2 col-12" style="vertical-align: top;">
				<p-checkbox label="Attributs supplémentaires" [binary]="true" [(ngModel)]="optionsImpression.attributs"
					disabled="true"></p-checkbox>
			</div>
			<div class="flex flex-column gap-2 my-2 col-12" style="vertical-align: top;">
				<p-checkbox label="Historique" [binary]="true" [(ngModel)]="optionsImpression.historique"></p-checkbox>
			</div>
			<div class="flex flex-column gap-2 my-2 col-12" style="vertical-align: top;">
				<p-checkbox [disabled]="item.categorie.estUnique == true" label="Inventaire" [binary]="true"
					[(ngModel)]="optionsImpression.inventaire"></p-checkbox>
			</div>
			<div class="flex flex-column gap-2 my-2 col-12" style="vertical-align: top;">
				<p-checkbox [disabled]="item.categorie.estUnique == true" label="Fournisseurs" [binary]="true"
					[(ngModel)]="optionsImpression.fournisseurs">
				</p-checkbox>
			</div>
			<div class="flex flex-column gap-2 my-2 col-12" style="vertical-align: top;">
				<p-checkbox [disabled]="item.categorie.estUnique == true" label="Achats" [binary]="true"
					[(ngModel)]="optionsImpression.achats"></p-checkbox>
			</div>
			<div class="flex flex-column gap-2 my-2 col-12" style="vertical-align: top;">
				<p-checkbox [disabled]="item.categorie.estUnique == false" label="Liaisons" [binary]="true"
					[(ngModel)]="optionsImpression.liaisons"></p-checkbox>
			</div>
			<div class="flex flex-column gap-2 my-2 col-12" style="vertical-align: top;">
				<p-checkbox [disabled]="item.categorie.estUnique == false" label="Garanties" [binary]="true"
					[(ngModel)]="optionsImpression.garanties"></p-checkbox>
			</div>
			<div class="flex flex-column gap-2 my-2 col-12" style="vertical-align: top;">
				<p-checkbox label="Notes" [binary]="true" [(ngModel)]="optionsImpression.notes"></p-checkbox>
			</div>

		</div>

	</ng-template>

	<ng-template pTemplate="footer">
		<button pButton pRipple label="Annuler" icon="pi pi-times" class="p-button-text"
			(click)="annulerImpression()"></button>
		<button pButton pRipple label="Confirmer" icon="pi pi-check" class="p-button-text"
			(click)="imprimer()"></button>
	</ng-template>
</p-dialog>



<!-- ##########   DIALOGUE DÉTAIL GARANTIE   ########## -->

<p-blockUI [blocked]="dialogueGarantieBloque">
	<div class="fluid formgrid grid">
		<div class="flex flex-column gap-2 my-2 col-12 text-center">
			<p-progressSpinner></p-progressSpinner>
		</div>
	</div>
</p-blockUI>

<p-dialog #dialogueGarantie [(visible)]="dialogueGarantieOuvert" [style]="{width: '85%'}" header="Détail de la garantie"
	[modal]="true" styleClass="fluid formgrid grid">
	<ng-template pTemplate="content">
		<div class="fluid formgrid grid">
			<div class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-3">
				<label for="type">Type de garantie<span class="requis">*</span></label>
				<p-dropdown appendTo="body" [options]="typesGarantie" [(ngModel)]="garantie.type" optionLabel="nom"
					[filter]="true" [style]="{'width':'100%'}" filterBy="nom,prenom" [showClear]="true"
					placeholder="Selectionnez un type de garantie...">
					<ng-template pTemplate="selectedItem">
						<div *ngIf="garantie.type">
							<div>{{garantie.type.nom}}</div>
						</div>
					</ng-template>
					<ng-template let-type pTemplate="item">
						<div>
							<div>{{type.nom}}</div>
						</div>
					</ng-template>
				</p-dropdown>
				<small class="p-error" *ngIf="grantieSubmitted && !garantie.type">Le type de garantie est
					obligatoire.</small>
			</div>

			<div class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-3">
				<label for="lastname">Date de début<span class="requis">*</span></label>
				<p-calendar showIcon=true [(ngModel)]="garantie.dateDebut" dateFormat="yy-mm-dd" [yearNavigator]="true"
					[monthNavigator]="true" yearRange="2000:2040" appendTo="body">
				</p-calendar>
				<small class="p-error" *ngIf="grantieSubmitted && !garantie.dateDebut">La date de début est
					obligatoire.</small>
			</div>
			<div class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-3">
				<label for="lastname">Date fin<span class="requis">*</span></label>
				<i *ngIf="garantie.dateDebut" class="pi pi-question-circle ml-3" style="font-size: 1rem"
					(click)="selectionnerDelai()"></i>
				<p-calendar showIcon=true [(ngModel)]="garantie.dateFin" dateFormat="yy-mm-dd" [yearNavigator]="true"
					[monthNavigator]="true" yearRange="2000:2040" appendTo="body">
				</p-calendar>
				<small class="p-error" *ngIf="grantieSubmitted && !garantie.dateFin">La date de fin est
					obligatoire.</small>
			</div>
		</div>
		<div class="fluid formgrid grid">
			<div class="flex flex-column gap-2 my-2 col-12">
				<label for="description">Description</label>
				<input type="text" pInputText id="description" [(ngModel)]="garantie.description" autocomplete="off" />
			</div>

		</div>

	</ng-template>

	<ng-template pTemplate="footer">
		<button pButton pRipple label="Enregistrer" icon="pi pi-save" class="p-button-primary"
			(click)="sauvegarderGarantie()"></button>
		<button pButton pRipple label="Annuler" icon="pi pi-ban" class="p-button-text"
			(click)="annulerGarantie()"></button>

	</ng-template>
</p-dialog>


<!-- ##########   DIALOGUE DÉTAIL NOTE   ########## -->

<p-blockUI [blocked]="dialogueNoteBloque">
	<div class="fluid formgrid grid">
		<div class="flex flex-column gap-2 my-2 col-12 text-center">
			<p-progressSpinner></p-progressSpinner>
		</div>
	</div>
</p-blockUI>

<p-dialog #dialogueNote [(visible)]="dialogueNoteOuvert" [style]="{width: '85%'}" header="Détail de la note"
	[modal]="true" styleClass="fluid formgrid grid">
	<ng-template pTemplate="content">
		<div class="fluid formgrid grid">
			<div class="flex flex-column gap-2 my-2 col-12">
				<label for="description">Description</label>
				<!-- 	<input  type="text" pInputText id="description" [(ngModel)]="note.description"
				autocomplete="off"  /> -->
				<textarea #champNote pInputTextarea [(ngModel)]="note.description" autocomplete="off"
					autoResize="autoResize"></textarea>
			</div>
		</div>
	</ng-template>

	<ng-template pTemplate="footer">
		<button pButton pRipple label="Enregistrer" icon="pi pi-save" class="p-button-primary"
			(click)="sauvegarderNote()"></button>
		<button pButton pRipple label="Annuler" icon="pi pi-ban" class="p-button-text" (click)="annulerNote()"></button>

	</ng-template>
</p-dialog>
<!-- #endregion -->

<p-sidebar [(visible)]="afficherDetailBdc" [style]="{width:'95%'}" position="left">
	<app-detail-bdc [bdcId]="detailBdcId" *ngIf="afficherDetailBdc"></app-detail-bdc>
</p-sidebar>
import { Component, ViewChild, ElementRef, ɵConsole } from "@angular/core"; import { Location } from "@angular/common";
import { DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from 'src/app/base/base-component';
import { ProfilService } from 'src/app/services/profil-service';
import { AppMainComponent } from 'src/app/app.main.component';
import { ItemService } from 'src/app/services/item-service';
import { FournisseurService } from 'src/app/services/fournisseur-service';
import { DonneesBaseService } from 'src/app/services/donnees-base-service';
import { CategorieEntretienEntite } from 'src/app/entites/mecanique/categorie-entretien';
import { ItemMin } from 'src/app/entites/item-min';
import { PrixFournisseurEntite } from 'src/app/entites/prix-fournisseur';
import { PieceService } from 'src/app/services/piece-service';
import { Item } from 'src/app/entites/item';
import { LocalisationEntrepotEntite } from 'src/app/entites/localisation-entrepot';
import { Fournisseur } from 'src/app/entites/fournisseur';
import { SelectionMutipleUniteMecaniqueComponent } from './selection-multiple-unite-mecanique.component';

export interface ParametresCreationPiece {
    fournisseurId?: number;
    description?: string;
    marque?: string;
    modele?: string;
}

export interface ResultatCreationPiece {
    item: Item;
    prixFournisseur: PrixFournisseurEntite;
}

@Component({
    selector: 'app-creation-piece',
    templateUrl: './creation-piece.component.html'
})
export class CreationPieceComponent extends BaseComponent {

    donnees: ParametresCreationPiece;

    nouvelItem: Item;
    listeLocalisations: LocalisationEntrepotEntite[];
    
    listeFournisseurs: Fournisseur[];
    prixFournisseur: PrixFournisseurEntite;

    listeUnites: ItemMin[] = [];
    listeCategoriesEntretien: CategorieEntretienEntite[];
    selectionCategorieEntretien:CategorieEntretienEntite;

    descriptions: string[];
    suggestionDescription: string[];

    marques: string[];
    suggestionMarque: string[];

    submitted: boolean;
    blocked: boolean = false;

    @ViewChild('champDescription') inputDescription;

    constructor(
        profilService: ProfilService,
        route: ActivatedRoute, 
        routerService: Router,
		appMain: AppMainComponent, location: Location, 
        public pieceService: PieceService,
        public fournisseurService: FournisseurService,
        public messageService: MessageService,
        public dialogService: DialogService,
        public itemService: ItemService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public sdb: DonneesBaseService,
        public itemsService: ItemService
    ) {
        super(sdb, profilService, route, routerService, appMain);
    }

    ngOnInit() {

        this.donnees = this.config.data;
        this.marques = this.sdb.Marques();

        this.listeLocalisations = this.sdb.LocalisationsEntrepot();

        this.listeCategoriesEntretien = this.sdb.CategoriesEntretien();


        this.nouvelItem = {
            itemId: 0,
            //TODO
            categorie: this.sdb.Categorie(6),
            description: this.donnees.description,
            marque: this.donnees.marque,
            modele: this.donnees.modele
        };

        this.prixFournisseur = {
            prixFournisseurId: 0,
            fournisseur: null
        };

        this.fournisseurService.ObtenirFournisseurs().then(res => {
            this.listeFournisseurs = res.valeur;

            if (this.donnees.fournisseurId) {
                this.prixFournisseur.fournisseur = this.listeFournisseurs.find(x => x.fournisseurId == this.donnees.fournisseurId);
            }

            this.CompleterChargement();

            setTimeout(() => { this.inputDescription.focusInput(); }, 100);
        });

       

      
    }

    annuler() {
        this.ref.close(null);
    }

    enregistrer() {

        this.submitted = true;

        if (!this.valider()) {
            this.messageService.add({ key: 'detailEntretien', severity: 'error', summary: 'Erreurs', detail: 'Veuillez corriger les erreurs de saisie.' });
            return;
        }

        this.blocked = true;

        this.itemService.CreerPiece({
            item: this.nouvelItem,
            prixFournisseur: this.prixFournisseur,
            unites: this.listeUnites,
            categorieEntretien: this.selectionCategorieEntretien
        }).then(x => {
            if (x.estUnSucces) {
                this.Debloquer()
                this.blocked = false;
                this.ref.close({
                    item: x.valeur,
                    prixFournisseur: this.prixFournisseur
                });
                
            } else {
                x.messages.forEach(x => {
                    this.messageService.add({ key: "creationPiece", severity: 'error', summary: 'Erreur', detail: x.description, sticky: true });
                });

            }

        });

    }

    valider() {
        if (!this.nouvelItem.categorie) {
            return false;
        }

        if (this.nouvelItem.categorie.estUnique &&
            (!this.nouvelItem.numero || !this.nouvelItem.description || !this.nouvelItem.emplacement || !this.nouvelItem.compagnie)) {
            return false;
        }

        return true;
        
    }

    async rechercherDescritpion(event) {
        while (!this.itemService.items) {
            await new Promise(resolve => setTimeout(resolve, 50));
        }
        this.suggestionDescription = this.itemService.items.filter(x => this.nouvelItem.description.localeCompare(x.description.substring(0, this.nouvelItem.description.length), 'en', { sensitivity: 'base' }) == 0).map(x => x.description);
    }

    rechercherMarque(event) {
        this.suggestionMarque = this.marques.filter(x => this.nouvelItem.marque.localeCompare(x.substring(0, this.nouvelItem.marque.length), 'en', { sensitivity: 'base' }) == 0);
    }


    ajouterAssociations() {
        const ref = this.dialogService.open(SelectionMutipleUniteMecaniqueComponent, {
            data: {
                
            },
            header: 'Associations',
            width: '70%'
        }).onClose.subscribe((res: ItemMin[]) => {
            if (res) {
                res.forEach(item => {
                    this.listeUnites.push(item);    
                });
                
            }
        });
    }

    supprimerUnite(unite: ItemMin) {
        this.listeUnites = this.listeUnites.filter(x => x.itemId != unite.itemId);
    }

}
<p-toast key="assignerEntretien"></p-toast>

<p-blockUI [blocked]="blocked">
	<div class="fluid formgrid grid">
		<div class="flex flex-column gap-2 col-12 text-center">
			<p-progressSpinner></p-progressSpinner>
		</div>
	</div>
</p-blockUI>


<p-card *ngIf="!EstCharge()">
	<div class="fluid formgrid grid">
		<div class="flex flex-column gap-2 col-12 text-center">
			<p-progressSpinner></p-progressSpinner>
		</div>
	</div>
</p-card>

<div #contenu *ngIf="EstCharge()">

	<div class="fluid formgrid grid mb-2" *ngIf="donnees.alerteEtapesNonCompletes">
		<div class="flex flex-column gap-2 col-12">
			<p-message severity="warn" text="Certaines étapes ne sont pas complétés." styleClass="mr-2"></p-message>
		</div>
	</div>

	<div class="fluid formgrid grid">

		<div class="flex flex-column gap-2 col-12 md:col-6 lg:col-3">
			<label for="date">Date de début <span style="color:red">*</span></label>
			<p-calendar appendTo="body" showIcon=true [(ngModel)]="bonTravail.dateTravail" dateFormat="yy-mm-dd"
				[yearNavigator]="true" [monthNavigator]="true" yearRange="2000:2040"></p-calendar>
			<small class="p-error" *ngIf="submitted && !bonTravail.dateTravail">La date de début est
				obligatoire.</small>

		</div>
		<div class="flex flex-column gap-2 col-12 md:col-6 lg:col-3">
			<label for="date">Date de fin <span style="color:red">*</span></label>
			<p-calendar appendTo="body" showIcon=true [(ngModel)]="bonTravail.dateFin" dateFormat="yy-mm-dd"
				[yearNavigator]="true" [monthNavigator]="true" yearRange="2000:2040"></p-calendar>
			<small class="p-error" *ngIf="submitted && !bonTravail.dateFin">La date de fin est
				obligatoire.</small>
		</div>
		<div class="flex flex-column gap-2 col-12 md:col-6 lg:col-3">
			<label for="date">Heures au compteur
				<span *ngIf="bonTravail.item.indSuiviHeures" style="color:red">*</span>
			</label>
			<input #inputHeures type="number" pInputText id="heures" [(ngModel)]="bonTravail.heuresCompteur"
				autocomplete="off" />
		</div>
		<div class="flex flex-column gap-2 col-12 md:col-6 lg:col-3">
			<label for="date">KMs au compteur
				<span *ngIf="bonTravail.item.indSuiviKms" style="color:red">*</span>
			</label>
			<input #inputHeures type="number" pInputText id="heures" [(ngModel)]="bonTravail.kmCompteur"
				autocomplete="off" />
		</div>

	</div>

	<div class="fluid formgrid grid mt-2" *ngIf="donnees.alerteEtapesNonCompletes">
		<div class="flex flex-column gap-2 col-12">
			<p-checkbox label="Créer un nouveau bon de travail avec les étapes non complétées" [binary]="true"
				[(ngModel)]="indCreerPartiel"></p-checkbox>
		</div>
	</div>

	<div class="text-right mt-3">
		<button pButton pRipple label="Approuver" icon="pi pi-save" class="p-button-primary"
			(click)="approuver()"></button>
		<button pButton pRipple label="Annuler" icon="pi pi-ban" class="p-button-text" (click)="annuler()"></button>
	</div>
</div>
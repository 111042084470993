import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { ConfirmationService, MessageService } from 'primeng/api';
import { BaseComponent } from 'src/app/base/base-component';
import { DonneesBaseService } from 'src/app/services/donnees-base-service';
import { ProfilService } from 'src/app/services/profil-service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AppMainComponent } from 'src/app/app.main.component';
import { filter } from 'rxjs/operators';
import { BonTravailService } from 'src/app/services/bon-travail-service';
import { BonTravailEntite } from 'src/app/entites/mecanique/bon-travail';
import { RequeteAjouterAnalyseBonTravail } from 'src/app/services/requetes/requete-approuver-bontravail';
import { ItemBonCommandeAnalyse } from 'src/app/entites/analyse-bon-travail';
export interface ParametresAjoutAnalyseBonTravail {
    // Define your parameters here
}

@Component({
    selector: 'app-ajout-analyse-bon-travail',
    templateUrl: './ajout-analyse-bon-travail.component.html',
})
export class AjoutAnalyseBonTravailComponent extends BaseComponent implements OnInit {
    bonTravail: BonTravailEntite;

    dateDebut: Date;
    dateFin: Date;

    items: ItemBonCommandeAnalyse[] = [];
    
    loading: boolean = false;

    rechercheSubmitted: boolean = false;

    @ViewChild('someInputField') someInputField: ElementRef;

    constructor(
        public sdb: DonneesBaseService,
        public profilService: ProfilService,
        public route: ActivatedRoute,
        public routerService: Router,
        public appMain: AppMainComponent,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private messageService: MessageService,
        private bonTravailService: BonTravailService,
        private confirmationService: ConfirmationService
    ) {
        super(sdb, profilService, route, routerService, appMain);
    }

    ngOnInit() {
        this.bonTravail = this.config.data.bonTravail;

        this.DemarrerChargement();

        this.routerService.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe((event: NavigationEnd) => {
            this.ref.close(null);
        });
    }

    confirmer() {
        if (!this.rechercheSubmitted || !this.dateDebut || !this.dateFin) {
            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: 'Veuillez rechercher les bon de commandes avant de confirmer.' });
            return;
        }

        const requete: RequeteAjouterAnalyseBonTravail = {
            bonTravail: this.bonTravail,
            items: this.items,
            dateDebut: this.dateDebut,
            dateFin: this.dateFin
        };

        this.Bloquer();

        this.bonTravailService.AjouterAnalyseBonTravail(requete).subscribe({
            next: res => {
                this.Debloquer();
                this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'L\'analyse a été ajoutée avec succès.' });

                if (!res.body.emailSent) {
                    this.messageService.add({ severity: 'error', summary: 'Email', detail: 'Un email n\'a pas pu être envoyé à l\'utilisateur.' });
                }

                this.ref.close(res.body.analyseBonTravail);
            },
            error: err => {
                this.Debloquer();
                this.messageService.add({ severity: 'error', summary: 'Erreur', detail: 'Une erreur est survenue lors de l\'ajout de l\'analyse.' });
            }
        });
    }

    annuler() {
        this.ref.close(null);
    }

    rechercher() {
        this.rechercheSubmitted = true;
        if (!this.dateDebut || !this.dateFin) return;

        this.loading = true;
        this.bonTravailService.ObtenirItemBonCommandeAssocie(this.bonTravail.bonTravailId, this.dateDebut, this.dateFin).subscribe({
            next: res => {
                this.items = res.body.map(x => ({
                    itemBonCommandeAnalyseId: 0,
                    analyseBonTravail: null,
                    itemBonCommandeId: x.itemBonCommandeId,
                    itemBonCommande: x,
                    item: x.item,
                    quantite: x.quantite,
                    prix: x.prix,
                    conflit: false,
                    date: x.bonCommande.dateAchat,
                    quantiteRequise: x.quantite,
                    tempId: Math.floor(Math.random() * 1000000) // Generate a random number between 0 and 999999
                }));

                this.loading = false;
            },
            error: err => {
                this.messageService.add({ severity: 'error', summary: 'Erreur', detail: 'Une erreur est survenue lors de la recherche des items de bon de commande.' });
            }
        });
    }

    stringToColor(str: string): string {
        return '#' + Math.floor(Math.random() * 16777215).toString(16);
    }

    groupBy = <T, K extends keyof any>(list: T[], getKey: (item: T) => K) =>
        list.reduce((previous, currentItem) => {
          const group = getKey(currentItem);
          if (!previous[group]) previous[group] = [];
          previous[group].push(currentItem);
          return previous;
        }, {} as Record<K, T[]>);
}
<p-toast key="selectionEtapes"></p-toast>

<p-card *ngIf="!EstCharge()">
    <div class="fluid formgrid grid">
        <div class="flex flex-column gap-2 my-2 col-12 text-center">
            <p-progressSpinner></p-progressSpinner>
        </div>
    </div>
</p-card>



<div #contenu *ngIf="EstCharge()">

    <div class="col-12">
        <div class="card">
            <p-tabView [scrollable]="true" #tabView orientation="left" (onChange)="surChangementOnglet($event)">
                <p-tabPanel [header]="'Préventifs (' + entretiensPreventifs.length +')'" leftIcon="pi pi-shield">


                    <!------------ PRÉVENTIFS ------------>

                    <div class="fluid formgrid grid">


                        <div class="col-12">
                            <p-table #dt [value]="entretiensPreventifs" [columns]="selectedColumns"
                                dataKey="entretienPreventifId" autoLayout="true" tableStyleClass="tableNormale"
                                [(selection)]="selectionentretiensPreventifs"
                                styleClass="p-datatable-sm p-datatable-striped" [rowHover]="true" [rows]="10"
                                [paginator]="true" [filterDelay]="0" [globalFilterFields]="['description']">
                                <ng-template pTemplate="caption">
                                    <div class="flex align-content-center justify-content-between">
                                        <div>
                                            <span class="p-input-icon-left">
                                                <i class="pi pi-search"></i>
                                                <input #rechercheItem pInputText type="text"
                                                    (input)="dt.filterGlobal($event.target.value, 'contains')"
                                                    (keyup.enter)="surEntreeRecherchePreventifs()"
                                                    placeholder="Recherche..." />
                                            </span>
                                        </div>
                                        <div>
                                            <h5 class="titreTableau m-0">Entretiens préventifs</h5>
                                        </div>

                                        <div>
                                            <p-multiSelect disabled="true" [style]="{minWidth: '200px'}"
                                                placeholder="Colonnes..."></p-multiSelect>
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="header" let-columns>
                                    <tr>
                                        <th rowspan="2" style="min-width: 3rem">
                                            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                                        </th>
                                        <th rowspan="2" pSortableColumn="description">
                                            <div class="flex justify-content-between align-items-center">
                                                Description
                                                <p-sortIcon field="description"></p-sortIcon>
                                                <p-columnFilter type="text" field="description" display="menu"
                                                    class="ml-auto">
                                                </p-columnFilter>
                                            </div>
                                        </th>
                                        <th rowspan="2" pSortableColumn="etat">
                                            <div class="flex justify-content-between align-items-center">
                                                État
                                                <p-sortIcon field="etat"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th colspan="3">
                                            Heures
                                        </th>
                                        <th colspan="3">
                                            KMs
                                        </th>
                                        <th colspan="3">
                                            Mois
                                        </th>


                                    </tr>

                                    <tr>
                                        <th pSortableColumn="heuresFait">
                                            <div class="flex justify-content-between align-items-center">
                                                Fait
                                                <p-sortIcon field="heuresFait"></p-sortIcon>
                                                <p-columnFilter type="numeric" field="heuresFait" display="menu"
                                                    class="ml-auto">
                                                </p-columnFilter>
                                            </div>
                                        </th>
                                        <th pSortableColumn="heuresDu">
                                            <div class="flex justify-content-between align-items-center">
                                                Dû
                                                <p-sortIcon field="heuresDu"></p-sortIcon>
                                                <p-columnFilter type="numeric" field="heuresDu" display="menu"
                                                    class="ml-auto">
                                                </p-columnFilter>
                                            </div>
                                        </th>
                                        <th pSortableColumn="heuresEcart">
                                            <div class="flex justify-content-between align-items-center">
                                                Écart
                                                <p-sortIcon field="heuresEcart"></p-sortIcon>
                                                <p-columnFilter type="numeric" field="heuresEcart" display="menu"
                                                    class="ml-auto">
                                                </p-columnFilter>
                                            </div>
                                        </th>
                                        <th pSortableColumn="heuresFait">
                                            <div class="flex justify-content-between align-items-center">
                                                Fait
                                                <p-sortIcon field="heuresFait"></p-sortIcon>
                                                <p-columnFilter type="numeric" field="heuresFait" display="menu"
                                                    class="ml-auto">
                                                </p-columnFilter>
                                            </div>
                                        </th>
                                        <th pSortableColumn="heuresDu">
                                            <div class="flex justify-content-between align-items-center">
                                                Dû
                                                <p-sortIcon field="heuresDu"></p-sortIcon>
                                                <p-columnFilter type="numeric" field="heuresDu" display="menu"
                                                    class="ml-auto">
                                                </p-columnFilter>
                                            </div>
                                        </th>
                                        <th pSortableColumn="heuresEcart">
                                            <div class="flex justify-content-between align-items-center">
                                                Écart
                                                <p-sortIcon field="heuresEcart"></p-sortIcon>
                                                <p-columnFilter type="numeric" field="heuresEcart" display="menu"
                                                    class="ml-auto">
                                                </p-columnFilter>
                                            </div>
                                        </th>
                                        <th pSortableColumn="heuresFait">
                                            <div class="flex justify-content-between align-items-center">
                                                Fait
                                                <p-sortIcon field="heuresFait"></p-sortIcon>
                                                <p-columnFilter type="numeric" field="heuresFait" display="menu"
                                                    class="ml-auto">
                                                </p-columnFilter>
                                            </div>
                                        </th>
                                        <th pSortableColumn="heuresDu">
                                            <div class="flex justify-content-between align-items-center">
                                                Dû
                                                <p-sortIcon field="heuresDu"></p-sortIcon>
                                                <p-columnFilter type="numeric" field="heuresDu" display="menu"
                                                    class="ml-auto">
                                                </p-columnFilter>
                                            </div>
                                        </th>
                                        <th pSortableColumn="heuresEcart">
                                            <div class="flex justify-content-between align-items-center">
                                                Écart
                                                <p-sortIcon field="heuresEcart"></p-sortIcon>
                                                <p-columnFilter type="numeric" field="heuresEcart" display="menu"
                                                    class="ml-auto">
                                                </p-columnFilter>
                                            </div>
                                        </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-preventif let-columns="columns">
                                    <tr [pContextMenuRow]="preventif">
                                        <td>
                                            <p-tableCheckbox [value]="preventif"></p-tableCheckbox>
                                        </td>
                                        <td>{{preventif.description}}</td>

                                        <td><span
                                                [class]="'statut ' + preventif.statut.nom.toLowerCase().replace(' ','')">
                                                {{preventif.statut.nom}}
                                            </span>
                                        </td>
                                        <td>{{preventif.heureFait}}</td>
                                        <td>{{preventif.heureDu}}</td>
                                        <td>{{preventif.heureEcart}}</td>
                                        <td>{{preventif.kmFait}}</td>
                                        <td>{{preventif.kmDu}}</td>
                                        <td>{{preventif.kmEcart}}</td>
                                        <td>{{preventif.moisFait | date:'yyyy-MM-dd'}}</td>
                                        <td>{{preventif.moisDu | date:'yyyy-MM-dd'}}</td>
                                        <td>{{preventif.moisEcart}}</td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="emptymessage">
                                    <tr>
                                        <td colspan="8">Aucun item.</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                </p-tabPanel>
                <p-tabPanel [header]="'Correctifs (' + entretiensCorrectifs.length +')'" leftIcon="pi pi-briefcase">

                    <!------------ CORRECTIFS ------------>

                    <div class="grid">
                        <div class="col-12">
                            <p-table #dtCorrectifs [value]="entretiensCorrectifs" [columns]="selectedColumns"
                                dataKey="entretienCorrectifId" autoLayout="true" tableStyleClass="tableNormale"
                                [(selection)]="selectionentretiensCorrectifs"
                                (onRowSelect)="surSelectionEntretiensCorrectifs($event)"
                                (onRowUnselect)="surDeselectionEntretiensCorrectifs($event)"
                                styleClass="p-datatable-sm" [rowHover]="true" [rows]="10"
                                [paginator]="true" [filterDelay]="0" [globalFilterFields]="['description']">
                                <ng-template pTemplate="caption">
                                    <div class="flex align-content-center justify-content-between">
                                        <div>
                                            <span class="p-input-icon-left">
                                                <i class="pi pi-search"></i>
                                                <input #rechercheItem pInputText type="text"
                                                    (input)="dtCorrectifs.filterGlobal($event.target.value, 'contains')"
                                                    (keyup.enter)="surEntreeRechercheCorrectifs()"
                                                    placeholder="Recherche..." />
                                                
                                            </span>
                                            <button
                                                    *ngIf="estAutorise('entretien_correctif_creer') || estAutorise('temp_mec')"
                                                    pButton pRipple icon="pi pi-plus"
                                                    class="p-button-success mr-2 ml-4 mb-2 boutonGrille"
                                                    (click)="nouveauEntretienCorrectif()"></button>
                                        </div>
                                        <div>
                                            <h5 class="titreTableau m-0">Entretiens correctifs</h5>
                                        </div>

                                        <div>
                                            <p-multiSelect disabled="true" [style]="{minWidth: '200px'}"
                                                placeholder="Colonnes..."></p-multiSelect>
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="header" let-columns>
                                    <tr>
                                        <th style="min-width: 3rem; width: 3rem;">
                                            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                                        </th>
                                        <th pSortableColumn="etat">
                                            <div class="flex justify-content-between align-items-center">
                                                Sévérité
                                                <p-sortIcon field="etat"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="description">
                                            <div class="flex justify-content-between align-items-center">
                                                Description
                                                <p-sortIcon field="description"></p-sortIcon>
                                                <p-columnFilter type="text" field="description" display="menu"
                                                    class="ml-auto">
                                                </p-columnFilter>
                                            </div>
                                        </th>
                                        <th pSortableColumn="note">
                                            <div class="flex justify-content-between align-items-center">
                                                Note
                                                <p-sortIcon field="note"></p-sortIcon>
                                                <p-columnFilter type="text" field="note" display="menu"
                                                    class="ml-auto">
                                                </p-columnFilter>
                                            </div>
                                        </th>
                                        <th pSortableColumn="employe.nom">
                                            <div class="flex justify-content-between align-items-center">
                                                Saisi par
                                                <p-sortIcon field="employe.nom"></p-sortIcon>
                                                <p-columnFilter type="text" field="employe.nom" display="menu"
                                                    class="ml-auto">
                                                </p-columnFilter>
                                            </div>
                                        </th>
                                        <th pSortableColumn="date">
                                            <div class="flex justify-content-between align-items-center">
                                                Date
                                                <p-sortIcon field="dateCreation"></p-sortIcon>
                                                <p-columnFilter type="date" field="dateCreation" display="menu"
                                                    class="ml-auto">
                                                </p-columnFilter>
                                            </div>
                                        </th>

                                    </tr>


                                </ng-template>
                                <ng-template pTemplate="body" let-correctif let-columns="columns">
                                    <tr [pContextMenuRow]="correctif">
                                        <td>
                                            <p-tableCheckbox [value]="correctif"></p-tableCheckbox>
                                        </td>
                                        <td><span
                                                [class]="'severite ' + correctif.severite.nom.toLowerCase().replace(' ', '')">
                                                {{correctif.severite.nom}}
                                            </span></td>
                                        <td>{{correctif.description}}</td>
                                        <td>{{correctif.note}}</td>
                                        <td>{{correctif.employe | nomEmploye}}</td>
                                        <td>{{correctif.dateCreation | date:'yyyy-MM-dd'}}</td>

                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="emptymessage">
                                    <tr>
                                        <td colspan="8">Aucun item.</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                </p-tabPanel>

                <p-tabPanel header="Manuel" leftIcon="pi pi-pencil">


                    <!------------ MANUEL ------------>

                    <div class="fluid formgrid grid">



                        <div class="flex flex-column gap-2 my-2 col-12 md:col-6">
                            <label for="description">Description</label>
                            <input type="text" pInputText id="description" [(ngModel)]="description"
                                autocomplete="off" />
                        </div>
                        <div class="flex flex-column gap-2 my-2 col-12 md:col-3">
                            <label for="lastname">Code de catégorie</label>
                            <p-dropdown [options]="categoriesEntretien" [(ngModel)]="selectionCategorieEntretien"
                                optionLabel="description" [showClear]="true" filter="true" filterBy="code,description"
                                appendTo="body" placeholder="Sélectionner une catégorie...">
                                <ng-template pTemplate="selectedItem">
                                    <div *ngIf="selectionCategorieEntretien">
                                        <div>{{selectionCategorieEntretien.code}} -
                                            {{selectionCategorieEntretien.description}}</div>
                                    </div>
                                </ng-template>
                                <ng-template let-categorie pTemplate="item">
                                    <div>
                                        <div>{{categorie.code}} - {{categorie.description}}</div>
                                    </div>
                                </ng-template>
                            </p-dropdown>
                        </div>
                        <div class="flex flex-column gap-2 my-2 col-12">
                            <label for="note">Note</label>
                            <input type="text" pInputText id="note" [(ngModel)]="note" autocomplete="off" />
                        </div>
                    </div>
                </p-tabPanel>

                <p-tabPanel header="Liste de vérification" leftIcon="pi pi-list">

                    <!------------ LISTES VÉRIFICATION ------------>

                    <div class="fluid formgrid grid">

                        <div class="col-12">
                            <p-table #dtListe [value]="listesVerification" [columns]="selectedColumns"
                                dataKey="listeVerificationId" autoLayout="true" tableStyleClass="tableNormale"
                                [(selection)]="selectionListesVerification"
                                styleClass="p-datatable-sm p-datatable-striped" [rowHover]="true" [rows]="10"
                                [paginator]="true" [filterDelay]="0" [globalFilterFields]="['nom','description']">
                                <ng-template pTemplate="caption">
                                    <div class="flex align-content-center justify-content-between">
                                        <div>
                                            <span class="p-input-icon-left">
                                                <i class="pi pi-search"></i>
                                                <input #rechercheItem pInputText type="text"
                                                    (input)="dtListe.filterGlobal($event.target.value, 'contains')"
                                                    (keyup.enter)="surEntreeRechercheCorrectifs()"
                                                    placeholder="Recherche..." />
                                            </span>
                                        </div>
                                        <div>
                                            <h5 class="titreTableau m-0">Listes de vérification</h5>
                                        </div>

                                        <div>
                                            <p-multiSelect disabled="true" [style]="{minWidth: '200px'}"
                                                placeholder="Colonnes..."></p-multiSelect>
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="header" let-columns>
                                    <tr>
                                        <th style="min-width: 3rem; width: 3rem;">
                                            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                                        </th>
                                        <th pSortableColumn="nom">
                                            <div class="flex justify-content-between align-items-center">
                                                Nom
                                                <p-sortIcon field="nom"></p-sortIcon>
                                                <p-columnFilter type="text" field="nom" display="menu"
                                                    class="ml-auto">
                                                </p-columnFilter>
                                            </div>
                                        </th>
                                        <th pSortableColumn="description">
                                            <div class="flex justify-content-between align-items-center">
                                                Description
                                                <p-sortIcon field="description"></p-sortIcon>
                                                <p-columnFilter type="text" field="description" display="menu"
                                                    class="ml-auto">
                                                </p-columnFilter>
                                            </div>
                                        </th>
                                        <th pSortableColumn="nbPoints">
                                            <div class="flex justify-content-between align-items-center">
                                                Nb points
                                                <p-sortIcon field="nbPoints"></p-sortIcon>
                                                <p-columnFilter type="numeric" field="nbPoints" display="menu"
                                                    class="ml-auto">
                                                </p-columnFilter>
                                            </div>
                                        </th>

                                    </tr>


                                </ng-template>
                                <ng-template pTemplate="body" let-listeVerif let-columns="columns">
                                    <tr [pContextMenuRow]="listeVerif">
                                        <td>
                                            <p-tableCheckbox [value]="listeVerif"></p-tableCheckbox>
                                        </td>
                                        <td>{{listeVerif.nom}}</td>
                                        <td>{{listeVerif.description}}</td>
                                        <td>{{listeVerif.nbPoints}}</td>

                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="emptymessage">
                                    <tr>
                                        <td colspan="4">Aucune liste de vérification.</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                </p-tabPanel>
            </p-tabView>
            <div class="grid">
                <div class="col-12 text-right mt-3">
                    <button pButton pRipple label="Ajouter" icon="pi pi-check" class="p-button-primary"
                    [disabled]='(selectionentretiensPreventifs == null || selectionentretiensPreventifs.length == 0) &&
                    (selectionentretiensCorrectifs == null || selectionentretiensCorrectifs.length == 0) &&
                    (!description) &&
                    (selectionListesVerification == null || selectionListesVerification.length == 0)'
                    (click)="surSelection()"></button>
                    <button pButton pRipple label="Annuler" icon="pi pi-ban" class="p-button-text"
                        (click)="annuler()"></button>
                </div>
            </div>
        </div>
    </div>

</div>
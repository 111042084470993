<p-toast></p-toast>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<p-menu #menuContextuelEntretien popup="popup" [model]="contenuMenuContextuelEntretien"></p-menu>
<p-card *ngIf="!EstCharge()">
	<div class="fluid formgrid grid">
		<div class="flex flex-column gap-2 my-2 col-12 text-center">
			<p-progressSpinner></p-progressSpinner>
		</div>
	</div>
</p-card>

<div *ngIf="EstCharge()" class="grid">
	<div class="col-12">
		<div class="card">
			<h5>Planification</h5>
			<p-menubar autoDisplay="false" [model]="menu" *ngIf="!modeApercu">
			</p-menubar>
		</div>
	</div>
</div>


<div *ngIf="EstCharge()" class="grid">
	<div class="col-12">
		<div class="card">
			<p-tabView [scrollable]="true" orientation="left">
				<p-tabPanel header="Entretiens préventifs" leftIcon="pi pi-shield">

					<div class="fluid formgrid grid">
						<div class="flex flex-column gap-2 my-2 col-12 md:col-3">
							<label for="noUnite">Unité</label>
							<p-dropdown [options]="unites" [(ngModel)]="unite" optionLabel="description" appendTo="body"
								filter="true" filterBy="numero,description" [showClear]="true"
								placeholder="Sélectionnez une unité...">
								<ng-template pTemplate="selectedItem">
									<div *ngIf="unite">
										<div>{{unite.description}} ({{unite.numero}})</div>
									</div>
								</ng-template>
								<ng-template let-unite pTemplate="item">
									<div>
										<div>{{unite.description}} ({{unite.numero}})</div>
									</div>
								</ng-template>
							</p-dropdown>
						</div>
						<div class="flex flex-column gap-2 my-2 col-12 md:col-3">
							<label for="noUnite">Emplacement</label>
							<p-dropdown [options]="emplacements" [(ngModel)]="emplacement" optionLabel="nom"
								appendTo="body" filter="true" filterBy="nom,code" [showClear]="true"
								placeholder="Sélectionnez un emplacement...">
								<ng-template pTemplate="selectedItem">
									<div *ngIf="emplacement">
										<div>{{emplacement.nom}} ({{emplacement.code}})</div>
									</div>
								</ng-template>
								<ng-template let-emplacement pTemplate="item">
									<div>
										<div>{{emplacement.nom}} ({{emplacement.code}})</div>
									</div>
								</ng-template>
							</p-dropdown>
						</div>
						<div class="flex flex-column gap-2 my-2 col-12 lg:col-3">
							<label for="description">Département</label>
							<p-dropdown [options]="departements" [(ngModel)]="departement" optionLabel="nom"
								appendTo="body" filter="true" filterBy="nom" [showClear]="true"
								placeholder="Sélectionnez un departement...">
								<ng-template pTemplate="selectedItem"> 
									<div *ngIf="departement">
										<div>{{departement.nom}}</div>
									</div>
								</ng-template>
								<ng-template let-departement pTemplate="item">
									<div>
										<div>{{departement.nom}}</div>
									</div>
								</ng-template>
							</p-dropdown>
						</div>
						<div class="col-12 md:col-6 lg:col-3">
							<label>&nbsp;</label>
							<button style="width: auto; margin-top: 22px; margin-right: 10px;" pButton pRipple label="Filtrer" icon="pi pi-filter" class="p-button-primary"
								(click)="filtrer()"></button>
							<button style="width: auto; margin-top: 22px;" pButton pRipple label="Annuler" icon="pi pi-filter-slash" class="p-button-secondary"
								(click)="retirerFiltres()"></button>
						</div>
					</div>

					<p-table #dt [value]="entretiens" [columns]="selectedColumns" dataKey="entretienPreventifId"
						autoLayout="true" tableStyleClass="tableNormale" [(selection)]="selectionMultipleEntretiens"
						(onRowSelect)="surChangementSelectionEntretiens()"
						(onRowUnselect)="surChangementSelectionEntretiens()"
						(onHeaderCheckboxToggle)="surChangementSelectionEntretiens()"
						 [rowHover]="true" [rows]="500"
						[paginator]="true" [filterDelay]="0" [globalFilterFields]="['description','item.numero','item.description']">
						<ng-template pTemplate="caption">
							<div class="flex align-content-center justify-content-between">
								<div>
									<span class="p-input-icon-left">
										<i class="pi pi-search"></i>
										<input #rechercheItem pInputText type="text"
											(input)="dt.filterGlobal($event.target.value, 'contains')"
											(keyup.enter)="surEntreeRecherchePreventifs()" placeholder="Recherche..." />
									</span>
								</div>
								<div>
									<h5 class="titreTableau m-0">Entretiens préventifs</h5>
								</div>

								<div>
									<p-multiSelect disabled="true" [style]="{minWidth: '200px'}"
										placeholder="Colonnes..."></p-multiSelect>
								</div>
							</div>
						</ng-template>
						<ng-template pTemplate="header" let-columns>
							<tr> 
								<th style="min-width: 3rem">
									<p-tableHeaderCheckbox></p-tableHeaderCheckbox>
								</th>
								<th pSortableColumn="description">
									<div class="flex justify-content-between align-items-center">
										Numéro
										<p-sortIcon field="description"></p-sortIcon>
										<p-columnFilter type="text" field="description" display="menu"
											class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="description">
									<div class="flex justify-content-between align-items-center">
										Description
										<p-sortIcon field="description"></p-sortIcon>
										<p-columnFilter type="text" field="description" display="menu"
											class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="description">
									<div class="flex justify-content-between align-items-center">
										Entretien
										<p-sortIcon field="description"></p-sortIcon>
										<p-columnFilter type="text" field="description" display="menu"
											class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="statut.nom">
									<div class="flex justify-content-between align-items-center">
										Statut
										<p-sortIcon field="statut.nom"></p-sortIcon>
										<p-columnFilter field="statut" matchMode="in" display="menu" [showMatchModes]="false"
                                    [showOperator]="false" [showAddButton]="false">
                                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                        <p-multiSelect [ngModel]="value" [options]="statuts" placeholder="Any"
                                            (onChange)="filter($event.value)" optionLabel="name">
                                            <ng-template let-option pTemplate="item">
                                                <div class="multiselect-representative-option">

                                                    <span class="ml-1">{{option.nom}}</span>
                                                </div>
                                            </ng-template>
                                        </p-multiSelect>
                                    </ng-template>
                                </p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="heureFait">
									<div class="flex justify-content-between align-items-center">
										Fait
										<p-sortIcon field="heuresFait"></p-sortIcon>
										<p-columnFilter type="numeric" field="heureFait" display="menu"
											class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="heureDu">
									<div class="flex justify-content-between align-items-center">
										Dû
										<p-sortIcon field="heuresDu"></p-sortIcon>
										<p-columnFilter type="numeric" field="heureDu" display="menu" class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="heureEcart">
									<div class="flex justify-content-between align-items-center">
										Écart
										<p-sortIcon field="heuresEcart"></p-sortIcon>
										<p-columnFilter type="numeric" field="heureEcart" display="menu"
											class="ml-auto">
										</p-columnFilter>
									</div>
								</th>

								<th pSortableColumn="bonTravail.numero">
									<div class="flex justify-content-between align-items-center">
										No bon de travail
										<p-sortIcon field="bonTravail.numero"></p-sortIcon>
									</div>
								</th>
								<th pSortableColumn="bonTravail.item.emplacement.nom">
									<div class="flex justify-content-between align-items-center">
										Emplacement
										<p-sortIcon field="bonTravail.item.emplacement.nom"></p-sortIcon>
									</div>
								</th>
								<th pSortableColumn="bonTravail.numero">
									<div class="flex justify-content-between align-items-center">
										Département
										<p-sortIcon field="bonTravail.numero"></p-sortIcon>
									</div>
								</th>
								<th rowspan="2" style="min-width: 7rem; width: 7rem;"></th>
							</tr>

						</ng-template>
						<ng-template pTemplate="body" let-preventif let-columns="columns">
							<tr *ngIf="!preventif.indCalculEnCours" [pContextMenuRow]="preventif">
								<td>
									<p-tableCheckbox [value]="preventif"></p-tableCheckbox>
								</td>
								<td>{{preventif.item.numero}}</td>
								<td>{{preventif.item.description}}</td>
								<td>{{preventif.description}}</td>
								<td><span [class]="'statut ' + preventif.statut.nom.toLowerCase().replace(' ','')">
										{{preventif.statut.nom}}
									</span>
								</td>
								<td>
									<div class="champHeure" *ngIf="preventif.indSeuilHeure">
										{{preventif.heureFaitCompteur | valeurVide}} H
									</div>
									<div class="champHeure" *ngIf="preventif.indSeuilKm">
										{{preventif.kmFaitCompteur | valeurVide}} KM
									</div>
									<div class="champHeure" *ngIf="preventif.indSeuilMois">
										{{preventif.moisFait | date:'yyyy-MM-dd' | valeurVide}}
									</div>
								</td>
								<td>
									<div class="champHeure" *ngIf="preventif.indSeuilHeure">
										{{preventif.heureDuCompteur | valeurVide}} H
									</div>
									<div class="champHeure" *ngIf="preventif.indSeuilKm">
										{{preventif.kmDuCompteur | valeurVide}} KM
									</div>
									<div class="champHeure" *ngIf="preventif.indSeuilMois">
										{{preventif.moisDu | date:'yyyy-MM-dd' | valeurVide}}
									</div>
								</td>
								<td>
									<div class="champHeure" *ngIf="preventif.indSeuilHeure">
										{{preventif.heureEcart | valeurVide}} H
									</div>
									<div class="champHeure" *ngIf="preventif.indSeuilKm">
										{{preventif.kmEcart | valeurVide}} KM
									</div>
									<div  class="champHeure" *ngIf="preventif.indSeuilMois">
										{{preventif.moisEcart | valeurVide}}
									</div>
								</td>
								<td>{{preventif.bonTravail?.numero}}</td>
								<td>{{preventif.item.emplacement?.nom}}</td>
								<td>{{preventif.item.departementMecanique?.nom}}</td>
								<td class="celluleTableau">
									<div class="flex">
										<button pButton pRipple icon="pi pi-bars"
											class="p-button-secondary mr-2 boutonGrille"
											(click)="surMenuContextuelEntretien($event, preventif)"></button>
									</div>
								</td>
							</tr>
							<tr *ngIf="preventif.indCalculEnCours" [pContextMenuRow]="preventif">
								<td>
									<p-tableCheckbox [value]="preventif"></p-tableCheckbox>
								</td>
								<td>{{preventif.item.numero}}</td>
								<td>{{preventif.item.description}}</td>
								<td>{{preventif.description}} (calcul en cours...)</td>
								<td>---</td>
								<td>---</td>
								<td>---</td>
								<td>---</td>
								<td>---</td>
								<td class="celluleTableau">
									<div class="flex">
										<button pButton pRipple icon="pi pi-bars" disabled="true"
											class="p-button-secondary mr-2 boutonGrille"
											(click)="surMenuContextuelEntretien($event, preventif)"></button>
									</div>
								</td>
							</tr>
						</ng-template>
						<ng-template pTemplate="emptymessage">
							<tr>
								<td colspan="8">Aucun entretien.</td>
							</tr>
						</ng-template>
					</p-table>



					<br>

				</p-tabPanel>
				<!-- <p-tabPanel header="Calendrier" leftIcon="pi pi-calendar">


					


				</p-tabPanel> -->
			</p-tabView>
		</div>
	</div>


</div>
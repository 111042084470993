<p-toast></p-toast>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<p-menu #menuContxtuelItem popup="popup" [model]="menuContextuelItems"></p-menu>
<p-confirmPopup key="itemsnc"></p-confirmPopup>

<p-card *ngIf="!EstCharge()">
    <div class="fluid formgrid grid">
        <div class="flex flex-column gap-2 my-2 col-12 text-center">
            <p-progressSpinner></p-progressSpinner>
        </div>
    </div>
</p-card>

<div *ngIf="EstCharge()" class="grid">
    <div class="col-12">
        <div class="card">
            <p-menubar autoDisplay="false" [model]="menu"></p-menubar>
        </div>
    </div>
    <div class="col-12">

        <div class="card">
            <p-tabView [scrollable]="true" #tabView [(activeIndex)]="tabIndex" orientation="left" (onChange)="surChangementOnglet($event)">
                <p-tabPanel header="Informations générales" leftIcon="pi pi-info-circle">

                    <p-table #dt [value]="items" [columns]="selectedColumns" dataKey="reparationId" autoLayout="true"
                        tableStyleClass="tableNormale" 
                        [(selection)]="selectionMultipleItem" (onRowSelect)="surChangementSelection()"
                        (onRowUnselect)="surChangementSelection()" [rowHover]="true" [rows]="100" [paginator]="true"
                        [rowsPerPageOptions]="[10,25,50,100]" [filterDelay]="0"
                        [globalFilterFields]="['item.numero','item.description','item.categorie.nom','item.sousCategorie.nom','item.marque','item.modele','fournisseur.nom']">

                        <ng-template pTemplate="caption" let-state>
                            <div class="flex align-content-center justify-content-between">
                                <div>
                                    <span class="p-input-icon-left">
                                        <i class="pi pi-search"></i>
                                        <input pInputText type="text"
                                            (input)="dt.filterGlobal($event.target.value, 'contains')"
                                            placeholder="Recherche..." />
                                    </span>
                                    <button pButton class="p-button-outlined boutonGrille mr-2 ml-4 mb-2" icon="pi pi-filter-slash" (click)="clearFilters(dt)"></button>
                                </div>
                                <div>
                                    <h5 class="titreTableau m-0">Items en réparation</h5>
                                </div>

                                <div>
                                    <p-multiSelect [options]="colonnesDisponibles" [(ngModel)]="colonnesSelectionnees"
                                        optionLabel="entete" selectedItemsLabel="{0} colonnes sélectionnés"
                                        [style]="{minWidth: '200px'}" placeholder="Colonnes..."></p-multiSelect>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th style="min-width: 3rem">
                                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                                </th>
                                <th pSortableColumn="item.numero">
                                    <div class="flex justify-content-between align-items-center">
                                        Numéro
                                        <p-sortIcon field="item.numero"></p-sortIcon>
                                        <p-columnFilter type="text" field="item.numero" display="menu"
                                            class="ml-auto">
                                        </p-columnFilter>
                                    </div>
                                </th>
                                <th pSortableColumn="item.description">
                                    <div class="flex justify-content-between align-items-center">
                                        Description
                                        <p-sortIcon field="item.description"></p-sortIcon>
                                        <p-columnFilter type="text" field="item.description" display="menu"
                                            class="ml-auto">
                                        </p-columnFilter>
                                    </div>
                                </th>
                                <th *ngIf="EstColonneSelectionnee('categorie')" pSortableColumn="item.categorie.nom">
                                    <div class="flex justify-content-between align-items-center">
                                        Catégorie
                                        <p-sortIcon field="item.categorie.nom"></p-sortIcon>
                                        <p-columnFilter field="item.categorie.nom" matchMode="in" display="menu"
                                            [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
                                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                                <p-multiSelect [ngModel]="value" [options]="listeFiltreCategories"
                                                    placeholder="Any" (onChange)="filter($event.value)"
                                                    optionLabel="name">
                                                    <ng-template let-option pTemplate="item">
                                                        <div class="multiselect-representative-option">
                                                            <span class="ml-1">{{option}}</span>
                                                        </div>
                                                    </ng-template>
                                                </p-multiSelect>
                                            </ng-template>
                                        </p-columnFilter>
                                    </div>
                                </th>
                                <th *ngIf="EstColonneSelectionnee('sousCategorie')"
                                    pSortableColumn="item.sousCategorie.nom">
                                    <div class="flex justify-content-between align-items-center">
                                        Sous-catégorie
                                        <p-sortIcon field="item.sousCategorie.nom"></p-sortIcon>
                                        <p-columnFilter type="text" field="sousCategorie" display="menu"
                                            class="ml-auto">
                                        </p-columnFilter>
                                    </div>
                                </th>
                                <th *ngIf="EstColonneSelectionnee('marque')" pSortableColumn="item.marque">
                                    <div class="flex justify-content-between align-items-center">
                                        Marque
                                        <p-columnFilter type="text" field="item.marque" display="menu"
                                            class="ml-auto">
                                        </p-columnFilter>
                                    </div>
                                </th>
                                <th *ngIf="EstColonneSelectionnee('modele')" pSortableColumn="item.modele">
                                    <div class="flex justify-content-between align-items-center">
                                        Modèle
                                        <p-sortIcon field="item.modele"></p-sortIcon>
                                        <p-columnFilter type="text" field="item.modele" display="menu"
                                            class="ml-auto">
                                        </p-columnFilter>
                                    </div>
                                </th>
                                <th *ngIf="EstColonneSelectionnee('emplacement')"
                                    pSortableColumn="item.emplacement.nom">
                                    <div class="flex justify-content-between align-items-center">
                                        Emplacement
                                        <p-sortIcon field="item.emplacement.nom"></p-sortIcon>
                                        <p-columnFilter type="text" field="item.emplacement.nom" display="menu"
                                            class="ml-auto">
                                        </p-columnFilter>
                                    </div>
                                </th>
                                <th pSortableColumn="statut.nom">
                                    <div class="flex justify-content-between align-items-center">
                                        Statut
                                        <p-sortIcon field="statut.nom"></p-sortIcon>
                                        <p-columnFilter type="text" field="statut.nom" display="menu" class="ml-auto">
                                        </p-columnFilter>
                                    </div>
                                </th>
                                <th *ngIf="EstColonneSelectionnee('fournisseur')" pSortableColumn="fournisseur.nom">
                                    <div class="flex justify-content-between align-items-center">
                                        Fournisseur
                                        <p-sortIcon field="fournisseur.nom"></p-sortIcon>
                                        <p-columnFilter type="text" field="fournisseur.nom" display="menu"
                                            class="ml-auto">
                                        </p-columnFilter>
                                    </div>
                                </th>
                                <th *ngIf="EstColonneSelectionnee('dateEnvoi')" pSortableColumn="dateEnvoi">
                                    <div class="flex justify-content-between align-items-center">
                                        Date envoi
                                        <p-sortIcon field="dateEnvoi"></p-sortIcon>
                                        <p-columnFilter type="text" field="dateEnvoi" display="menu" class="ml-auto">
                                        </p-columnFilter>
                                    </div>
                                </th>
                                <th *ngIf="EstColonneSelectionnee('dateRetour')" pSortableColumn="dateRetour">
                                    <div class="flex justify-content-between align-items-center">
                                        Date retour prévue
                                        <p-sortIcon field="dateRetour"></p-sortIcon>
                                        <p-columnFilter type="text" field="dateRetour" display="menu" class="ml-auto">
                                        </p-columnFilter>
                                    </div>
                                </th>
                                <th *ngIf="EstColonneSelectionnee('nbJours')" pSortableColumn="nbJours">
                                    <div class="flex justify-content-between align-items-center">
                                        Nb Jours
                                        <p-sortIcon field="nbJours"></p-sortIcon>
                                        <p-columnFilter type="text" field="nbJours" display="menu" class="ml-auto">
                                        </p-columnFilter>
                                    </div>
                                </th>
                                <th *ngIf="EstColonneSelectionnee('emplacementRetour')"
                                    pSortableColumn="emplacement.nom">
                                    <div class="flex justify-content-between align-items-center">
                                        Emplacement retour
                                        <p-sortIcon field="emplacement.nom"></p-sortIcon>
                                        <p-columnFilter type="text" field="emplacement.nom" display="menu"
                                            class="ml-auto">
                                        </p-columnFilter>
                                    </div>
                                </th>
                                <th *ngIf="EstColonneSelectionnee('indRemplace')" pSortableColumn="indRemplace">
                                    <div class="flex justify-content-between align-items-center">
                                        Remplacé
                                        <p-sortIcon field="indRemplace"></p-sortIcon>
                                        <p-columnFilter type="text" field="indRemplace" display="menu"
                                            class="ml-auto">
                                        </p-columnFilter>
                                    </div>
                                </th>
                                <th style="min-width: 7rem; width: 7rem;"></th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-reparation let-columns="columns">
                            <tr [pContextMenuRow]="item">
                                <td>
                                    <p-tableCheckbox [value]="reparation"></p-tableCheckbox>
                                </td>
                                <td>{{reparation.item.numero}}
                                </td>
                                <td>{{reparation.item.description}}</td>
                                <td *ngIf="EstColonneSelectionnee('categorie')">
                                    {{reparation.item.categorie.nom}}
                                </td>
                                <td *ngIf="EstColonneSelectionnee('sousCategorie')">
                                    {{reparation.item.sousCategorie?.nom}}
                                </td>
                                <td *ngIf="EstColonneSelectionnee('marque')">
                                    {{reparation.item.marque}}
                                </td>
                                <td *ngIf="EstColonneSelectionnee('modele')">
                                    {{reparation.item.modele}}
                                </td>
                                <td *ngIf="EstColonneSelectionnee('emplacement')">
                                    {{reparation.item.emplacement | nomEmplacement}}
                                </td>
                                <td><span [class]="'statut ' + reparation.statut.nom.replace(' ','').toLowerCase()">
                                        {{reparation.statut.nom}}
                                    </span>
                                </td>
                                <td *ngIf="EstColonneSelectionnee('fournisseur')">{{reparation.fournisseur.nom}}</td>
                                <td *ngIf="EstColonneSelectionnee('dateEnvoi')">
                                    {{reparation.dateEnvoi | date:'yyyy-MM-dd'}}</td>
                                <td *ngIf="EstColonneSelectionnee('dateRetour')">
                                    {{reparation.dateRetourPrevue | date:'yyyy-MM-dd'}}</td>
                                <td *ngIf="EstColonneSelectionnee('nbJours')">{{reparation.nbJours}}</td>
                                <td *ngIf="EstColonneSelectionnee('emplacementRetour')">
                                    {{reparation.emplacement | nomEmplacement}}
                                </td>

                                <td *ngIf="EstColonneSelectionnee('indRemplace')" class="text-center">
                                    <i class="pi"
                                        [ngClass]="{'true-icon pi-check-circle': reparation.indRemplace, 'false-icon pi-times-circle': !reparation.indRemplace}"></i>
                                </td>
                                <td class="celluleTableau">
                                    <div class="flex">
                                        <button pButton pRipple icon="pi pi-bars"
                                            class="p-button-secondary mr-2 boutonGrille"
                                            (click)="surMenuContextuelItem($event, reparation)"></button>
                                    </div>
                                </td>
                            </tr>
                        </ng-template>

                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="6">Aucun item en réparation.</td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="paginatorleft" let-state>
                            {{state.totalRecords}} entrée(s).
                        </ng-template>
                    </p-table>

                </p-tabPanel>

                <p-tabPanel header="Historique" leftIcon="pi pi-info-circle">

                    <p-menu #menuContextuelHistorique popup="popup" [model]="contenuMenuContextuelHistorique"></p-menu>

					<p-card *ngIf="!chargementCompleteHistorique">
						<div class="fluid formgrid grid">
							<div class="flex flex-column gap-2 my-2 col-12 text-center">
								<p-progressSpinner></p-progressSpinner>
							</div>
						</div>
                    </p-card>
                    
                    <p-table #dtHistorique [value]="itemsHistorique" [columns]="selectedColumns" dataKey="reparationId" autoLayout="true"
                        tableStyleClass="tableNormale" 
                         [rowHover]="true" [rows]="100" [paginator]="true"
                        [rowsPerPageOptions]="[10,25,50,100]" [filterDelay]="0"
                        [globalFilterFields]="['item.numero','item.description','item.categorie.nom','item.sousCategorie.nom','item.marque','item.modele','fournisseur.nom']">

                        <ng-template pTemplate="caption" let-state>
                            <div class="flex align-content-center justify-content-between">
                                <div>
                                    <span class="p-input-icon-left">
                                        <i class="pi pi-search"></i>
                                        <input pInputText type="text"
                                            (input)="dtHistorique.filterGlobal($event.target.value, 'contains')"
                                            placeholder="Recherche..." />
                                    </span>
                                </div>
                                <div>
                                    <h5 class="titreTableau m-0">Réparations complétés</h5>
                                </div>

                                <div>
                                    <p-multiSelect [options]="colonnesDisponiblesHistorique" [(ngModel)]="colonnesSelectionneesHistorique"
                                        optionLabel="entete" selectedItemsLabel="{0} colonnes sélectionnés"
                                        [style]="{minWidth: '200px'}" placeholder="Colonnes..."></p-multiSelect>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th pSortableColumn="item.numero">
                                    <div class="flex justify-content-between align-items-center">
                                        Numéro
                                        <p-sortIcon field="item.numero"></p-sortIcon>
                                        <p-columnFilter type="text" field="item.numero" display="menu"
                                            class="ml-auto">
                                        </p-columnFilter>
                                    </div>
                                </th>
                                <th pSortableColumn="item.description">
                                    <div class="flex justify-content-between align-items-center">
                                        Description
                                        <p-sortIcon field="item.description"></p-sortIcon>
                                        <p-columnFilter type="text" field="item.description" display="menu"
                                            class="ml-auto">
                                        </p-columnFilter>
                                    </div>
                                </th>
                                <th *ngIf="EstColonneSelectionneeHistorique('categorie')" pSortableColumn="item.categorie.nom">
                                    <div class="flex justify-content-between align-items-center">
                                        Catégorie
                                        <p-sortIcon field="item.categorie.nom"></p-sortIcon>
                                        <p-columnFilter field="item.categorie.nom" matchMode="in" display="menu"
                                            [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
                                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                                <p-multiSelect [ngModel]="value" [options]="listeFiltreCategories"
                                                    placeholder="Any" (onChange)="filter($event.value)"
                                                    optionLabel="name">
                                                    <ng-template let-option pTemplate="item">
                                                        <div class="multiselect-representative-option">
                                                            <span class="ml-1">{{option}}</span>
                                                        </div>
                                                    </ng-template>
                                                </p-multiSelect>
                                            </ng-template>
                                        </p-columnFilter>
                                    </div>
                                </th>
                                <th *ngIf="EstColonneSelectionneeHistorique('sousCategorie')"
                                    pSortableColumn="item.sousCategorie.nom">
                                    <div class="flex justify-content-between align-items-center">
                                        Sous-catégorie
                                        <p-sortIcon field="item.sousCategorie.nom"></p-sortIcon>
                                        <p-columnFilter type="text" field="sousCategorie" display="menu"
                                            class="ml-auto">
                                        </p-columnFilter>
                                    </div>
                                </th>
                                <th *ngIf="EstColonneSelectionneeHistorique('marque')" pSortableColumn="item.marque">
                                    <div class="flex justify-content-between align-items-center">
                                        Marque
                                        <p-columnFilter type="text" field="item.marque" display="menu"
                                            class="ml-auto">
                                        </p-columnFilter>
                                    </div>
                                </th>
                                <th *ngIf="EstColonneSelectionneeHistorique('modele')" pSortableColumn="item.modele">
                                    <div class="flex justify-content-between align-items-center">
                                        Modèle
                                        <p-sortIcon field="item.modele"></p-sortIcon>
                                        <p-columnFilter type="text" field="item.modele" display="menu"
                                            class="ml-auto">
                                        </p-columnFilter>
                                    </div>
                                </th>
                                <th *ngIf="EstColonneSelectionneeHistorique('emplacement')"
                                    pSortableColumn="item.emplacement.nom">
                                    <div class="flex justify-content-between align-items-center">
                                        Emplacement
                                        <p-sortIcon field="item.emplacement.nom"></p-sortIcon>
                                        <p-columnFilter type="text" field="item.emplacement.nom" display="menu"
                                            class="ml-auto">
                                        </p-columnFilter>
                                    </div>
                                </th>
                                <th pSortableColumn="statut.nom">
                                    <div class="flex justify-content-between align-items-center">
                                        Statut
                                        <p-sortIcon field="statut.nom"></p-sortIcon>
                                        <p-columnFilter type="text" field="statut.nom" display="menu" class="ml-auto">
                                        </p-columnFilter>
                                    </div>
                                </th>
                                <th *ngIf="EstColonneSelectionneeHistorique('fournisseur')" pSortableColumn="fournisseur.nom">
                                    <div class="flex justify-content-between align-items-center">
                                        Fournisseur
                                        <p-sortIcon field="fournisseur.nom"></p-sortIcon>
                                        <p-columnFilter type="text" field="fournisseur.nom" display="menu"
                                            class="ml-auto">
                                        </p-columnFilter>
                                    </div>
                                </th>
                                <th *ngIf="EstColonneSelectionneeHistorique('dateEnvoi')" pSortableColumn="dateEnvoi">
                                    <div class="flex justify-content-between align-items-center">
                                        Date envoi
                                        <p-sortIcon field="dateEnvoi"></p-sortIcon>
                                        <p-columnFilter type="text" field="dateEnvoi" display="menu" class="ml-auto">
                                        </p-columnFilter>
                                    </div>
                                </th>
                                <th *ngIf="EstColonneSelectionneeHistorique('dateRetour')" pSortableColumn="dateRetour">
                                    <div class="flex justify-content-between align-items-center">
                                        Date retour
                                        <p-sortIcon field="dateRetour"></p-sortIcon>
                                        <p-columnFilter type="text" field="dateRetour" display="menu" class="ml-auto">
                                        </p-columnFilter>
                                    </div>
                                </th>
                               
                                <th style="min-width: 7rem; width: 7rem;"></th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-reparation let-columns="columns">
                            <tr [pContextMenuRow]="item">
                                <td>{{reparation.item.numero}}
                                </td>
                                <td>{{reparation.item.description}}</td>
                                <td *ngIf="EstColonneSelectionneeHistorique('categorie')">
                                    {{reparation.item.categorie.nom}}
                                </td>
                                <td *ngIf="EstColonneSelectionneeHistorique('sousCategorie')">
                                    {{reparation.item.sousCategorie?.nom}}
                                </td>
                                <td *ngIf="EstColonneSelectionneeHistorique('marque')">
                                    {{reparation.item.marque}}
                                </td>
                                <td *ngIf="EstColonneSelectionneeHistorique('modele')">
                                    {{reparation.item.modele}}
                                </td>
                                <td *ngIf="EstColonneSelectionneeHistorique('emplacement')">
                                    {{reparation.item.emplacement | nomEmplacement}}
                                </td>
                                <td><span [class]="'statut ' + reparation.statut.nom.replace(' ','').toLowerCase()">
                                        {{reparation.statut.nom}}
                                    </span>
                                </td>
                                <td *ngIf="EstColonneSelectionneeHistorique('fournisseur')">{{reparation.fournisseur.nom}}</td>
                                <td *ngIf="EstColonneSelectionneeHistorique('dateEnvoi')">
                                    {{reparation.dateEnvoi | date:'yyyy-MM-dd'}}</td>
                                <td *ngIf="EstColonneSelectionneeHistorique('dateRetour')">
                                    {{reparation.dateRetour | date:'yyyy-MM-dd'}}</td>

                                <td class="celluleTableau">
                                    <div class="flex">
                                        <button pButton pRipple icon="pi pi-bars"
                                            class="p-button-secondary mr-2 boutonGrille"
                                            (click)="surMenuContextuelItemHistorique($event, reparation)"></button>
                                    </div>
                                </td>
                            </tr>
                        </ng-template>

                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="6">Aucune réparation.</td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="paginatorleft" let-state>
                            {{state.totalRecords}} entrée(s).
                        </ng-template>
                    </p-table>

                </p-tabPanel>
            </p-tabView>

        </div>
    </div>


</div>




<p-dialog [(visible)]="dialogueReparation" [style]="{width: '85%'}" header="Retour de réparation" [modal]="true"
    [contentStyle]="{'overflow': 'visible'}" styleClass="fluid formgrid grid">
    <ng-template pTemplate="content">

        <div class="formgrid grid">
            <div class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-3">
                <label for="lastname">Date de retour<span class="requis">*</span></label>
                <p-calendar showIcon=true [(ngModel)]="dateRetour"></p-calendar>
            </div>
            <div class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-3">
                <label for="lastname">Numéro de facture</label>
                <p-calendar showIcon=true [(ngModel)]="noFactureRetour"></p-calendar>
            </div>


        </div>

    </ng-template>

    <ng-template pTemplate="footer">
        <button pButton pRipple label="Annuler" icon="pi pi-times" class="p-button-text"
            (click)="annulerRetour()"></button>
        <button pButton pRipple label="Confirmer" icon="pi pi-check" class="p-button-text"
            (click)="confirmerRetour()"></button>
    </ng-template>
</p-dialog>
<p-toast></p-toast>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<p-confirmPopup key="popup"></p-confirmPopup>
<p-menu #menuContextuelItemCommande popup="popup" [model]="menuContextuelEntrees"></p-menu>

<p-card *ngIf="!EstCharge()">
	<div class="fluid formgrid grid">
		<div class="flex flex-column gap-2 my-2 col-12 text-center">
			<p-progressSpinner></p-progressSpinner>
		</div>
	</div>
</p-card>

<div *ngIf="EstCharge()" class="grid">
	<div class="col-12">
		<div class="card">
			<h5>
				Commandes
			</h5>

			<p-menubar autoDisplay="false" [model]="menu"></p-menubar>

		</div>
	</div>
</div>


<div *ngIf="EstCharge()" class="grid">
	<div class="col-12">

		<div class="card">
			<div class="fluid formgrid grid">
				<div class="col-12 md:col-6 lg:col-6 mb-3">
					<p-selectButton (onChange)="filtrer()"  [options]="statuts" [(ngModel)]="statut" optionLabel="nom">
					</p-selectButton>
				</div>
		
			</div>
			<div class="fluid formgrid grid">

				<div class="flex flex-column gap-2 my-2 col-12 md:col-3">
					<label for="noUnite">Emplacement(s)</label>
					<p-multiSelect [options]="emplacements" [(ngModel)]="emplacement"
						optionLabel="nom" (onChange)="filtrer()"
						selectedItemsLabel="{0} emplacements sélectionnés" 
						placeholder="Sélectionnez un emplacement...">
					</p-multiSelect>
					<!-- <p-dropdown [options]="emplacements" [(ngModel)]="emplacement" optionLabel="nom"
						appendTo="body" filter="true" filterBy="nom,code" [showClear]="true"
						placeholder="Sélectionnez un emplacement...">
						<ng-template pTemplate="selectedItem">
							<div *ngIf="emplacement">
								<div>{{emplacement.nom}} ({{emplacement.code}})</div>
							</div>
						</ng-template>
						<ng-template let-emplacement pTemplate="item">
							<div>
								<div>{{emplacement.nom}} ({{emplacement.code}})</div>
							</div>
						</ng-template>
					</p-dropdown> -->
				</div>
				
				<div class="col-12 md:col-6 lg:col-3">
					<label>&nbsp;</label>
					<button style="width: auto; margin-top: 22px; margin-right: 10px;" pButton pRipple label="Filtrer" icon="pi pi-filter" class="p-button-primary"
						(click)="filtrer()"></button>
					<button style="width: auto; margin-top: 22px;" pButton pRipple label="Annuler" icon="pi pi-filter-slash" class="p-button-secondary"
						(click)="retirerFiltres()"></button>
				</div>
			</div>
	
		</div>

		<div class="card">

					<div class="fluid formgrid grid">

					</div>

					<p-table #dt [value]="itemsCommande" [columns]="selectedColumns" dataKey="itemCommandeId" *ngIf="visible" tableStyleClass="tableNormale"
					[(selection)]="selectionMultipleItemCommande"  (onRowSelect)="surChangementSelection()" (onRowUnselect)="surChangementSelection()"
					(onHeaderCheckboxToggle)="surChangementSelection()"
						autoLayout="true" styleClass="p-datatable-sm p-datatable-striped"
						[rowHover]="true"  [filterDelay]="0"
						[globalFilterFields]="['item.numero','item.description','demandeur']">
						<ng-template pTemplate="caption">
							<div class="flex align-content-center justify-content-between">
								<div>
									<span class="p-input-icon-left">
										<i class="pi pi-search"></i>
										<input pInputText type="text"
											(input)="dt.filterGlobal($event.target.value, 'contains')"
											placeholder="Recherche..." />
									</span>
									
									<button *ngIf="estAutorise('commande_ajout_item')"
										[disabled]="!emplacement || emplacement.length != 1"
									 	pButton pRipple icon="pi pi-plus"
											class="p-button-success mr-2 ml-4 mb-2 boutonGrille"
											(click)="surAjoutItem()"></button>

								</div>
								<h5 class="m-0 mt-2">Items</h5>
								<div>
									<p-multiSelect [options]="categories" [(ngModel)]="categorie"
										optionLabel="nom" (onChange)="filtrer()"
										selectedItemsLabel="{0} catégories sélectionnées" [style]="{minWidth: '200px'}"
										placeholder="Catégorie...">
									</p-multiSelect>
									<!-- <p-dropdown [options]="categories" [(ngModel)]="categorie" optionLabel="nom" appendTo="body"
										filter="true" filterBy="nom,description" [showClear]="true" (onChange)="filtrer()"
										placeholder="Sélectionnez une catégori...">
										<ng-template pTemplate="selectedItem">
											<div *ngIf="categorie">
												<div>{{categorie.nom}}</div>
											</div>
										</ng-template>
										<ng-template let-categorie pTemplate="item">
											<div>
												<div>{{categorie.nom}}</div>
											</div>
										</ng-template>
									</p-dropdown> -->
									<p-multiSelect [options]="colonnesDisponibles" [(ngModel)]="colonnesSelectionnees" optionLabel="entete"
										selectedItemsLabel="{0} colonnes sélectionnés" [style]="{minWidth: '200px', marginLeft: '10px'}"
										placeholder="Colonnes..."></p-multiSelect>
								</div>
							</div>
						</ng-template>
						<ng-template pTemplate="header" let-columns>
							<tr>

								<th style="min-width: 3rem">
									<p-tableHeaderCheckbox></p-tableHeaderCheckbox>
								</th>
								
								
								<th pSortableColumn="emplacement.nom">
									<div class="flex justify-content-between align-items-center">
										Emplacement
										<p-sortIcon field="emplacement.nom"></p-sortIcon>
										<p-columnFilter type="text" field="emplacement.nom" display="menu"
											class="ml-auto">
										</p-columnFilter>
									</div>
								</th>

								<th pSortableColumn="quantite">
									<div class="flex justify-content-between align-items-center">
										Qte
										<p-sortIcon field="quantiteDemande"></p-sortIcon>
										<p-columnFilter type="text" field="quantiteDemande" display="menu"
											class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
							
								<th pSortableColumn="statut.nom">
									<div class="flex justify-content-between align-items-center">
										Statut
										<p-sortIcon field="statut.nom"></p-sortIcon>
										<p-columnFilter field="statut" matchMode="in" display="menu" [showMatchModes]="false"
										[showOperator]="false" [showAddButton]="false">
										<ng-template pTemplate="filter" let-value let-filter="filterCallback">
											<p-multiSelect [ngModel]="value" [options]="statuts" placeholder="Any"
												(onChange)="filter($event.value)" optionLabel="name">
												<ng-template let-option pTemplate="item">
													<div class="multiselect-representative-option">
	
														<span class="ml-1">{{option.nom}}</span>
													</div>
												</ng-template>
											</p-multiSelect>
										</ng-template>
									</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="item.numero">
									<div class="flex justify-content-between align-items-center">
										Numéro
										<p-sortIcon field="item.numero"></p-sortIcon>
										<p-columnFilter type="text" field="item.numero" display="menu"
											class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="item.description">
									<div class="flex justify-content-between align-items-center">
										Description
										<p-sortIcon field="item.description"></p-sortIcon>
										<p-columnFilter type="text" field="item.description" display="menu"
											class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th *ngIf="EstColonneSelectionnee('categorie')" pSortableColumn="item.categorie.nom">
									<div class="flex justify-content-between align-items-center">
										Catégorie
										<p-sortIcon field="item.categorie.nom"></p-sortIcon>
										<p-columnFilter type="text" field="item.categorie.nom" display="menu"
											class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th *ngIf="EstColonneSelectionnee('marque')" pSortableColumn="item.marque">
									<div class="flex justify-content-between align-items-center">
										Marque
										<p-sortIcon field="item.marque"></p-sortIcon>
										<p-columnFilter type="text" field="item.marque" display="menu"
											class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th *ngIf="EstColonneSelectionnee('modele')" pSortableColumn="item.modele">
									<div class="flex justify-content-between align-items-center">
										Modèle
										<p-sortIcon field="item.modele"></p-sortIcon>
										<p-columnFilter type="text" field="item.modele" display="menu"
											class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								
								<th *ngIf="EstColonneSelectionnee('demandeur')" pSortableColumn="demandeur.prenomNom">
									<div class="flex justify-content-between align-items-center">
										Demandeur
										<p-sortIcon field="demandeur.prenomNom"></p-sortIcon>
										<p-columnFilter type="text" field="demandeur.prenomNom" display="menu"
											class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th *ngIf="EstColonneSelectionnee('dateDemande')" pSortableColumn="dateDemande">
									<div class="flex justify-content-between align-items-center">
										Date de demande
										<p-sortIcon field="dateDemande"></p-sortIcon>
										<p-columnFilter type="date" field="dateDemande" display="menu"
											class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th *ngIf="EstColonneSelectionnee('dateRequis')" pSortableColumn="dateRequis">
									<div class="flex justify-content-between align-items-center">
										Date requise
										<p-sortIcon field="dateRequis"></p-sortIcon>
										<p-columnFilter type="date" field="dateRequis" display="menu"
											class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th *ngIf="EstColonneSelectionnee('expediteur')" pSortableColumn="employe.prenomNom">
									<div class="flex justify-content-between align-items-center">
										Expéditeur
										<p-sortIcon field="employe.prenomNom"></p-sortIcon>
										<p-columnFilter type="text" field="employe.prenomNom" display="menu"
											class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th *ngIf="EstColonneSelectionnee('camion')" pSortableColumn="camion">
									<div class="flex justify-content-between align-items-center">
										Camion
										<p-sortIcon field="camion"></p-sortIcon>
										<p-columnFilter type="text" field="camion" display="menu"
											class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th *ngIf="EstColonneSelectionnee('remorque')" pSortableColumn="remorque">
									<div class="flex justify-content-between align-items-center">
										Remorque
										<p-sortIcon field="remorque"></p-sortIcon>
										<p-columnFilter type="text" field="remorque" display="menu"
											class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th *ngIf="EstColonneSelectionnee('chauffeur')" pSortableColumn="chauffeur">
									<div class="flex justify-content-between align-items-center">
										Chauffeur
										<p-sortIcon field="chauffeur"></p-sortIcon>
										<p-columnFilter type="text" field="chauffeur" display="menu"
											class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th *ngIf="EstColonneSelectionnee('unite')" pSortableColumn="unite.numero">
									<div class="flex justify-content-between align-items-center">
										Unité
										<p-sortIcon field="unite.numero"></p-sortIcon>
										<p-columnFilter type="text" field="unite.numero" display="menu"
											class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th *ngIf="EstColonneSelectionnee('codeProjet')" pSortableColumn="codeProjet.code">
									<div class="flex justify-content-between align-items-center">
										Code projet
										<p-sortIcon field="codeProjet.code"></p-sortIcon>
										<p-columnFilter type="text" field="codeProjet.code" display="menu"
											class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th *ngIf="EstColonneSelectionnee('codeActivite')" pSortableColumn="codeActivite.code">
									<div class="pvd-flex justify-content-between align-items-center">
										Code activité
										<p-sortIcon field="codeActivite.code"></p-sortIcon>
										<p-columnFilter type="text" field="codeActivite.code" display="menu"
											class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th *ngIf="EstColonneSelectionnee('codeCategorie')" pSortableColumn="codeCategorie.code">
									<div class="flex justify-content-between align-items-center">
										Code catégorie
										<p-sortIcon field="codeCategorie.code"></p-sortIcon>
										<p-columnFilter type="text" field="codeCategorie.code" display="menu"
											class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th *ngIf="EstColonneSelectionnee('departement')" pSortableColumn="departement">
									<div class="flex justify-content-between align-items-center">
										Département
										<p-sortIcon field="departement"></p-sortIcon>
										<p-columnFilter field="departement" matchMode="in" display="menu" [showMatchModes]="false"
										[showOperator]="false" [showAddButton]="false">
										<ng-template pTemplate="filter" let-value let-filter="filterCallback">
											<p-multiSelect [ngModel]="value" [options]="departements" placeholder="Any"
												(onChange)="filter($event.value)" optionLabel="name">
												<ng-template let-option pTemplate="item">
													<div class="multiselect-representative-option">
	
														<span class="ml-1">{{option.nom}}</span>
													</div>
												</ng-template>
											</p-multiSelect>
										</ng-template>
									</p-columnFilter>
									</div>
								</th>
								<th *ngIf="EstColonneSelectionnee('note')" pSortableColumn="note">
									<div class="flex justify-content-between align-items-center">
										Note
										<p-sortIcon field="note"></p-sortIcon>
										<p-columnFilter type="text" field="note" display="menu"
											class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
							
								<th style="min-width: 10rem; width: 10rem;"></th>
							</tr>
						</ng-template>
						<ng-template pTemplate="body" let-item>
							
							<tr [pContextMenuRow]="item">
								<td>
									<p-tableCheckbox [value]="item"></p-tableCheckbox>
								</td>
								<td>{{item.emplacement.nom}}</td>
								<td>{{item.quantite}} / {{item.quantiteDemande}}</td>
								<td><span [class]="'statut ' + item.statut.nom.replace(' ','').toLowerCase()">
									{{item.statut.nom}}
								</span>
								</td>
							
								<td>{{item.item.numero}}</td>
								<td>{{item.item.description}}</td>
								<td *ngIf="EstColonneSelectionnee('categorie')">{{item.item.categorie?.nom}}</td>
								<td *ngIf="EstColonneSelectionnee('marque')">{{item.item.marque}}</td>
								<td *ngIf="EstColonneSelectionnee('modele')">{{item.item.modele}}</td>
								<td *ngIf="EstColonneSelectionnee('demandeur')">{{item.demandeur | nomEmploye}}</td>
								<td *ngIf="EstColonneSelectionnee('dateDemande')">{{item.dateDemande | date:'yyyy-MM-dd'}}</td>
								<td *ngIf="EstColonneSelectionnee('dateRequis')">{{item.dateRequis | date:'yyyy-MM-dd'}}</td>
								<td *ngIf="EstColonneSelectionnee('expediteur')">{{item.employe | nomEmploye}}</td>
								<td *ngIf="EstColonneSelectionnee('camion')">{{item.camion}}</td>
								<td *ngIf="EstColonneSelectionnee('remorque')">{{item.remorque}}</td>
								<td *ngIf="EstColonneSelectionnee('chauffeur')">{{item.chauffeur}}</td>
								<td *ngIf="EstColonneSelectionnee('unite')">{{item.unite?.numero}}</td>
								<td *ngIf="EstColonneSelectionnee('codeProjet')">{{item.codeProjet?.code}}</td>
								<td *ngIf="EstColonneSelectionnee('codeActivite')">{{item.codeActivite?.code}}</td>
								<td *ngIf="EstColonneSelectionnee('codeCategorie')">{{item.codeCategorie?.code}}</td>
								<td *ngIf="EstColonneSelectionnee('departement')">{{item.departement?.nom}}</td>
								<td *ngIf="EstColonneSelectionnee('note')">{{item.note}}</td>
								<td>
									<button *ngIf="estAutorise('commande_supprimer_item')" pButton pRipple icon="pi pi-trash" class="p-button-danger mr-2 boutonGrille"
									(click)="supprimerItems([item])"></button>
									<button *ngIf="item.documentCount > 0" pButton pRipple icon="pi pi-file-pdf" class="p-button-secondary mr-2 boutonGrille" tooltip="Documents"
									(click)="surDocumentsItem(item)"></button>
									<button pButton pRipple icon="pi pi-bars" class="p-button-secondary mr-2 boutonGrille"
									(click)="surMenuContextuelItem($event, item)"></button>
								</td>
							</tr>
						</ng-template>
						<ng-template pTemplate="emptymessage">
							<tr>
								<td colspan="8">Aucun item.</td>
							</tr>
						</ng-template>
					</p-table>

			
		</div>
	</div>


</div>

<div *ngIf="EstCharge()" class="grid">
	<div class="col-12">
		<div class="card">
			<p-menubar autoDisplay="false" [model]="menu"></p-menubar>

		</div>
	</div>
</div>
import { Component, Input } from '@angular/core';
import { BaseComponent } from '../../base/base-component';
import { ProfilService } from '../../services/profil-service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppMainComponent } from '../../app.main.component';
import { DonneesBaseService } from '../../services/donnees-base-service';

@Component({
    selector: 'app-help-tooltip',
    templateUrl: './help-tooltip.component.html'
})
export class HelpTooltipComponent extends BaseComponent {
    @Input() text: string = '';
    @Input() position: string = 'right';

    constructor(
        protected sdb: DonneesBaseService,
        protected profilService: ProfilService,
        protected route: ActivatedRoute,
        protected routerService: Router,
        public appMain: AppMainComponent
    ) {
        super(sdb, profilService, route, routerService, appMain);
    }
}
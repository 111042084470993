<p-card *ngIf="!EstCharge()">
	<div class="fluid formgrid grid">
		<div class="flex flex-column gap-2 my-2 col-12 text-center">
			<p-progressSpinner></p-progressSpinner>
		</div>
	</div>
</p-card>

<div class="grid" *ngIf="EstCharge()">
    <div class="col-12">

        <div class="card">
 
            <p-table #dt [value]="emplacements" dataKey="emplacementId" tableStyleClass="tableNormale" autoLayout="true"
                styleClass="p-datatable-sm p-datatable-striped" [rowHover]="true" [filterDelay]="0"
                [globalFilterFields]="['nom','description','code']">
                
                <ng-template pTemplate="caption">
                    <div class="flex align-content-center justify-content-between">
                        <div>
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input #champRecherche pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                (keyup.enter)="surEntreeRecherche()"
                                    placeholder="Recherche..." />
                            </span>
                            <button *ngIf="estAutorise('emplacement_creer')" pButton pRipple icon="pi pi-plus"
                            class="p-button-success mr-2 ml-4 mb-2 boutonGrille"
                            (click)="surAjoutEmplacement()"></button>
                        </div>
                        <h5 class="titreTableau m-0">Emplacements</h5>
                        <div>
                            <p-multiSelect disabled="true" [style]="{minWidth: '200px'}" placeholder="Colonnes..."></p-multiSelect>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th pSortableColumn="code">
                            <div class="flex justify-content-between align-items-center">
                                Code
                                <p-sortIcon field="code"></p-sortIcon>
                                <p-columnFilter type="text" field="code" display="menu" class="ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="nom">
                            <div class="flex justify-content-between align-items-center">
                                Nom
                                <p-sortIcon field="nom"></p-sortIcon>
                                <p-columnFilter type="text" field="nom" display="menu" class="ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="statut.nom">
                            <div class="flex justify-content-between align-items-center">
                               Statut
                                <p-sortIcon field="statut.nom"></p-sortIcon>
                                <p-columnFilter type="text" field="statut.nom" display="menu"
                                    class="ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>

                        <th style="min-width: 5rem; width: 5rem"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-emplacement>
                    <tr [pContextMenuRow]="liste">
                        <td>
                            <a *ngIf="estAutorise('emplacement_detail')" [routerLink]="'/pilotage/emplacement/' + emplacement.emplacementId">{{emplacement.code}}</a>
                            <span *ngIf="!estAutorise('emplacement_detail')">{{emplacement.code}}</span>

                        </td>
                        <td>{{emplacement.nom}}</td>
                        <td><span [class]="'statut ' + emplacement.statut.nom.replace(' ','').toLowerCase()">
                            {{emplacement.statut.nom}}
                        </span>
                        </td>
                        <td>
                            <button *ngIf="estAutorise('emplacement_detail')" pButton pRipple icon="pi pi-eye" class="p-button-success mr-2 boutonGrille"
                                (click)="OuvrirDetail(emplacement.emplacementId)"></button>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="4">Aucun emplacement.</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>


</div>

<p-dialog [(visible)]="dialogueNouvelEmplacement" [style]="{width: '85%'}" header="Nouvel emplacement" [modal]="true"
    styleClass="fluid formgrid grid">
    <ng-template pTemplate="content">

        <p-toast key="nouvelEmplacement"></p-toast>

        <div class="formgrid grid">
            <div class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-3">
                <label for="code">Code<span class="requis">*</span></label>
                <input type="text" pInputText id="code" [(ngModel)]="nouvelEmplacement.code" autocomplete="off" />
                <small class="p-error" *ngIf="submitted && !nouvelEmplacement.code">Le code est obligatoire.</small>
            </div>
            <div class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-6">
                <label for="nom">Nom<span class="requis">*</span></label>
                <input type="text" pInputText id="nom" [(ngModel)]="nouvelEmplacement.nom" autocomplete="off" />
                <small class="p-error" *ngIf="submitted && !nouvelEmplacement.nom">Le nom est obligatoire.</small>
            </div>
            
        </div>

    </ng-template>

    <ng-template pTemplate="footer">
        <button pButton pRipple label="Annuler" icon="pi pi-times" class="p-button-text"
            (click)="annulerNouvelEmplacement()"></button>
        <button pButton pRipple label="Créer" icon="pi pi-check" class="p-button-text"
            (click)="creerNouvelEmplacement()"></button>
    </ng-template>
</p-dialog>
<p-card *ngIf="!EstCharge()">
	<div class="fluid formgrid grid">
		<div class="flex flex-column gap-2 col-12 text-center">
			<p-progressSpinner></p-progressSpinner>
		</div>
	</div>
</p-card>
<div class="card" *ngIf="EstCharge()">
<div class="fluid formgrid grid">
    <div class="flex flex-column gap-2 col-12">
        <label for="lastname">Fournisseur</label>
                <p-dropdown [options]="fournisseurs" [(ngModel)]="selectionFournisseur" optionLabel="nom" [showClear]="true" 
                    filter="true" filterBy="numero,nom"
                    placeholder="Sélectionner un fournisseur...">
                    <ng-template pTemplate="selectedItem">
                        <div *ngIf="selectionFournisseur">
                            <div>{{selectionFournisseur.nom}} ({{selectionFournisseur.numero}})</div>
                        </div>
                    </ng-template>
                    <ng-template let-fournisseur pTemplate="item">
                        <div>
                            <div>{{fournisseur.nom}} ({{fournisseur.numero}})</div>
                        </div>
                    </ng-template>
                </p-dropdown>
                <small class="p-error" *ngIf="submitted && !selectionFournisseur">Le fournisseur est obligatoire.</small>
    </div>
    <div class="flex flex-column gap-2 col-12 md:col-6">
        <label for="noFournisseur">Numéro fournisseur</label>
        <input #champNoFournisseur type="text" pInputText id="noFournisseur" [(ngModel)]="numeroFournisseur"
                autocomplete="off" (keyup.enter)="confirmer()" />
    </div>
    <div class="flex flex-column gap-2 col-12 md:col-6">
        <label for="prix">Prix</label>
            <p-inputNumber #champPrix [(ngModel)]="prix" mode="currency" currency="USD" locale="en-US" (keyup.enter)="confirmer()"></p-inputNumber>
    </div>
</div>
<div class="grid mt-2">
    <div class="col-12 text-right ">
        <button pButton pRipple label="Confirmer" icon="pi pi-save" class="p-button-primary"
        (click)="confirmer()"></button>
        <button pButton pRipple label="Annuler" icon="pi pi-ban" class="p-button-text" (click)="annuler()"></button>
    </div>
</div>
</div>
		<p-toast></p-toast>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<p-card *ngIf="!EstCharge()">
	<div class="fluid formgrid grid">
		<div class="flex flex-column gap-2 my-2 col-12 text-center">
			<p-progressSpinner></p-progressSpinner>
		</div>
	</div>
</p-card>

<div *ngIf="EstCharge()" class="grid">
	<div class="col-12">
		<div class="card">
			<h5>Entretien préventif - {{entretien.item.numero}}
				<span [class]="'statut ' + entretien.statut.nom.replace(' ','').toLowerCase()">
					{{entretien.statut.nom}}
				</span>
			</h5>
			<p-menubar autoDisplay="false" [model]="menu">
				<p-button *ngIf="estAutorise('temp_mec') && modeConsultation() && estModifiable"
					(click)="surBoutonModifier()" icon="pi pi-pencil" styleClass="p-button-primary"></p-button>
				<p-button *ngIf="modeAjoutModification()" (onClick)="enregistrerModifications()" label
					icon="pi pi-check" styleClass="p-button-success mr-2">
				</p-button>
				<p-button *ngIf="modeModification()" (onClick)="surBoutonAnnulerModification($event)" icon="pi pi-times"
					styleClass="p-button-secondary"></p-button>
			</p-menubar>
		</div>

	</div>
</div>


<div *ngIf="EstCharge()" class="grid">
	<div class="col-12">
		<div class="card">

			<div class="fluid formgrid grid">
				<!-- <div class="flex flex-column gap-2 my-2 col-12 ">
					<label for="description">Description<span class="requis">*</span></label>
					<span *ngIf="modeConsultation()" class="champConsultation" >{{entretien.description | valeurVide}}</span>
					<input *ngIf="!modeConsultation()" #champDescription id="description" type="text" pInputText
						[(ngModel)]="entretien.description">
					<small class="p-error" *ngIf="submitted && !entretien.description">La description est obligatoire.</small>
				</div> -->

				<div class="flex flex-column gap-2 my-2 col-12">
					<label for="description">Description<span class="requis">*</span></label>
					<span *ngIf="modeConsultation()" class="champConsultation" >{{entretien.description | valeurVide}}</span>
					<p-autoComplete *ngIf="!modeConsultation()" #champDescription appendTo="body" [(ngModel)]="entretien.description"
						[suggestions]="suggestionDescription" (completeMethod)="rechercherDescritpion($event)">
					</p-autoComplete>
					<small class="p-error" *ngIf="submitted && !entretien.description">La description
						est
						obligatoire.</small>
				</div>
			</div>

			<div class="fluid formgrid grid">

				<div class="flex flex-column gap-2 my-2 col-12 md:col-3 ">
					<label for="lastname">Code de catégorie</label>
					<span *ngIf="modeConsultation()" class="champConsultation" >{{entretien.categorie?.description | valeurVide}}</span>
					<p-dropdown *ngIf="!modeConsultation()" [options]="categories" [(ngModel)]="entretien.categorie" optionLabel="description" filter="true"
						appendTo="body" filterBy="code,description" placeholder="Sélectionner une catégorie...">
						<ng-template pTemplate="selectedItem">
							<div *ngIf="entretien.categorie">
								<div>{{entretien.categorie.code}} - {{entretien.categorie.description}}</div>
							</div>
						</ng-template>
						<ng-template let-categorie pTemplate="item">
							<div>
								<div>{{categorie.code}} - {{categorie.description}}</div>
							</div>
						</ng-template>
					</p-dropdown>
				</div>

			</div>

			<small class="p-error" *ngIf="submitted && !entretien.indSeuilHeure && !entretien.indSeuilKm && !entretien.indSeuilMois">L'entretien doit avoir au minimum un déclencheur.</small>

			<p-divider align="left">
				<div class="inline-flex align-items-center">
					<b>Déclenchement à l'heure</b>
				</div>
			</p-divider>


			<div class="fluid formgrid grid">
				<div class="flex flex-column gap-2 my-2 col-3 md:col-2 lg:col-1">
					<label for="indHeure">Actif</label><br>
					<p-inputSwitch [disabled]="modeConsultation()" [(ngModel)]="entretien.indSeuilHeure"></p-inputSwitch>
				</div>
				<div class="flex flex-column gap-2 my-2 col-3 lg:col-2">
					<label for="seuilHeure">Seuil<span *ngIf="entretien.indSeuilHeure" class="requis">*</span></label>
					<span *ngIf="modeConsultation()" class="champConsultation" >{{entretien.nbHeure | valeurVide}}</span>
					<p-inputNumber *ngIf="!modeConsultation()" [min]="0" [(ngModel)]="entretien.nbHeure">
					</p-inputNumber>
					<small class="p-error" *ngIf="submitted && entretien.indSeuilHeure && !entretien.nbHeure">Le seuil est obligatoire.</small>
				</div>
				<div class="flex flex-column gap-2 my-2 col-3 lg:col-2">
					<label for="seuilHeure">Tolérence<span *ngIf="entretien.indSeuilHeure" class="requis">*</span></label>
					<span *ngIf="modeConsultation()" class="champConsultation" >{{entretien.tolHeure | valeurVide}}</span>
					<p-inputNumber *ngIf="!modeConsultation()" [min]="0" [(ngModel)]="entretien.tolHeure">
					</p-inputNumber>
					<small class="p-error" *ngIf="submitted && entretien.indSeuilHeure && !entretien.tolHeure">La tolérance est obligatoire.</small>
				</div>
				<div class="flex flex-column gap-2 my-2 col-3 lg:col-2">
					<label for="moisFait">Date du dernier entretien effectué</label>
					<span *ngIf="modeConsultation()" class="champConsultation" >{{entretien.dateHeure | date:'yyyy-MM-dd' | valeurVide}}</span>
					<p-calendar showIcon=true [(ngModel)]="entretien.dateHeure" [disabled]="bloquerDernierHeure" dateFormat="yy-mm-dd" [yearNavigator]="true" *ngIf="!modeConsultation()"
						[monthNavigator]="true" yearRange="2000:2040" appendTo="body">
					</p-calendar>
					<small class="p-error" *ngIf="submitted && entretien.indSeuilHeure && entretien.heureFaitCompteur && entretien.heureFaitCompteur != 0 && !entretien.dateHeure">La date du dernier entretien est obligatoire.</small>
				</div>
				<div class="flex flex-column gap-2 my-2 col-3 lg:col-2">
					<label for="heureFait">Nb heures du dernier entretien effectué<span *ngIf="entretien.indSeuilHeure" class="requis">*</span></label>
					<span *ngIf="modeConsultation()" class="champConsultation" >{{entretien.heureFaitCompteur | valeurVide}}</span>
					<p-inputNumber *ngIf="!modeConsultation()" [disabled]="bloquerDernierHeure" [min]="0" [(ngModel)]="entretien.heureFaitCompteur">
					</p-inputNumber>
					<small class="p-error" *ngIf="submitted && entretien.indSeuilHeure && !bloquerDernierHeure && !entretien.heureFaitCompteur && entretien.heureFaitCompteur != 0">Le dernier entretien est obligatoire.</small>
				</div>
			</div>

			<p-divider align="left">
				<div class="inline-flex align-items-center">
					<b>Déclenchement au KM</b>
				</div>
			</p-divider>


			<div class="fluid formgrid grid">
				<div class="flex flex-column gap-2 my-2 col-3 md:col-2 lg:col-1">
					<label for="indKm">Actif</label><br>
					<p-inputSwitch [disabled]="modeConsultation()" [(ngModel)]="entretien.indSeuilKm"></p-inputSwitch>
				</div>
				<div class="flex flex-column gap-2 my-2 col-3 lg:col-2">
					<label for="seuilKm">Seuil<span *ngIf="entretien.indSeuilKm" class="requis">*</span></label>
					<span *ngIf="modeConsultation()" class="champConsultation" >{{entretien.nbKm | valeurVide}}</span>
					<p-inputNumber *ngIf="!modeConsultation()" [min]="0" [(ngModel)]="entretien.nbKm">
					</p-inputNumber>
					<small class="p-error" *ngIf="submitted && entretien.indSeuilKm && !entretien.nbKm">Le seuil est obligatoire.</small>
				</div>
				<div class="flex flex-column gap-2 my-2 col-3 lg:col-2">
					<label for="seuilKm">Tolérence<span *ngIf="entretien.indSeuilKm" class="requis">*</span></label>
					<span *ngIf="modeConsultation()" class="champConsultation" >{{entretien.tolKm | valeurVide}}</span>
					<p-inputNumber *ngIf="!modeConsultation()" [min]="0" [(ngModel)]="entretien.tolKm">
					</p-inputNumber>
					<small class="p-error" *ngIf="submitted && entretien.indSeuilKm && !entretien.tolKm">La tolérance est obligatoire.</small>
				</div>
				<div class="flex flex-column gap-2 my-2 col-3 lg:col-2">
					<label for="moisFait">Date du dernier entretien effectué</label>
					<span *ngIf="modeConsultation()" class="champConsultation" >{{entretien.dateKm | date:'yyyy-MM-dd' | valeurVide}}</span>
					<p-calendar showIcon=true [(ngModel)]="entretien.dateKm" dateFormat="yy-mm-dd" [yearNavigator]="true" *ngIf="!modeConsultation()"
						[monthNavigator]="true" [disabled]="bloquerDernierKm" yearRange="2000:2040" appendTo="body">
					</p-calendar>
					<small class="p-error" *ngIf="submitted && entretien.indSeuilKm && entretien.kmFaitCompteur && entretien.kmFaitCompteur != 0 && !entretien.dateKm">La date du dernier entretien est obligatoire.</small>
				</div>
				<div class="flex flex-column gap-2 my-2 col-3 lg:col-2">
					<label for="kmFait">Nb KM du dernier entretien effectué<span *ngIf="entretien.indSeuilKm" class="requis">*</span></label>
					<span *ngIf="modeConsultation()" class="champConsultation" >{{entretien.kmFaitCompteur | valeurVide}}</span>
					<p-inputNumber *ngIf="!modeConsultation()" [disabled]="bloquerDernierKm" [min]="0" [(ngModel)]="entretien.kmFaitCompteur">
					</p-inputNumber>
					<small class="p-error" *ngIf="submitted && entretien.indSeuilKm && !bloquerDernierKm && !entretien.kmFaitCompteur && entretien.kmFaitCompteur != 0">Le dernier entretien est obligatoire.</small>
				</div>
			</div>

			<p-divider align="left">
				<div class="inline-flex align-items-center">
					<b>Déclenchement au mois</b>
				</div>
			</p-divider>


			<div class="fluid formgrid grid">
				<div class="flex flex-column gap-2 my-2 col-3 md:col-2 lg:col-1">
					<label for="indMois">Actif</label><br>
					<p-inputSwitch [disabled]="modeConsultation()" [(ngModel)]="entretien.indSeuilMois"></p-inputSwitch>
				</div>
				<div class="flex flex-column gap-2 my-2 col-3 lg:col-2">
					<label for="seuilMois">Seuil<span *ngIf="entretien.indSeuilMois" class="requis">*</span></label>
					<span *ngIf="modeConsultation()" class="champConsultation" >{{entretien.nbMois | valeurVide}}</span>
					<p-inputNumber *ngIf="!modeConsultation()" [min]="0" [(ngModel)]="entretien.nbMois">
					</p-inputNumber>
					<small class="p-error" *ngIf="submitted && entretien.indSeuilMois && !entretien.nbMois">Le seuil est obligatoire.</small>
				</div>
				<div class="flex flex-column gap-2 my-2 col-3 lg:col-2">
					<label for="seuilMois">Tolérence<span *ngIf="entretien.indSeuilMois" class="requis">*</span></label>
					<span *ngIf="modeConsultation()" class="champConsultation" >{{entretien.tolMois | valeurVide}}</span>
					<p-inputNumber *ngIf="!modeConsultation()" [min]="0" [(ngModel)]="entretien.tolMois">
					</p-inputNumber>
					<small class="p-error" *ngIf="submitted && entretien.indSeuilMois && !entretien.tolMois">La tolérance est obligatoire.</small>
				</div>
				<div class="flex flex-column gap-2 my-2 col-3 lg:col-2">
					<label for="moisFait">Date du dernier entretien effectué<span *ngIf="entretien.indSeuilMois" class="requis">*</span></label>
					<span *ngIf="modeConsultation()" class="champConsultation" >{{entretien.moisFait | valeurVide}}</span>
					<p-calendar showIcon=true [(ngModel)]="entretien.moisFait" dateFormat="yy-mm-dd" [yearNavigator]="true" *ngIf="!modeConsultation()"
						[monthNavigator]="true" [disabled]="bloquerDernierMois" yearRange="2000:2040" appendTo="body">
					</p-calendar>
					<small class="p-error" *ngIf="submitted && entretien.indSeuilMois && !entretien.moisFait">Le dernier entretien est obligatoire.</small>
				</div>
				<div class="flex flex-column gap-2 my-2 col-3 lg:col-2">
					<label for="moisFait">Début de mois</label>
					<br>
					<p-checkbox [disabled]="modeConsultation()" [binary]="true" [(ngModel)]="entretien.indDebutMois"></p-checkbox>
				</div>

			</div>

			<p-divider align="left">
				<div class="inline-flex align-items-center">
					<b>Pièces nécéssaires</b>
				</div>
			</p-divider>


				<div class="flex flex-column gap-2 my-2 col-12">
					<p-table #dtPieces [value]="entretien.pieces" [columns]="selectedColumns"
						dataKey="itemId" autoLayout="true" tableStyleClass="tableNormale"
						 [rowHover]="true" [rows]="50" [paginator]="true"
						[filterDelay]="0" [globalFilterFields]="['numero','description','marque','modele']">
						<ng-template pTemplate="caption">
							<div class="flex align-content-center justify-content-between">
								<div>
									<span class="p-input-icon-left">
										<i class="pi pi-search"></i>
										<input #rechercheItem pInputText type="text"
											(input)="dtPieces.filterGlobal($event.target.value, 'contains')"
											 placeholder="Recherche..." />
									</span>
									<button *ngIf="estAutorise('temp_mec') && !modeConsultation()" pButton pRipple icon="pi pi-plus"
										class="p-button-success mr-2 ml-4 mb-2 boutonGrille"
										(click)="ajouterPiece()"></button>
								</div>
								<div>
									<h5 class="titreTableau m-0">Pièces</h5>
								</div>

								<div>
									<p-multiSelect disabled="true" [style]="{minWidth: '200px'}"
										placeholder="Colonnes..."></p-multiSelect>
								</div>
							</div>
						</ng-template>
						<ng-template pTemplate="header" let-columns>
							<tr>
								<th pSortableColumn="item.numero">
									<div class="flex justify-content-between align-items-center">
										Numéro
										<p-sortIcon field="item.numero"></p-sortIcon>
										<p-columnFilter type="text" field="item.numero" display="menu" class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="item.description">
									<div class="flex justify-content-between align-items-center">
										Description
										<p-sortIcon field="item.description"></p-sortIcon>
										<p-columnFilter type="text" field="item.description" display="menu" class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="quantite">
									<div class="flex justify-content-between align-items-center">
										Quantité
										<p-sortIcon field="quantite"></p-sortIcon>
										<p-columnFilter type="numeric" field="quantite" display="menu" class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="modele">
									<div class="flex justify-content-between align-items-center">
										Marque
										<p-sortIcon field="modele"></p-sortIcon>
										<p-columnFilter type="text" field="modele" display="menu" class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="modele">
									<div class="flex justify-content-between align-items-center">
										Modèle
										<p-sortIcon field="modele"></p-sortIcon>
										<p-columnFilter type="text" field="description" display="menu" class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th style="min-width: 7rem; width: 7rem;"></th>
							</tr>
						</ng-template>
						<ng-template pTemplate="body" let-piece let-columns="columns">
							<tr >
								<td>{{piece.item.numero}}</td>
								<td>{{piece.item.description}}</td>
								<td>{{piece.quantite}}</td>
								<td>{{piece.item.marque}}</td>
								<td>{{piece.item.modele}}</td>
								<td class="celluleTableau flex">
									<p-menu #menuContextuelPiece popup="popup" appendTo="body" [model]="contenuMenuContextuelPiece"></p-menu>
									<button *ngIf="!modeConsultation()" pButton pRipple icon="pi pi-trash"
										class="p-button-danger mr-2 boutonGrille"
										(click)="supprimerPiece($event, piece)"></button>
									<button pButton pRipple icon="pi pi-bars"
										class="p-button-secondary mr-2 boutonGrille"
										(click)="surMenuContextuelPiece($event, piece)"></button>
								</td>

							</tr>
						</ng-template>
						<ng-template pTemplate="emptymessage">
							<tr>
								<td colspan="6">Aucune pièce.</td>
							</tr>
						</ng-template>
					</p-table>
				</div>

		

			<div class="fluid formgrid grid">
				<div class="flex flex-column gap-2 my-2 col-12">
					<label for="note">Note</label>
					<span *ngIf="modeConsultation()" class="champConsultation" >{{entretien.note | valeurVide}}</span>
					<input *ngIf="!modeConsultation()" id="note" type="text" pInputText [(ngModel)]="entretien.note">
				</div>
			</div>

		</div>
	</div>

</div>

<p-toast></p-toast>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<p-menu #menuContextuelInventaire popup="popup" [model]="contenuMenuContextuelInventaire"></p-menu>

<div class="fluid formgrid grid">
	<div class="flex flex-column gap-2 my-2 col-12">
		<p-message severity="warn" styleClass="w-full"
			text="ATTENTION : Tous les items présents dans la liste ci-bas seront automatiquement archivés."></p-message>
	</div>
</div>

<div class="grid">
	<div class="col-12">
		<div class="card">
			<p-menubar autoDisplay="false" [model]="menu">
				<ng-template pTemplate="start">
					<div class="flex gap-2">
						<p-button [text]="true" label="Annuler" icon="pi pi-fw pi-ban"
							(click)="routerService.navigateByUrl('pilotage/emplacement/' + emplacement.emplacementId)"></p-button>
						<p-button label="Confirmer l'archivage" icon="pi pi-fw pi-check"
							(click)="archiver()"></p-button>
					</div>
				</ng-template>
			</p-menubar>
		</div>
	</div>
</div>

<div class="grid">
	<div class="col-12">
		<div class="card">
			<p-table #dtInventaire [value]="inventaire" dataKey="itemId" [lazy]="true" [totalRecords]="totalInventaire"
				[loading]="!chargementCompleteInventaire || !inventaire" tableStyleClass="tableNormale"
				autoLayout="true" [rowHover]="true" [filterDelay]="0" [paginator]="true" [rows]="100"
				[rowsPerPageOptions]="[10,20,50,100,200,500]" [globalFilterFields]="['numero','quantite','description']"
				(onLazyLoad)="onLazyLoadInventaire($event)">
				<ng-template pTemplate="caption">
					<div class="flex align-content-center justify-content-between">
						<div>
							<span class="p-input-icon-left">
								<i class="pi pi-search"></i>
								<input pInputText type="text"
									(input)="dtInventaire.filterGlobal($event.target.value, 'contains')"
									placeholder="Recherche..." />
							</span>

						</div>
						<h5 class="titreTableau m-0">Inventaire</h5>
						<div>
							<p-multiSelect disabled="true" [style]="{minWidth: '200px'}"
								placeholder="Colonnes..."></p-multiSelect>
						</div>
					</div>
				</ng-template>
				<ng-template pTemplate="header">
					<tr>
						<th pSortableColumn="categorie.nom">
							<div class="flex justify-content-between align-items-center">
								Catégorie
								<p-sortIcon field="categorie.nom"></p-sortIcon>
								<p-columnFilter type="text" field="categorie.nom" display="menu" class="ml-auto">
								</p-columnFilter>
							</div>
						</th>
						<th pSortableColumn="numero">
							<div class="flex justify-content-between align-items-center">
								Numéro
								<p-sortIcon field="numero"></p-sortIcon>
								<p-columnFilter type="text" field="numero" display="menu" class="ml-auto">
								</p-columnFilter>
							</div>
						</th>
						<th pSortableColumn="description">
							<div class="flex justify-content-between align-items-center">
								Description
								<p-sortIcon field="description"></p-sortIcon>
								<p-columnFilter type="text" field="description" display="menu" class="ml-auto">
								</p-columnFilter>
							</div>
						</th>
						<th pSortableColumn="quantite">
							<div class="flex justify-content-between align-items-center">
								Quantité
								<p-sortIcon field="quantite"></p-sortIcon>
								<p-columnFilter type="numeric" field="quantite" display="menu" class="ml-auto">
								</p-columnFilter>
							</div>
						</th>
						<th style="min-width: 5rem; width: 5rem;"></th>
					</tr>
				</ng-template>
				<ng-template pTemplate="body" let-item>

					<tr>
						<td>{{item.categorie.nom}}</td>
						<td>{{item.numero}}</td>
						<td>{{item.description}}</td>
						<td>{{item.quantite}}</td>
						<td>
							<button pButton pRipple icon="pi pi-bars" class="p-button-secondary mr-2 boutonGrille"
								(click)="surMenuContextuelInventaire($event, item)"></button>
						</td>
					</tr>
				</ng-template>
				<ng-template pTemplate="emptymessage">
					<tr>
						<td colspan="5">Aucun item unique en inventaire.</td>
					</tr>
				</ng-template>
				<ng-template pTemplate="loadingicon">
					<p-progressSpinner></p-progressSpinner>
				</ng-template>
			</p-table>
		</div>
	</div>
</div>
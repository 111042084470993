<p-toast></p-toast>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<p-menu #menuContextuelItemCommande popup="popup" [model]="menuContextuelEntrees"></p-menu>

<p-card *ngIf="!EstCharge()">
	<div class="fluid formgrid grid">
		<div class="flex flex-column gap-2 my-2 col-12 text-center">
			<p-progressSpinner></p-progressSpinner>
		</div>
	</div>
</p-card>

<div *ngIf="EstCharge()" class="grid">
	<div class="col-12">
		<div class="card">
			<h5>Modèle "{{modeleListeAchat.nom}}"
			</h5>

			<p-menubar autoDisplay="false" [model]="menu">
				<!-- <p-button *ngIf="modeConsultation() && bdc.statut.statutId == 'Ouvert'" (click)="btnModifier_click()"
					label="Modifier" icon="pi pi-pencil" iconPos="left"></p-button>
				<p-button *ngIf="modeAjoutModification()" label="Sauvegarder" icon="pi pi-pencil" iconPos="left">
				</p-button>
				<p-button *ngIf="modeAjoutModification()" label="Annuler" icon="pi pi-pencil" iconPos="left"
					(onClick)="btnAnnuler_click()"></p-button> -->
			</p-menubar>
		</div>
	</div>
</div>

<div *ngIf="EstCharge()" class="grid">
	<div class="col-12">
		<div class="card">
			<p-tabView [scrollable]="true" orientation="left">
				<p-tabPanel header="Informations générales" leftIcon="pi pi-info-circle">
					<div class="fluid formgrid grid">
						<div class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-3">
							<label for="nom">Nom</label>
							<input id="nom" type="text" pInputText [readonly]="modeConsultation()" [(ngModel)]="modeleListeAchat.nom">
                        </div>
						<div class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-9">
							<label for="description">Description</label>
							<input id="description" type="text" pInputText [readonly]="modeConsultation()" [(ngModel)]="modeleListeAchat.description">
						</div>
					</div>

					<p-table #dt [value]="modeleListeAchat.itemsModeleListeAchat" [columns]="selectedColumns" dataKey="itemModeleListeAchatId" tableStyleClass="tableNormale"
					[(selection)]="selectionMultipleEntree"  (onRowSelect)="surChangementSelection()" (onRowUnselect)="surChangementSelection()"
					(onHeaderCheckboxToggle)="surChangementSelection()"
						styleClass="p-datatable-sm p-datatable-striped"
					 [rowHover]="true" [rows]="25" [paginator]="true" [filterDelay]="0"
						[globalFilterFields]="['item.numero','item.description']">
						<ng-template pTemplate="caption">
							<div class="flex align-content-center justify-content-between">
								<div>
									<span class="p-input-icon-left">
										<i class="pi pi-search"></i>
										<input pInputText type="text"
											(input)="dt.filterGlobal($event.target.value, 'contains')"
											placeholder="Recherche..." />
									</span><button  pButton pRipple icon="pi pi-plus"
										class="p-button-rounded p-button-success p-button-sm mb-2 ml-4"
										(click)="surAjoutItem()"></button>
								</div>
								<h5 class="titreTableau m-0">Liste des items</h5>
								<div>
									<p-multiSelect disabled="true" [style]="{minWidth: '200px'}" placeholder="Colonnes..."></p-multiSelect>
								</div>
							</div>
						</ng-template>
						
						<ng-template pTemplate="header">
							<tr>
								<th style="min-width: 3rem">
									<p-tableHeaderCheckbox></p-tableHeaderCheckbox>
								</th>
								<th pSortableColumn="numero">
									<div class="flex justify-content-between align-items-center">
										No SIHC
										<p-sortIcon field="numero"></p-sortIcon>
										<p-columnFilter type="text" field="numero" display="menu" class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="description">
									<div class="flex justify-content-between align-items-center">
										Description
										<p-sortIcon field="description"></p-sortIcon>
										<p-columnFilter type="text" field="description" display="menu"
											class="ml-auto">
										</p-columnFilter>
									</div>
                                </th>
                                <th pSortableColumn="categorie.nom">
                                    <div class="flex justify-content-between align-items-center">
                                        Catégorie
                                        <p-sortIcon field="categorie.nom"></p-sortIcon>
                                        <p-columnFilter type="text" field="categorie" display="menu" class="ml-auto">
                                        </p-columnFilter>
                                    </div>
                                </th>
                                <th pSortableColumn="sousCategorie.nom">
                                    <div class="flex justify-content-between align-items-center">
                                        Sous-catégorie
                                        <p-sortIcon field="sousCategorie.nom"></p-sortIcon>
                                        <p-columnFilter type="text" field="sousCategorie" display="menu" class="ml-auto">
                                        </p-columnFilter>
                                    </div>
                                </th>
                                <th  pSortableColumn="modele">
                                    <div class="flex justify-content-between align-items-center">
                                        Marque
                                        <p-sortIcon field="modele"></p-sortIcon>
                                        <p-columnFilter type="text" field="modele" display="menu" class="ml-auto">
                                        </p-columnFilter>
                                    </div>
                                </th>
                                <th pSortableColumn="modele">
                                    <div class="flex justify-content-between align-items-center">
                                        Modèle
                                        <p-sortIcon field="modele"></p-sortIcon>
                                        <p-columnFilter type="text" field="description" display="menu" class="ml-auto">
                                        </p-columnFilter>
                                    </div>
                                </th>
								<th ></th>

							</tr>
						</ng-template>
						<ng-template pTemplate="body" let-entree>
							<tr >
								<td>
									<p-tableCheckbox [value]="entree"></p-tableCheckbox>
								</td>
								<td>{{entree.item.numero}}</td>
								<td>{{entree.item.description}}</td>
								<td>{{entree.item.categorie.nom}}</td>
								<td>{{entree.item.sousCategorie?.nom}}</td>
								<td>{{entree.item.marque}}</td>
								<td>{{entree.item.modele}}</td>
								<td>
									<button pButton pRipple icon="pi pi-bars"
										class="p-button-rounded p-button-secondary"
										(click)="surMenuContextuelItem($event, entree)"></button>
								</td>

							</tr>
						</ng-template>
						<ng-template pTemplate="emptymessage">
							<tr>
								<td colspan="8">Aucun item.</td>
							</tr>
						</ng-template>
					</p-table>
				</p-tabPanel>
			</p-tabView>
		</div>
	</div>


</div>
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from "@angular/core"; import { Location } from "@angular/common";

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { ReponseBase } from './reponse-base';
import { RequisitionMin } from '../entites/requisition-min';
import { Requisition } from '../entites/requisition';
import { Commande } from '../entites/commande';
import { CommandeMin } from '../entites/commande-min';
import { RequeteObtenirCommande } from './requetes/requete-obtenir-commande';
import { ReponseObtenirCommande } from './reponses/reponse-obtenir-commande';
import { ItemCommandeEntite } from '../entites/item-commande';
import { RequeteCreerItemCommande } from './requetes/requete-creer-item-commande';
import { RequeteModifierItemCommande } from './requetes/requete-modifier-item-commande';
import { ReponseModifierItemCommande } from './reponses/reponse-modifier-item-commande';
import { EmplacementService } from './emplacement-service';
import { Emplacement } from '../entites/emplacement';
import { ReponseCreerItemCommande } from './reponses/reponse-creer-item-commande';
import { RequeteRemplacerItemCommande } from './requetes/requete-remplacer-item-commande';
import { TransporteurEntite } from '../entites/transporteur';
import { ItemService } from './item-service';
import { RequetePrecommandeEnChantier } from './requetes/requete-precommande-en-chantier';

@Injectable({ providedIn: 'root' })
export class CommandeService {

  private obtenirCommandeUrl = environment.apiBaseUrl + 'commande/{emplacementId}';
  private obtenirCommandeParDepartementUrl = environment.apiBaseUrl + 'commande/departement/{departementId}';
  private obtenirCommandeCompletUrl = environment.apiBaseUrl + 'commande/complet';
  private obtenirPrecommandeUrl = environment.apiBaseUrl + 'commande/{emplacementId}/{employeId}';
  private obtenirApprobationsPrecommandeUrl = environment.apiBaseUrl + 'commande/approbations';
  private obtenirApprobationPrecommandeUrl = environment.apiBaseUrl + 'commande/{emplacementId}/approbation';
  private creerItemCommandeUrl = environment.apiBaseUrl + 'commande';
  private modifierItemCommandeUrl = environment.apiBaseUrl + 'commande/modifier';
  private modifierQteItemCommandeUrl = environment.apiBaseUrl + 'commande/modifierqte';
  private modifierAssignationItemCommandeUrl = environment.apiBaseUrl + 'commande/modifierassignation';
  private approuverItemPreCommandeUrl = environment.apiBaseUrl + 'commande/approuveritemprecommande';
  private remplacerItemCommandeUrl = environment.apiBaseUrl + 'commande/remplacerItemCommande';
  private supprimerItemCommandeUrl = environment.apiBaseUrl + 'commande/';
  private modifierCommandesActivesUrl = environment.apiBaseUrl + 'commande/commandesactives';
  private supprimerItemsUrl = environment.apiBaseUrl + 'commande/supprimerItems';
  private obtenirTransporteursUrl = environment.apiBaseUrl + 'commande/{emplacementId}/transporteurs';
  private envoyerCourrielPreCommandeApprobationUrl = environment.apiBaseUrl + 'commande/envoyerCourrielPreCommandeApprobation';
  private modifierItemPreCommandeUrl = environment.apiBaseUrl + 'commande/modifier-pre-commande/{id_item_commande}';
  private convertirItemPreCommandeADiversUrl = environment.apiBaseUrl + 'commande/convertirItemPrecommandeEnDivers/{id_item_commande}';
  private envoyerPrecommandeEnChantierUrl = environment.apiBaseUrl + 'commande/envoyerenchantier';


  public httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(
    public itemService: ItemService,
    private http: HttpClient,
    public router: Router) { }

  public ObtenirCommande(emplacementId: number): Promise<ReponseBase<ReponseObtenirCommande>> {

    var p = this.http.get<ReponseBase<ReponseObtenirCommande>>(this.obtenirCommandeUrl.replace('{emplacementId}', emplacementId.toString()))
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  public ObtenirCommandeParDepartement(departementId: number): Promise<ReponseBase<ReponseObtenirCommande>> {

    var p = this.http.get<ReponseBase<ReponseObtenirCommande>>(this.obtenirCommandeParDepartementUrl.replace('{departementId}', departementId.toString()))
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  public ObtenirCommandeComplet(): Promise<ReponseBase<ItemCommandeEntite[]>> {

    var p = this.http.get<ReponseBase<ItemCommandeEntite[]>>(this.obtenirCommandeCompletUrl)
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  public ObtenirPrecommande(emplacementId: number, employeId: number): Promise<ReponseBase<ReponseObtenirCommande>> {
    var p = this.http.get<ReponseBase<ReponseObtenirCommande>>(this.obtenirPrecommandeUrl
      .replace('{emplacementId}', emplacementId.toString())
      .replace('{employeId}', employeId.toString()))
    .toPromise();

    p.catch(() => this.router.navigateByUrl('error'));

    return p;
  }

  public ObtenirApprobationsPrecommande(): Promise<ReponseBase<Emplacement[]>> {

    var p = this.http.get<ReponseBase<Emplacement[]>>(this.obtenirApprobationsPrecommandeUrl)
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  public ObtenirApprobationPrecommande(emplacementId: number): Promise<ReponseBase<ReponseObtenirCommande>> {

    var p = this.http.get<ReponseBase<ReponseObtenirCommande>>(this.obtenirApprobationPrecommandeUrl.replace('{emplacementId}', emplacementId.toString()))
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }


  public CreerItemCommande(requete: RequeteCreerItemCommande): Promise<ReponseBase<ReponseCreerItemCommande>> {
    var p = this.http.post<ReponseBase<ReponseCreerItemCommande>>(this.creerItemCommandeUrl, JSON.stringify(requete), this.httpOptions).toPromise();

    p.catch(() => this.router.navigateByUrl('error'));

    return p;
  }

  public ModifierItemCommande(requete: RequeteModifierItemCommande): Promise<ReponseBase<ReponseModifierItemCommande>> {
    var p = this.http.post<ReponseBase<ReponseModifierItemCommande>>(this.modifierItemCommandeUrl, JSON.stringify(requete), this.httpOptions).toPromise();

    p.catch(() => this.router.navigateByUrl('error'));

    return p;
  }

  public EnvoyerCourrielApprobation(requete: RequeteModifierItemCommande): Promise<ReponseBase<ReponseModifierItemCommande>> {
    var p = this.http.post<ReponseBase<ReponseModifierItemCommande>>(this.envoyerCourrielPreCommandeApprobationUrl, JSON.stringify(requete), this.httpOptions).toPromise();

    p.catch(() => this.router.navigateByUrl('error'));

    return p;
  }

  public ModifierqteItemCommande(requete: RequeteModifierItemCommande): Promise<ReponseBase<ReponseModifierItemCommande>> {
    var p = this.http.post<ReponseBase<ReponseModifierItemCommande>>(this.modifierQteItemCommandeUrl, JSON.stringify(requete), this.httpOptions).toPromise();

    p.catch(() => this.router.navigateByUrl('error'));

    return p;
  }

  public ModifierAssignationItemCommande(requete: RequeteModifierItemCommande): Promise<ReponseBase<ReponseModifierItemCommande>> {
    var p = this.http.post<ReponseBase<ReponseModifierItemCommande>>(this.modifierAssignationItemCommandeUrl, JSON.stringify(requete), this.httpOptions).toPromise();

    p.catch(() => this.router.navigateByUrl('error'));

    return p;
  }

  public ApprouverItemPreCommande(requete: RequeteModifierItemCommande): Promise<ReponseBase<ReponseModifierItemCommande>> {
    var p = this.http.post<ReponseBase<ReponseModifierItemCommande>>(this.approuverItemPreCommandeUrl, JSON.stringify(requete), this.httpOptions).toPromise();

    p.catch(() => this.router.navigateByUrl('error'));

    return p;
  }

  public RemplacerItemCommande(requete: RequeteRemplacerItemCommande): Promise<ReponseBase<boolean>> {
    var p = this.http.post<ReponseBase<boolean>>(this.remplacerItemCommandeUrl, JSON.stringify(requete), this.httpOptions).toPromise();

    p.catch(() => this.router.navigateByUrl('error'));

    return p;
  }

  public SupprimerItemCommande(itemCommandeId: number): Promise<ReponseBase<boolean>> {
    var p = this.http.delete<ReponseBase<boolean>>(this.supprimerItemCommandeUrl + itemCommandeId).toPromise();

    p.catch(() => this.router.navigateByUrl('error'));

    return p;
  }

  public ModifierCommandesActives(requete: Emplacement[]): Promise<ReponseBase<boolean>> {
    var p = this.http.post<ReponseBase<boolean>>(this.modifierCommandesActivesUrl, JSON.stringify(requete), this.httpOptions).toPromise();

    p.catch(() => this.router.navigateByUrl('error'));

    return p;
  }

  public SupprimerItemsCommande(ids: number[]): Promise<ReponseBase<boolean>> {
    var p = this.http.post<ReponseBase<boolean>>(this.supprimerItemsUrl, JSON.stringify(ids), this.httpOptions ).toPromise();

    p.catch(() => this.router.navigateByUrl('error'));

    return p;
  }

  public ObtenirTransporteur(emplacementId: number): Promise<ReponseBase<TransporteurEntite[]>> {
    var p = this.http.get<ReponseBase<TransporteurEntite[]>>(this.obtenirTransporteursUrl.replace('{emplacementId}', emplacementId.toString()))
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  public ModifierItemPreCommande(idItemCommande: number, requete: RequeteCreerItemCommande): Promise<ReponseBase<ReponseModifierItemCommande>> {
    var p = this.http.put<ReponseBase<ReponseModifierItemCommande>>(this.modifierItemPreCommandeUrl.replace('{id_item_commande}', idItemCommande.toString()), JSON.stringify(requete), this.httpOptions).toPromise();

    p.catch(() => this.router.navigateByUrl('error'));

    return p;
  }
  
  public ConvertirItemPreCommandeADivers(idItemCommande: number): Promise<ReponseBase<ReponseModifierItemCommande>> {
      var p = this.http.put<ReponseBase<ReponseModifierItemCommande>>(this.convertirItemPreCommandeADiversUrl.replace('{id_item_commande}', idItemCommande.toString()), null, this.httpOptions).toPromise();
  
      p.catch(() => this.router.navigateByUrl('error'));
  
      return p;
  }

  public EnvoyerPrecommandeEnChantier(requete: RequetePrecommandeEnChantier): Observable<HttpResponse<RequisitionMin>> {
    return this.http.post<RequisitionMin>(this.envoyerPrecommandeEnChantierUrl, JSON.stringify(requete), { ...this.httpOptions, observe: 'response' });
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);

      this.log(`${operation} failed: ${error.message}`);

      this.router.navigateByUrl('erreurtechnique');

      return of(result as T);
    };
  }

  private log(message: string) {
    console.log(message);
  }
}

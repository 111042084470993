import { Component, OnInit, ViewChild, Input } from "@angular/core"; import { Location } from "@angular/common";
import { Table } from 'primeng/table';
import { BreadcrumbService } from '../../app.breadcrumb.service';

import { RequisitionMin } from '../../entites/requisition-min';
import { MenuItem, TreeNode } from 'primeng/api';
import { MessageService, LazyLoadEvent, ConfirmationService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';

import { DialogService } from 'primeng/dynamicdialog';
import { EnvoiRequisitionComponent } from '../../controles/envoi-requisition.component';
import { ItemMin } from '../../entites/item-min';
import { ItemService } from '../../services/item-service';
import { Router, ActivatedRoute } from '@angular/router';

import { ToolbarModule } from 'primeng/toolbar';

import { MenubarModule } from 'primeng/menubar';
import { BaseComponent } from '../../base/base-component';
import { Item } from '../../entites/item';
import { Inventaire } from '../../entites/inventaire';
import { NoteEntite } from '../../entites/note';
import { Fournisseur } from 'src/app/entites/fournisseur';
import { FournisseurService } from 'src/app/services/fournisseur-service';
import { DonneesBaseService } from 'src/app/services/donnees-base-service';
import { ValeurAttribut } from 'src/app/entites/valeur-attribut';
import { CategorieMin } from 'src/app/entites/categorie';
import { SousCategorieEntite } from 'src/app/entites/sousCategorie';
import { ProfilService } from 'src/app/services/profil-service';
import { RapportService } from 'src/app/services/rapport-service';
import { DatePipe } from '@angular/common';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { OptionsImpression } from 'src/app/entites/options-impression';
import { EStatuts } from 'src/app/entites/enums/statuts';
import { PrixFournisseurEntite } from 'src/app/entites/prix-fournisseur';
import { ItemBonCommande } from 'src/app/entites/item-bon-commande';
import { AchatService } from 'src/app/services/achat-service';
import { CreationCommandeComponent, ResultatCreationBonCommande } from 'src/app/controles/creation-commande.component';
import { AttributService } from 'src/app/services/attribut-service';
import { ArchivageComponent, RetourArchivage } from 'src/app/controles/archivage.component';
import { GarantieEntite, TypeGarantieEntite } from 'src/app/entites/garantie';
import { ContextMenu } from 'primeng/contextmenu/public_api';
import { GarantieService } from 'src/app/services/garantie-service';
import { ReparationComponent } from 'src/app/controles/reparation.component';
import { SaisieNumeroComponent } from 'src/app/controles/saisie-numero.component';
import { AppMainComponent } from 'src/app/app.main.component';
import { NoteService } from 'src/app/services/note-service';
import { ParametresRapportItem } from 'src/app/services/requetes/rapports/parametres-rapport-item';
import { HistoriqueItem } from 'src/app/entites/historiqueItem';
import { AjustementInventaireComponent, ResultatAjustementInventaire } from 'src/app/controles/ajustement-inventaire.component';
import { ResultatSelectionItemSimple, SelectionItemSimpleComponent } from 'src/app/controles/selection-item-simple.component';
import { LiaisonService } from 'src/app/services/liaison-service';
import { SoumissionEntite } from 'src/app/entites/soumission';
import { SoumissionService } from 'src/app/services/soumission-service';
import { CompagnieEntite } from 'src/app/entites/compagnie';
import { LocalisationEntrepotEntite } from 'src/app/entites/localisation-entrepot';
import { TypeFacturationEntite } from 'src/app/entites/type-facturation';
import { ReleveHeureEntite } from 'src/app/entites/releve-heures';
import { RetourReparationComponent, ParametresRetourReparation } from 'src/app/controles/retour-reparation.component';
import { ReparationService } from 'src/app/services/reparation-service';
import { DocumentService } from 'src/app/services/document-service';
import { RequeteObtenirDocuments } from 'src/app/services/requetes/requete-obtenir-documents';
import { DocumentEntite } from 'src/app/entites/document';
import { ContexteService } from 'src/app/services/contexte-service';
import { environment } from 'src/environments/environment';
import { DetailDocumentComponent } from '../documents/controles/detail-document.component';
import { SelectionDelaiComponent } from 'src/app/controles/selection-delai.component';
import { SelectionEmplacementComponent } from 'src/app/controles/selection-emplacement.component';
import { RequisitionService } from 'src/app/services/requisition-service';
import { Emplacement } from 'src/app/entites/emplacement';
import { TypeRequisition } from 'src/app/entites/typeRequisition';
import { ETypesRequisition } from 'src/app/entites/enums/type-requisition';
import { ReparationEntite } from 'src/app/entites/reparation';
import { DocumentLieEntite } from 'src/app/entites/document-lie';
import { EntretienPreventifEntite } from 'src/app/entites/mecanique/entretien-preventif';
import { EntretienCorrectifEntite } from 'src/app/entites/mecanique/entretien-correctif';
import { HistoriqueEntretienComponent } from 'src/app/controles/mecanique/historique-entretien.component';
import { DetailEntretienCorrectifComponent } from 'src/app/controles/mecanique/detail-entretien-correctif.component';
import { VadEntite } from 'src/app/entites/mecanique/verification-avant-depart';
import { VadService } from 'src/app/services/vad-service';
import { DetailVadComponent } from 'src/app/controles/mecanique/detail-vad.component';
import { AssignerEntretiensComponent } from '../mecanique/bonsTravail/controles/assigner-entretiens.component';
import { ReleveKmEntite } from 'src/app/entites/releve-kms';
import { EntretienCorrectifService } from 'src/app/services/entretien-correctif-service';
import { BonTravailService } from 'src/app/services/bon-travail-service';
import { EntretienPreventifService } from 'src/app/services/entretien-preventif-service';
import { PieceUniteEntite } from 'src/app/entites/piece-unite';
import { PieceService } from 'src/app/services/piece-service';
import { HistoriquePosePieceComponent } from 'src/app/controles/mecanique/historique-pose-piece.component';
import { SelectionPieceComponent } from 'src/app/controles/mecanique/selection-piece.component';
import { ResultatSelectionItem } from 'src/app/controles/selection-item.component';
import { SelectionSimplePieceComponent } from 'src/app/controles/mecanique/selection-simple-piece.component';
import { DetailPieceUniteComponent } from 'src/app/controles/mecanique/detail-piece-unite.component';
import { ConsulterFournisseursComponent } from 'src/app/controles/consulter-fournisseurs.component';
import { CategorieEntretienEntite } from 'src/app/entites/mecanique/categorie-entretien';
import { AjoutFournisseurPieceComponent } from 'src/app/controles/mecanique/ajout-fournisseur-piece.component';
import { SelectionMutipleUniteMecaniqueComponent } from 'src/app/controles/mecanique/selection-multiple-unite-mecanique.component';
import { SelectionCategorieEntretienComponent } from 'src/app/controles/mecanique/selection-categorie-entretiendetail-piece-unite.component';
import { BonTravailEntite } from 'src/app/entites/mecanique/bon-travail';
import { AjustementsComponent } from '../mecanique/heures/controles/ajustements.component';
import { AjouterHeureComponent } from '../mecanique/heures/controles/ajouter-heure.component';
import { AnalyseItemComponent } from 'src/app/controles/analyse-item.component';
import { ModifierFournisseurPieceComponent } from 'src/app/controles/mecanique/modifier-fournisseur-piece.component';
import { AjouterKmComponent } from '../mecanique/heures/controles/ajouter-km.component';
import { HeureService } from 'src/app/services/heure-service';
import { KmService } from 'src/app/services/km-service';
import { AjustementsKmComponent } from '../mecanique/heures/controles/ajustements-km.component';
import { DepartementMecaniqueEntite } from 'src/app/entites/mecanique/departement-mecanique';
import { ConsulterPiecesComponent } from 'src/app/controles/consulter-pieces.component';
import { SelectionEntretienPreventifComponent } from 'src/app/controles/mecanique/selection-entretien-preventif.component';
import { ETypeLienDocument } from 'src/app/entites/enums/type-lien-document';

@Component({
    selector: 'app-detail-item',
    templateUrl: './detail-item.component.html',
    styleUrls: ['./detail-item.component.scss'],
    providers: [MessageService, ConfirmationService, DialogService, DatePipe]
})
export class DetailItemComponent extends BaseComponent implements OnInit {

    afficherDetailBdc: boolean = false;
    detailBdcId: number;

    @Input('itemId') inputItemId: number;

    //GÉNÉRAL

    itemId: number;
    item: Item;

    menu: MenuItem[];

    modeApercu: boolean = false;


    tabIndex: number = 0;

    listeCategories: CategorieMin[];
    listeSousCategoriesComplet: SousCategorieEntite[];
    listeSousCategories: SousCategorieEntite[];
    listeCompagnies: CompagnieEntite[];
    listeLocalisations: LocalisationEntrepotEntite[];
    listeTypesFacturation: TypeFacturationEntite[];
    listeDepartementsMecanique: DepartementMecaniqueEntite[];

    filtresCategorieEntretien: string[];
    filtresStatut: string[];

    // ATTRIBUTS

    chargementCompleteAttributs: boolean = false;
    attributs: ValeurAttribut[];
    attributsEnModification: boolean = false;
    nbAttributsRenseignes: number = 0;
    nbAttributsTotal: number = 0;

    // INVENTAIRE

    chargementCompleteInventaire: boolean = false;
    selectionInventaire: Inventaire;
    contenuMenuContextuelInventaire: MenuItem[];
    @ViewChild('menuContextuelInventaire') menuContextuelInventaire: ContextMenu;

    // HISTORIQUE

    chargementCompleteHistorique: boolean = false;
    selectionHistorique: HistoriqueItem;
    contenuMenuContextuelHistorique: MenuItem[];
    @ViewChild('menuContextuelHistorique') menuContextuelHistorique: ContextMenu;

    // HEURES / KM

    chargementCompleteRelevesHeure: boolean = false;
    selectionReleveHeure: ReleveHeureEntite;
    selectionReleveKm: ReleveKmEntite;
    contenuMenuContextuelReleveHeure: MenuItem[];
    contenuMenuContextuelReleveKm: MenuItem[];
    @ViewChild('menuContextuelReleveHeure') menuContextuelReleveHeure: ContextMenu;
    @ViewChild('menuContextuelReleveKm') menuContextuelReleveKm: ContextMenu;

    // FOURNISSEURS

    
    //dateJour = this.datepipe.transform(new Date(), 'yyyy-MM-dd HH:mm:s');
    dateJour: Date = new Date();
    

    chargementCompletePrixFournisseurs: boolean = false;
    selectionPrixFournisseur: PrixFournisseurEntite;
    contenuMenuContextuelFournisseur: MenuItem[];
    @ViewChild('menuContextuelFournisseur') menuContextuelFournisseur: ContextMenu;

    // ACHATS

    chargementCompleteAchats: boolean = false;
    selectionAchat: ItemBonCommande;
    contenuMenuContextuelAchat: MenuItem[];
    @ViewChild('menuContextuelAchat') menuContextuelAchat: ContextMenu;

    // SOUMISSIONS

    chargementCompleteSoumissions: boolean = false;
    selectionSoumissions: SoumissionEntite;
    contenuMenuContextuelSoumissions: MenuItem[];
    @ViewChild('menuContextuelSoumissions') menuContextuelSoumissions: ContextMenu;

    // ASSOCIATIONS

    chargementCompleteAssociations: boolean = false;
    contenuMenuContextuelAssociation: MenuItem[];
    @ViewChild('menuContextuelAssociation') menuContextuelAssociation: ContextMenu;

    // DOCUMENTS

    chargementCompleteDocuments: boolean = false;
    typesDocuments: TreeNode[];
    selectionTypeDocument: TreeNode;
    documentsComplet: DocumentEntite[];
    documentsFiltres: DocumentEntite[];
    afficherImageDocument: boolean = false;
    imageDocumentChargee: boolean = false;
    imageDocument: string;

    contenuMenuContextuelDocuments: MenuItem[];
    @ViewChild('menuContextuelDocuments') menuContextuelDocuments: ContextMenu;

    // LIAISONS

    chargementCompleteDocumentsLies: boolean = false;
    selectionDocumentLie: DocumentLieEntite;
    contenuMenuContextuelDocumentLieEntite: MenuItem[];
    @ViewChild('menuContextuelDocumentLieEntite') menuContextuelDocumentLieEntite: ContextMenu;


    // LIAISONS

    chargementCompleteLiaisons: boolean = false;
    selectionLiaison: ItemMin;
    contenuMenuContextuelLiaison: MenuItem[];
    @ViewChild('menuContextuelLiaison') menuContextuelLiaison: ContextMenu;

    // GARANTIES

    chargementCompleteGaranties: boolean = false;
    selectionGarantie: GarantieEntite;
    garantie: GarantieEntite;
    dialogueGarantieOuvert: boolean = false;
    dialogueGarantieBloque: boolean = false;
    grantieSubmitted: boolean = false;
    typesGarantie: TypeGarantieEntite[];
    selectionTypeGarantie: TypeGarantieEntite;
    contenuMenuContextuelGarantie: MenuItem[];
    @ViewChild('menuContextuelGarantie') menuContextuelGarantie: ContextMenu;
    @ViewChild('dialogueGarantie') dialogueGarantie;


    // NOTES

    chargementCompleteNotes: boolean = false;
    selectionNote: NoteEntite;
    note: NoteEntite;
    dialogueNoteOuvert: boolean = false;
    dialogueNoteBloque: boolean = false;
    noteSubmitted: boolean = false;
    contenuMenuContextuelNote: MenuItem[];
    @ViewChild('menuContextuelNote') menuContextuelNote: ContextMenu;
    @ViewChild('dialogueNote') dialogueNote;
    @ViewChild('champNote') champNote;


    // ENTRETIENS

    chargementCompleteEntretiens: boolean = false;
    chargementCompleteBonsTravail: boolean = false;
    chargementCompleteEntretiensPreventifs: boolean = false;
    chargementCompleteEntretiensCorrectifs: boolean = false;
    chargementCompleteVad: boolean = false;
    chargementCompletePieces: boolean = false;
    selectionEntretiensPreventifs: EntretienPreventifEntite[];
    selectionEntretiensCorrectifs: EntretienCorrectifEntite[];
    tabIndexEntretiens: number = 0;
    afficherHeures: boolean = true;
    afficherKms: boolean = true;
    afficherMois: boolean = true;
    calculEnCours: boolean = false;
    contenuMenuContextuelEntretiensPreventifs: MenuItem[];
    
    entretiensCorrectifsFiltrees: EntretienCorrectifEntite[];
    selectionCategorieEntretienCorrectifs: CategorieEntretienEntite;

    @ViewChild('menuContextuelEntretiensPreventifs') menuContextuelEntretiensPreventifs: ContextMenu;
    contenuMenuContextuelEntretiensCorrectifs: MenuItem[];
    @ViewChild('menuContextuelEntretiensCorrectifs') menuContextuelEntretiensCorrectifs: ContextMenu;
    contenuMenuContextuelVads: MenuItem[];
    @ViewChild('menuContextuelVads') menuContextuelVads: ContextMenu;
    contenuMenuContextuelPieces: MenuItem[];
    @ViewChild('menuContextuelPieces') menuContextuelPieces: ContextMenu;

    //ENTRETIENSD - PIEECES

    piecesFiltrees: PieceUniteEntite[];
    listeCategoriesEntretien: CategorieEntretienEntite[];
    selectionCategorieEntretien: CategorieEntretienEntite;

    // ENTRETIEN - HISTORIQUE ACHAT  PIECES

    chargementCompleteHistoriqueAchatPieces: boolean = false;
    contenuMenuContextuelHistoriqueAchatPieces: MenuItem[];
    @ViewChild('menuContextuelHistoriqueAchatPieces') menuContextuelHistoriqueAchatPieces: ContextMenu;

    // ENTRETIEN - HISTORIQUE ENVOI PIECES

    chargementCompleteHistoriquePieces: boolean = false;
    contenuMenuContextuelHistoriquePieces: MenuItem[];
    @ViewChild('menuContextuelHistoriquePieces') menuContextuelHistoriquePieces: ContextMenu;



    dialogueImpression = false;
    optionsImpression: OptionsImpression = {
        identification: true,
        attributs: true,
        historique: false,
        inventaire: false,
        liaisons: false,
        achats: false,
        fournisseurs: false,
        garanties: false,
        notes: false
    }

    @ViewChild('tabView') tabView;
    @ViewChild('tabViewEntretiens') tabViewEntretiens;
    @ViewChild('panelNavigationDirecte') panelNavigationDirecte;
    @ViewChild('champNavigationDirecte') champNavigationDirecte;

    constructor(
        sdb: DonneesBaseService,
        profilService: ProfilService,
        route: ActivatedRoute,
        appMain: AppMainComponent, location: Location, 
        private breadcrumbService: BreadcrumbService,
        private ItemsService: ItemService,
        private rapportService: RapportService,
        private messageService: MessageService,
        private fournisseurService: FournisseurService,
        private heureService: HeureService,
        private kmService: KmService,
        private reparationService: ReparationService,
        private documentService: DocumentService,
        private entretienPreventifService: EntretienPreventifService,
        private entretienCorrectifService: EntretienCorrectifService,
        private vadService: VadService,
        private bonTravailService: BonTravailService,
        private pieceService: PieceService,
        private liaisonService: LiaisonService,
        private requisitionService: RequisitionService,
        private soumissionService: SoumissionService,
        private contexteService: ContexteService,
        private achatService: AchatService,
        private garantieService: GarantieService,
        private noteService: NoteService,
        private attributService: AttributService,
        private confirmationService: ConfirmationService,
        public dialogService: DialogService,
        public routerService: Router,
        public datepipe: DatePipe) {

        super(sdb, profilService, route, routerService, appMain);

        this.breadcrumbService.setItems([
            { label: 'items', routerLink: '/items' },
            { label: 'Détails' }
        ]);

        this.route.params.subscribe(params => this.itemId = params.id);
    }

    /* #region  Initialisation */
    ngOnInit() {

        if (this.inputItemId) {
            this.itemId = this.inputItemId;
            this.modeApercu = true;
        }

        this.verifierAccesPage('item_detail');

        this.dateJour.setDate(new Date().getDate() + 1);;

        this.listeCategories = this.sdb.Categories();
        this.listeSousCategoriesComplet = this.sdb.SousCategories();
        this.listeSousCategories = this.sdb.SousCategories();
        this.listeCompagnies = this.sdb.Compagnies();
        this.listeLocalisations = this.sdb.LocalisationsEntrepot();
        this.listeTypesFacturation = this.sdb.TypesFacturation();
        this.listeCategoriesEntretien = this.sdb.CategoriesEntretien();
        this.listeDepartementsMecanique = this.sdb.DepartementsMecanique();

        this.filtresStatut = [];
        this.sdb.Statuts().forEach(x => {
            this.filtresStatut.push(x.nom);
        });

        this.filtresCategorieEntretien = [];
        this.sdb.CategoriesEntretien().forEach(x => {
            this.filtresCategorieEntretien.push(x.description);
        });

        this.ItemsService.ObtenirItem(this.itemId).then(x => {
            this.item = x.valeur;

            
            this.initialiser();

            this.initialiserMenu();

            this.surSelectionCategorie();

            this.chargerDocuments();

            this.CompleterChargement(`Item - ${this.item.description} (${this.item.numero})`);

            if (this.contexteService.ValiderPresence(["messageInitItem"])) {
                this.messageService.add({ severity: 'success', summary: 'Succès', detail: this.contexteService.Obtenir("messageInitItem") });
                this.contexteService.Supprimer("messageInitItem");
            }

            if (this.contexteService.ValiderPresence(["tab"])) {
                this.tabIndex = Number(this.contexteService.Obtenir("tab"));
                this.contexteService.Supprimer("tab");

                setTimeout(() => {
                    this.gererChangementOnglet(this.tabIndex);
                }, 250);
            } else {

                if (this.profilService.Profil().roles.filter(x => x.nom == 'SIHC_ContremaitreMecanique').length > 0
                && this.profilService.Profil().roles.filter(x => x.nom == 'SIHC_Approvisionnement').length == 0) {
                    this.tabIndex = 7;
                    setTimeout(() => {
                        this.gererChangementOnglet(this.tabIndex);
                    }, 250);
                }

            }

            if (this.contexteService.ValiderPresence(["tabentretien"])) {
                this.tabIndexEntretiens = Number(this.contexteService.Obtenir("tabentretien"));
                this.contexteService.Supprimer("tabentretien");

                setTimeout(() => {
                    this.gererChangementOngletEntretiens(this.tabIndexEntretiens);
                }, 750);
            } 
        });



        this.ItemsService.ObtenirAttributsItem(this.itemId).then(x => {
            this.attributs = x.valeur;
            this.nbAttributsRenseignes = this.attributs.filter(x => x.valeur).length;
            this.nbAttributsTotal = this.attributs.length;


            this.chargementCompleteAttributs = true;
        });





    }

    rafraichir() {
        this.contexteService.Ajouter("tab", this.tabIndex.toString());
        this.contexteService.Ajouter("tabentretien", this.tabIndexEntretiens.toString());
        this.naviguer(`item/${this.item.itemId}`)
    }

    /* GÉNÉRAL */

    initialiser() {
        if (this.item.departementMecanique) {
            this.item.departementMecanique = this.listeDepartementsMecanique.find(x => x.departementMecaniqueId == this.item.departementMecanique.departementMecaniqueId);
        }
    }

    initialiserMenu() {
        this.menu = [
            {
                label: 'Retour', icon: 'pi pi-fw pi-angle-double-left', command: () => this.retour()
            },
            {
                label: 'Imprimer', icon: 'pi pi-fw pi-print', command: () => this.afficherImpression()
            },
            {
                label: 'Actions', icon: 'pi pi-fw pi-bars',
                items: [
                    {
                        label: 'Marquer comme complet', icon: 'pi pi-fw pi-times',
                        visible: this.estAutorise('item_action_marquer_complet') && this.item.statut.statutId == EStatuts.Incomplet,
                        command: () => this.marquerComplet()
                    },


                    /* {
                        label: 'Dupliquer', icon: 'pi pi-fw pi-clone', command: () => this.test()
                    }, */
                    {
                        label: 'Déplacer',
                        icon: 'pi pi-fw pi-reply',
                        visible: this.estAutorise('item_action_deplacer') && this.item.statut.statutId != EStatuts.Archive && this.item.categorie.estUnique,
                        disabled: this.item.estLie,
                        command: () => this.deplacer()
                    },
                    {
                        label: 'Envoyer en réparation',
                        icon: 'pi pi-fw pi-minus-circle',
                        visible: this.estAutorise('reparation_creer') && this.item.statut.statutId == EStatuts.Actif && this.item.categorie.estUnique,
                        command: () => this.reparation(this.item)
                    },
                    {
                        label: 'Retour de réparation',
                        icon: 'pi pi-fw pi-minus-circle',
                        visible: this.estAutorise('reparation_retour') && this.item.statut.statutId == EStatuts.EnReparation,
                        command: () => this.retourReparation(this.item)
                    },
                    { separator: true },
                    {
                        label: 'Changer de numéro',
                        icon: 'pi pi-fw pi-tag',
                        visible: this.estAutorise('item_action_modifier_numero'),
                        command: () => this.changerNumero()
                    },
                    {
                        label: 'Fusionner',
                        icon: 'pi pi-fw pi-share-alt',
                        visible: this.estAutorise('item_action_modifier_numero') && (this.item.categorie.categorieId == 3 || this.item.categorie.categorieId == 6),
                        command: () => this.fusionner()
                    },

                    { separator: true },
                    {
                        label: 'Réactiver',
                        icon: 'pi pi-fw pi-undo',
                        visible: this.estAutorise('item_action_archiver') && this.item.statut.statutId == EStatuts.Archive,
                        command: () => this.reactiver()
                    },
                    {
                        label: 'Marquer comme incomplet', icon: 'pi pi-fw pi-minus-circle',
                        visible: this.estAutorise('item_action_marquer_complet') && this.item.statut.statutId == EStatuts.Actif,
                        command: () => this.marquerIncomplet()
                    },
                    {
                        label: 'Archiver',
                        icon: 'pi pi-fw pi-times',
                        visible: this.estAutorise('item_action_archiver') && this.item.statut.statutId != EStatuts.Archive,
                        command: () => this.archiver()
                    },
                    /* {
                        label: 'Changer de numéro', icon: 'pi pi-fw pi-file-pdf', command: () => this.test()
                    }, */
                ]
            },
        ];
    }

    retour() {

        var contextePresent = this.contexteService.ValiderPresence(['origine']);

        if (contextePresent) {
            
            var origine = this.contexteService.Obtenir('origine');
            switch (origine) {
                case 'U':
                    this.routerService.navigateByUrl('mecanique/unites');        
                    break;
                    case 'P':
                        this.routerService.navigateByUrl('mecanique/pieces');        
                        break;
                        case 'I':
                        this.routerService.navigateByUrl('items');        
                        break;
                default:
                    this.routerService.navigateByUrl('items');        
                    break;
            }
        } else {
            this.routerService.navigateByUrl('items');
        }
    }

    surChangementOnglet(event) {
        this.gererChangementOnglet(event.index);
    }

    gererChangementOnglet(index: number) {

        if (this.tabView.tabs[index]._header.startsWith('Inventaire') && !this.chargementCompleteInventaire) {
            this.ItemsService.ObtenirInventaireItem(this.itemId).then(x => {
                this.item.inventaire = x.valeur;

                this.chargementCompleteInventaire = true;
            });
        }
        if (this.tabView.tabs[index]._header.startsWith('Historique') && !this.chargementCompleteHistorique) {
            this.ItemsService.ObtenirHistoriqueItem(this.itemId).then(x => {
                this.item.historique = x.valeur;

                this.chargementCompleteHistorique = true;
            });
        }
        if (this.tabView.tabs[index]._header.startsWith('Heures') && !this.chargementCompleteRelevesHeure) {

            var p1 = new Promise<number>((resolve, reject) => {
                this.heureService.ObtenirListeParItem(this.itemId).then(x => {
                    this.item.relevesHeure = x.valeur;
                    resolve(null);
                });
            });

            var p2 = new Promise<number>((resolve, reject) => {
                this.kmService.ObtenirListeParItem(this.itemId).then(x => {
                    this.item.relevesKm = x.valeur;
                    resolve(null);
                });
            });



            Promise.all([p1, p2]).then(x => {
                this.chargementCompleteRelevesHeure = true;
            });


        }

        if (this.tabView.tabs[index]._header.startsWith('Fournisseurs') && !this.chargementCompletePrixFournisseurs) {
            this.fournisseurService.ObtenirListePrixPourItem(this.itemId).then(res => {
                if (res.estUnSucces) {
                    this.item.fournisseurs = res.valeur;
                    this.chargementCompletePrixFournisseurs = true;
                }
            });
        }

        if (this.tabView.tabs[index]._header.startsWith('Achats') && !this.chargementCompleteAchats) {
            this.achatService.ObtenirHistoriqueItem(this.itemId).then(res => {
                if (res.estUnSucces) {
                    this.item.achats = res.valeur;
                    this.chargementCompleteAchats = true;
                }
            });
        }

        if (this.tabView.tabs[index]._header.startsWith('Soumissions') && !this.chargementCompleteSoumissions) {
            this.soumissionService.ObtenirSoumissionsParItem(this.itemId).then(res => {
                if (res.estUnSucces) {
                    this.item.soumissions = res.valeur;
                    this.chargementCompleteSoumissions = true;
                }
            });
        }

        if (this.tabView.tabs[index]._header.startsWith('Associations') && !this.chargementCompleteAssociations) {
            this.pieceService.ObtenirParPiece(this.itemId).then(res => {
                if (res.estUnSucces) {
                    this.item.associations = res.valeur;
                    this.chargementCompleteAssociations = true;
                }
            });
        }

        if (this.tabView.tabs[index]._header.startsWith('Documents liés') && !this.chargementCompleteDocumentsLies) {
            this.documentService.ObtenirListeItemLies({
                id: this.item.itemId
            }).then(x => {
                this.item.documentslies = x.valeur;

                this.chargementCompleteDocumentsLies = true;
            });
        }

        if (this.tabView.tabs[index]._header.startsWith('Liaisons') && !this.chargementCompleteLiaisons) {
            this.ItemsService.ObtenirLiaisonsItem(this.itemId).then(x => {
                this.item.liaisons = x.valeur;

                this.chargementCompleteLiaisons = true;
            });
        }
        if (this.tabView.tabs[index]._header.startsWith('Garanties') && !this.chargementCompleteGaranties) {

            this.typesGarantie = this.sdb.TypesGarantie();

            this.garantieService.ObtenirListeParItem(this.itemId).then(x => {
                this.item.garanties = x.valeur;

                this.chargementCompleteGaranties = true;
            });
        }
        if (this.tabView.tabs[index]._header.startsWith('Notes') && !this.chargementCompleteNotes) {
            this.ItemsService.ObtenirNotesItem(this.itemId).then(x => {
                this.item.notes = x.valeur;

                this.chargementCompleteNotes = true;
            });
        }

        if (this.tabView.tabs[index]._header.startsWith('Entretien') && !this.chargementCompleteEntretiens) {
            this.chargementCompleteEntretiens = true;
            
            setTimeout(() => {
                this.gererChangementOngletEntretiens(this.tabIndexEntretiens);
            }, 750);

        }
    }

    /* #endregion */

    /* #region  Actions */

    marquerComplet() {

        var attributsManquants = this.attributs.filter(x => x.attribut.indObligatoire && !x.valeur);

        if (attributsManquants && attributsManquants.length > 0) {

            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: 'Certains attributs obligatoires sont manquants. Veuillez compléter les attributs obligatoire avant de marquer l\'item comme étant complet.' });
            return;
        }

        this.ItemsService.ModifierStatut(this.item.itemId, EStatuts.Actif).then(res => {
            if (res) {
                this.item.statut = this.sdb.Statut(EStatuts.Actif);
                this.initialiserMenu();
                this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'L\'item a été complété.' });
            } else {
                res.messages.forEach(x => {
                    this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description, sticky: true });
                });
            }
        });



    }

    marquerIncomplet() {

        this.confirmationService.confirm({
            message: `Voulez-vous vraiment marquer l'item ${this.item.description} (${this.item.numero}) comme étant incomplet ?`,
            accept: () => {
                this.Bloquer();
                this.ItemsService.ModifierStatut(this.item.itemId, EStatuts.Incomplet).then(res => {
                    if (res) {
                        this.Debloquer();
                        this.item.statut = this.sdb.Statut(EStatuts.Incomplet);
                        this.initialiserMenu();
                        this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'L\'item a été marqué comme étant incomplet.' });
                    } else {
                        res.messages.forEach(x => {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description, sticky: true });
                        });
                    }
                });
            }
        });
    }

    ouvrirItemLie() {
        this.routerService.navigateByUrl('/', { skipLocationChange: true }).then(() =>
            this.routerService.navigate(['item/' + this.item.parentId]));
    }

    archiver() {
        const ref = this.dialogService.open(ArchivageComponent, {
            data: {
                itemId: this.item.itemId
            },
            header: `Archivage de : ${this.item.description} (${this.item.numero})`,
            width: '60%'
        }).onClose.subscribe((res: RetourArchivage) => {
            if (res) {
                this.item.statut = this.sdb.Statut(EStatuts.Archive);
                this.item.raisonArchive = this.sdb.RaisonArchive(res.raisonArchiveId);
                this.item.commentaireArchive = res.commentaire;
                this.initialiserMenu();
                this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'L\'item a été archivé.' });
            }
        });

    }

    reactiver() {
        this.confirmationService.confirm({
            message: `Voulez-vous vraiment réactiver l'item ${this.item.description} (${this.item.numero}) ?`,
            accept: () => {
                this.DemarrerChargement();
                this.ItemsService.ModifierStatut(this.item.itemId, EStatuts.Actif).then(x => {
                    this.item.statut = this.sdb.Statut(x.valeur);
                    this.initialiserMenu();
                    this.CompleterChargement();
                    this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Item réactivé.' });
                });
            }
        });
    }

    changerNumero() {
        const ref = this.dialogService.open(SaisieNumeroComponent, {
            header: 'Changement de numéro',
            width: '60%'
        }).onClose.subscribe((res: string) => {
            if (res) {

                this.Bloquer();

                this.ItemsService.ModifierNumero({
                    itemId: this.item.itemId,
                    numero: res
                }).then(resModifNumero => {

                    this.Debloquer();

                    if (resModifNumero.estUnSucces) {

                        this.item.numero = resModifNumero.valeur.numero;

                        this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Le numéro de l\'item a été changé.' });

                    } else {
                        resModifNumero.messages.forEach(x => {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                        });
                    }
                })
            }
        });
    }

    fusionner() {
        const ref = this.dialogService.open(SelectionItemSimpleComponent, {
            data: {
                choixCategorieDisponible: false,
                categorieIds: [3,6]
            },
            header: 'Sélectionner l\'item cible',
            width: '60%'
        }).onClose.subscribe((res: ResultatSelectionItemSimple) => {
            if (res) {

                this.confirmationService.confirm({
                    target: event.target,
                    message: `Voulez-vous vraiment fusionner l'item '${this.item.numero}' vers l'item '${res.item.numero}' ? Cette action est irreversible.`,
                    header: 'Confirmation',
                    icon: 'pi pi-exclamation-triangle',
                    accept: () => {
                        this.Bloquer();

                        this.ItemsService.Fusionner(this.item.itemId, res.item.itemId).then(resModifNumero => {

                            this.Debloquer();

                            if (resModifNumero.estUnSucces) {

                                this.naviguer('item/' + res.item.itemId);

                            } else {
                                resModifNumero.messages.forEach(x => {
                                    this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                                });
                            }
                        })

                    }
                });


            }
        });
    }

    deplacer() {
        const ref = this.dialogService.open(SelectionEmplacementComponent, {
            header: 'Déplacement',
            width: '60%',
            data: {
                selection: this.item.emplacement.emplacementId != 10000 ? 10000 : null
            }
        }).onClose.subscribe((res: Emplacement) => {
            if (res) {

                if (this.item.emplacement.emplacementId == res.emplacementId) {
                    this.messageService.add({ severity: 'error', summary: 'Erreur', detail: 'L\'item est déjà à l\'emplacement sélectionné.' });
                    return;
                }

                this.Bloquer();

                var type: ETypesRequisition = ETypesRequisition.Transfert;
                if (res.emplacementId == 10000) {
                    type = ETypesRequisition.Retour;
                } else if (this.item.emplacement.emplacementId == 10000) {
                    type = ETypesRequisition.Requisition;
                }


                this.requisitionService.CreerRequisition({
                    date: new Date(),
                    de: this.item.emplacement,
                    a: res,
                    demandeur: this.profilService.ProfilCourant(),
                    type: { typeRequisitionId: type },
                    statut: this.sdb.Statut(EStatuts.Actif),
                    itemsRequisition: [{
                        itemRequisitionId: 0,
                        item: this.ItemsService.ItemVersItemMin(this.item),
                        statut: this.sdb.Statut(EStatuts.Actif),
                        employe: this.profilService.ProfilCourant(),
                        demandeur: this.profilService.ProfilCourant(),
                        quantite: 1
                    }]
                }).then(x => {
                    if (x.estUnSucces) {

                        this.item.emplacement = res;

                        this.initialiserMenu();

                        this.Debloquer();
                        this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Déplacement effectué avec succès.' });

                    } else {
                        x.messages.forEach(x => {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description, sticky: true });
                        });

                        this.Debloquer();
                    }
                });


            }
        });
    }

    ouvrirFicheComplete() {
        this.routerService.navigateByUrl('item/' + this.itemId);
    }

    SupprimerRequisition(req: ItemMin) {
        this.confirmationService.confirm({
            message: `Voulez-vous vraiment archiver l'item #${req.numero}`,
            accept: () => {
                this.messageService.add({ severity: 'info', summary: 'Suppression complétée', detail: `Item #${req.numero} supprimé` });
            }
        });
    }

    reparation(item: Item) {

        const ref = this.dialogService.open(ReparationComponent, {
            data: {
                itemId: this.item.itemId
            },
            header: 'Envoi en réparation',
            width: '60%'
        }).onClose.subscribe((res: ReparationEntite) => {
            if (res) {
                this.initialiserMenu();
                this.item.statut = this.sdb.Statut(EStatuts.EnReparation);
                this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Item envoyé en réparation.' });
            }
        });
    }



    retourReparation(item: Item) {

        this.Bloquer();

        this.reparationService.ObtenirListeParItem(this.item.itemId).then(res => {

            this.Debloquer();

            if (res) {

                const reparationActive = res.valeur.find(x => x.statut.statutId != EStatuts.Complete);

                if (reparationActive) {

                    const ref = this.dialogService.open(RetourReparationComponent, {
                        data: {
                            reparations: [
                                {
                                    reparationId: reparationActive.reparationId,
                                    item: this.item
                                }
                            ]
                        },
                        header: 'Retour de réparation',
                        width: '60%'
                    }).onClose.subscribe((res: boolean) => {
                        if (res) {
                            this.item.statut = this.sdb.Statut(EStatuts.Actif);

                            this.chargementCompleteGaranties = false;

                            this.garantieService.ObtenirListeParItem(this.itemId).then(x => {
                                this.item.garanties = x.valeur;

                                this.chargementCompleteGaranties = true;
                            });

                            this.messageService.add({ severity: 'success', summary: 'Succès', detail: `Retour complété` });
                        }


                    });

                }
                else {
                    this.messageService.add({ severity: 'error', summary: 'Erreur', detail: 'Impossible d\'effectuer le retour de réparation pour l\'instant.' });
                }

            } else {
                this.messageService.add({ severity: 'error', summary: 'Erreur', detail: 'Impossible d\'effectuer le retour de réparation pour l\'instant.' });
            }

        });

    }

    ajustementInventaire(inventaire: Inventaire) {

        const ref = this.dialogService.open(AjustementInventaireComponent, {
            data: {
                itemId: this.item.itemId,
                emplacementId: inventaire.emplacement.emplacementId,
                numero: this.item.numero,
                description: this.item.description,
                emplacement: inventaire.emplacement,
                quantiteCourante: inventaire.quantite // TODO : donner la responsabilité au composant d'ajustement d'aller chercher l'info sur l'inventaire courant
            },
            header: 'Ajustement d\'inventaire',
            width: '50%'
        }).onClose.subscribe((res: ResultatAjustementInventaire) => {
            if (res) {
                inventaire.quantite = res.nouvelleQuantite;
                this.messageService.add({ severity: 'success', summary: 'Succès', detail: `L'inventaire a été ajusté.` });
            }
        });
    }


    commander(item: Item, fournisseur: Fournisseur, numeroFournisseur: string, prix: number) {
        const ref = this.dialogService.open(CreationCommandeComponent, {
            data: {
                itemId: item.itemId,
                numeroItem: item.numero,
                descriptionItem: item.description,
                fournisseurId: fournisseur.fournisseurId,
                nomFournisseur: fournisseur.nom,
                numeroFournisseur: fournisseur.numero,
                numeroItemFournisseur: numeroFournisseur,
                prix: prix
            },
            header: 'Création d\'un bon de commande',
            width: '60%'
        }).onClose.subscribe((res: ResultatCreationBonCommande) => {
            if (res) {
                if (res.redirection) {
                    this.routerService.navigateByUrl('achat/' + res.bonCommandeId);
                } else {
                    this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'L\'item a été ajouté au bon de commande.' });
                }
            }


        });
    }

    /* #endregion */

    /* #region  Modification */

    btnModifier_click() {
        this.Modifier();
    }

    annulerModification() {
        this.DemarrerChargement();

        this.ItemsService.ObtenirItem(this.itemId).then(x => {
            this.item.description = x.valeur.description;
            this.item.categorie = x.valeur.categorie;
            this.item.sousCategorie = x.valeur.sousCategorie;
            this.item.marque = x.valeur.marque;
            this.item.modele = x.valeur.modele;
            this.CompleterChargement();
        });

        this.Consulter();
    }

    submitted = false;

    enregistrerModifications() {
        this.submitted = true;
        this.messageService.clear();

        if (this.validerModificationItem()) {
            this.DemarrerChargement();
            this.ItemsService.ModifierItem(this.item).then(x => {
                this.CompleterChargement();
                if (x.estUnSucces) {
                    this.Consulter();
                    this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Item modifié.' });
                } else {
                    x.messages.forEach(x => {
                        this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description, sticky: true });
                    });

                }

            });


        }
    }

    validerModificationItem(): boolean {

        if (!this.item.categorie) {
            return false;
        }

        if (this.item.categorie.estUnique &&
            (!this.item.numero || !this.item.description)) {
            return false;
        }

        return true;
    }

    surSelectionCategorie() {
        this.listeSousCategories = this.listeSousCategoriesComplet.filter(x => x.categorieId == this.item.categorie.categorieId);
    }

    /* #endregion */

    /* #region  Rapports */


    afficherImpression() {
        if (this.item.categorie.estUnique) {
            this.optionsImpression.inventaire = false;
            this.optionsImpression.fournisseurs = false;
            this.optionsImpression.achats = false;
        } else {
            this.optionsImpression.liaisons = false;
            this.optionsImpression.garanties = false;
        }
        this.dialogueImpression = true;

    }

    imprimer() {
        this.messageService.add({ severity: 'info', summary: 'Demande de rapport', detail: `Votre demande de rapport a été envoyée.` });
        this.dialogueImpression = false;

        this.rapportService.ProduireRapportItem({
            itemId: this.item.itemId,
            options: this.optionsImpression
        }).then(x => {
            if (x) {
                this.messageService.add({ severity: 'success', summary: 'Succès', detail: `Votre rapport a été généré.` });
            } else {
                this.messageService.add({ severity: 'error', summary: 'Erreur', detail: `Une erreur est survenue pendant la génération de votre rapport.` });
            }
        });
    }

    selectionnerTout() {
        this.optionsImpression.historique = true;
        this.optionsImpression.notes = true;

        if (this.item.categorie.estUnique) {
            this.optionsImpression.liaisons = true;
            this.optionsImpression.garanties = true;
        } else {
            this.optionsImpression.inventaire = true;
            this.optionsImpression.fournisseurs = true;
            this.optionsImpression.achats = true;
        }
    }

    deselectionnerTout() {
        this.optionsImpression.historique = false;
        this.optionsImpression.inventaire = false;
        this.optionsImpression.liaisons = false;
        this.optionsImpression.achats = false;
        this.optionsImpression.fournisseurs = false;
        this.optionsImpression.garanties = false;
        this.optionsImpression.notes = false;

    }

    annulerImpression() {
        this.dialogueImpression = false;
    }

    /* #endregion */

    /* #region  Attributs */

    modifierAttributs() {

        this.attributsEnModification = true;

    }

    enregistrerAttributs() {

        this.chargementCompleteAttributs = false;

        this.ItemsService.ModifierAttributsItem(this.item.itemId, this.attributs).then(res => {
            if (res.estUnSucces) {
                this.messageService.add({ severity: 'success', summary: 'Succès', detail: `Attribut modifiés.` });
                this.attributsEnModification = false;
                this.chargementCompleteAttributs = true;
            } else {
                res.messages.forEach(x => {
                    this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                });
            }


        });

    }

    annulerAttributs() {
        this.attributsEnModification = false;
        this.chargementCompleteAttributs = false;

        this.ItemsService.ObtenirAttributsItem(this.itemId).then(x => {
            this.attributs = x.valeur;
            this.chargementCompleteAttributs = true;
        });
    }

    /* #endregion */

    /* #region  Documents */


    chargerDocuments() {
        const requete: RequeteObtenirDocuments = {
            id: this.item.itemId
        }
        this.documentService.ObtenirListeItem(requete).then(res => {
            if (res.estUnSucces) {

                res.valeur.documents.forEach(doc => {
                    doc.apercu = 'data:image/jpeg;base64,' + doc.apercu;
                });

                this.typesDocuments = [
                    {
                        "label": this.item.numero,
                        "data": "",
                        "expandedIcon": "pi pi-folder-open",
                        "expanded": true,
                        "collapsedIcon": "pi pi-folder"
                    }];

                this.typesDocuments[0].children = [];

                this.sdb.TypesDocument().forEach(d => {
                    this.typesDocuments[0].children.push({
                        "label": d.nom,
                        "data": d.dossier,
                        "expandedIcon": "pi pi-folder-open",
                        "collapsedIcon": "pi pi-folder"
                    });
                });


                this.documentsComplet = res.valeur.documents;
                this.documentsFiltres = this.documentsComplet;

                this.chargementCompleteDocuments = true;
            }
        });
    }

    onSelectionDocument(event) {
        var node: TreeNode = event.node;

        this.selectionTypeDocument = node;

        if (node.data == "") {
            this.documentsFiltres = this.documentsComplet;
        } else {
            this.documentsFiltres = this.documentsComplet.filter(x => x.typeDocument.dossier == node.data);
        }
    }

    onDeselectionDocument(event) {
    }

    ajouterDocument() {

        this.contexteService.Vider();
        this.contexteService.Ajouter("itemId", this.item.itemId.toString());
        this.contexteService.Ajouter("numero", this.item.numero);
        this.contexteService.Ajouter("dossier", this.selectionTypeDocument?.data);

        this.routerService.navigateByUrl('document/nouveau');
    }

    afficherImage(doc: DocumentEntite) {
        window.open(`${environment.apiDocumentUrl}v/${doc.cheminFichier}/${doc.nomFichier}`, "_blank");
    }

    telechargerDocument(doc: DocumentEntite) {
        window.open(`${environment.apiDocumentUrl}t/${doc.cheminFichier}/${doc.nomFichier}`, "_blank");
    }

    surMenuContextuelDocuments(event, doc: DocumentEntite) {
        //this.selectionDocument = doc;

        this.contenuMenuContextuelDocuments = [
            {
                label: 'Modifier',
                icon: 'pi pi-fw pi-pencil',
                visible: this.estAutorise('item_document_modifier'),
                command: () => this.modifierDocument(doc)
            },
            {
                label: 'Supprimer',
                icon: 'pi pi-fw pi-trash',
                visible: this.estAutorise('item_document_supprimer'),
                command: () => this.supprimerDocument(doc)
            }
        ];

        this.menuContextuelDocuments.toggle(event);
    }

    modifierDocument(doc: DocumentEntite) {
        const ref = this.dialogService.open(DetailDocumentComponent, {
            data: {
                document: doc
            },
            header: 'Modifier un document',
            width: '60%'
        }).onClose.subscribe((res: DocumentEntite) => {
            if (res) {
                doc.date = res.date;
                doc.nom = res.nom;
                doc.description = doc.description;
                this.messageService.add({ severity: 'success', summary: 'Succès', detail: `Document modifié.` });
            }

        });
    }

    supprimerDocument(doc: DocumentEntite) {
        this.confirmationService.confirm({
            target: event.target,
            message: 'Voulez-vous vraiment supprimer le document sélectionné ?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.documentService.SupprimerDocument(doc.documentId).then(res => {
                    if (res.estUnSucces) {
                        this.documentsComplet = this.documentsComplet.filter(val => val.documentId != doc.documentId);
                        this.documentsFiltres = this.documentsFiltres.filter(val => val.documentId != doc.documentId);
                        this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Document supprimé.' });
                    } else {
                        res.messages.forEach(x => {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                        });
                    }
                });

            }
        });
    }

    public get ETypeLienDocument(): typeof ETypeLienDocument {
        return ETypeLienDocument; 
      }

    /* #endregion */

    /* #region  Inventaire */


    surMenuContextuelInventaire(event, inventaire: Inventaire) {
        this.selectionInventaire = inventaire;

        this.contenuMenuContextuelInventaire = [
            {
                label: 'Voir l\'emplacement',
                icon: 'pi pi-fw pi-eye',
                visible: this.estAutorise('emplacement_detail'),
                command: () => this.routerService.navigateByUrl('pilotage/emplacement/' + this.selectionInventaire.emplacement.emplacementId)
            },
            { separator: true, visible: this.estAutorise('item_ajuster_inventaire') },
            {
                label: 'Ajuster l\'inventaire',
                icon: 'pi pi-fw pi-sliders-h',
                visible: this.estAutorise('item_ajuster_inventaire'),
                command: () => this.ajustementInventaire(inventaire)
            },
        ];

        this.menuContextuelInventaire.toggle(event);
    }

    /* #endregion */

    /* #region  Historique */


    surMenuContextuelHistorique(event, historique: HistoriqueItem) {
        this.selectionHistorique = historique;

        this.contenuMenuContextuelHistorique = [
            {
                label: 'Voir la réquisition',
                icon: 'pi pi-fw pi-eye',
                visible: this.estAutorise('requisition_detail'),
                command: () => this.routerService.navigateByUrl('requisition/' + this.selectionHistorique.requisition.requisitionId)
            }
        ];

        this.menuContextuelHistorique.toggle(event);
    }

    /* #endregion */

    /* #region  Heures */


    surMenuContextuelReleveHeure(event, releveHeure: ReleveHeureEntite) {
        this.selectionReleveHeure = releveHeure;

        this.contenuMenuContextuelReleveHeure = [
            {
                label: 'Supprimer',
                icon: 'pi pi-fw pi-trash',
                visible: this.estAutorise('item_heure_modifier'),
                command: _ => this.supprimerReleveHeure(releveHeure)
            }
        ];

        this.menuContextuelReleveHeure.toggle(event);
    }

    supprimerReleveHeure(releveHeure: ReleveHeureEntite) {
        this.confirmationService.confirm({
            target: event.target,
            message: 'Voulez-vous vraiment supprimer les heures sélectionnées ?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.heureService.SupprimerHeures(releveHeure.releveHeureId).then(res => {
                    if (res.estUnSucces) {
                        this.contexteService.Ajouter("messageInitItem", 'Heures supprimées..');
                        this.rafraichir();
                        // this.item.relevesHeure = this.item.relevesHeure.filter(val => val.releveHeureId != releveHeure.releveHeureId);
                        // this.chargementCompleteEntretiensPreventifs = false;
                        // this.tabIndexEntretiens = 0;
                        // this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'HEures supprimées.' });

                        // this.contexteService.Ajouter("tab", "2");
                        // this.naviguer(`item/${this.item.itemId}`)
                    } else {
                        res.messages.forEach(x => {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                        });
                    }
                });

            }
        });
    }

    // ajouterReleveHeure() {
    //     const ref = this.dialogService.open(AjoutReleveHeureComponent, {
    //         data: {
    //             item: this.ItemsService.ItemVersItemMin(this.item)
    //         },
    //         header: 'Ajout d\'un relevé d\'heures',
    //         width: '50%'
    //     }).onClose.subscribe((res: ReleveHeureEntite) => {
    //         if (res) {
    //             this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Relevé d\'heures ajouté' });
    //             this.item.relevesHeure.unshift(res);
    //         }
    //     });
    // }

    surMenuContextuelReleveKm(event, releveKm: ReleveKmEntite) {
        this.selectionReleveKm = releveKm;

        this.contenuMenuContextuelReleveKm = [
            {
                label: 'Supprimer',
                icon: 'pi pi-fw pi-trash',
                visible: this.estAutorise('item_heure_modifier'),
                command: _ => this.supprimerReleveKm(releveKm)

            }
        ];

        this.menuContextuelReleveKm.toggle(event);
    }

    supprimerReleveKm(releveKm: ReleveKmEntite) {
        this.confirmationService.confirm({
            target: event.target,
            message: 'Voulez-vous vraiment supprimer les km sélectionnés ?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.kmService.SupprimerKms(releveKm.releveKmId).then(res => {
                    if (res.estUnSucces) {
                        this.contexteService.Ajouter("messageInitItem", 'KM supprimés.');
                        this.rafraichir();
                        // this.item.relevesKm = this.item.relevesKm.filter(val => val.releveKmId != releveKm.releveKmId);
                        // this.chargementCompleteEntretiensPreventifs = false;
                        // this.tabIndexEntretiens = 0;
                        // this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Kms supprimés.' });
                        
                        // this.contexteService.Ajouter("tab", "2");
                        // this.naviguer(`item/${this.item.itemId}`)
                    } else {
                        res.messages.forEach(x => {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                        });
                    }
                });

            }
        });
    }

    // ajouterReleveKm() {
    //     const ref = this.dialogService.open(AjoutReleveKmComponent, {
    //         data: {
    //             item: this.ItemsService.ItemVersItemMin(this.item)
    //         },
    //         header: 'Ajout d\'un relevé de KM',
    //         width: '50%'
    //     }).onClose.subscribe((res: ReleveHeureEntite) => {
    //         if (res) {
    //             this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Relevé de KM ajouté' });
    //             this.item.relevesKm.unshift(res);
    //         }
    //     });
    // }

    ouvrirAjustements() {
        const ref = this.dialogService.open(AjustementsComponent, {
            data: {
                itemId: this.item.itemId
            },
            header: 'Ajustements de compteur',
            width: '50%'
        });
    }

    ouvrirAjustementsKm() {
        const ref = this.dialogService.open(AjustementsKmComponent, {
            data: {
                itemId: this.item.itemId
            },
            header: 'Ajustements de compteur',
            width: '50%'
        });
    }

    ajouterHeuresApprouvee() {
        const ref = this.dialogService.open(AjouterHeureComponent, {
            data: {
                item: this.item
            },
            header: 'Ajout d\'heures (sans approbation)',
            width: '50%'
        }).onClose.subscribe((res: any) => {
            if (res) {
                
                this.contexteService.Ajouter("messageInitItem", 'Heures ajoutées.');
                this.rafraichir();

                

                // this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Heures ajoutées.' });
                // this.chargementCompleteEntretiensPreventifs = false;
                // this.tabIndexEntretiens = 0;
                // this.Bloquer();
                // this.ItemsService.ObtenirItem(this.item.itemId).then(x => {
                //     this.item.heuresCompteur = x.valeur.heuresCompteur;
                //     this.item.heuresReelles = x.valeur.heuresReelles;
                //     this.item.dateHeures = x.valeur.dateHeures;
                //     this.Debloquer();
                // })
            }


        });
    }

    ajouterKmsApprouvee() {
        const ref = this.dialogService.open(AjouterKmComponent, {
            data: {
                item: this.item
            },
            header: 'Ajout de KM (sans approbation)',
            width: '50%'
        }).onClose.subscribe((res: any) => {
            if (res) {
                
                this.contexteService.Ajouter("messageInitItem", 'KM ajoutés.');
                this.rafraichir();
                // this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'KM ajoutés.' });
                // this.chargementCompleteEntretiensPreventifs = false;
                // this.tabIndexEntretiens = 0;
                // this.Bloquer();
                // this.ItemsService.ObtenirItem(this.item.itemId).then(x => {
                //     this.item.kmCompteur = x.valeur.kmCompteur;
                //     this.item.kmReel = x.valeur.kmReel;
                //     this.item.dateKm = x.valeur.dateKm;
                //     this.Debloquer();
                // });
            }


        });
    }

    /* #endregion */

    /* #region  Fournisseurs */


    surMenuContextuelFournisseur(event, prixFournisseur: PrixFournisseurEntite) {
        this.selectionPrixFournisseur = prixFournisseur;

        this.contenuMenuContextuelFournisseur = [
            {
                label: 'Voir le fournisseur',
                icon: 'pi pi-fw pi-eye',
                visible: this.estAutorise('fournisseur_detail'),
                command: () => this.routerService.navigateByUrl('fournisseur/' + this.selectionPrixFournisseur.fournisseur.fournisseurId)
            },
            { separator: true, visible: this.estAutorise('bdc_creer') },
            {
                label: 'Modifier', icon: 'pi pi-fw pi-pencil',
                visible: this.estAutorise('bdc_creer') && !this.modeApercu,
                command: () => this.modifierPrixFournisseur(prixFournisseur)
            },
            { separator: true },
            {
                label: 'Supprimer', icon: 'pi pi-fw pi-times',
                visible: this.estAutorise('bdc_creer') && !this.modeApercu,
                command: () => this.supprimerPrixFournisseur(prixFournisseur)
            },
            { separator: true },
            {
                label: 'Commander',
                icon: 'pi pi-fw pi-shopping-cart',
                visible: this.estAutorise('bdc_creer'),
                command: () => this.commander(this.item, this.selectionPrixFournisseur.fournisseur, this.selectionPrixFournisseur.numeroFournisseur, this.selectionPrixFournisseur.prix),
            }
        ];

        this.menuContextuelFournisseur.toggle(event);
    }

    AjouterFournisseur() {
        const ref = this.dialogService.open(AjoutFournisseurPieceComponent, {
            data: {
                item: this.item
            },
            header: 'Ajout d\'un fournisseur',
            width: '50%'
        }).onClose.subscribe((res: PrixFournisseurEntite) => {
            if (res) {
                this.chargementCompletePrixFournisseurs = false;
                this.fournisseurService.ObtenirListePrixPourItem(this.itemId).then(res => {
                    if (res.estUnSucces) {
                        this.item.fournisseurs = res.valeur;
                        this.chargementCompletePrixFournisseurs = true;
                        this.messageService.add({ key: 'selectionItem', severity: 'success', summary: 'Succès', detail: 'Fournisseur ajouté' });
                        this.item.nbEntreesFournisseurs++;
                    }
                });

            }
        });
    }

    modifierPrixFournisseur(prixFournisseur: PrixFournisseurEntite) {
        const ref = this.dialogService.open(ModifierFournisseurPieceComponent, {
            data: prixFournisseur,
            header: 'Modifier un fournisseur',
            width: '50%'
        }).onClose.subscribe((res: PrixFournisseurEntite) => {
            if (res) {
                this.chargementCompletePrixFournisseurs = false;
                this.fournisseurService.ObtenirListePrixPourItem(this.itemId).then(res => {
                    if (res.estUnSucces) {
                        this.item.fournisseurs = res.valeur;
                        this.chargementCompletePrixFournisseurs = true;
                        this.messageService.add({ key: 'selectionItem', severity: 'success', summary: 'Succès', detail: 'Prix modifié' });
                        this.item.nbEntreesFournisseurs++;
                    }
                });

            }
        });
    }

    supprimerPrixFournisseur(prixFournisseur: PrixFournisseurEntite) {
        this.confirmationService.confirm({
            target: event.target,
            message: 'Voulez-vous vraiment supprimer prix fournisseur ?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.fournisseurService.SupprimerPrixFournisseur(prixFournisseur.prixFournisseurId).then(res => {
                    if (res.estUnSucces) {
                        this.item.fournisseurs = this.item.fournisseurs.filter(x => x.prixFournisseurId != prixFournisseur.prixFournisseurId);
                        this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Prix supprimé.' });
                    } else {
                        res.messages.forEach(x => {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                        });
                    }
                });

            }
        });
    }

    /* #endregion */

    /* #region  Achats */

    OuvrirDetailBdcNouvelOnglet(itemId: number) {
        this.detailBdcId = itemId;
        this.afficherDetailBdc = true;
    }
    
    surMenuContextuelAchat(event, itemBonCommande: ItemBonCommande) {
        this.selectionAchat = itemBonCommande;

        this.contenuMenuContextuelAchat = [
            {
                label: 'Voir le bon de commande',
                icon: 'pi pi-fw pi-eye',
                visible: this.estAutorise('bdc_detail'),
                command: () => this.routerService.navigateByUrl('achat/' + this.selectionAchat.bonCommande.bonCommandeId)
            },
            {
                label: 'Voir le fournisseur',
                icon: 'pi pi-fw pi-eye',
                visible: this.estAutorise('fournisseur_detail'),
                command: () => this.routerService.navigateByUrl('fournisseur/' + this.selectionAchat.bonCommande.fournisseur.fournisseurId)
            },
            { separator: true },
            {
                label: 'Commander',
                icon: 'pi pi-fw pi-shopping-cart',
                visible: this.estAutorise('bdc_creer'),
                command: () => this.commander(this.item, this.selectionAchat.bonCommande.fournisseur, this.selectionAchat.noFournisseur, this.selectionAchat.prix)
            }
        ];

        this.menuContextuelAchat.toggle(event);
    }

    analyserItem() {
        const ref = this.dialogService.open(AnalyseItemComponent, {
            data: {
                "itemId": this.item.itemId
            },
            header: 'Analyse du prix',
            width: '50%'
        });
    }

    /* #endregion */

    /* #region  Soumissions */


    surMenuContextuelSoumission(event, soumission: SoumissionEntite) {
        this.selectionSoumissions = soumission;

        this.contenuMenuContextuelSoumissions = [
            {
                label: 'Voir la soumission',
                icon: 'pi pi-fw pi-eye',
                visible: this.estAutorise('soumission_detail'),
                command: () => this.routerService.navigateByUrl('soumission/' + this.selectionSoumissions.soumissionId)
            },
            {
                label: 'Voir le fournisseur',
                icon: 'pi pi-fw pi-eye',
                visible: this.estAutorise('fournisseur_detail'),
                command: () => this.routerService.navigateByUrl('fournisseur/' + this.selectionSoumissions.fournisseur.fournisseurId)
            }
        ];

        this.menuContextuelSoumissions.toggle(event);
    }

    /* #endregion */

    /* #region  Associations */

    ajouterAssociation() {


        const ref = this.dialogService.open(SelectionCategorieEntretienComponent, {
            data: {
                obligatoire: false
            },
            header: 'Associations',
            width: '50%'
        }).onClose.subscribe((resSelectionCategorie: CategorieEntretienEntite) => {
            if (resSelectionCategorie) {

                const ref2 = this.dialogService.open(SelectionMutipleUniteMecaniqueComponent, {
                    data: {

                    },
                    header: 'Associations',
                    width: '70%'
                }).onClose.subscribe((res: ItemMin[]) => {
                    if (res) {

                        var piecesUnites: PieceUniteEntite[] = [];

                        res.forEach(item => {
                            piecesUnites.push({
                                unite: item,
                                piece: this.ItemsService.ItemVersItemMin(this.item),
                                categorieEntretien: resSelectionCategorie.categorieEntretienId != 0 ? resSelectionCategorie : null
                            });
                        });

                        this.Bloquer();

                        this.pieceService.Creer(piecesUnites).then(resCreerPU => {

                            this.Debloquer();
                            if (resCreerPU.estUnSucces) {
                                resCreerPU.valeur.forEach(pu => {
                                    if (pu.pieceUniteId) {
                                        this.item.associations.push(pu);
                                    }
                                });
                                this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Associations ajoutées' });
                            } else {
                                resCreerPU.messages.forEach(x => {
                                    this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                                });
                            }
                        });
                    }
                });

            }
        });


    }

    surMenuContextuelAssociation(event, pieceUnite: PieceUniteEntite) {
        this.contenuMenuContextuelAssociation = [
            {
                label: 'Voir l\'unité',
                icon: 'pi pi-fw pi-eye',
                visible: this.estAutorise('item_detail'),
                command: () => this.routerService.navigateByUrl('/', { skipLocationChange: true }).then(() => this.routerService.navigate(['item/' + pieceUnite.unite.itemId]))
            },
            {
                label: 'Voir l\'historique de pose',
                icon: 'pi pi-fw pi-eye',
                visible: this.estAutorise('temp_mec')
            },
            { separator: true },
            {
                label: 'Supprimer',
                icon: 'pi pi-fw pi-trash',
                visible: this.estAutorise('temp_mec') || this.estAutorise('piece_associer'),
                command: () => this.supprimerAssociation(pieceUnite)
            },
        ];

        this.menuContextuelAssociation.toggle(event);
    }

    supprimerAssociation(piece: PieceUniteEntite) {

        this.confirmationService.confirm({
            target: event.target,
            message: 'Voulez-vous vraiment supprimer la pièce sélectionnée ?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.Bloquer();
                this.pieceService.Supprimer(piece.pieceUniteId).then(res => {
                    this.Debloquer();
                    if (res.estUnSucces) {
                        this.item.associations = this.item.associations.filter(val => val.pieceUniteId != piece.pieceUniteId);
                        this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'association supprimée.' });
                    } else {
                        res.messages.forEach(x => {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                        });
                    }
                });
            }
        });
    }

    /* #endregion */

    /* #region  Documents Liés */


    surMenuContextuelDocumentLie(event, liaison: ItemMin) {
        this.selectionLiaison = liaison;

        this.contenuMenuContextuelLiaison = [
            {
                label: 'Voir l\'item',
                icon: 'pi pi-fw pi-eye',
                visible: this.estAutorise('item_detail'),
                command: () => this.routerService.navigateByUrl('/', { skipLocationChange: true }).then(() => this.routerService.navigate(['item/' + this.selectionLiaison.itemId]))
            },
            { separator: true },
            {
                label: 'Supprimer la liaison',
                icon: 'pi pi-fw pi-times',
                visible: this.estAutorise('item_liaison_supprimer'),
                command: () => this.supprimerLiaison(liaison)
            }
        ];

        this.menuContextuelLiaison.toggle(event);
    }

    supprimerDocumentLie(liaison: ItemMin) {
        this.confirmationService.confirm({
            message: `Voulez-vous vraiment supprimer la liaison sélectionnée ?`,
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.Bloquer()
                this.liaisonService.Supprimer({
                    itemParent: {
                        itemId: this.item.itemId
                    },
                    itemEnfant: {
                        itemId: liaison.itemId
                    }
                }).then(res => {
                    this.Debloquer();
                    if (res.estUnSucces) {

                        this.chargementCompleteLiaisons = false;

                        this.ItemsService.ObtenirLiaisonsItem(this.itemId).then(x => {
                            this.item.liaisons = x.valeur;

                            this.chargementCompleteLiaisons = true;
                        });

                        this.item.nbEntreesLiaisons--;
                        this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Liaison supprimée' });

                    } else {
                        res.messages.forEach(x => {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                        });
                    }
                });
            }
        });
    }

    /* #endregion */

    /* #region  Liaisons */

    ajouterLiaison() {

        const ref = this.dialogService.open(SelectionItemSimpleComponent, {
            data: {
                choixCategorieDisponible: false,
                categorieIds: [1, 2, 4]
            },
            header: 'Sélection d\'un item',
            width: '95%'
        }).onClose.subscribe((res: ResultatSelectionItemSimple) => {

            if (res) {

                this.Bloquer();

                this.liaisonService.Ajouter({
                    itemParent: {
                        itemId: this.item.itemId
                    },
                    itemEnfant: {
                        itemId: res.item.itemId
                    }
                }).then(resAjoutLiaison => {
                    this.Debloquer();

                    if (resAjoutLiaison.estUnSucces) {

                        this.chargementCompleteLiaisons = false;

                        this.ItemsService.ObtenirLiaisonsItem(this.itemId).then(x => {
                            this.item.liaisons = x.valeur;

                            this.chargementCompleteLiaisons = true;
                        });

                        this.item.nbEntreesLiaisons++;

                        this.messageService.add({ severity: 'success', summary: 'Succès', detail: `La liaison a été ajoutée.` });

                    } else {
                        resAjoutLiaison.messages.forEach(x => {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                        });
                    }
                });
            }
        });


    }

    surMenuContextuelLiaison(event, liaison: ItemMin) {
        this.selectionLiaison = liaison;

        this.contenuMenuContextuelLiaison = [
            {
                label: 'Voir l\'item',
                icon: 'pi pi-fw pi-eye',
                visible: this.estAutorise('item_detail'),
                command: () => this.routerService.navigateByUrl('/', { skipLocationChange: true }).then(() => this.routerService.navigate(['item/' + this.selectionLiaison.itemId]))
            },
            { separator: true },
            {
                label: 'Supprimer la liaison',
                icon: 'pi pi-fw pi-times',
                visible: this.estAutorise('item_liaison_supprimer'),
                command: () => this.supprimerLiaison(liaison)
            }
        ];

        this.menuContextuelLiaison.toggle(event);
    }

    supprimerLiaison(liaison: ItemMin) {
        this.confirmationService.confirm({
            message: `Voulez-vous vraiment supprimer la liaison sélectionnée ?`,
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.Bloquer()
                this.liaisonService.Supprimer({
                    itemParent: {
                        itemId: this.item.itemId
                    },
                    itemEnfant: {
                        itemId: liaison.itemId
                    }
                }).then(res => {
                    this.Debloquer();
                    if (res.estUnSucces) {

                        this.chargementCompleteLiaisons = false;

                        this.ItemsService.ObtenirLiaisonsItem(this.itemId).then(x => {
                            this.item.liaisons = x.valeur;

                            this.chargementCompleteLiaisons = true;
                        });

                        this.item.nbEntreesLiaisons--;
                        this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Liaison supprimée' });

                    } else {
                        res.messages.forEach(x => {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                        });
                    }
                });
            }
        });
    }

    /* #endregion */

    /* #region  Garanties */


    surMenuContextuelGarantie(event, garantie: GarantieEntite) {
        this.garantie = garantie;

        this.contenuMenuContextuelGarantie = [
            {
                label: 'Modifier',
                icon: 'pi pi-fw pi-pencil',
                visible: this.estAutorise('item_garantie_modifier'),
                command: () => this.modifierGarantie(this.garantie)
            },
            { separator: true, visible: this.estAutorise('item_garantie_supprimer') },
            {
                label: 'Supprimer',
                icon: 'pi pi-fw pi-trash',
                visible: this.estAutorise('item_garantie_supprimer'),
                command: () => this.supprimerGarantie(this.garantie)
            },
        ];

        this.menuContextuelGarantie.toggle(event);
    }

    ajouterGarantie() {
        this.garantie = {
            item: {
                itemId: this.item.itemId
            },
            statut: this.sdb.Statut(EStatuts.Actif)
        };

        this.grantieSubmitted = false;
        this.dialogueGarantieOuvert = true;
    }

    modifierGarantie(garantie: GarantieEntite) {
        this.garantie = { ...garantie };

        this.garantie.dateDebut = new Date(this.garantie.dateDebut);
        this.garantie.dateFin = new Date(this.garantie.dateFin);

        this.dialogueGarantieOuvert = true;
    }

    supprimerGarantie(garantie: GarantieEntite) {
        this.confirmationService.confirm({
            message: `Voulez-vous vraiment supprimer le garantie sélectionnée ?`,
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.garantieService.Supprimer(garantie.garantieId).then(res => {
                    if (res.estUnSucces) {
                        this.item.garanties = this.item.garanties.filter(val => val.garantieId !== garantie.garantieId);
                        this.garantie = {};
                        this.item.nbEntreesGaranties--;
                        this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Garantie supprimé' });
                    } else {
                        res.messages.forEach(x => {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                        });
                    }
                });
            }
        });
    }

    annulerGarantie() {
        this.dialogueGarantieOuvert = false;
        this.grantieSubmitted = false;
    }

    sauvegarderGarantie() {
        this.grantieSubmitted = true;


        if (!this.valider()) {
            this.messageService.add({ severity: 'error', summary: 'Erreurs', detail: 'Veuillez corriger les erreurs de saisie.' });
            return;
        }

        this.dialogueGarantieBloque = true;

        if (this.garantie.garantieId) {

            this.garantieService.Modifier(this.garantie).then(res => {
                this.dialogueGarantieBloque = false;
                if (res.estUnSucces) {
                    this.garantie = res.valeur;
                    this.item.garanties[this.findIndexById(this.garantie.garantieId)] = this.garantie;
                    this.item.garanties = [...this.item.garanties];
                    this.dialogueGarantieOuvert = false;
                    this.garantie = {};
                    this.item.nbEntreesGaranties++;
                    this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Garantie modifié' });
                } else {
                    res.messages.forEach(x => {
                        this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                    });
                }
            });

        }
        else {

            this.garantieService.Ajouter(this.garantie).then(res => {
                this.dialogueGarantieBloque = false;
                if (res.estUnSucces) {
                    this.garantie = res.valeur;
                    this.item.garanties.push(this.garantie);
                    this.item.garanties = [...this.item.garanties];
                    this.dialogueGarantieOuvert = false;
                    this.garantie = {};
                    this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Garantie ajouté' });
                } else {
                    res.messages.forEach(x => {
                        this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                    });
                }
            });
        }

    }

    valider() {
        return this.garantie.type && this.garantie.dateDebut && this.garantie.dateFin
    }

    findIndexById(id: number): number {
        let index = -1;
        for (let i = 0; i < this.item.garanties.length; i++) {
            if (this.item.garanties[i].garantieId === id) {
                index = i;
                break;
            }
        }

        return index;
    }

    selectionnerDelai() {
        const ref = this.dialogService.open(SelectionDelaiComponent, {
            data: {
                dateDebut: this.garantie.dateDebut
            },
            header: 'Sélectionner un délai',
            width: '50%'
        }).onClose.subscribe((res: Date) => {
            if (res) {
                this.garantie.dateFin = new Date(res);
            }

        });
    }

    /* #endregion */

    /* #region  Notes */


    surMenuContextuelNote(event, note: NoteEntite) {
        this.note = note;

        this.contenuMenuContextuelNote = [
            {
                label: 'Modifier',
                icon: 'pi pi-fw pi-pencil',
                visible: this.estAutorise('item_note_modifier'),
                command: () => this.modifierNote(this.note)
            },
            { separator: true },
            {
                label: 'Créer entretien correctif',
                icon: 'pi pi-fw pi-briefcase',
                visible: this.item.indSuiviMecanique && (this.estAutorise('entretien_correctif_creer') || this.estAutorise('temp_mec')),
                command: () => this.creerEntretienCorrectifDepuisNote(this.note)
            },
            { separator: true },
            {
                label: 'Supprimer',
                icon: 'pi pi-fw pi-trash',
                visible: this.estAutorise('item_note_supprimer'),
                command: () => this.supprimerNote(this.note)
            },
        ];

        this.menuContextuelNote.toggle(event);
    }

    ajouterNote() {
        this.note = {
            item: {
                itemId: this.item.itemId
            },
            date: new Date(),
            employe: this.profilService.ProfilCourant()
        };

        this.noteSubmitted = false;
        this.dialogueNoteOuvert = true;

        setTimeout(() => { this.champNote.nativeElement.focus() }, 100);
    }

    modifierNote(note: NoteEntite) {
        this.note = { ...note };
        this.dialogueNoteOuvert = true;

        setTimeout(() => { this.champNote.nativeElement.focus() }, 100);

    }

    supprimerNote(note: NoteEntite) {
        this.confirmationService.confirm({
            message: `Voulez-vous vraiment supprimer le note sélectionnée ?`,
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.noteService.Supprimer(note.noteId).then(res => {
                    if (res.estUnSucces) {
                        this.item.notes = this.item.notes.filter(val => val.noteId !== note.noteId);
                        this.note = {};
                        this.item.nbEntreesNotes--;
                        this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Note supprimée' });
                    } else {
                        res.messages.forEach(x => {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                        });
                    }
                });
            }
        });
    }

    annulerNote() {
        this.dialogueNoteOuvert = false;
        this.noteSubmitted = false;
    }

    sauvegarderNote() {
        this.noteSubmitted = true;


        if (!this.validerNote()) {
            this.messageService.add({ severity: 'error', summary: 'Erreurs', detail: 'Veuillez corriger les erreurs de saisie.' });
            return;
        }

        this.dialogueNoteBloque = true;

        if (this.note.noteId) {

            this.noteService.Modifier(this.note).then(res => {
                this.dialogueNoteBloque = false;
                if (res.estUnSucces) {
                    this.note = res.valeur;
                    this.item.notes[this.findNoteIndexById(this.note.noteId)] = this.note;
                    this.item.notes = [...this.item.notes];
                    this.dialogueNoteOuvert = false;
                    this.note = {};
                    this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Note modifiée' });
                } else {
                    res.messages.forEach(x => {
                        this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                    });
                }
            });

        }
        else {

            this.noteService.Ajouter(this.note).then(res => {
                this.dialogueNoteBloque = false;
                if (res.estUnSucces) {
                    this.note = res.valeur;
                    this.item.notes.push(this.note);
                    this.item.notes = [...this.item.notes];
                    this.dialogueNoteOuvert = false;
                    this.note = {};
                    this.item.nbEntreesNotes++;
                    this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Note ajoutée' });
                } else {
                    res.messages.forEach(x => {
                        this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                    });
                }
            });
        }

    }

    validerNote() {
        return this.note.description
    }

    findNoteIndexById(id: number): number {
        let index = -1;
        for (let i = 0; i < this.item.notes.length; i++) {
            if (this.item.notes[i].noteId === id) {
                index = i;
                break;
            }
        }

        return index;
    }

    /* #endregion */

    /* #region  Bons de travail */


    OuvrirDetailBdt(bonTravailId: number) {
        this.routerService.navigateByUrl(`mecanique/bontravail/${bonTravailId}`);
    }

    OuvrirDetailBdtNouvelOnglet(bonTravailId: number) {
        this.appMain.detailBonTravailId = bonTravailId;
        this.appMain.afficherDetailBonTravail = true;
    }

    AjouterBdt() {
        this.routerService.navigateByUrl("mecanique/bontravail/nouveau/" + this.item.itemId);
    }

    /* #endregion */

    /* #region  Entretiens */


    surChangementOngletEntretiens(event) {
        this.gererChangementOngletEntretiens(event.index);
    }

    gererChangementOngletEntretiens(index: number) {

        if (this.tabViewEntretiens.tabs[index]._header.startsWith('Bons de travail') && !this.chargementCompleteBonsTravail) {
            this.bonTravailService.ObtenirParItem(this.item.itemId).then(x => {
                this.item.bonsTravail = x.valeur;
                this.chargementCompleteBonsTravail = true;
            });
        }
        if (this.tabViewEntretiens.tabs[index]._header.startsWith('Préventifs') && !this.chargementCompleteEntretiensPreventifs) {
            this.entretienPreventifService.ObtenirParItem(this.item.itemId).then(x => {
                this.item.entretiensPreventifs = x.valeur;
                this.afficherHeures = this.item.entretiensPreventifs.filter(x => x.indSeuilHeure).length > 0;
                this.afficherKms = this.item.entretiensPreventifs.filter(x => x.indSeuilKm).length > 0;
                this.afficherMois = this.item.entretiensPreventifs.filter(x => x.indSeuilMois).length > 0;
                this.calculEnCours = this.item.entretiensPreventifs.filter(x => x.indCalculEnCours).length > 0;
                this.chargementCompleteEntretiensPreventifs = true;
            });
        }
        if (this.tabViewEntretiens.tabs[index]._header.startsWith('Correctifs') && !this.chargementCompleteEntretiensCorrectifs) {
            this.entretienCorrectifService.ObtenirParItem(this.item.itemId).then(x => {
                this.item.entretiensCorrectifs = x.valeur;
                
                this.filtrerEntretiensCorrectifs();
                this.chargementCompleteEntretiensCorrectifs = true;
            });
        }
        if (this.tabViewEntretiens.tabs[index]._header.startsWith('Vérification') && !this.chargementCompleteVad) {
            this.vadService.ObtenirVads(this.item.itemId).then(x => {
                this.item.vads = x.valeur;
                this.chargementCompleteVad = true;
            });
        }

        if (this.tabViewEntretiens.tabs[index]._header.startsWith('Pièces') && !this.chargementCompletePieces) {
            this.pieceService.ObtenirParUnite(this.item.itemId).then(x => {
                this.item.pieces = x.valeur;
                this.filtrerPieces();
                this.chargementCompletePieces = true;
            });
        }

        if (this.tabViewEntretiens.tabs[index]._header.startsWith('Historique d\'achat') && !this.chargementCompleteHistoriqueAchatPieces) {
            this.achatService.ObtenirHistoriqueAchatPieces(this.item.itemId).then(x => {
                this.item.historiqueAchatPieces = x.valeur;
                this.chargementCompleteHistoriqueAchatPieces = true;
            });
        }

        if (this.tabViewEntretiens.tabs[index]._header.startsWith('Historique d\'envoi') && !this.chargementCompleteHistoriquePieces) {
            this.ItemsService.ObtenirHistoriquePiecesItem(this.item.itemId).then(x => {
                this.item.historiquePieces = x.valeur;
                this.chargementCompleteHistoriquePieces = true;
            });
        }
    }

    RecalculerEntreriensPreventifs() {
        this.confirmationService.confirm({
            target: event.target,
            message: 'Voulez-vous vraiment recalculer tous les entretiens pour l\'item courant ?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.Bloquer();
                this.entretienPreventifService.Recalculer(this.item.itemId).then(res => {
                    this.Debloquer();
                    if (res.estUnSucces) {
                        this.naviguer(`item/${this.item.itemId}`)
                    } else {
                        res.messages.forEach(x => {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                        });
                    }
                });
            }
        });
    }

    AjouterEntretienPreventif() {
        this.contexteService.Ajouter("item", JSON.stringify(this.item));
        this.routerService.navigateByUrl('mecanique/entretienpreventif/0');
    }

    surMenuContextuelEntretienPreventif(event, entretien: EntretienPreventifEntite) {
        this.contenuMenuContextuelEntretiensPreventifs = [
            {
                label: 'Voir l\'historique', icon: 'pi pi-fw pi-calendar',
                visible: this.estAutorise('temp_mec') && !entretien.indCalculEnCours,
                command: _ => this.consulterHistoriqueEntretienPreventif(this.item, entretien)
            },
            {
                label: 'Voir les pièces nécessaires', icon: 'pi pi-fw pi-eye',
                visible: this.estAutorise('temp_mec') && !entretien.indCalculEnCours,
                command: _ => this.consulterPiecesNecessaires(entretien)
            },
            { separator: true },
            {
                label: 'Détail',
                icon: 'pi pi-fw pi-pencil',
                visible: this.estAutorise('temp_mec') || this.estAutorise('bonstravail') && !entretien.indCalculEnCours,
                command: () => this.detailEntretienPreventif(entretien)
            },
            { separator: true },
            {
                label: 'Voir le bon de travail',
                icon: 'pi pi-fw pi-eye',
                visible: this.estAutorise('temp_mec') && entretien.bonTravail != null && entretien.bonTravail != undefined && !entretien.indCalculEnCours,
                command: () => this.routerService.navigateByUrl('mecanique/bontravail/' + entretien.bonTravail.bonTravailId)
            },
            {
                label: 'Créer un bon de travail',
                icon: 'pi pi-fw pi-plus',
                visible: this.estAutorise('temp_mec') && (entretien.bonTravail == null || entretien.bonTravail == undefined) && !entretien.indCalculEnCours,
                command: _ => this.assignerEntretiensPreventifs([entretien])
            },
            { separator: true },
            {
                label: 'Supprimer',
                icon: 'pi pi-fw pi-trash',
                styleClass: 'menuSuppression',
                visible: this.estAutorise('temp_mec'),
                command: _ => this.supprimerEntretienPreventif(entretien)
            },
        ];

        this.menuContextuelEntretiensPreventifs.toggle(event);
    }

    surMenuContextuelEntretiensPreventifs(event) {
        this.contenuMenuContextuelEntretiensPreventifs = [
            {
                label: 'Recalculer les entretiens', icon: 'pi pi-fw pi-refresh',
                visible: this.estAutorise('temp_mec'),
                command: _ => this.RecalculerEntreriensPreventifs()
            },
            {
                label: 'Réactiver un entretien', icon: 'pi pi-fw pi-plus',
                visible: this.estAutorise('temp_mec'),
                command: _ => this.reactiverEntretienPreventif()
            },
            {
                label: 'Imprimer', icon: 'pi pi-fw pi-print',
                visible: this.estAutorise('temp_mec'),
                command: _ => this.ImprimerEntretienPreventif()
            }
        ];

        this.menuContextuelEntretiensPreventifs.toggle(event);
    }

    supprimerEntretienPreventif(entretien: EntretienPreventifEntite) {

        this.confirmationService.confirm({
            target: event.target,
            message: 'Voulez-vous vraiment supprimer l\'entretien préventif sélectionné. ?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.Bloquer();
                this.entretienPreventifService.Supprimer(entretien.entretienPreventifId).then(res => {
                    this.Debloquer();
                    if (res.estUnSucces) {
                        this.item.entretiensPreventifs = this.item.entretiensPreventifs.filter(val => val.entretienPreventifId != entretien.entretienPreventifId);
                        this.filtrerPieces();
                        this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Entretien supprimé.' });
                    } else {
                        res.messages.forEach(x => {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                        });
                    }
                });
            }
        });
    }

    reactiverEntretienPreventif() {

        const ref = this.dialogService.open(SelectionEntretienPreventifComponent, {
            header: 'Réactiver un entretien préventif',
            width: '60%',
            data: this.item.itemId
        }).onClose.subscribe((res: EntretienPreventifEntite) => {
            if (res) {

                this.confirmationService.confirm({
                    target: event.target,
                    message: 'Voulez-vous vraiment réactiver l\'entretien préventif sélectionné. ?',
                    header: 'Confirmation',
                    icon: 'pi pi-exclamation-triangle',
                    accept: () => {
                        this.Bloquer();
                        this.entretienPreventifService.Reactiver(res.entretienPreventifId).then(res => {
                            this.Debloquer();
                            if (res.estUnSucces) {
                                this.naviguer(`item/${this.item.itemId}`)
                            } else {
                                res.messages.forEach(x => {
                                    this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                                });
                            }
                        });
                    }
                });


            }
        });
    }

    ImprimerEntretienPreventif() {
        this.messageService.add({ severity: 'info', summary: 'Demande de rapport', detail: `Votre demande de rapport a été envoyée.` });
        this.dialogueImpression = false;

        this.rapportService.ProduireRapportEntretienPreventif(this.item.itemId).then(x => {
            if (x) {
                this.messageService.add({ severity: 'success', summary: 'Succès', detail: `Votre rapport a été généré.` });
            } else {
                this.messageService.add({ severity: 'error', summary: 'Erreur', detail: `Une erreur est survenue pendant la génération de votre rapport.` });
            }
        });
    }

    detailEntretienPreventif(entretien: EntretienPreventifEntite) {
        this.routerService.navigateByUrl('mecanique/entretienpreventif/' + entretien.entretienPreventifId);
    }

    consulterHistoriqueEntretienPreventif(item: Item, entretien: EntretienPreventifEntite) {
        const ref = this.dialogService.open(HistoriqueEntretienComponent, {
            data: {
                itemId: item.itemId,
                entretienPreventifId: entretien.entretienPreventifId
            },
            header: `Historique d'entretien - ${item.numero} - ${item.description} - ${entretien.description}`,
            width: '75%'
        }).onClose.subscribe(() => {


        });
    }

    assignerEntretiensPreventifs(entretiens: EntretienPreventifEntite[]) {

        const ref = this.dialogService.open(AssignerEntretiensComponent, {
            data: {
                itemId: entretiens[0].item.itemId,
                entretiensPreventifs: entretiens,
                employeId: this.profilService.ProfilCourant().employeId,
                contreMaitreId: entretiens[0].item.departementMecanique?.contreMaitre?.employeId
            },
            header: `Assigner des entretiens`,
            width: '75%'
        }).onClose.subscribe((bt: BonTravailEntite) => {

            if (bt) {
                this.selectionEntretiensPreventifs = null;

                //TODO
                entretiens.forEach(ent => {
                    ent.statut = this.sdb.Statut(EStatuts.EnCours);
                    ent.bonTravail = bt
                });
    
                this.chargementCompleteBonsTravail = false;
    
                this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Bon de travail créé.' });
            }
            

        });

    }

    consulterPiecesNecessaires(entretien: EntretienPreventifEntite) {
        const ref = this.dialogService.open(ConsulterPiecesComponent, {
            header: 'Pièces nécessaires',
            width: '90%',
            data: entretien
        })
    }



    surMenuContextuelEntretienCorrectif(event, entretien: EntretienCorrectifEntite) {
        this.contenuMenuContextuelEntretiensCorrectifs = [
            {
                label: 'Modifier',
                icon: 'pi pi-fw pi-pencil',
                visible: this.estAutorise('temp_mec'),
                disabled: entretien.statut.statutId == EStatuts.Complete,
                command: () => this.modifierEntretienCorrectif(entretien)
            },
            { separator: true },
            {
                label: 'Voir le bon de travail',
                icon: 'pi pi-fw pi-eye',
                visible: this.estAutorise('temp_mec') && entretien.bonTravail != null && entretien.bonTravail != undefined,
                command: () => this.routerService.navigateByUrl('mecanique/bontravail/' + entretien.bonTravail.bonTravailId)
            },
            {
                label: 'Créer un bon de travail',
                icon: 'pi pi-fw pi-plus',
                visible: this.estAutorise('temp_mec') && (entretien.bonTravail == null || entretien.bonTravail == undefined),
                command: _ => this.assignerEntretiensCorrectifs([entretien])
            },
            { separator: true },
            {
                label: 'Supprimer',
                icon: 'pi pi-fw pi-trash',
                visible: this.estAutorise('temp_mec'),
                disabled: entretien.statut.statutId == EStatuts.Complete,
                command: () => this.supprimerEntretienCorrectif(entretien)
            },
        ];

        this.menuContextuelEntretiensCorrectifs.toggle(event);
    }

    assignerEntretiensCorrectifs(entretiens: EntretienCorrectifEntite[]) {

        const ref = this.dialogService.open(AssignerEntretiensComponent, {
            data: {
                itemId: entretiens[0].item.itemId,
                entretiensCorrectifs: entretiens,
                employeId: this.profilService.ProfilCourant().employeId,
                contreMaitreId: entretiens[0].item.departementMecanique?.contreMaitre?.employeId
            },
            header: `Assigner des entretiens`,
            width: '75%'
        }).onClose.subscribe((bt: BonTravailEntite) => {

            if (bt) {

                this.contexteService.Ajouter("messageInitItem", 'Bon de travail créé.');
                this.rafraichir();

                // this.selectionEntretiensCorrectifs = null;

                // //TODO
                // entretiens.forEach(ent => {
                //     ent.statut = this.sdb.Statut(EStatuts.EnCours);
                //     ent.bonTravail = bt
                // });

                // this.chargementCompleteBonsTravail = false;

                // this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Bon de travail créé.' });
            }
        });

    }

    modifierEntretienCorrectif(entretien: EntretienCorrectifEntite) {

        const ref = this.dialogService.open(DetailEntretienCorrectifComponent, {
            data: {
                entretienCorrectif: entretien
            },
            header: `Modifier un entretien correctif`,
            width: '75%'
        }).onClose.subscribe(res => {

            if (res) {
                entretien = res;
                this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Entretien correctif modifié.' });
            }

        });

    }

    ajouterEntretienCorrectif() {

        const ref = this.dialogService.open(DetailEntretienCorrectifComponent, {
            data: {
                entretienCorrectif: {
                    entretienCorrectifId: 0,
                    item: {
                        itemId: this.item.itemId
                    },
                    employe: this.profilService.ProfilCourant()
                }
            },
            header: `Ajouter un entretien correctif`,
            width: '75%'
        }).onClose.subscribe(res => {

            if (res) {
                this.item.entretiensCorrectifs.push(res);
                this.filtrerEntretiensCorrectifs();
                this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Entretien correctif créé.' });
            }

        });

    }

    creerEntretienCorrectifDepuisNote(note: NoteEntite) {
        this.Bloquer();
        const ref = this.dialogService.open(DetailEntretienCorrectifComponent, {
            data: {
                entretienCorrectif: {
                    entretienCorrectifId: 0,
                    item: {
                        itemId: this.item.itemId
                    },
                    description: note.description,
                    employe: this.profilService.ProfilCourant()
                }
            },
            header: `Ajouter un entretien correctif`,
            width: '75%'
        }).onClose.subscribe(res => {

            this.Debloquer();
            if (res) {
                if (!this.item.entretiensCorrectifs) {
                    this.item.entretiensCorrectifs = [];
                }
                this.item.entretiensCorrectifs.push(res);
                this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Entretien correctif créé.' });
            }

        });
    }

    supprimerEntretienCorrectif(entretien: EntretienCorrectifEntite) {

        this.confirmationService.confirm({
            target: event.target,
            message: 'Voulez-vous vraiment supprimer l\'entretien sélectionné ?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.Bloquer();
                this.entretienCorrectifService.Supprimer(entretien.entretienCorrectifId).then(res => {
                    this.Debloquer();
                    if (res.estUnSucces) {
                        this.item.entretiensCorrectifs = this.item.entretiensCorrectifs.filter(val => val.entretienCorrectifId != entretien.entretienCorrectifId);
                        this.filtrerEntretiensCorrectifs();
                        this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Entretien supprimé.' });
                    } else {
                        res.messages.forEach(x => {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                        });
                    }
                });

            }
        });

    }

    filtrerEntretiensCorrectifs() {
        this.Bloquer();
        this.entretiensCorrectifsFiltrees = this.item.entretiensCorrectifs;
        if (this.selectionCategorieEntretienCorrectifs) {
            this.entretiensCorrectifsFiltrees = this.item.entretiensCorrectifs.filter(x => x.categorie && x.categorie.categorieEntretienId == this.selectionCategorieEntretienCorrectifs.categorieEntretienId);
        }

        this.Debloquer();
    }

    /* #endregion */

    /* #region  VAD */


    ouvrirDetailVad(vad: VadEntite) {

        const ref = this.dialogService.open(DetailVadComponent, {
            data: {
                vadId: vad.vadId
            },
            header: 'Détail de la vérification avant départ',
            width: '90%'
        });

    }

    surMenuContextuelVad(event, vad: VadEntite) {
        this.contenuMenuContextuelVads = [

        ];

        this.menuContextuelVads.toggle(event);
    }

    /* #endregion */

    /* #region  Pièces */


    surMenuContextuelPiece(event, piece: PieceUniteEntite) {
        this.contenuMenuContextuelPieces = [
            {
                label: 'Voir la pièce',
                icon: 'pi pi-fw pi-eye',
                visible: this.estAutorise('item_detail'),
                command: () => this.naviguer('item/' + piece.piece.itemId)
            },
            {
                label: 'Voir l\'historique de pose (unité)',
                icon: 'pi pi-fw pi-eye',
                visible: this.estAutorise('temp_mec'),
                command: () => this.historiquePosePiece(piece, false)
            },
            {
                label: 'Voir l\'historique de pose (complet)',
                icon: 'pi pi-fw pi-eye',
                visible: this.estAutorise('temp_mec'),
                command: () => this.historiquePosePiece(piece, true)
            },
            {
                label: 'Voir les fournisseurs',
                icon: 'pi pi-fw pi-eye',
                visible: this.estAutorise('item_fournisseurs'),
                command: () => this.consulterFournisseurs(piece)
            },
            { separator: true },
            {
                label: 'Modifier',
                icon: 'pi pi-fw pi-pencil',
                visible: this.estAutorise('temp_mec') || this.estAutorise('piece_associer'),
                command: () => this.modifierPiece(piece)
            },
            { separator: true },
            {
                label: 'Supprimer',
                icon: 'pi pi-fw pi-trash',
                visible: this.estAutorise('temp_mec') || this.estAutorise('piece_associer'),
                command: () => this.supprimerPiece(piece)
            },
        ];

        this.menuContextuelPieces.toggle(event);

    }

    ajouterPiece() {
        const refSelectionPiece = this.dialogService.open(SelectionSimplePieceComponent, {
            data: {
                diversPermis: false
            },
            header: 'Sélection d\'une pièce',
            width: '95%'
        }).onClose.subscribe((resSelectionPiece: ItemMin) => {

            if (resSelectionPiece) {

                

                const refPieceUnite = this.dialogService.open(DetailPieceUniteComponent, {
                    data: {
                        unite: this.ItemsService.ItemVersItemMin(this.item),
                        piece: resSelectionPiece,
                        obligatoire: false
                    },
                    header: 'Détail d\'une pièce',
                    width: '95%'
                }).onClose.subscribe((resDetailPiece: PieceUniteEntite) => {

                    if (resDetailPiece) {

                        this.item.pieces.push(resDetailPiece);
                        this.messageService.add({ severity: 'success', summary: 'Succès', detail: `La pièce '${resDetailPiece.piece.description} (${resDetailPiece.piece.numero})' a été ajouté.` });

                    }

                });

            }

        });
    }

    modifierPiece(piece: PieceUniteEntite) {
        const refPieceUnite = this.dialogService.open(DetailPieceUniteComponent, {
            data: {
                pieceUnite: piece,
                unite: this.ItemsService.ItemVersItemMin(this.item),
                piece: piece.piece,
                obligatoire: false
            },
            header: 'Détail d\'une pièce',
            width: '95%'
        }).onClose.subscribe((resDetailPiece: PieceUniteEntite) => {

            if (resDetailPiece) {
                piece.categorieEntretien = resDetailPiece.categorieEntretien;
                this.messageService.add({ severity: 'success', summary: 'Succès', detail: `La pièce '${resDetailPiece.piece.description} (${resDetailPiece.piece.numero})' a été modifiée.` });

            }

        });
    }

    supprimerPiece(piece: PieceUniteEntite) {

        this.confirmationService.confirm({
            target: event.target,
            message: 'Voulez-vous vraiment supprimer la pièce sélectionnée ?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.Bloquer();
                this.pieceService.Supprimer(piece.pieceUniteId).then(res => {
                    this.Debloquer();
                    if (res.estUnSucces) {
                        this.item.pieces = this.item.pieces.filter(val => val.pieceUniteId != piece.pieceUniteId);
                        this.filtrerPieces();
                        this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Pièce supprimée.' });
                    } else {
                        res.messages.forEach(x => {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                        });
                    }
                });
            }
        });
    }

    historiquePosePiece(piece: PieceUniteEntite, complet: boolean) {
        const ref = this.dialogService.open(HistoriquePosePieceComponent, {
            data: {
                pieceId: piece.piece.itemId,
                uniteId: complet ? null : this.item.itemId
            },
            header: 'Historique de pose',
            width: '90%'
        });
    }

    consulterFournisseurs(piece: PieceUniteEntite) {
        const ref = this.dialogService.open(ConsulterFournisseursComponent, {
            data: {
                itemId: piece.piece.itemId,
                afficherSelection: false,
                afficherCommander: true
            },
            header: 'Consulter les fournisseurs',
            width: '50%'
        }).onClose.subscribe((res: PrixFournisseurEntite) => {
            if (res) {

                const ref2 = this.dialogService.open(CreationCommandeComponent, {
                    data: {
                        itemId: res.item.itemId,
                        numeroItem: res.item.numero,
                        descriptionItem: res.item.description,
                        fournisseurId: res.fournisseur.fournisseurId,
                        nomFournisseur: res.fournisseur.nom,
                        numeroFournisseur: res.fournisseur.numero,
                        numeroItemFournisseur: res.numeroFournisseur,
                        prix: res.prix,
                        //selectionCodeProjet: codeProjetId,
                        //selectionCodeActivite: item.codeActivite?.codeActiviteId

                    },
                    header: 'Création d\'un bon de commande',
                    width: '60%'
                }).onClose.subscribe((res2: ResultatCreationBonCommande) => {
                    if (res2) {
                        this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'L\'item a été ajouté au bon de commande.' });
                        /* if (res2.redirection) {
                            this.routerService.navigateByUrl('achat/' + res2.bonCommandeId);
                        } else {
                            this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'L\'item a été ajouté au bon de commande.' });
                        } */
                    }
                });
            }
        });
    }

    filtrerPieces() {
        this.Bloquer();
        this.piecesFiltrees = this.item.pieces;
        if (this.selectionCategorieEntretien) {
            this.piecesFiltrees = this.item.pieces.filter(x => x.categorieEntretien && x.categorieEntretien.categorieEntretienId == this.selectionCategorieEntretien.categorieEntretienId);
        }

        this.Debloquer();
    }

    surMenuContextuelHistoriqueAchatPieces(event, itemBdc: ItemBonCommande) {
        //this.selectionDocument = doc;

        this.contenuMenuContextuelHistoriqueAchatPieces = [
            {
                label: 'Voir le bon de commande',
                icon: 'pi pi-fw pi-eye',
                visible: this.estAutorise('bdc_detail'),
                command: () => this.routerService.navigateByUrl('achat/' + itemBdc.bonCommande.bonCommandeId)
            }
        ];

        this.menuContextuelHistoriqueAchatPieces.toggle(event);
    }

    surMenuContextuelHistoriquePieces(event, historique: HistoriqueItem) {
        //this.selectionDocument = doc;

        this.contenuMenuContextuelHistoriquePieces = [
            {
                label: 'Voir la réquisition',
                icon: 'pi pi-fw pi-eye',
                visible: this.estAutorise('requisition_detail'),
                command: () => this.routerService.navigateByUrl('requisition/' + historique.requisition.requisitionId)
            }
        ];

        this.menuContextuelHistoriquePieces.toggle(event);
    }

    /* #endregion */
}

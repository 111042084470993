<p-toast></p-toast>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<p-menu #menuContextuelContact popup="popup" [model]="contenuMenuContextuelContact"></p-menu>

<p-card *ngIf="!EstCharge()">
	<div class="fluid formgrid grid">
		<div class="flex flex-column gap-2 col-12 text-center">
			<p-progressSpinner></p-progressSpinner>
		</div>
	</div>
</p-card>

<div *ngIf="EstCharge()">

	<div class="grid">
		<div class="col-12">
			<div class="card">
				<h5>{{fournisseur.nom}} ({{fournisseur.numero}})
					<span class="statut actif">
						Actif
					</span>
					<app-bouton-favori [titrePage]="titrePage"></app-bouton-favori>
				</h5>

				<p-menubar autoDisplay="false" [model]="menu">
					<p-button *ngIf="estAutorise('fournisseur_creer') && modeConsultation()"
						(click)="btnModifier_click()" icon="pi pi-pencil" styleClass="p-button-primary"></p-button>
					<p-button *ngIf="modeAjoutModification()" (onClick)="enregistrerModifications()" label
						icon="pi pi-check" styleClass="p-button-success mr-2">
					</p-button>
					<p-button *ngIf="modeAjoutModification()" (onClick)="annulerModification($event)" icon="pi pi-times"
						styleClass="p-button-secondary"></p-button>
				</p-menubar>

			</div>
		</div>


	</div>


	<div class="grid">
		<div class="col-12">
			<div class="card">
				<p-tabView [scrollable]="true" orientation="left" (onChange)="surChangementOnglet($event)">
					<p-tabPanel header="Informations générales" leftIcon="pi pi-info-circle">
						<div class="fluid formgrid grid">

							<div class="flex flex-column gap-2 col-12 md:col-6 lg:col-3">
								<label for="">Nom</label>
								<input id="" type="text" pInputText [readonly]="true" value="{{fournisseur.nom}}">
							</div>
							<div class="flex flex-column gap-2 col-12 md:col-6 lg:col-3">
								<label for="">Numéro</label>
								<input id="" type="text" pInputText [readonly]="true" value="{{fournisseur.numero}}">
							</div>
							<div class="flex flex-column gap-2 col-12 md:col-6 lg:col-3">
								<label for="">No compte chez le fournisseur</label>
								<input id="" type="text" pInputText [readonly]="modeConsultation()"
									[(ngModel)]="fournisseur.noCompte">
							</div>
						</div>
						<div class="fluid formgrid grid">
							<div class="flex flex-column gap-2 col-12">
								<label for="note">Note</label>
								<textarea [rows]="5" pInputTextarea autoResize="autoResize" [readonly]="modeConsultation()"
									[(ngModel)]="fournisseur.note"></textarea>
							</div>
						</div>

						<!-- <h5>Contact principal</h5>

						<div class="fluid formgrid grid">

							<div class="flex flex-column gap-2 col-12 md:col-6 lg:col-3">
								<label for="prenom">Prénom</label>
								<input id="prenom" type="text" pInputText [readonly]="modeConsultation()"
								[(ngModel)]="fournisseur.contactPrincipal.prenom">
							</div>
							<div class="flex flex-column gap-2 col-12 md:col-6 lg:col-3">
								<label for="prenom">Nom</label>
								<input id="prenom" type="text" pInputText [readonly]="modeConsultation()"
								[(ngModel)]="fournisseur.contactPrincipal.nom">
							</div>
						</div>
						<div class="fluid formgrid grid">
							<div class="flex flex-column gap-2 col-12 md:col-6 lg:col-3">
								<label for="telephone">Téléphone</label>
								<input id="telephone" type="text" pInputText [readonly]="modeConsultation()"
								[(ngModel)]="fournisseur.contactPrincipal.telephone">
							</div>
							<div class="flex flex-column gap-2 col-12 md:col-6 lg:col-3">
								<label for="cellulaire">Cellulaire</label>
								<input id="cellulaire" type="text" pInputText [readonly]="modeConsultation()"
								[(ngModel)]="fournisseur.contactPrincipal.cellulaire">
							</div>
							<div class="flex flex-column gap-2 col-12 md:col-6 lg:col-3">
								<label for="courriel">Courriel</label>
								<input id="courriel" type="text" pInputText [readonly]="modeConsultation()"
								[(ngModel)]="fournisseur.contactPrincipal.courriel">
							</div>
						</div> -->

						<h5>Contacts
							<button *ngIf="estAutorise('fournisseur_ajout_contact')" pButton pRipple icon="pi pi-plus"
								class="p-button-success mr-2 ml-4 boutonGrille" (click)="ajouterContact()"></button>

						</h5>

						<p-table [value]="fournisseur.contacts" autoLayout="true" tableStyleClass="tableNormale">
							<ng-template pTemplate="header">
								<tr>
									<th style="min-width: 4rem;"></th>
									<th>Nom</th>
									<th>Prénom</th>
									<th>Fonction</th>
									<th>Téléphone</th>
									<th>Cellulaire</th>
									<th>Courriel</th>
									<th style="min-width: 5rem;"></th>
								</tr>
							</ng-template>
							<ng-template pTemplate="body" let-contact>
								<tr>
									<td>
										<i *ngIf="contact.indPrincipal" class="pi pi-star"></i>
									</td>
									<td>{{contact.nom}}</td>
									<td>{{contact.prenom}}</td>
									<td>{{contact.fonction}}</td>
									<td>{{contact.telephone}}</td>
									<td>{{contact.cellulaire}}</td>
									<td>{{contact.courriel}}</td>
									<td>
										<button
											*ngIf="this.estAutorise('fournisseur_modifier_contact') || this.estAutorise('fournisseur_supprimer_contact')"
											pButton pRipple icon="pi pi-bars"
											class="p-button-secondary mr-2 boutonGrille"
											(click)="surMenuContextuelContact($event, contact)"></button>
									</td>
								</tr>
							</ng-template>
							<ng-template pTemplate="emptymessage">
								<tr>
									<td colspan="5">Aucun contact.</td>
								</tr>
							</ng-template>
						</p-table>

					</p-tabPanel>
					<p-tabPanel *ngIf="estAutorise('fournisseur_historique')"
						[header]="'Historique d\'achat (' + fournisseur.nbEntreesAchats + ')'" leftIcon="pi pi-clock">
						<p-menu #menuContextuelAchat popup="popup" [model]="contenuMenuContextuelAchat"></p-menu>

						<div *ngIf="!chargementCompleteHistorique" class="fluid formgrid grid">
							<div class="flex flex-column gap-2 col-12 text-center">
								<p-progressSpinner></p-progressSpinner>
							</div>
						</div>

						<p-table *ngIf="chargementCompleteHistorique" #dtHistorique [value]="historique"
							tableStyleClass="tableNormale" dataKey="id" autoLayout="true"
							[globalFilterFields]="['bonCommande.dateachat','bonCommande.numero','item.numero','item.description','prix']">
							<ng-template pTemplate="caption">
								<div class="flex p-flex-column p-flex-md-row p-jc-md-between table-header">
									<div>
										<span class="p-input-icon-left">
											<i class="pi pi-search"></i>
											<input #rechercheHistorique pInputText type="text"
												(input)="dtHistorique.filterGlobal($event.target.value, 'contains')"
												placeholder="Recherche..." />
										</span>
									</div>
									<h5 class="titreTableau m-0">Historique d'achat</h5>
									<div>
										<button pButton pRipple icon="pi pi-file-pdf"
											class="p-button-secondary mr-2 ml-4 mb-2 boutonGrille"
											(click)="exportExcel()"></button>
										<p-multiSelect disabled="true" [style]="{minWidth: '200px'}"
											placeholder="Colonnes..."></p-multiSelect>
										<!-- <p-multiSelect [options]="colonnesDisponibles" [(ngModel)]="selectedColumns"
										optionLabel="entete" (onChange)="surChangementColonnes($event)"
										selectedItemsLabel="{0} colonnes sélectionnés" [style]="{minWidth: '200px'}"
										placeholder="Colonnes..."></p-multiSelect> -->
									</div>
								</div>
							</ng-template>
							<ng-template pTemplate="header">
								<tr>
									<th pSortableColumn="date">
										<div class="flex justify-content-between align-items-center">
											Date
											<p-sortIcon field="date"></p-sortIcon>
											<p-columnFilter type="date" field="date" display="menu" class="ml-auto">
											</p-columnFilter>
										</div>
									</th>
									<th pSortableColumn="item.numero">
										<div class="flex justify-content-between align-items-center">
											No Item
											<p-sortIcon field="item.numero"></p-sortIcon>
											<p-columnFilter type="text" field="item.numero" display="menu"
												class="ml-auto">
											</p-columnFilter>
										</div>
									</th>
									<th pSortableColumn="item.description">
										<div class="flex justify-content-between align-items-center">
											Description
											<p-sortIcon field="item.description"></p-sortIcon>
											<p-columnFilter type="text" field="item.description" display="menu"
												class="ml-auto">
											</p-columnFilter>
										</div>
									</th>
									<th pSortableColumn="item.marque">
										<div class="flex justify-content-between align-items-center">
											Marque
											<p-sortIcon field="item.marque"></p-sortIcon>
											<p-columnFilter type="text" field="marque" display="menu" class="ml-auto">
											</p-columnFilter>
										</div>
									</th>
									<th pSortableColumn="item.modele">
										<div class="flex justify-content-between align-items-center">
											Modèle
											<p-sortIcon field="item.modele"></p-sortIcon>
											<p-columnFilter type="text" field="description" display="menu"
												class="ml-auto">
											</p-columnFilter>
										</div>
									</th>
									<th pSortableColumn="quantite">
										<div class="flex justify-content-between align-items-center">
											Quantité
											<p-sortIcon field="quantite"></p-sortIcon>
											<p-columnFilter type="text" field="quantite" display="menu"
												class="ml-auto"></p-columnFilter>
										</div>
									</th>
									<th pSortableColumn="prix">
										<div class="flex justify-content-between align-items-center">
											Prix
											<p-sortIcon field="prix"></p-sortIcon>
											<p-columnFilter type="text" field="prix" display="menu" class="ml-auto">
											</p-columnFilter>
										</div>
									</th>
									<th pSortableColumn="noBdc">
										<div class="flex justify-content-between align-items-center">
											No BDC
											<p-sortIcon field="noBdc"></p-sortIcon>
											<p-columnFilter type="text" field="noBdc" display="menu" class="ml-auto">
											</p-columnFilter>
										</div>
									</th>
									<th style="min-width: 5rem; width: 5rem;"></th>
								</tr>
							</ng-template>
							<ng-template pTemplate="body" let-achat>
								<tr [pContextMenuRow]="achat">
									<td>
										{{achat.bonCommande.dateAchat | date:'yyyy-MM-dd'}}
									</td>

									<td>
										{{achat.item.numero}}
									</td>
									<td>
										{{achat.item.description}}
									</td>
									<td>
										{{achat.item.marque}}
									</td>
									<td>
										{{achat.item.modele}}
									</td>
									<td>
										{{achat.quantite}}
									</td>
									<td>
										{{achat.prix | currency}}
									</td>
									<td>
										{{achat.bonCommande.numero}}
									</td>
									<td>
										<button pButton pRipple icon="pi pi-bars"
											class="p-button-secondary mr-2 boutonGrille"
											(click)="surMenuContextuelAchat($event, achat)"></button>
									</td>

								</tr>
							</ng-template>
						</p-table>
					</p-tabPanel>
					<p-tabPanel *ngIf="estAutorise('fournisseur_sommaire_achats')" header="Sommaire des achats"
						leftIcon="pi pi-chart-line">

						<div *ngIf="!chargementCompleteSommaire" class="fluid formgrid grid">
							<div class="flex flex-column gap-2 col-12 text-center">
								<p-progressSpinner></p-progressSpinner>
							</div>
						</div>

						<p-table *ngIf="chargementCompleteSommaire" #dtSommaire [value]="sommaire"
							tableStyleClass="tablePetit" dataKey="id" autoLayout="true">
							<ng-template pTemplate="caption">
								<div class="flex p-flex-column p-flex-md-row p-jc-md-between table-header">
								</div>
							</ng-template>
							<ng-template pTemplate="header">
								<tr>
									<th>
										<div class="flex justify-content-between align-items-center">
											Période
										</div>
									</th>
									<th>
										<div class="flex justify-content-between align-items-center">
											Total des achats
										</div>
									</th>

								</tr>
							</ng-template>
							<ng-template pTemplate="body" let-sommaire>
								<tr [pContextMenuRow]="sommaire">
									<td>
										{{sommaire.periode}}
									</td>
									<td>
										{{sommaire.total | currency}}
									</td>
								</tr>
							</ng-template>
						</p-table>
					</p-tabPanel>

					<!-- <p-tabPanel header="Notes (1)" leftIcon="pi pi-pencil">
					<p-contextMenu #cmNotes [model]="menuContexteNote"></p-contextMenu>

					<div class="grid">
						<div class="col-12 text-right">
							<p-button label="Ajouter une note" icon="pi pi-plus" iconPos="left"></p-button>
						</div>
						</div>
                    <p-table [value]="item.notes" [(contextMenuSelection)]="selectionNote" [contextMenu]="cmNotes" dataKey="noteId">
						<ng-template pTemplate="header">
							<tr>
								<th>Date</th>
								<th>Auteur</th>
								<th>Note</th>
							</tr>
						</ng-template>
						<ng-template pTemplate="body" let-note>
							<tr [pContextMenuRow]="note">
								<td>{{note.date}}</td>
								<td>{{note.auteur}}</td>
								<td>{{note.description}}</td>
							</tr>
						</ng-template>
					</p-table>
				</p-tabPanel> -->
				</p-tabView>
			</div>
		</div>


	</div>

</div>



<!-- ##########   DIALOGUE DÉTAIL CONTACT   ########## -->

<p-dialog [(visible)]="dialogueOuvert" [style]="{width: '85%'}" header="Détail du contact" [modal]="true"
	styleClass="fluid formgrid grid">
	<ng-template pTemplate="content">
		<div class="fluid formgrid grid">
			<div class="flex flex-column gap-2 col-12 md:col-6 lg:col-3">
				<label for="prenom">Prénom<span class="requis">*</span></label>
				<input #prenomContact type="text" pInputText id="prenom" [(ngModel)]="contact.prenom" autocomplete="off"
					autofocus />
				<small class="p-error" *ngIf="submitted && !contact.prenom">Le prénom est
					obligatoire.</small>
			</div>
			<div class="flex flex-column gap-2 col-12 md:col-6 lg:col-3">
				<label for="nom">Nom<span class="requis">*</span></label>
				<input type="text" pInputText id="nom" [(ngModel)]="contact.nom" autocomplete="off" />
				<small class="p-error" *ngIf="submitted && !contact.nom">Le nom est
					obligatoire.</small>
			</div>
			<div class="flex flex-column gap-2 col-12 md:col-6 lg:col-3">
				<label for="fonction">Fonction</label>
				<input type="text" pInputText id="fonction" [(ngModel)]="contact.fonction" autocomplete="off" />
			</div>
		</div>
		<div class="fluid formgrid grid">
			<div class="flex flex-column gap-2 col-12 md:col-6 lg:col-3">
				<label for="fonction">Téléphone</label>
				<input #nomAttribut type="text" pInputText id="fonction" [(ngModel)]="contact.telephone"
					autocomplete="off" />
			</div>
			<div class="flex flex-column gap-2 col-12 md:col-6 lg:col-3">
				<label for="fonction">Cellulaire</label>
				<input #nomAttribut type="text" pInputText id="fonction" [(ngModel)]="contact.cellulaire"
					autocomplete="off" />
			</div>
			<div class="flex flex-column gap-2 col-12 md:col-6 lg:col-3">
				<label for="fonction">Courriel</label>
				<input #nomAttribut type="text" pInputText id="fonction" [(ngModel)]="contact.courriel"
					autocomplete="off" />
			</div>
		</div>
		<div class="fluid formgrid grid">
			<div class="flex flex-column gap-2 col-12 md:col-6 lg:col-3">
				<p-checkbox label="Principal" [binary]="true" [(ngModel)]="contact.indPrincipal"
					[disabled]="principalForce" class="mt-3"></p-checkbox>
			</div>
		</div>

	</ng-template>

	<ng-template pTemplate="footer">
		<button pButton pRipple label="Enregistrer" icon="pi pi-save" class="p-button-primary"
			(click)="sauvegarderContact()"></button>
		<button pButton pRipple label="Annuler" icon="pi pi-ban" class="p-button-text"
			(click)="annulerContact()"></button>

	</ng-template>
</p-dialog>
<p-toast></p-toast>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<p-card *ngIf="!EstCharge()">
	<div class="fluid formgrid grid">
		<div class="flex flex-column gap-2 my-2 col-12 text-center">
			<p-progressSpinner></p-progressSpinner>
		</div>
	</div>
</p-card>

<div *ngIf="EstCharge() && !modeAjout()" class="grid">
	<div class="col-12">
		<div class="card">
			<h5>Location - {{location.description}}
				<span [class]="'statut ' + location.statut.nom.replace(' ','').toLowerCase()">
					{{location.statut.nom}}
				</span>
			</h5>
			<p-menubar autoDisplay="false" [model]="menu">
				<p-button *ngIf="estAutorise('location_modifier') && modeConsultation() && estModifiable"
					(click)="surBoutonModifier()" icon="pi pi-pencil" styleClass="p-button-primary"></p-button>
				<p-button *ngIf="modeAjoutModification()" (onClick)="enregistrerModifications()" label
					icon="pi pi-check" styleClass="p-button-success mr-2">
				</p-button>
				<p-button *ngIf="modeAjoutModification()" (onClick)="surBoutonAnnulerModification($event)"
					icon="pi pi-times" styleClass="p-button-secondary"></p-button>
			</p-menubar>
		</div>

	</div>
</div>


<div *ngIf="EstCharge()" class="grid">
	<div class="col-12">
		<div class="card">
			<p-tabView [scrollable]="true" #tabView [(activeIndex)]="tabIndex" orientation="left" (onChange)="surChangementOnglet($event)">
				<p-tabPanel header="Informations générales" leftIcon="pi pi-info-circle">
					<!-- <p-divider align="left">
				<div class="inline-flex align-items-center">
					<b>Informations générales</b>
				</div>
			</p-divider> -->
					<div class="fluid formgrid grid">

						<div class="flex flex-column gap-2 my-2 col-12 lg:col-3">
							<label for="lastname">Type de location<span class="requis">*</span></label>
							<input *ngIf="modeConsultation()" id="firstname" type="text" pInputText readonly
								value="{{location.typeLocation.nom}}">
							<p-dropdown *ngIf="!modeConsultation()" [options]="typesLocation"
								[(ngModel)]="location.typeLocation" optionLabel="nom" [showClear]="true" filter="true"
								filterBy="numero,nom" (onHide)="surFermetureTypeLocation()"
								placeholder="Sélectionner un type de location...">
								<ng-template pTemplate="selectedItem">
									<div *ngIf="location.typeLocation">
										<div>{{location.typeLocation.nom}}</div>
									</div>
								</ng-template>
								<ng-template let-type pTemplate="item">
									<div>
										<div>{{type.nom}}</div>
									</div>
								</ng-template>
							</p-dropdown>
							<small class="p-error" *ngIf="submitted && !location.typeLocation">Le type de location est
								obligatoire.</small>
						</div>
						<div class="flex flex-column gap-2 my-2 col-12 p-lg-6">
							<label for="lastname">Fournisseur<span class="requis">*</span></label>
							<input *ngIf="modeConsultation()" id="firstname" type="text" pInputText readonly
								value="{{location.fournisseur.nom}} ({{location.fournisseur.numero}})">
							<p-dropdown *ngIf="!modeConsultation()" [options]="fournisseurs"
								[(ngModel)]="location.fournisseur" optionLabel="nom" [showClear]="true" filter="true"
								filterBy="numero,nom" placeholder="Sélectionner un fournisseur...">
								<ng-template pTemplate="selectedItem">
									<div *ngIf="location.fournisseur">
										<div>{{location.fournisseur.nom}} ({{location.fournisseur.numero}})</div>
									</div>
								</ng-template>
								<ng-template let-fournisseur pTemplate="item">
									<div>
										<div>{{fournisseur.nom}} ({{fournisseur.numero}})</div>
									</div>
								</ng-template>
							</p-dropdown>
							<small class="p-error" *ngIf="submitted && !location.fournisseur">Le fournisseur est
								obligatoire.</small>
						</div>

					</div>
					<div class="fluid formgrid grid">
						<div class="flex flex-column gap-2 my-2 col-12">
							<label for="description">Description<span class="requis">*</span></label>
							<input [readonly]="modeConsultation()" type="text" pInputText id="description"
								[(ngModel)]="location.description" autocomplete="off" />
							<small class="p-error" *ngIf="submitted && !location.description">La description est
								obligatoire.</small>
						</div>
					</div>
					<div class="fluid formgrid grid">
						<div class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-3">
							<label for="lastname">Date de début de location<span class="requis">*</span></label>
							<input *ngIf="modeConsultation()" id="firstname" type="text" pInputText readonly
								value="{{location.dateDebut | date:'yyyy-MM-dd'}}">
							<p-calendar *ngIf="!modeConsultation()" appendTo="body" showIcon=true
								[(ngModel)]="location.dateDebut" dateFormat="yy-mm-dd" [yearNavigator]="true"
								[monthNavigator]="true" yearRange="2000:2040">
							</p-calendar>
							<small class="p-error" *ngIf="submitted && !location.dateDebut">La date de début est
								obligatoire.</small>
						</div>
						<div class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-3">
							<label for="dateFin">Date prévue de fin de location<span class="requis">*</span></label>
							<input *ngIf="modeConsultation()" id="firstname" type="text" pInputText readonly
								value="{{location.dateFin | date:'yyyy-MM-dd'}}">
							<p-calendar *ngIf="!modeConsultation()" appendTo="body" showIcon=true
								[(ngModel)]="location.dateFin" dateFormat="yy-mm-dd" [yearNavigator]="true"
								[monthNavigator]="true" yearRange="2000:2040">
							</p-calendar>
							<small class="p-error" *ngIf="submitted && !location.dateFin">La date de fin prévue est
								obligatoire.</small>
						</div>
					</div>
					<div class="fluid formgrid grid">
						<div class="flex flex-column gap-2 my-2 col-3">
							<label for="description">Valeur de l'équipement</label>
							<p-inputNumber [readonly]="modeConsultation()" #champPrix
								[(ngModel)]="location.valeurEquipement" mode="currency" currency="USD" locale="en-US">
							</p-inputNumber>
						</div>
						<div class="flex flex-column gap-2 my-2 col-3">
							<label for="description">Heures au début de la location</label>
							<input *ngIf="modeConsultation()" id="firstname" type="text" pInputText readonly
								value="{{location.heuresDebut}}">
							<p-inputNumber *ngIf="!modeConsultation()" [min]="0" [(ngModel)]="location.heuresDebut">
							</p-inputNumber>
						</div>
					</div>

					
					<p-divider align="left">
						<b>Suivi des heures de l'équipement</b>
					</p-divider>

					<div class="fluid formgrid grid">
						<div class="flex flex-column gap-2 my-2 col-4">
							<label for="description">Équipement</label>
							<span>
								<!-- <input id="firstname" type="text" pInputText readonly
								[value]="location.item?.numero + ' - ' + location.item?.description">
								<input> -->
								<span style="font-size: 1.1rem; margin-right: 10px;">{{(location.item?.numero | valeurVide) + ' - ' + (location.item?.description | valeurVide)}}</span>
							<button *ngIf="!modeConsultation()" pButton pRipple icon="pi pi-search" class="p-button-rounded p-button-primary mr-2 mt-3"
                				(click)="selectionnerItem()"></button>
								<button *ngIf="!modeConsultation()" pButton pRipple icon="pi pi-times" class="p-button-rounded p-button-secondary mr-2 mt-3"
									(click)="retirerItem()"></button>
								</span>
						</div>
						<div class="flex flex-column gap-2 my-2 col-3">
							<label for="description">Nb heures prévues par mois</label>
							<input *ngIf="modeConsultation()" id="firstname" type="text" pInputText readonly
								value="{{location.nbHeuresParMois}}">
								<p-inputNumber *ngIf="!modeConsultation()" [min]="0" [(ngModel)]="location.nbHeuresParMois">
								</p-inputNumber>
						</div>
					</div>
					<p-divider align="left">
						<b>Coûts</b>
					</p-divider>

					<div class="fluid formgrid grid" *ngIf="modeConsultation() && location.statut.statutId == 13">
						<div class="flex flex-column gap-2 my-2 col-3">
							<label for="coutTotal" style="font-weight: 800;">Coût total calculé : </label>
							{{location.coutLocation | currency}}

						</div>
					</div>

					<div class="fluid formgrid grid" *ngFor="let cout of location.couts">
						<div class="flex flex-column gap-2 my-2 col-3">
							<label for="descriptionCout">Description<span class="requis">*</span></label>
							<i *ngIf="!modeConsultation()" class="pi pi-copy ml-3" style="font-size: 1rem"
								(click)="copierDescription(cout)"></i>
							<input [readonly]="modeConsultation()" type="text" pInputText id="descriptionCout"
								[(ngModel)]="cout.description" autocomplete="off" />

						</div>
						<div class="flex flex-column gap-2 my-2 col-3">
							<label for="quantiteCout">Quantité</label>
							<input *ngIf="modeConsultation()" id="firstname" type="text" pInputText readonly
								value="{{cout.quantite}}">
							<p-inputNumber *ngIf="!modeConsultation()" [min]="0" [(ngModel)]="cout.quantite">
							</p-inputNumber>
						</div>
						<div class="flex flex-column gap-2 my-2 col-3">
							<label for="lastname">Unité</label>
							<input *ngIf="modeConsultation()" id="firstname" type="text" pInputText readonly
								value="{{cout.unite?.nom}}">
							<p-dropdown *ngIf="!modeConsultation()" [options]="unites" [(ngModel)]="cout.unite"
								optionLabel="nom" appendTo="body" [showClear]="true" filter="true" filterBy="code,nom"
								placeholder="Sélectionner une unité...">
								<ng-template pTemplate="selectedItem">
									<div *ngIf="cout.unite">
										<div>{{cout.unite?.nom}}</div>
									</div>
								</ng-template>
								<ng-template let-unite pTemplate="item">
									<div>
										<div>{{unite.nom}} ({{unite.code}})</div>
									</div>
								</ng-template>
							</p-dropdown>
						</div>
						<div class="flex flex-column gap-2 my-2 col-2">
							<label for="prixCout">Prix</label>
							<input *ngIf="modeConsultation()" id="firstname" type="text" pInputText readonly
								value="{{cout.prix | currency}}">
							<p-inputNumber *ngIf="!modeConsultation()" [(ngModel)]="cout.prix" mode="currency"
								currency="USD" locale="en-US">
							</p-inputNumber>

						</div>
						<div class="flex flex-column gap-2 my-2 col-1" *ngIf="!modeConsultation()">
							<button pButton pRipple icon="pi pi-trash" class="p-button-danger mr-2 boutonGrille"
								(click)="supprimerCout(cout)"></button>

						</div>
					</div>
					<button *ngIf="!modeConsultation()" pButton pRipple icon="pi pi-plus"
						class="p-button-primary mr-2 boutonGrille" (click)="ajouterCout()"></button>



					<p-divider align="left" *ngIf="!modeAjout()">
						<div class="inline-flex align-items-center">
							<b class="mr-3">Bon de commande</b>
							<span *ngIf="location.bonCommande"
								[class]="'statut ' + location.bonCommande.statut.nom.replace(' ','').toLowerCase()">
								{{location.bonCommande.statut.nom}}
							</span>
						</div>
					</p-divider>

					<div class="grid" *ngIf="estAutorise('bdc_creer') && !location.bonCommande && modeConsultation()">
						<div class="flex flex-column gap-2 my-2 col-12" *ngIf="estModifiable">
							<p-button label="Créer le bon de commande" (click)="creerBdc()" icon="pi pi-plus"
								iconPos="left">
							</p-button>
						</div>
					</div>
					<div class="fluid formgrid grid" *ngIf="location.bonCommande">
						<div class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-3">
							<label for="firstname">Numéro</label>
							<input id="firstname" type="text" pInputText readonly
								value="{{location.bonCommande.numero}}">
						</div>
						<div class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-3">
							<label for="dateBdc">Date</label>
							<input id="dateBdc" type="date" pInputText readonly
								value="{{location.bonCommande.dateAchat | date:'yyyy-MM-dd'}}">
						</div>
						<div class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-3"
							*ngIf="estAutorise('bdc_detail') && modeConsultation()">
							<button pButton pRipple icon="pi pi-eye" class="p-button-success mr-2 mt-5 boutonGrille"
								(click)="voirBdc()"></button>
						</div>
					</div>

					<p-divider align="left">
						<div class="inline-flex align-items-center">
							<b>Information supplémentaire</b>
						</div>
					</p-divider>

					<div class="fluid formgrid grid">
						<div *ngIf="!location.item" class="flex flex-column gap-2 my-2 col-12">
							<label for="lastname">Emplacement de la location<span class="requis">*</span></label>
							<input *ngIf="modeConsultation()" id="firstname" type="text" pInputText readonly [disabled]="true"
								value="{{location.emplacement.nom}} ({{location.emplacement.code}})">
							<p-dropdown *ngIf="!modeConsultation()" [options]="emplacements"
								[(ngModel)]="location.emplacement" optionLabel="nom" appendTo="body" [showClear]="true"
								filter="true" filterBy="code,nom" placeholder="Sélectionner un emplacement...">
								<ng-template pTemplate="selectedItem">
									<div *ngIf="location.emplacement">
										<div>{{location.emplacement.nom}} ({{location.emplacement.code}})</div>
									</div>
								</ng-template>
								<ng-template let-emplacement pTemplate="item">
									<div>
										<div>{{emplacement.nom}} ({{emplacement.code}})</div>
									</div>
								</ng-template>
							</p-dropdown>
							<small class="p-error" *ngIf="submitted && !location.emplacement">L'emplacement est
								obligatoire.</small>
						</div>
						<div *ngIf="location.item" class="flex flex-column gap-2 my-2 col-12">
							<label for="lastname">Emplacement de la location<span class="requis">*</span></label>
							<input id="firstname" type="text" pInputText readonly
								value="{{location.item.emplacement?.nom}} ({{location.item.emplacement?.code}})">
						</div>
					</div> 
					<div class="fluid formgrid grid">
						<div class="flex flex-column gap-2 my-2 col-12">
							<label for="description">Notes</label>
							<input [readonly]="modeConsultation()" type="text" pInputText id="description"
								[(ngModel)]="location.note" autocomplete="off" />
						</div>
					</div>
					<div class="col-12 text-right" *ngIf="modeAjout()">
						<p-button label="Créer" (click)="creerLocation()" icon="pi pi-save" iconPos="left"></p-button>
					</div>

				</p-tabPanel>
				<p-tabPanel *ngIf="estAutorise('location_detail') && !modeAjout()" header="Documents" leftIcon="pi pi-file-pdf">
					<ng-template pTemplate="content">
						<app-documents-location [typeLien]="ETypeLienDocument.Location" [idLien]="location.locationId" [numero]="location.numero" [showFolders]="false"></app-documents-location>
					</ng-template>
				</p-tabPanel>
			</p-tabView>
		</div>
	</div>


</div>



<!-- 

<div *ngIf="EstCharge()" class="grid">
	<div class="col-12">
		<div class="card">
			<p-divider align="left">
				<div class="inline-flex align-items-center">
					<b>Général</b>
				</div>
			</p-divider>
			<div class="fluid formgrid grid">
				<div class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-3">
					<label for="lastname">Date de début</label>
					<input *ngIf="modeConsultation()" id="firstname" type="text" pInputText readonly
						value="{{location.dateDebut | date:'yyyy-MM-dd'}}">
					<p-calendar *ngIf="!modeConsultation()" appendTo="body" showIcon=true
						[(ngModel)]="location.dateDebut" dateFormat="yy-mm-dd" [yearNavigator]="true" [monthNavigator]="true" yearRange="2000:2040"></p-calendar>
				</div>
				<div class="flex flex-column gap-2 my-2 col-3">
					<label for="description">Type de location</label>
					<input *ngIf="modeConsultation()" id="firstname" type="text" pInputText readonly
						value="{{location.typeLocation.nom}}">
					<p-dropdown *ngIf="!modeConsultation()" [options]="typesLocation"
						[(ngModel)]="location.typeLocation" optionLabel="nom" [showClear]="true" filter="true"
						filterBy="numero,nom" placeholder="Sélectionner un fournisseur...">
						<ng-template pTemplate="selectedItem">
							<div *ngIf="location.typeLocation">
								<div>{{location.typeLocation.nom}}</div>
							</div>
						</ng-template>
						<ng-template let-type pTemplate="item">
							<div>
								<div>{{type.nom}}</div>
							</div>
						</ng-template>
					</p-dropdown>
					<small class="p-error" *ngIf="submitted && !location.typeLocation">Le type de location est
						obligatoire.</small>
				</div>
				<div class="flex flex-column gap-2 my-2 col-6">
					<label for="description">Fournisseur</label>
					<input *ngIf="modeConsultation()" id="firstname" type="text" pInputText readonly
						value="{{location.fournisseur.nom}}">

					<p-dropdown *ngIf="!modeConsultation()" [options]="fournisseurs" [(ngModel)]="selectionFournisseur"
						optionLabel="nom" [showClear]="true" filter="true" filterBy="numero,nom"
						placeholder="Sélectionner un fournisseur...">
						<ng-template pTemplate="selectedItem">
							<div *ngIf="selectionFournisseur">
								<div>{{selectionFournisseur.nom}} ({{selectionFournisseur.numero}})</div>
							</div>
						</ng-template>
						<ng-template let-fournisseur pTemplate="item">
							<div>
								<div>{{fournisseur.nom}} ({{fournisseur.numero}})</div>
							</div>
						</ng-template>
					</p-dropdown>
					<small class="p-error" *ngIf="submitted && !selectionFournisseur">Le fournisseur est
						obligatoire.</small>
				</div>
			</div>
			<div class="fluid formgrid grid">
				<div class="flex flex-column gap-2 my-2 col-12">
					<label for="description">Description</label>
					<input type="text" pInputText id="description" [(ngModel)]="location.description"
						[readonly]="modeConsultation()" autocomplete="off" />
				</div>
			</div>
			<div class="fluid formgrid grid">
				<div class="flex flex-column gap-2 my-2 col-3">
					<label for="description">Cout de location</label>
					<p-inputNumber #champPrix [readonly]="modeConsultation()" [(ngModel)]="location.coutLocation"
						mode="currency" currency="USD" locale="en-US"></p-inputNumber>
				</div>
				<div class="flex flex-column gap-2 my-2 col-3">
					<label for="description">Valeur de l'équipement</label>
					<p-inputNumber #champPrix [readonly]="modeConsultation()" [(ngModel)]="location.valeurEquipement"
						mode="currency" currency="USD" locale="en-US"></p-inputNumber>
				</div>
				<div class="flex flex-column gap-2 my-2 col-3">
					<label for="description">Heures au début de la location</label>
					<p-inputNumber [readonly]="modeConsultation()" [min]="0" [(ngModel)]="location.heuresDebut">
					</p-inputNumber>
				</div>
			</div>

			<p-divider align="left">
				<div class="inline-flex align-items-center">
					<b class="mr-3">Bon de commande</b>
					<span *ngIf="location.bonCommande"
						[class]="'statut ' + location.bonCommande.statut.nom.replace(' ','').toLowerCase()">
						{{location.bonCommande.statut.nom}}
					</span>
				</div>
			</p-divider>

			<div class="grid" *ngIf="!location.bonCommande">
				<div class="flex flex-column gap-2 my-2 col-12" *ngIf="estModifiable">
					<p-button label="Créer le bon de commande" (click)="creerBdc()" icon="pi pi-plus" iconPos="left">
					</p-button>
				</div>
			</div>
			<div class="fluid formgrid grid" *ngIf="location.bonCommande">
				<div class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-3">
					<label for="firstname">Numéro</label>
					<input id="firstname" type="text" pInputText readonly value="{{location.bonCommande.numero}}">
				</div>
				<div class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-3">
					<label for="dateBdc">Date</label>
					<input id="dateBdc" type="date" pInputText readonly
						value="{{location.bonCommande.dateAchat | date:'yyyy-MM-dd'}}">
				</div>
				<div class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-3">
					<button pButton pRipple icon="pi pi-eye" class="p-button-success mr-2 mt-5 boutonGrille"
						(click)="voirBdc()"></button>
				</div>
			</div>

			<p-divider align="left">
				<div class="inline-flex align-items-center">
					<b>Fin de location</b>
				</div>
			</p-divider>

			<div class="fluid formgrid grid" *ngIf="location.dateFin">
				<div class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-3">
					<label for="lastname">Date de fin</label>
					<input *ngIf="modeConsultation()" id="firstname" type="text" pInputText readonly
						value="{{location.dateFin | date:'yyyy-MM-dd'}}">
					<p-calendar *ngIf="!modeConsultation()" appendTo="body" showIcon=true [(ngModel)]="location.dateFin"
						dateFormat="yy-mm-dd" [yearNavigator]="true" [monthNavigator]="true" yearRange="2000:2040"></p-calendar>
				</div>
				<div class="flex flex-column gap-2 my-2 col-3">
					<label for="description">Heures à la fin de la location</label>
					<p-inputNumber [readonly]="modeConsultation()" [min]="0" [(ngModel)]="location.heuresFin">
					</p-inputNumber>
				</div>

			</div>
			<div class="fluid formgrid grid" *ngIf="location.dateFin">
				<div class="flex flex-column gap-2 my-2 col-12">
					<label for="etat">État de l'équipement au retour</label>
					<input type="text" pInputText id="etat" [(ngModel)]="location.etat" [readonly]="modeConsultation()"
						autocomplete="off" />
				</div>
			</div>

			<p-divider align="left">
				<div class="inline-flex align-items-center">
					<b>Notes</b>
				</div>
			</p-divider>
			<div class="fluid formgrid grid">
				<div class="flex flex-column gap-2 my-2 col-12">
					<label for="description">Notes</label>
					<input type="text" pInputText id="description" [(ngModel)]="location.note"
						[readonly]="modeConsultation()" autocomplete="off" />
				</div>
			</div>
		</div>
	</div>


</div> -->
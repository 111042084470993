<div class="card">
<div class="grid ">
    <div class="fluid col-12">
        

            <h5>Destinataires <span class="text-danger">*</span></h5>
            <p-multiSelect [options]="employes" [(ngModel)]="selection" display="chip" optionLabel="prenomNom" appendTo="body" styleClass="w-full"></p-multiSelect>
           
            <br>

    </div>
</div>
<div class="grid mt-2">
    <div class="col-12 text-right ">
        <button pButton pRipple label="Confirmer" icon="pi pi-check" class="p-button-primary"
            (click)="Envoyer()"></button>
        <button pButton pRipple label="Annuler" icon="pi pi-ban" class="p-button-text"
            (click)="Annuler()"></button>
    </div>
</div>
</div>
<!-- <div class="flex justify-content-center gap-2 mb-3">
    <p-panel styleClass="flex-grow-1">
        <p>Analyse du bon de travail</p>
    </p-panel>
    <p-panel styleClass="flex-grow-1">
        <p>Analyse du bon de travail</p>
    </p-panel>
</div> -->

<p-card *ngIf="!EstCharge()">
    <div class="fluid formgrid grid">
        <div class="flex flex-column gap-2 my-2 col-12 text-center">
            <p-progressSpinner></p-progressSpinner>
        </div>
    </div>
</p-card>

<p-table *ngIf="EstCharge()" [value]="analyses" [tableStyle]="{'min-width': '50rem'}" 
        [columns]="colonnesSelectionnees"
        [paginator]="true" [rows]="100" [rowsPerPageOptions]="[100, 200, 500]" [showCurrentPageReport]="true"
        [globalFilterFields]="['numero', 'nbHeures', 'nbItems', 'coutSoumission', 'coutBonTravail']"
        [rowHover]="true" dataKey="id"
        currentPageReportTemplate="Affichage {first} à {last} sur {totalRecords} entrées"
        [sortField]="'numero'" [sortOrder]="-1">
    <ng-template pTemplate="caption">
        <div class="flex align-items-center justify-content-between">
            <span class="p-input-icon-left">
                <i class="pi pi-search"></i>
                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Rechercher..." />
            </span>
            <h5 class="m-0">Analyse des Bons de Travail</h5>
            <p-multiSelect [options]="colonnesDisponibles" [(ngModel)]="colonnesSelectionnees" optionLabel="entete"
                           selectedItemsLabel="{0} colonnes sélectionnées" [style]="{minWidth: '200px'}"
                           placeholder="Choisir les colonnes"></p-multiSelect>
        </div>
    </ng-template>
    <ng-template pTemplate="header">
        <tr>
            <th pSortableColumn="numero" style="width:20%">Numéro <p-sortIcon field="numero"></p-sortIcon></th>
            <th pSortableColumn="statut" style="width:15%">Statut <p-sortIcon field="statut"></p-sortIcon></th>
            <th pSortableColumn="nbHeures" style="width:15%">Nombre d'Heures <p-sortIcon field="nbHeures"></p-sortIcon></th>
            <th pSortableColumn="nbItems" style="width:15%">Nombre de pièces <p-sortIcon field="nbItems"></p-sortIcon></th>
            <th pSortableColumn="coutSoumission" style="width:20%">Coût Soumission <p-sortIcon field="coutSoumission"></p-sortIcon></th>
            <th pSortableColumn="coutBonTravail" style="width:20%">Coût Bon Travail <p-sortIcon field="coutBonTravail"></p-sortIcon></th>
            <th style="width:5%">&nbsp;</th>
            <th style="width:5%">&nbsp;</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-analyse>
        <tr>
            <td><a [routerLink]="['/mecanique/bontravail/analyse/', analyse.analyseBonTravailId]">{{analyse.numero}}</a></td>
            <td>
                <div *ngIf="analyse.statut.nom">
                    <span [class]="'statut ' + analyse.statut.nom.toLowerCase().replace(' ', '')">
                        {{analyse.statut.nom}}
                    </span>
                </div>
            </td>
            <td>{{analyse.nbHeures}}</td>
            <td>{{analyse.nbItems}}</td>
            <td>{{analyse.coutSoumission | currency:'CAD':'symbol-narrow':'1.2-2'}}</td>
            <td>{{analyse.coutBonTravail | currency:'CAD':'symbol-narrow':'1.2-2'}}</td>
            <td>
                <i *ngIf="analyse.coutBonTravail > analyse.coutSoumission" class="pi pi-arrow-up" style="color: red;"></i>
                <i *ngIf="analyse.coutBonTravail < analyse.coutSoumission" class="pi pi-arrow-down" style="color: green;"></i>
                <i *ngIf="analyse.coutBonTravail === analyse.coutSoumission" class="pi pi-minus" style="color: gray;"></i>
            </td>
            <td>
                <button *ngIf="estAutorise('bontravail_detail') || estAutorise('temp_mec')" pButton pRipple icon="pi pi-eye" class="p-button-success mr-2 boutonGrille"
                                        (click)="OuvrirDetailAnalyse(analyse.analyseBonTravailId)"></button>
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
        <tr>
            <td colspan="6">Aucune analyse de bon de travail disponible.</td>
        </tr>
    </ng-template>
</p-table>


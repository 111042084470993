import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from "@angular/core"; import { Location } from "@angular/common";

import { Observable, of, shareReplay } from 'rxjs';

import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { ReponseBase } from './reponse-base';
import { Emplacement } from '../entites/emplacement';
import { ItemMin } from '../entites/item-min';
import { ItemService } from './item-service';
import { EmployeEntite } from '../entites/employe';
import { CodeProjetEntite } from '../entites/code-projet';
import { ServiceBase } from './service-base';
import { LazyLoadEvent } from 'primeng/api';
import { ReponseFiltre } from './reponses/reponse-filter';

@Injectable({ providedIn: 'root' })
export class EmplacementService extends ServiceBase {

  private modifierEmplacementUrl = environment.apiBaseUrl + 'emplacement/modifier';
  private ajouterEmplacementUrl = environment.apiBaseUrl + 'emplacement';
  private obtenirInventaireEmplacementUrl = environment.apiBaseUrl + 'emplacement/{id}/inventaire';
  private obtenirEmplacementsUrl = environment.apiBaseUrl + 'emplacement';
  private obtenirDetailEmplacementUrl = environment.apiBaseUrl + 'emplacement/{id}';
  private obtenirResponsablesUrl = environment.apiBaseUrl + 'emplacement/{id}/responsables';
  private obtenirChargesProjetUrl = environment.apiBaseUrl + 'emplacement/{id}/chargesprojet';
  private obtenirCodeProjetsUrl = environment.apiBaseUrl + 'emplacement/codeProjets';
  private ajouterChargeProjetUrl = environment.apiBaseUrl + 'emplacement/chargeProjet/{emplacementId}/{employeId}';
  private ajouterResponsableUrl = environment.apiBaseUrl + 'emplacement/responsable/{emplacementId}/{employeId}';
  private supprimerChargeProjetUrl = environment.apiBaseUrl + 'emplacement/{id}/chargeProjet/{employeId}';
  private supprimerResponsableUrl = environment.apiBaseUrl + 'emplacement/{id}/responsable/{employeId}';
  private archiverEmplacementUrl = environment.apiBaseUrl + 'emplacement/archiver';
  private obtenirNombreInventaireEmplacementUrl = environment.apiBaseUrl + 'emplacement/{id}/inventaire/count';

  private readonly CACHE_SIZE = 20;
  private readonly CACHE_TIME_MS = 5 * 60 * 1000;
  private cacheMapInventaire = new Map<string, Observable<ReponseBase<ReponseFiltre<ItemMin>>>>();

  public httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(
    public itemService: ItemService,
    private http: HttpClient,
    public router: Router) {
      super();
    }

    public Obtenir(): Promise<ReponseBase<Emplacement[]>> {

      var p = this.http.get<ReponseBase<Emplacement[]>>(this.obtenirEmplacementsUrl)
        .toPromise();
  
        p.catch(() => this.router.navigateByUrl('error'));
  
        return p;
    }

  public ObtenirDetail(emplacementId: number): Promise<ReponseBase<Emplacement>> {

    var p = this.http.get<ReponseBase<Emplacement>>(this.obtenirDetailEmplacementUrl.replace('{id}', emplacementId.toString()))
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  public ModifierEmplacement(emplacement: Emplacement): Promise<ReponseBase<Emplacement>> {
    var p = this.http.post<ReponseBase<Emplacement>>(this.modifierEmplacementUrl, JSON.stringify(emplacement), this.httpOptions).toPromise();

    p.catch(() => this.router.navigateByUrl('error'));

    return p;
  }

  public AjouterResponsable(emplacementId: number, employeId: number): Promise<ReponseBase<number>> {
    var p = this.http.post<ReponseBase<number>>(
      this.ajouterResponsableUrl.replace('{emplacementId}', emplacementId.toString())
      .replace('{employeId}', employeId.toString()), '', this.httpOptions).toPromise();

    p.catch(() => this.router.navigateByUrl('error'));

    return p;
  }

  public AjouterChargeProjet(emplacementId: number, employeId: number): Promise<ReponseBase<number>> {
    var p = this.http.post<ReponseBase<number>>(
      this.ajouterChargeProjetUrl.replace('{emplacementId}', emplacementId.toString())
      .replace('{employeId}', employeId.toString()), '', this.httpOptions).toPromise();

    p.catch(() => this.router.navigateByUrl('error'));

    return p;
  }

  public ObtenirInventaire(emplacementId: number, filters: LazyLoadEvent): Observable<ReponseBase<ReponseFiltre<ItemMin>>> {
    const cacheKey = `${emplacementId}-${JSON.stringify(filters)}`;
    
    if (!this.cacheMapInventaire.has(cacheKey)) {
      const params = this.GenerateFilterParams(filters);
      
      const request$ = this.http.get<ReponseBase<ReponseFiltre<ItemMin>>>(
        this.obtenirInventaireEmplacementUrl.replace('{id}', emplacementId.toString()),
        { params }
      ).pipe(
        shareReplay({
          bufferSize: this.CACHE_SIZE,
          refCount: false,
          windowTime: this.CACHE_TIME_MS
        })
      );
      
      this.cacheMapInventaire.set(cacheKey, request$);
    }

    return this.cacheMapInventaire.get(cacheKey)!;
  }

  public ObtenirResponsables(emplacementId: number): Promise<ReponseBase<EmployeEntite[]>> {

    var p = this.http.get<ReponseBase<EmployeEntite[]>>(this.obtenirResponsablesUrl.replace('{id}', emplacementId.toString()))
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  public ObtenirChargesProjet(emplacementId: number): Promise<ReponseBase<EmployeEntite[]>> {

    var p = this.http.get<ReponseBase<EmployeEntite[]>>(this.obtenirChargesProjetUrl.replace('{id}', emplacementId.toString()))
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  public ObtenirCodeProjets(): Promise<ReponseBase<CodeProjetEntite[]>> {

    var p = this.http.get<ReponseBase<CodeProjetEntite[]>>(this.obtenirCodeProjetsUrl)
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  public AjouterEmplacement(emplacement: Emplacement): Promise<ReponseBase<Emplacement>> {
    var p = this.http.post<ReponseBase<Emplacement>>(this.ajouterEmplacementUrl, JSON.stringify(emplacement), this.httpOptions).toPromise();

    p.catch(() => this.router.navigateByUrl('error'));

    return p;
  }

  public SupprimerResponsable(id: number, employeId: number): Promise<ReponseBase<number>> {
    var p = this.http.delete<ReponseBase<number>>(this.supprimerResponsableUrl.replace('{id}', id.toString()).replace('{employeId}', employeId.toString()))
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  public SupprimerChargeProjet(id: number, employeId: number): Promise<ReponseBase<number>> {
    var p = this.http.delete<ReponseBase<number>>(this.supprimerChargeProjetUrl.replace('{id}', id.toString()).replace('{employeId}', employeId.toString()))
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  public Archiver(emplacement: Emplacement): Promise<ReponseBase<boolean>> {
    var p = this.http.post<ReponseBase<boolean>>(this.archiverEmplacementUrl, JSON.stringify(emplacement), this.httpOptions).toPromise();

    p.catch(() => this.router.navigateByUrl('error'));

    p.then(() => this.itemService.ReinitialiserItems());

    return p;
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);

      this.log(`${operation} failed: ${error.message}`);

      this.router.navigateByUrl('erreurtechnique');

      return of(result as T);
    };
  }

  private log(message: string) {
      console.log(message);
  }
}

import { HttpClient, HttpEvent, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from "@angular/core"; import { Location } from "@angular/common";

import { lastValueFrom, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { ReponseBase } from './reponse-base';
import { ContactEntite } from '../entites/contact';
import { ReponseObtenirDocuments } from './reponses/reponse-obtenir-documents';
import { RequeteObtenirDocuments } from './requetes/requete-obtenir-documents';
import { RequeteObtenirImage } from './requetes/requete-obtenir-image';
import { DocumentEntite } from '../entites/document';
import { RequeteAjouterDocuments } from './requetes/requete-ajouter-documents';
import { DocumentLieEntite } from '../entites/document-lie';
import { ETypeLienDocument } from '../entites/enums/type-lien-document';
import { TypeDocumentEntite } from '../entites/type-document';
import { DonneesBaseService } from './donnees-base-service';
import { ProfilService } from './profil-service';
import { ContexteService } from './contexte-service';
import { MessageService } from 'primeng/api';
import { EStatuts } from '../entites/enums/statuts';
import { BaseService } from './base-service';
import { EventSourcePolyfill } from 'event-source-polyfill';
import { AuthHttpInterceptor, AuthService } from '@auth0/auth0-angular';
@Injectable({ providedIn: 'root' })
export class DocumentService extends BaseService {

  private obtenirListeItemUrl = environment.apiBaseUrl + 'document/obteniritem';
  private obtenirListeItemLies = environment.apiBaseUrl + 'document/obtenirlies';
  private obtenirListeRequisitionUrl = environment.apiBaseUrl + 'document/obtenirrequisition';
  private obtenirListeLocationUrl = environment.apiBaseUrl + 'document/obtenirlocation';
  private obtenirListeBonTravailUrl = environment.apiBaseUrl + 'document/obtenirbontravail';
  private obtenirListeNonConformiteUrl = environment.apiBaseUrl + 'document/obtenirnonconformite';
  private obtenirListeItemCommandeUrl = environment.apiBaseUrl + 'document/obteniritemcommande';
  private obtenirApercusUrl = environment.apiBaseUrl + 'document/{type}/apercu?id={id}';
  private ajouterDocumentItemUrl = environment.apiBaseUrl + 'document/creerdocumentsitem';
  private ajouterDocumentLieUrl = environment.apiBaseUrl + 'document/creerdocumentlie';
  private ajouterDocumentsRequisitionUrl = environment.apiBaseUrl + 'document/creerdocumentsrequisition';
  private ajouterDocumentsLocationUrl = environment.apiBaseUrl + 'document/creerdocumentslocation';
  private ajouterDocumentsBonTravailUrl = environment.apiBaseUrl + 'document/creerdocumentsbontravail';
  private ajouterDocumentsNonConformiteUrl = environment.apiBaseUrl + 'document/creerdocumentsnonconformite';
  private ajouterDocumentsItemCommandeUrl = environment.apiBaseUrl + 'document/creerdocumentsprecommande';
  private ajouterDocumentsMessageUrl = environment.apiBaseUrl + 'document/message';
  private modifierDocumentUrl = environment.apiBaseUrl + 'document';
  private supprimerUrl = environment.apiBaseUrl + 'document/{id}';

  public httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(
    public http: HttpClient,
    public router: Router,
    public sdb: DonneesBaseService,
    public profilService: ProfilService,
    public contexteService: ContexteService,
    public messageService: MessageService,
    private auth: AuthService
  ) { 
    super(http, router, messageService);
  }

  public ObtenirListeItem(requete: RequeteObtenirDocuments): Promise<ReponseBase<ReponseObtenirDocuments>> {
    var p = this.http.post<ReponseBase<ReponseObtenirDocuments>>(this.obtenirListeItemUrl, JSON.stringify(requete), this.httpOptions)
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  public ObtenirListeItemLies(requete: RequeteObtenirDocuments): Promise<ReponseBase<DocumentLieEntite[]>> {
    var p = this.http.post<ReponseBase<DocumentLieEntite[]>>(this.obtenirListeItemLies, JSON.stringify(requete), this.httpOptions)
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  public ObtenirListeNonConformite(requete: RequeteObtenirDocuments): Promise<ReponseBase<ReponseObtenirDocuments>> {
    var p = this.http.post<ReponseBase<ReponseObtenirDocuments>>(this.obtenirListeNonConformiteUrl, JSON.stringify(requete), this.httpOptions)
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  public ObtenirListeItemCommande(requete: RequeteObtenirDocuments): Promise<ReponseBase<ReponseObtenirDocuments>> {
      var p = this.http.post<ReponseBase<ReponseObtenirDocuments>>(this.obtenirListeItemCommandeUrl, JSON.stringify(requete), this.httpOptions)
      .toPromise();
  
      p.catch(() => this.router.navigateByUrl('error'));
  
      return p;
  }
  
  public ObtenirListeRequisition(requete: RequeteObtenirDocuments): Promise<ReponseBase<ReponseObtenirDocuments>> {
    var p = this.http.post<ReponseBase<ReponseObtenirDocuments>>(this.obtenirListeRequisitionUrl, JSON.stringify(requete), this.httpOptions)
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }
  
  public ObtenirListeLocation(requete: RequeteObtenirDocuments): Promise<ReponseBase<ReponseObtenirDocuments>> {
    var p = this.http.post<ReponseBase<ReponseObtenirDocuments>>(this.obtenirListeLocationUrl, JSON.stringify(requete), this.httpOptions)
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  public ObtenirListeBonTravail(requete: RequeteObtenirDocuments): Promise<ReponseBase<ReponseObtenirDocuments>> {
    var p = this.http.post<ReponseBase<ReponseObtenirDocuments>>(this.obtenirListeBonTravailUrl, JSON.stringify(requete), this.httpOptions)
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  public ObtenirApercus(type: ETypeLienDocument, ids: number[]): Observable<{id: string, apercu: string}> {
    return new Observable(observer => {
      lastValueFrom(this.auth.getAccessTokenSilently()).then(token => {
        const eventSource = new EventSourcePolyfill(this.obtenirApercusUrl.replace('{type}', type.toString()).replace('{id}', ids.join('&id=')), {
          withCredentials: true,
          headers: {
            'Accept': 'text/event-stream',
            'Authorization': `Bearer ${token}`
          }
        });

        eventSource.onmessage = (event) => {
          if (event.data == 'fin') {
            eventSource.close();
            observer.complete();
          } else {
            observer.next({id: event.lastEventId, apercu: event.data});
          }
        };

        eventSource.onerror = (error) => {
          observer.error(error);
          eventSource.close();
        };

        return () => {
          eventSource.close();
        };
      }).catch(error => {
        observer.error(error);
      });
    });
  }

  public AjouterDocumentsItem(requete: RequeteAjouterDocuments): Promise<HttpResponse<DocumentEntite[]>> {
    var p = this.http.post<DocumentEntite[]>(this.ajouterDocumentItemUrl, JSON.stringify(requete), {headers: new HttpHeaders({ 'Content-Type': 'application/json' }), observe: 'response'});
    return lastValueFrom(p);
  }

  public AjouterDocumentsLie(requete: DocumentLieEntite): Promise<HttpResponse<DocumentLieEntite>> {
    var p = this.http.post<DocumentLieEntite>(this.ajouterDocumentLieUrl, JSON.stringify(requete), {headers: new HttpHeaders({ 'Content-Type': 'application/json' }), observe: 'response'});
    return lastValueFrom(p);
  }

  public AjouterDocumentsNonConformite(requete: RequeteAjouterDocuments): Promise<HttpResponse<DocumentEntite[]>> {
    var p = this.http.post<DocumentEntite[]>(this.ajouterDocumentsNonConformiteUrl, JSON.stringify(requete), {headers: new HttpHeaders({ 'Content-Type': 'application/json' }), observe: 'response'});
    return lastValueFrom(p);
  }

  public AjouterDocumentsItemCommande(requete: RequeteAjouterDocuments): Promise<HttpResponse<DocumentEntite[]>> {
    var p = this.http.post<DocumentEntite[]>(this.ajouterDocumentsItemCommandeUrl, JSON.stringify(requete), {headers: new HttpHeaders({ 'Content-Type': 'application/json' }), observe: 'response'});
    return lastValueFrom(p);
  }

  public AjouterDocumentsRequisition(requete: RequeteAjouterDocuments): Promise<HttpResponse<DocumentEntite[]>> {
    var p = this.http.post<DocumentEntite[]>(this.ajouterDocumentsRequisitionUrl, JSON.stringify(requete), {headers: new HttpHeaders({ 'Content-Type': 'application/json' }), observe: 'response'});
    return lastValueFrom(p);
  }

  public AjouterDocumentsLocation(requete: RequeteAjouterDocuments): Promise<HttpResponse<DocumentEntite[]>> {
    var p = this.http.post<DocumentEntite[]>(this.ajouterDocumentsLocationUrl, JSON.stringify(requete), {headers: new HttpHeaders({ 'Content-Type': 'application/json' }), observe: 'response'});
    return lastValueFrom(p);
  }

  public AjouterDocumentsBonTravail(requete: RequeteAjouterDocuments): Promise<HttpResponse<DocumentEntite[]>> {
    var p = this.http.post<DocumentEntite[]>(this.ajouterDocumentsBonTravailUrl, JSON.stringify(requete), {headers: new HttpHeaders({ 'Content-Type': 'application/json' }), observe: 'response'});
    return lastValueFrom(p);
  }

  public AjouterDocumentsMessage(requete: RequeteAjouterDocuments): Promise<HttpResponse<DocumentEntite[]>> {
    var p = this.http.post<DocumentEntite[]>(this.ajouterDocumentsMessageUrl, JSON.stringify(requete), {headers: new HttpHeaders({ 'Content-Type': 'application/json' }), observe: 'response'});
    return lastValueFrom(p);
  }

  public async AjouterDocuments(typeLien: ETypeLienDocument, idLien: number, date: Date, documents: File[], dossier?: TypeDocumentEntite): Promise<HttpResponse<DocumentEntite[]>> {

    var documentEntites: DocumentEntite[] = [];
    var promises: Promise<boolean>[] = [];

    for (let file of documents) {

        var p = new Promise<boolean>((resolve) => {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (e) => {
                documentEntites.push({
                    contenu: reader.result.toString(),
                    itemId: typeLien == ETypeLienDocument.Item ? idLien : null,
                    itemCommandeId: typeLien == ETypeLienDocument.ItemCommande ? idLien : null,
                    locationId: typeLien == ETypeLienDocument.Location ? idLien : null,
                    bonTravailId: typeLien == ETypeLienDocument.BonTravail ? idLien : null,
                    nonConformiteId: typeLien == ETypeLienDocument.NonConformite ? idLien : null,
                    requisitionId: typeLien == ETypeLienDocument.Requisition ? idLien : null,
                    messageId: typeLien == ETypeLienDocument.Message ? idLien : null,
                    date: date,
                    nomFichier: file.name,
                    typeDocument: dossier,
                    statut: this.sdb.Statut(EStatuts.Actif),
                    employe: this.profilService.ProfilCourant()
                });

                resolve(true);
            }
        });

        promises.push(p);
    }

    await Promise.all(promises);
           
      var requeteAjouterDocument: RequeteAjouterDocuments = {
          typeLien: typeLien,
          idLien: idLien,
          dossier: dossier != null ? dossier.code : null,
          documents: documentEntites
      };

      if (typeLien == ETypeLienDocument.Item) {
        return await this.AjouterDocumentsItem(requeteAjouterDocument);
      } else if (typeLien == ETypeLienDocument.ItemCommande) {
        return await this.AjouterDocumentsItemCommande(requeteAjouterDocument);
      } else if (typeLien == ETypeLienDocument.Requisition) {
        return await this.AjouterDocumentsRequisition(requeteAjouterDocument);
      } else if (typeLien == ETypeLienDocument.Location) {
        return await this.AjouterDocumentsLocation(requeteAjouterDocument);
      } else if (typeLien == ETypeLienDocument.BonTravail) {
        return await this.AjouterDocumentsBonTravail(requeteAjouterDocument);
      } else if (typeLien == ETypeLienDocument.NonConformite) {
        return await this.AjouterDocumentsNonConformite(requeteAjouterDocument);
      } else if (typeLien == ETypeLienDocument.Message) {
        return await this.AjouterDocumentsMessage(requeteAjouterDocument);
      }

      return null;
  }

  public ModifierDocument(requete: DocumentEntite): Promise<ReponseBase<DocumentEntite>> {
    var p = this.http.put<ReponseBase<DocumentEntite>>(this.modifierDocumentUrl, JSON.stringify(requete), this.httpOptions)
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  public SupprimerDocument(id: number): Promise<ReponseBase<number>> {
    var p = this.http.delete<ReponseBase<number>>(this.supprimerUrl.replace('{id}', id.toString()))
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }
  

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);

      this.log(`${operation} failed: ${error.message}`);

      this.router.navigateByUrl('erreurtechnique');

      return of(result as T);
    };
  }

  private log(message: string) {
    console.log(message);
  }
}

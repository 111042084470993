import { Component, OnInit, Input, Optional } from "@angular/core"; import { Location } from "@angular/common";
import { ActivatedRoute } from '@angular/router';
import { MessageService, ConfirmationService, TreeNode } from 'primeng/api';
import { NonConformiteEntite } from '../../entites/non-conformite';
import { NonConformiteService } from '../../services/non-conformite-service';
import { BaseComponent } from '../../base/base-component';
import { DonneesBaseService } from '../../services/donnees-base-service';
import { ProfilService } from '../../services/profil-service';
import { Router } from '@angular/router';
import { AppMainComponent } from '../../app.main.component';
import { BreadcrumbService } from '../../app.breadcrumb.service';
import { EmplacementService } from '../../services/emplacement-service';
import { ViewChild } from "@angular/core";
import { DocumentService } from '../../services/document-service';
import { EStatuts } from '../../entites/enums/statuts';
import { DocumentComponent } from '../documents/document.component';
import { ETypeLienDocument } from '../../entites/enums/type-lien-document';
import { ApprobationComponent } from './approbation-non-conformite.component';
import { DialogService } from 'primeng/dynamicdialog';
import { DocumentEntite } from '../../entites/document';
import { ActionCorrectiveEntite } from '../../entites/action-corrective';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { AjoutActionCorrectiveComponent } from './ajout-action-corrective.component';

@Component({
  selector: 'app-detail-non-conformite',
  templateUrl: './detail-non-conformite.component.html',
  styleUrl: './detail-non-conformite.component.scss',
  providers: [MessageService, ConfirmationService, NonConformiteService, DialogService]
})
export class DetailNonConformiteComponent extends BaseComponent implements OnInit {
  modePopup: boolean = false;
  nonConformite: NonConformiteEntite;
  actionCorrectives: ActionCorrectiveEntite[];
  documents: DocumentEntite[];
  nonConformiteId: number;
  tabIndex: number = 0;
  menu: any[];
  types: any[];
  codeProjets: any[];
  chargementCompleteDocuments: boolean = false;
  chargementCompleteActionCorrective: boolean = false;

  typeDocumentSelectionne: string;

  @ViewChild('tabView') tabView;
  @ViewChild(DocumentComponent) docView: DocumentComponent;

  constructor(
    sdb: DonneesBaseService,
    profilService: ProfilService,
    route: ActivatedRoute,
    appMain: AppMainComponent, 
    private location: Location, 
    private nonConformiteService: NonConformiteService,
    private messageService: MessageService,
    private breadcrumbService: BreadcrumbService,
    public routerService: Router,
    private emplacementService: EmplacementService,
    private documentService: DocumentService,
    private dialogService: DialogService,
    @Optional() private config: DynamicDialogConfig
  ) {
    super(sdb, profilService, route, routerService, appMain);

    if (!this.modePopup) {
      this.breadcrumbService.setItems([
        { label: 'Non-conformités', routerLink: '/assurancequalite/nonconformite' },
        { label: 'Détails' }
      ]);
    }
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params['id']) {
        this.nonConformiteId = params['id'];
      } else {
        this.nonConformiteId = this.config.data;
        this.modePopup = true;
      }

      var promises = [];

      promises.push(this.emplacementService.ObtenirCodeProjets().then(
        (result) => {
            this.codeProjets = result.valeur;
        },
        (error) => {
            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: 'Erreur lors de la récupération du code projet' });
        }
      ));

      promises.push(this.nonConformiteService.ObtenirDetail(this.nonConformiteId).then(
        (data) => {
          this.nonConformite = data.valeur;
        },
        (error) => {
          this.messageService.add({ severity: 'error', summary: 'Erreur', detail: 'Erreur lors du chargement de la non-conformité' });
        }
      ));

      Promise.all(promises).then(() => {
        this.initialiserMenu();

        this.types = [{ id: 'Q', nom: 'Qualité' }, { id: 'S', nom: 'SST' }, { id: 'E', nom: 'Environnement' }];

        if (this.nonConformite.dateEmission) {
          this.nonConformite.dateEmission = new Date(this.nonConformite.dateEmission);
        }
        if (this.nonConformite.dateDetection) {
          this.nonConformite.dateDetection = new Date(this.nonConformite.dateDetection);
        }

        this.CompleterChargement();
      });
    });
  }

  initialiserMenu() {
    this.menu = [
      {
        label: 'Retour', icon: 'pi pi-fw pi-angle-double-left', command: () => this.location.back()
      },
      {
        label: 'Actions', icon: 'pi pi-fw pi-bars',
        items: [
          {
            label: 'Demande d\'action corrective', icon: 'pi pi-fw pi-file-check', command: () => this.actionCorrective(),
            visible: this.nonConformite.statut.statutId != EStatuts.Nouveau && this.nonConformite.employe?.employeId != this.profilService.ProfilCourant().employeId
          },
          {
            label: 'Traiter et approuver', icon: 'pi pi-fw pi-check-circle', command: () => this.traitement(),
            visible: this.nonConformite.statut.statutId == EStatuts.Nouveau
          },
          {separator: true},
          {
            label: 'Archiver', icon: 'pi pi-fw pi-folder-open',
            visible: /*this.estAutorise('nonconformite_action_archiver') &&*/ true,
            command: () => this.archiver()
          }
        ]
      },
    ];
  }

  actionCorrective() {
    this.dialogService.open(AjoutActionCorrectiveComponent, {
      header: 'Ajout d\'action corrective',
      width: '80%',
      data: this.nonConformite.idNonConformite
    }).onClose.subscribe((result) => {
      if (result) {
        this.routerService.navigate(['/assurancequalite/actioncorrective/' + result.actionCorrectiveId]);
      } else {
        this.breadcrumbService.setItems([
          { label: 'Non-conformités', routerLink: '/assurancequalite/nonconformite' },
          { label: 'Détails' }
        ]);
      }
    });
  }

  voirActionCorrective(actionCorrectiveId: number) {
    this.routerService.navigate(['/assurancequalite/actioncorrective/' + actionCorrectiveId]);
  }

  traitement() {

    this.dialogService.open(ApprobationComponent, {
      header: 'Traitement de la Non-Conformité',
      width: '50%',
      contentStyle: { overflow: 'visible' },
      closeOnEscape: true,
      data: { 
        nonConformite: this.nonConformite
      }
    }).onClose.subscribe((result) => {
      if (result) {
        this.nonConformite = result;
      }
    });
  }

  VoirDocumentsTraitement() {
    this.tabIndex = 3;

    this.typeDocumentSelectionne = "traitement_nc";
  }

  retour() {
    this.routerService.navigateByUrl('assurancequalite/nonconformite');
  }

  archiver() {
    this.nonConformiteService.Archiver(this.nonConformiteId).then(
      (result) => {
        this.retour();
      },
      (error) => {
        this.messageService.add({ severity: 'error', summary: 'Erreur', detail: 'Erreur lors de l\'archivage de la non-conformité' });
      }
    );
  }

  marquerComplet() {
    // Implement the logic to mark the non-conformity as complete
  }

  enregistrerModifications() {
    this.nonConformiteService.Modifier(this.nonConformite).then(
      (result) => {
          this.Consulter();
          this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'La non-conformité a été modifiée avec succès' });
      },
      (error) => {
        this.messageService.add({ severity: 'error', summary: 'Erreur', detail: 'Erreur lors de la modification de la non-conformité' });
      }
    );
  }

  chargerDocuments() {
    this.documentService.ObtenirListeNonConformite({id: this.nonConformite.idNonConformite}).then(
      (result) => {
        this.documents = result.valeur.documents;
        this.docView.chargerDocuments(this.documents);
        this.chargementCompleteDocuments = true;   
      },
      (error) => {
        this.messageService.add({ severity: 'error', summary: 'Erreur', detail: 'Erreur lors de la récupération des documents' });
      }
    );
  }

  chargerActionCorrective() {
    this.nonConformiteService.ObtenirActionCorrectivesParNonConformite(this.nonConformite.idNonConformite).then(
      (result) => {
        this.actionCorrectives = result.valeur;
        this.chargementCompleteActionCorrective = true;
      },
      (error) => {
        this.messageService.add({ severity: 'error', summary: 'Erreur', detail: 'Erreur lors de la récupération des actions correctives' });
      }
    );
  }

  surChangementOnglet(event) {
    this.tabIndex = event.index;
    if ((this.tabView.tabs[this.tabIndex]._header.startsWith('Photos') || this.tabView.tabs[this.tabIndex]._header.startsWith('Documents')) && !this.chargementCompleteDocuments) {
      this.chargerDocuments();
    }
    if (this.tabView.tabs[this.tabIndex]._header.startsWith('Suivi et actions correctives') && !this.chargementCompleteActionCorrective) {
      this.chargerActionCorrective();
    }
  }

  public get ETypeLienDocument(): typeof ETypeLienDocument {
    return ETypeLienDocument; 
  }

  public get EStatuts(): typeof EStatuts {
    return EStatuts; 
  }
}
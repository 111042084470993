import { HttpParams } from "@angular/common/http";
import { LazyLoadEvent } from "primeng/api";

export class ServiceBase {

  constructor() { }

  public GenerateFilterParams(event: LazyLoadEvent): HttpParams {
    let params = new HttpParams();

    params = params.set('first', event.first.toString());
    params = params.set('rows', event.rows.toString());

    if (event.sortField && event.sortOrder) {
      params = params.set('sortField', event.sortField);
      params = params.set('sortOrder', event.sortOrder.toString());
    }

    if (event.globalFilter) {
      params = params.set('globalFilter', event.globalFilter.toString());
    }

    if (event.filters) {
      var filtres = JSON.parse(JSON.stringify(event.filters));
      filtres.global = undefined;
      params = params.set('filters', JSON.stringify(filtres));
    }

    return params;
  }

}


